import * as React from 'react';
import { useQuerySentEmailsConnection } from './use-query-sent-emails-connection';
import {
    ConnectionTableDef,
    DefaultConnectionTable,
    Display,
    DisplayEntity,
    MenuBarHeaderItem,
    MenuBarSection,
    PageLayout,
    StickyMenuBar,
    VStack,
} from '@ez/components';
import { NodeType } from '@poolware/api';
import * as _ from 'lodash';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { SuggestInputFranchise, SuggestInputStaff } from '@poolware/app-shell';
import { useState } from 'react';

export interface PageProps {}

export const PageList: React.FC<PageProps> = () => {
    const [staff, setStaff] = useState<NodeType.Staff>();
    const [franchise, setFranchise] = useState<NodeType.Franchise>();
    const { connectionData, connectionState } = useQuerySentEmailsConnection({
        search: {
            submittedBy: {
                id: staff?.id,
                franchise: { id: franchise?.id },
            },
        },
    });
    const { AppNavigator } = useAppNavigator();

    const onView = (email) => {
        AppNavigator.navigateRelative(`/${email.id}`);
    };

    const tableDef: ConnectionTableDef<(typeof connectionData)[0]> = [
        {
            header: 'Date',
            sortKey: 'createdAt',
            cellProps: { width: '3' },
            cell: (r) => <Display.Date format={'lll'} value={r.createdAt} />,
        },
        {
            header: 'type',
            cell: (r) => r.type?.name || '--',
        },
        {
            header: 'subject',
            cell: (r) => _.truncate(r.subject, { length: 60 }),
        },
        {
            header: 'Recipient',
            cell: (r) => r.recipientEmail,
        },
        {
            header: 'Submitted By',
            cell: (r) => {
                return (
                    <span>
                        <DisplayEntity value={_.get(r, 'submittedBy.user')} />
                    </span>
                );
            },
        },
        {
            header: 'Franchise',
            cell: (r) => {
                return <span>{_.get(r, 'submittedBy.franchise.name')}</span>;
            },
        },
    ];

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem icon={'mail'}>Emails log</MenuBarHeaderItem>
                </MenuBarSection>
            </StickyMenuBar>

            <div className={'flex flex-row gap-1'}>
                <SuggestInputStaff label={'Submitted By'} value={staff} onChange={setStaff} />
                <SuggestInputFranchise label={'Franchise'} value={franchise} onChange={setFranchise} />
            </div>

            <DefaultConnectionTable
                debug={false}
                onRowClick={onView}
                tableDef={tableDef}
                connectionData={connectionData}
                connectionState={connectionState}
            />
        </PageLayout>
    );
};
