import * as React from 'react';
import { ConnectionTableDef, DefaultConnectionTable, MenuBar, StickyMenuBar, VStack } from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { ConexpItemType } from './types';
import { useConexpAvailableConnections } from './hooks';

export const PageList: React.FC = () => {
    const { AppNavigator } = useAppNavigator();

    const availableConnections = useConexpAvailableConnections();

    const tableDef: ConnectionTableDef<ConexpItemType> = [
        {
            header: 'Node',
            cell: (r) => {
                return <>{r.nodeTypeName}</>;
            },
        },
        {
            header: 'Connection Type Name',
            cell: (r) => {
                return <>{r.connectionTypeName}</>;
            },
        },
        {
            header: 'connectionId',
            cell: (r) => {
                return <>{r.connectionId}</>;
            },
        },
    ];

    const onView = (item: ConexpItemType) => {
        AppNavigator.navigateRelative(`/${item.connectionId}`, { setOrigin: true });
    };

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBar.Section>
                    <MenuBar.HeaderItem icon={'list'}>Data Explorer</MenuBar.HeaderItem>
                </MenuBar.Section>
            </StickyMenuBar>
            <DefaultConnectionTable tableDef={tableDef} connectionData={availableConnections} onRowClick={onView} />
        </VStack>
    );
};
