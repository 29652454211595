import * as React from 'react';
import { FormikDefaultForm, FormikInputField, FormikTextareaField } from '@ez/components';
import { PageControl } from './Page.Control';
import { ProjectsURLBuilder } from '../index';
import * as semver from 'semver';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    versionNumber: Yup.string()
        .required('Required')
        // @ts-ignore
        .test('semver', 'Must be in semver format, e.g. 1.3.0', function (value) {
            return semver.valid(value);
        }),
    remoteFileURL: Yup.string().url('Must be a valid URL').required('Required').nullable(),
    changelogSummary: Yup.string().required('Required'),
    description: Yup.string().notRequired(),
});

class Page extends React.Component<PageControl> {
    onSubmit = async (values) => {
        const { projectId } = this.props;
        const { versionNumber, changelogSummary, description, remoteFileURL } = values;
        const res = await this.props.ProjectMutator.createVersion(projectId, {
            versionNumber: versionNumber?.trim(),
            changelogSummary: changelogSummary?.trim(),
            description: description?.trim(),
            remoteFileURL: remoteFileURL?.trim(),
        });
        const versionId = res?.data?.ProjectVersion?.ProjectVersion?.id;
        this.props.AppNavigator.replace(ProjectsURLBuilder.ViewVersion(projectId, versionId), {
            relativeToModule: true,
        });
    };

    onCancel = () => {
        this.props.AppNavigator.navigateToOrigin();
    };

    render() {
        const initialValues = {
            versionNumber: '',
            remoteFileURL: '',
            changelogSummary: '',
            description: '',
        };
        return (
            <FormikDefaultForm
                header={'New App Version'}
                initialValues={initialValues}
                submitButton={{ content: 'Create' }}
                onSubmit={this.onSubmit}
                onCancel={this.onCancel}
                validationSchema={validationSchema}
                debug={true}
            >
                <FormikInputField
                    label={'Version Number'}
                    name={'versionNumber'}
                    placeholder={'e.g. 1.2.3'}
                    required={true}
                />
                <FormikInputField label={'File URL'} name={'remoteFileURL'} required={true} />
                <FormikTextareaField label={'Changelog'} name={'changelogSummary'} required={true} />
                <FormikTextareaField label={'Description'} name={'description'} />
            </FormikDefaultForm>
        );
    }
}

export default Page;
