import { compose, withProps } from '@ez/tools';
import {
    IMutateFranchise,
    IMutateRole,
    IMutateRoleFlag,
    NodeType,
    withFranchiseMutator,
    withRoleFlagMutator,
    withRoleMutator,
} from './api-types';
import _trim from 'lodash/trim';

export interface RoleFlagMutatorInjected extends IMutateRole, IMutateRoleFlag, IMutateFranchise {}

export interface RoleFlagMutator {
    createFlag: (tag: string, description: string) => Promise<any>;
    updateFlag: (flag: NodeType.NodeOrId<NodeType.RoleFlag>, tag: string, description: string) => Promise<any>;
    deleteFlag: (flag: NodeType.NodeOrId<NodeType.RoleFlag>) => Promise<any>;
    grantFlagToRole: (
        role: NodeType.NodeOrId<NodeType.Role>,
        flag: NodeType.NodeOrId<NodeType.RoleFlag>
    ) => Promise<any>;
    removeFlagFromRole: (
        role: NodeType.NodeOrId<NodeType.Role>,
        flag: NodeType.NodeOrId<NodeType.RoleFlag>
    ) => Promise<any>;
    grantFlagToFranchise: (
        franchise: NodeType.NodeOrId<NodeType.Franchise>,
        flag: NodeType.NodeOrId<NodeType.RoleFlag>
    ) => Promise<any>;
    removeFlagFromFranchise: (
        franchise: NodeType.NodeOrId<NodeType.Franchise>,
        flag: NodeType.NodeOrId<NodeType.RoleFlag>
    ) => Promise<any>;
}

export interface RoleFlagMutatorAdded {
    RoleFlagMutator: RoleFlagMutator;
}

export interface RoleFlagMutatorProps extends RoleFlagMutatorInjected, RoleFlagMutatorAdded {}

const createMutator = ({
    mutateRole,
    mutateRoleFlag,
    mutateFranchise,
}: RoleFlagMutatorInjected): RoleFlagMutatorAdded => {
    const grantFlagToRole = async (
        role: NodeType.NodeOrId<NodeType.Role>,
        flag: NodeType.NodeOrId<NodeType.RoleFlag>
    ) => {
        let mutationConfig = {
            id: NodeType.extractId(role),
            flags: {
                assign: [NodeType.extractId(flag)],
            },
        };
        await mutateRole.update(mutationConfig);
    };
    const removeFlagFromRole = async (
        role: NodeType.NodeOrId<NodeType.Role>,
        flag: NodeType.NodeOrId<NodeType.RoleFlag>
    ) => {
        let mutationConfig = {
            id: NodeType.extractId(role),
            flags: {
                delete: [NodeType.extractId(flag)],
            },
        };
        await mutateRole.update(mutationConfig);
    };

    const grantFlagToFranchise = async (
        franchise: NodeType.NodeOrId<NodeType.Franchise>,
        flag: NodeType.NodeOrId<NodeType.RoleFlag>
    ) => {
        let mutationConfig = {
            id: NodeType.extractId(franchise),
            flags: {
                assign: [NodeType.extractId(flag)],
            },
        };
        await mutateFranchise.update(mutationConfig);
    };

    const removeFlagFromFranchise = async (
        franchise: NodeType.NodeOrId<NodeType.Franchise>,
        flag: NodeType.NodeOrId<NodeType.RoleFlag>
    ) => {
        let mutationConfig = {
            id: NodeType.extractId(franchise),
            flags: {
                delete: [NodeType.extractId(flag)],
            },
        };
        await mutateFranchise.update(mutationConfig);
    };

    const createFlag = async (tag: string, description: string) => {
        let mutationConfig = {
            tag: _trim(tag),
            description: _trim(description),
        };
        await mutateRoleFlag.create(mutationConfig);
    };

    const updateFlag = async (flag: NodeType.NodeOrId<NodeType.RoleFlag>, tag: string, description: string) => {
        let mutationConfig = {
            id: NodeType.extractId(flag),
            tag: _trim(tag),
            description: _trim(description),
        };
        await mutateRoleFlag.update(mutationConfig);
    };

    const deleteFlag = async (flag: NodeType.NodeOrId<NodeType.RoleFlag>) => {
        let mutationConfig = {
            id: NodeType.extractId(flag),
        };
        await mutateRoleFlag.delete(mutationConfig);
    };

    return {
        RoleFlagMutator: {
            grantFlagToRole,
            removeFlagFromRole,
            grantFlagToFranchise,
            removeFlagFromFranchise,
            createFlag,
            updateFlag,
            deleteFlag,
        },
    };
};

export const withRoleFlagMutators = (refetchQueries) =>
    compose(
        withRoleMutator(refetchQueries),
        withRoleFlagMutator(refetchQueries),
        withFranchiseMutator(refetchQueries),
        withProps(createMutator)
    );
