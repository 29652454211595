import { createUseQueryNodeHook, NodeType } from '@poolware/api';
import gql from 'graphql-tag';

export const queryFranchiseNodeQL = gql`
    fragment FranchiseNode on Franchise {
        id
        name
        name
        email
        organisationType {
            id
            name
        }
        types
        staffAccessTokens(search: { staff: { isSystemHidden: {} } }) {
            edges {
                node {
                    id
                    token
                    staff {
                        id
                        user {
                            id
                            firstName
                            lastName
                        }
                        isSystemHidden
                        role {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;

export const queryFranchisesConnectionQL = gql`
    query QueryFranchisesConnection(
        $page: Int
        $sort: FranchiseViewerFranchisesSort
        $search: FranchiseViewerFranchisesSearch
    ) {
        viewer {
            franchises(first: 25, page: $page, search: $search, sort: $sort) {
                edges {
                    node {
                        ...FranchiseNode
                    }
                }
                pageMeta {
                    pageCount
                    totalCount
                }
            }
        }
    }
    ${queryFranchiseNodeQL}
`;

const QL = gql`
    query QueryFranchise($id: ID!) {
        node(id: $id) {
            ...FranchiseNode
        }
    }
    ${queryFranchiseNodeQL}
`;
export const useQueryFranchise = createUseQueryNodeHook<NodeType.Franchise>(QL);

export const mutateStaffAccessTokenRegistrationQuery = gql`
    mutation StaffAccessTokenRegistrationMutation($input: StaffAccessTokenRegistrationMutationInput!) {
        StaffAccessTokenRegistration(input: $input) {
            StaffAccessTokenRegistration {
                __typename
            }
            results {
                StaffAccessTokenRegistration {
                    __typename
                }
            }
        }
    }
`;
