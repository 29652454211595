import * as React from 'react';
import {
    Alert,
    ConnectionTableDef,
    DefaultConnectionTable,
    Display,
    MenuBarHeaderItem,
    MenuBarSection,
    PageLayout,
    StickyMenuBar,
    VStack,
} from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { NodeType } from '@poolware/api';
import { QueryProductConnection, tableDefColumn_ProductDeclarationsList } from '@poolware/app-catalog';

export interface PageProps {}

const Page: React.FC<PageProps> = () => {
    const { AppNavigator } = useAppNavigator();

    const tableDef: ConnectionTableDef<NodeType.Product> = [
        {
            header: 'SKU',
            cell: (p) => p.sku,
        },
        {
            header: 'Name',
            cell: (p) => p.name,
            sortKey: 'name',
        },
        {
            header: 'Brand',
            cell: (p) => p.brand?.name || '-',
        },
        {
            header: 'Franchise',
            cell: (p) => p.franchise?.name || '-',
        },
        tableDefColumn_ProductDeclarationsList({ header: 'Traits' }),
        {
            header: 'Created',
            sortKey: 'createdAt',
            cell: (p) => {
                return <Display.Date value={p.createdAt} />;
            },
        },
    ];

    const onRowClick = (p) => {
        AppNavigator.navigateRelative(`/${p.id}`);
    };

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem icon={'boxes'}>One-off products</MenuBarHeaderItem>
                </MenuBarSection>
                <MenuBarSection position={'right'} />
            </StickyMenuBar>

            <QueryProductConnection isOneOff={true}>
                {({ connectionState, connectionData }) => {
                    return (
                        <DefaultConnectionTable
                            onRowClick={onRowClick}
                            tableDef={tableDef}
                            connectionData={connectionData}
                            connectionState={connectionState}
                        />
                    );
                }}
            </QueryProductConnection>
            <Alert>List of one-off products created by franchisees.</Alert>
        </PageLayout>
    );
};

export default Page;
