import * as React from 'react';
import { SegmentedSelectButtons } from '@ez/components';
import { useOrgSwitcher } from '@poolware/app-shell';

const ALL_ORGS = '__ALL_ORGS_KEY__';

export const SegmentedSelectOrgSwitcher: React.FC = () => {
    const { organisation, setOrg, orgs } = useOrgSwitcher();

    const orgOptions = orgs.map((o) => ({
        text: o.name,
        value: o?.id,
    }));

    const options = [
        {
            text: 'All',
            value: ALL_ORGS,
        },
        ...orgOptions,
    ];

    const onChangeOrg = (option) => {
        setOrg(option?.value === ALL_ORGS ? null : option?.value);
    };

    return (
        <>
            <SegmentedSelectButtons options={options} onChange={onChangeOrg} value={organisation?.id || ALL_ORGS} />
            {/*<DebugJSON data={{ organisation }} />*/}
        </>
    );
};
