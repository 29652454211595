import { Icon } from '@ez/components';
import * as React from 'react';

export const IconFeatureStatus: React.FC<{ active?: boolean }> = ({ active }) => {
    if (active) {
        return <Icon name={'circle'} color={'blue'} />;
    } else {
        return <Icon name={'pause'} />;
    }
};
