import { SortDirection, useDefaultConnectionState, useQueryConnection } from '@ez/api-core';
import { NodeType } from '@poolware/api';
import gql from 'graphql-tag';

const QueryPoolDeclarationFlagFieldsConnection = gql`
    query QueryPoolDeclarationFlagFieldsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: PoolDeclarationFlagFieldViewerPoolDeclarationFlagFieldsSearch
        $sort: PoolDeclarationFlagFieldViewerPoolDeclarationFlagFieldsSort
    ) {
        viewer {
            poolDeclarationFlagFields(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                edges {
                    node {
                        id
                        name
                        priority
                        tag
                        useInExport
                        activatedAt
                        createdAt
                        description
                        exportName
                        organisation {
                            id
                            name
                        }
                    }
                }
                pageInfo {
                    startCursor
                    hasPreviousPage
                    hasNextPage
                    endCursor
                }
            }
        }
    }
`;
export const useQueryPoolDeclarationFlagFieldConnection = () => {
    const connectionPath = 'viewer.poolDeclarationFlagFields';
    const { variables, connectionState } = useDefaultConnectionState({
        defaultPageSize: 20,
        defaultSortDirection: SortDirection.ASC,
        defaultSortKey: 'priority',
        // search: mapPropsToSearchQuery(props),
    });

    return useQueryConnection<NodeType.FeatureFlag>({
        query: QueryPoolDeclarationFlagFieldsConnection,
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
};
