import * as React from 'react';
import { Router as ConexpRouter, ConexpProvider, ConexpProviderContextType } from '@ez/conexp-viewer';
import {
    availableConexpHooks,
    getAvailableConnectionItems,
    getConexpItemById,
    getConnectionUIConfigById,
} from './conexp-generated';

const conexpCtx: ConexpProviderContextType = {
    getConexpItems: getAvailableConnectionItems, //
    availableConexpHooks,
    getConexpItemById,
    getConnectionUIConfigById,
};

export interface IndexProps {}

export const Router: React.FC<IndexProps> = ({}) => {
    return (
        <ConexpProvider conexp={conexpCtx}>
            <ConexpRouter />
        </ConexpProvider>
    );
};
