import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { unregister } from './registerServiceWorker';
import '@ez/components/css/semantic.min.css';
import './styles/index.scss';
import '@poolware/react-datepicker/dist/react-datepicker.min.css';

import App from './App';
import { AppRootProvider, OrgSwitcherProvider } from '@poolware/app-shell';
import { store } from './redux/configureStore';

const basename = process.env.REACT_APP_ROUTER_BASENAME || undefined;

ReactDOM.render(
    <AppRootProvider router={{ basename }} reduxStore={store}>
        <OrgSwitcherProvider>
            <App />
        </OrgSwitcherProvider>
    </AppRootProvider>,
    document.getElementById('root')
);

unregister();
