import * as React from 'react';
import { ModuleNavLink, ModuleRedirect, ModuleRoot, ModuleRoute, ModuleSwitch } from '@poolware/react-app-navigator';
import { Router as OfficesRouter } from './Warranter/Router';
import { NotFoundPage } from '@poolware/app-shell';
import { WarrantersType } from './WarrantersType';
import { MenuBar } from '@ez/components';

const SectionsNavbar = () => (
    <div className={'p-2'}>
        <MenuBar.Root>
            <MenuBar.Section position={'left'}>
                <ModuleNavLink to={'/offices'}>Offices</ModuleNavLink>
                <ModuleNavLink to={'/franchises'}>Franchises</ModuleNavLink>
            </MenuBar.Section>
        </MenuBar.Root>
    </div>
);

export const Router: React.FC = () => {
    return (
        <ModuleRoot>
            <ModuleRoute path={'/offices'} exact={true} component={SectionsNavbar} />
            <ModuleRoute path={'/franchises'} exact={true} component={SectionsNavbar} />
            <ModuleSwitch notFound={NotFoundPage}>
                <ModuleRedirect path={'/'} exact to={'/offices'} />
                <ModuleRoute path={'/offices'} component={OfficesRouter} />
                <ModuleRoute path={'/franchises'} component={WarrantersType} />
            </ModuleSwitch>
        </ModuleRoot>
    );
};
