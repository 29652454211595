import {
    Button,
    ConnectionTableDef,
    DefaultConnectionTable,
    FormFieldInputDebounced,
    Modal,
    useModalCtrl,
    VStack,
} from '@ez/components';
import * as React from 'react';
import { useState } from 'react';
import { fromEdges, NodeType } from '@poolware/api';
import FranchisePage from './Franchise.Page';
import { useQueryFranchisesConnection } from './use-query-franchises-connection';

export interface FranchiseListProps {}

export const FranchiseList: React.FC<FranchiseListProps> = ({}) => {
    const [filterName, setFilterName] = useState('');
    const { connectionState, connectionData } = useQueryFranchisesConnection({ name: filterName });
    const [franchise, setFranchise] = useState<NodeType.Franchise>(null);

    const modalCtrl = useModalCtrl();

    const onEdit = (franchise) => {
        setFranchise(franchise);
        modalCtrl.onOpen();
    };

    const tableDef: ConnectionTableDef<NodeType.Franchise> = [
        {
            header: 'Name',
            sortKey: 'name',
            cell: (item) => {
                return <>{item.name}</>;
            },
        },
        {
            header: 'Flags',
            cell: (item) => {
                const flags = fromEdges(item?.flags);
                return flags.map((flag, i) => {
                    return (
                        <div className={'p-1 rounded gb-gray-200'} key={i}>
                            {flag.tag}
                        </div>
                    );
                });
            },
        },
        {
            header: 'Types',
            cell: (item) => {
                return item.types?.join(', ');
            },
        },
        {
            header: 'Org',
            cell: (item) => {
                return item?.organisationType?.name;
            },
        },
        {
            header: '',
            cell: (item) => {
                return <Button size={'mini'} onClick={() => onEdit(item)} icon={'edit'} />;
            },
        },
    ];

    return (
        <VStack>
            <h3>Franchises added flags</h3>
            <FormFieldInputDebounced
                variant={'search'}
                label={'Franchise Name'}
                value={filterName}
                placeholder={'Search by name...'}
                onChange={setFilterName}
                className={'max-w-96'}
            />
            <div className={'text-sm text-gray-500'}>
                <p>This page allows adding extra UI flags to franchises in addition to the system global flags.</p>
                <p>Added flags will be applied to all staff members of a franchise.</p>
            </div>

            <DefaultConnectionTable
                onRowClick={onEdit}
                tableDef={tableDef}
                connectionData={connectionData}
                connectionState={connectionState}
            />

            {franchise && (
                <Modal {...modalCtrl} closeIcon={true}>
                    <Modal.Header>{franchise?.name}</Modal.Header>
                    <Modal.Content>
                        <FranchisePage franchiseId={franchise.id} />
                    </Modal.Content>
                </Modal>
            )}
        </VStack>
    );
};
