//
// DO NOT EDIT!!!
// THIS IS AN AUTOMATICALLY GENERATED FILE.
// Date:  Tue Aug 13 2024 16:10:05 GMT+1000 (Australian Eastern Standard Time)
//

// range 50-1000

import { createUseQueryConnectionHook, createUseQueryNodeHook, NodeType } from '@poolware/api';
import { getConexpItemById } from './connection-queries';

// products: hook

export const useQueryViewerProductsConnection = createUseQueryConnectionHook<
    NodeType.Product,
    NodeType.ProductViewerProductsSearch
>({
    connectionPath: 'viewer.products',
    query: getConexpItemById('products')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryProductNode = createUseQueryNodeHook<NodeType.Product>(
    getConexpItemById('products')?.gqlNodeQuery
);

// projectHosting: hook

export const useQueryViewerProjectHostingConnection = createUseQueryConnectionHook<
    NodeType.Project,
    NodeType.ProjectViewerProjectHostingSearch
>({
    connectionPath: 'viewer.projectHosting',
    query: getConexpItemById('projectHosting')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryProjectNode = createUseQueryNodeHook<NodeType.Project>(
    getConexpItemById('projectHosting')?.gqlNodeQuery
);

// reports: hook

export const useQueryViewerReportsConnection = createUseQueryConnectionHook<
    NodeType.TestReport,
    NodeType.TestReportViewerReportsSearch
>({
    connectionPath: 'viewer.reports',
    query: getConexpItemById('reports')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryTestReportNode = createUseQueryNodeHook<NodeType.TestReport>(
    getConexpItemById('reports')?.gqlNodeQuery
);

// roleFlags: hook

export const useQueryViewerRoleFlagsConnection = createUseQueryConnectionHook<
    NodeType.RoleFlag,
    NodeType.RoleFlagViewerRoleFlagsSearch
>({
    connectionPath: 'viewer.roleFlags',
    query: getConexpItemById('roleFlags')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryRoleFlagNode = createUseQueryNodeHook<NodeType.RoleFlag>(
    getConexpItemById('roleFlags')?.gqlNodeQuery
);

// roles: hook

export const useQueryViewerRolesConnection = createUseQueryConnectionHook<
    NodeType.Role,
    NodeType.RoleViewerRolesSearch
>({
    connectionPath: 'viewer.roles',
    query: getConexpItemById('roles')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryRoleNode = createUseQueryNodeHook<NodeType.Role>(getConexpItemById('roles')?.gqlNodeQuery);

// sampleSets: hook

export const useQueryViewerSampleSetsConnection = createUseQueryConnectionHook<
    NodeType.SampleSet,
    NodeType.SampleSetViewerSampleSetsSearch
>({
    connectionPath: 'viewer.sampleSets',
    query: getConexpItemById('sampleSets')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQuerySampleSetNode = createUseQueryNodeHook<NodeType.SampleSet>(
    getConexpItemById('sampleSets')?.gqlNodeQuery
);

// sampleSources: hook

export const useQueryViewerSampleSourcesConnection = createUseQueryConnectionHook<
    NodeType.SampleSource,
    NodeType.SampleSourceViewerSampleSourcesSearch
>({
    connectionPath: 'viewer.sampleSources',
    query: getConexpItemById('sampleSources')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQuerySampleSourceNode = createUseQueryNodeHook<NodeType.SampleSource>(
    getConexpItemById('sampleSources')?.gqlNodeQuery
);

// sentEmails: hook

export const useQueryViewerSentEmailsConnection = createUseQueryConnectionHook<
    NodeType.EmailMessage,
    NodeType.EmailMessageViewerSentEmailsSearch
>({
    connectionPath: 'viewer.sentEmails',
    query: getConexpItemById('sentEmails')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryEmailMessageNode = createUseQueryNodeHook<NodeType.EmailMessage>(
    getConexpItemById('sentEmails')?.gqlNodeQuery
);

// serviceJobGroups: hook

export const useQueryViewerServiceJobGroupsConnection = createUseQueryConnectionHook<
    NodeType.ServiceJobGroup,
    NodeType.ServiceJobGroupViewerServiceJobGroupsSearch
>({
    connectionPath: 'viewer.serviceJobGroups',
    query: getConexpItemById('serviceJobGroups')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryServiceJobGroupNode = createUseQueryNodeHook<NodeType.ServiceJobGroup>(
    getConexpItemById('serviceJobGroups')?.gqlNodeQuery
);

// serviceJobStages: hook

export const useQueryViewerServiceJobStagesConnection = createUseQueryConnectionHook<
    NodeType.ServiceJobStage,
    NodeType.ServiceJobStageViewerServiceJobStagesSearch
>({
    connectionPath: 'viewer.serviceJobStages',
    query: getConexpItemById('serviceJobStages')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryServiceJobStageNode = createUseQueryNodeHook<NodeType.ServiceJobStage>(
    getConexpItemById('serviceJobStages')?.gqlNodeQuery
);

// serviceJobTemplates: hook

export const useQueryViewerServiceJobTemplatesConnection = createUseQueryConnectionHook<
    NodeType.ServiceJobTemplate,
    NodeType.ServiceJobTemplateViewerServiceJobTemplatesSearch
>({
    connectionPath: 'viewer.serviceJobTemplates',
    query: getConexpItemById('serviceJobTemplates')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryServiceJobTemplateNode = createUseQueryNodeHook<NodeType.ServiceJobTemplate>(
    getConexpItemById('serviceJobTemplates')?.gqlNodeQuery
);

// serviceJobs: hook

export const useQueryViewerServiceJobsConnection = createUseQueryConnectionHook<
    NodeType.ServiceJob,
    NodeType.ServiceJobViewerServiceJobsSearch
>({
    connectionPath: 'viewer.serviceJobs',
    query: getConexpItemById('serviceJobs')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryServiceJobNode = createUseQueryNodeHook<NodeType.ServiceJob>(
    getConexpItemById('serviceJobs')?.gqlNodeQuery
);

// settingStore: hook

export const useQueryViewerSettingStoreConnection = createUseQueryConnectionHook<
    NodeType.SettingStore,
    NodeType.SettingStoreViewerSettingStoreSearch
>({
    connectionPath: 'viewer.settingStore',
    query: getConexpItemById('settingStore')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQuerySettingStoreNode = createUseQueryNodeHook<NodeType.SettingStore>(
    getConexpItemById('settingStore')?.gqlNodeQuery
);

// shops: hook

export const useQueryViewerShopsConnection = createUseQueryConnectionHook<
    NodeType.FranchiseShop,
    NodeType.FranchiseShopViewerShopsSearch
>({
    connectionPath: 'viewer.shops',
    query: getConexpItemById('shops')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryFranchiseShopNode = createUseQueryNodeHook<NodeType.FranchiseShop>(
    getConexpItemById('shops')?.gqlNodeQuery
);

// sites: hook

export const useQueryViewerSitesConnection = createUseQueryConnectionHook<
    NodeType.Site,
    NodeType.SiteViewerSitesSearch
>({
    connectionPath: 'viewer.sites',
    query: getConexpItemById('sites')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQuerySiteNode = createUseQueryNodeHook<NodeType.Site>(getConexpItemById('sites')?.gqlNodeQuery);

// staff: hook

export const useQueryViewerStaffConnection = createUseQueryConnectionHook<
    NodeType.Staff,
    NodeType.StaffViewerStaffSearch
>({
    connectionPath: 'viewer.staff',
    query: getConexpItemById('staff')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryStaffNode = createUseQueryNodeHook<NodeType.Staff>(getConexpItemById('staff')?.gqlNodeQuery);

// staffTeams: hook

export const useQueryViewerStaffTeamsConnection = createUseQueryConnectionHook<
    NodeType.StaffTeam,
    NodeType.StaffTeamViewerStaffTeamsSearch
>({
    connectionPath: 'viewer.staffTeams',
    query: getConexpItemById('staffTeams')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryStaffTeamNode = createUseQueryNodeHook<NodeType.StaffTeam>(
    getConexpItemById('staffTeams')?.gqlNodeQuery
);

// staffValuesStore: hook

export const useQueryViewerStaffValuesStoreConnection = createUseQueryConnectionHook<
    NodeType.StaffValueStore,
    NodeType.StaffValueStoreViewerStaffValuesStoreSearch
>({
    connectionPath: 'viewer.staffValuesStore',
    query: getConexpItemById('staffValuesStore')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryStaffValueStoreNode = createUseQueryNodeHook<NodeType.StaffValueStore>(
    getConexpItemById('staffValuesStore')?.gqlNodeQuery
);

// testCriteria: hook

export const useQueryViewerTestCriteriaConnection = createUseQueryConnectionHook<
    NodeType.TestCriteria,
    NodeType.TestCriteriaViewerTestCriteriaSearch
>({
    connectionPath: 'viewer.testCriteria',
    query: getConexpItemById('testCriteria')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryTestCriteriaNode = createUseQueryNodeHook<NodeType.TestCriteria>(
    getConexpItemById('testCriteria')?.gqlNodeQuery
);

// testProblems: hook

export const useQueryViewerTestProblemsConnection = createUseQueryConnectionHook<
    NodeType.TestProblem,
    NodeType.TestProblemViewerTestProblemsSearch
>({
    connectionPath: 'viewer.testProblems',
    query: getConexpItemById('testProblems')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryTestProblemNode = createUseQueryNodeHook<NodeType.TestProblem>(
    getConexpItemById('testProblems')?.gqlNodeQuery
);

// testTreatmentGroupForFranchises: hook

export const useQueryViewerTestTreatmentGroupForFranchisesConnection = createUseQueryConnectionHook<
    NodeType.TestTreatmentFranchiseGroup,
    NodeType.TestTreatmentFranchiseGroupViewerTestTreatmentGroupForFranchisesSearch
>({
    connectionPath: 'viewer.testTreatmentGroupForFranchises',
    query: getConexpItemById('testTreatmentGroupForFranchises')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryTestTreatmentFranchiseGroupNode = createUseQueryNodeHook<NodeType.TestTreatmentFranchiseGroup>(
    getConexpItemById('testTreatmentGroupForFranchises')?.gqlNodeQuery
);

// testTreatmentGroups: hook

export const useQueryViewerTestTreatmentGroupsConnection = createUseQueryConnectionHook<
    NodeType.TestTreatmentGroup,
    NodeType.TestTreatmentGroupViewerTestTreatmentGroupsSearch
>({
    connectionPath: 'viewer.testTreatmentGroups',
    query: getConexpItemById('testTreatmentGroups')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryTestTreatmentGroupNode = createUseQueryNodeHook<NodeType.TestTreatmentGroup>(
    getConexpItemById('testTreatmentGroups')?.gqlNodeQuery
);

// testTreatments: hook

export const useQueryViewerTestTreatmentsConnection = createUseQueryConnectionHook<
    NodeType.TestTreatment,
    NodeType.TestTreatmentViewerTestTreatmentsSearch
>({
    connectionPath: 'viewer.testTreatments',
    query: getConexpItemById('testTreatments')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryTestTreatmentNode = createUseQueryNodeHook<NodeType.TestTreatment>(
    getConexpItemById('testTreatments')?.gqlNodeQuery
);

// timezones: hook

export const useQueryViewerTimezonesConnection = createUseQueryConnectionHook<NodeType.Timezone>({
    connectionPath: 'viewer.timezones',
    query: getConexpItemById('timezones')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryTimezoneNode = createUseQueryNodeHook<NodeType.Timezone>(
    getConexpItemById('timezones')?.gqlNodeQuery
);

// unitConversions: hook

export const useQueryViewerUnitConversionsConnection = createUseQueryConnectionHook<
    NodeType.UnitConversion,
    NodeType.UnitConversionViewerUnitConversionsSearch
>({
    connectionPath: 'viewer.unitConversions',
    query: getConexpItemById('unitConversions')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryUnitConversionNode = createUseQueryNodeHook<NodeType.UnitConversion>(
    getConexpItemById('unitConversions')?.gqlNodeQuery
);

// unitConverters: hook

export const useQueryViewerUnitConvertersConnection = createUseQueryConnectionHook<NodeType.UnitConverter>({
    connectionPath: 'viewer.unitConverters',
    query: getConexpItemById('unitConverters')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryUnitConverterNode = createUseQueryNodeHook<NodeType.UnitConverter>(
    getConexpItemById('unitConverters')?.gqlNodeQuery
);

// universalCriteria: hook

export const useQueryViewerUniversalCriteriaConnection = createUseQueryConnectionHook<
    NodeType.UniversalCriteria,
    NodeType.UniversalCriteriaViewerUniversalCriteriaSearch
>({
    connectionPath: 'viewer.universalCriteria',
    query: getConexpItemById('universalCriteria')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryUniversalCriteriaNode = createUseQueryNodeHook<NodeType.UniversalCriteria>(
    getConexpItemById('universalCriteria')?.gqlNodeQuery
);

// users: hook

export const useQueryViewerUsersConnection = createUseQueryConnectionHook<
    NodeType.User,
    NodeType.UserViewerUsersSearch
>({
    connectionPath: 'viewer.users',
    query: getConexpItemById('users')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryUserNode = createUseQueryNodeHook<NodeType.User>(getConexpItemById('users')?.gqlNodeQuery);

// warranterClaimMessages: hook

export const useQueryViewerWarranterClaimMessagesConnection = createUseQueryConnectionHook<
    NodeType.WarrantyClaimMessage,
    NodeType.WarrantyClaimMessageViewerWarranterClaimMessagesSearch
>({
    connectionPath: 'viewer.warranterClaimMessages',
    query: getConexpItemById('warranterClaimMessages')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryWarrantyClaimMessageNode = createUseQueryNodeHook<NodeType.WarrantyClaimMessage>(
    getConexpItemById('warranterClaimMessages')?.gqlNodeQuery
);

// warrantyClaimCases: hook

export const useQueryViewerWarrantyClaimCasesConnection = createUseQueryConnectionHook<
    NodeType.WarrantyClaimCase,
    NodeType.WarrantyClaimCaseViewerWarrantyClaimCasesSearch
>({
    connectionPath: 'viewer.warrantyClaimCases',
    query: getConexpItemById('warrantyClaimCases')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryWarrantyClaimCaseNode = createUseQueryNodeHook<NodeType.WarrantyClaimCase>(
    getConexpItemById('warrantyClaimCases')?.gqlNodeQuery
);

// warrantyClaims: hook

export const useQueryViewerWarrantyClaimsConnection = createUseQueryConnectionHook<
    NodeType.WarrantyClaim,
    NodeType.WarrantyClaimViewerWarrantyClaimsSearch
>({
    connectionPath: 'viewer.warrantyClaims',
    query: getConexpItemById('warrantyClaims')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryWarrantyClaimNode = createUseQueryNodeHook<NodeType.WarrantyClaim>(
    getConexpItemById('warrantyClaims')?.gqlNodeQuery
);

// workOrderFeed: hook

export const useQueryViewerWorkOrderFeedConnection = createUseQueryConnectionHook<
    NodeType.WorkOrderFeedItem,
    NodeType.WorkOrderFeedItemViewerWorkOrderFeedSearch
>({
    connectionPath: 'viewer.workOrderFeed',
    query: getConexpItemById('workOrderFeed')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryWorkOrderFeedItemNode = createUseQueryNodeHook<NodeType.WorkOrderFeedItem>(
    getConexpItemById('workOrderFeed')?.gqlNodeQuery
);

// workOrderTemplates: hook

export const useQueryViewerWorkOrderTemplatesConnection = createUseQueryConnectionHook<
    NodeType.WorkOrderTemplate,
    NodeType.WorkOrderTemplateViewerWorkOrderTemplatesSearch
>({
    connectionPath: 'viewer.workOrderTemplates',
    query: getConexpItemById('workOrderTemplates')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryWorkOrderTemplateNode = createUseQueryNodeHook<NodeType.WorkOrderTemplate>(
    getConexpItemById('workOrderTemplates')?.gqlNodeQuery
);

// workOrders: hook

export const useQueryViewerWorkOrdersConnection = createUseQueryConnectionHook<
    NodeType.WorkOrder,
    NodeType.WorkOrderViewerWorkOrdersSearch
>({
    connectionPath: 'viewer.workOrders',
    query: getConexpItemById('workOrders')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryWorkOrderNode = createUseQueryNodeHook<NodeType.WorkOrder>(
    getConexpItemById('workOrders')?.gqlNodeQuery
);

export const availableConexpHooksPartial2: { [keys: string]: any } = {
    products: {
        connectionHook: useQueryViewerProductsConnection,
        nodeHook: useQueryProductNode,
    },
    projectHosting: {
        connectionHook: useQueryViewerProjectHostingConnection,
        nodeHook: useQueryProjectNode,
    },
    reports: {
        connectionHook: useQueryViewerReportsConnection,
        nodeHook: useQueryTestReportNode,
    },
    roleFlags: {
        connectionHook: useQueryViewerRoleFlagsConnection,
        nodeHook: useQueryRoleFlagNode,
    },
    roles: {
        connectionHook: useQueryViewerRolesConnection,
        nodeHook: useQueryRoleNode,
    },
    sampleSets: {
        connectionHook: useQueryViewerSampleSetsConnection,
        nodeHook: useQuerySampleSetNode,
    },
    sampleSources: {
        connectionHook: useQueryViewerSampleSourcesConnection,
        nodeHook: useQuerySampleSourceNode,
    },
    sentEmails: {
        connectionHook: useQueryViewerSentEmailsConnection,
        nodeHook: useQueryEmailMessageNode,
    },
    serviceJobGroups: {
        connectionHook: useQueryViewerServiceJobGroupsConnection,
        nodeHook: useQueryServiceJobGroupNode,
    },
    serviceJobStages: {
        connectionHook: useQueryViewerServiceJobStagesConnection,
        nodeHook: useQueryServiceJobStageNode,
    },
    serviceJobTemplates: {
        connectionHook: useQueryViewerServiceJobTemplatesConnection,
        nodeHook: useQueryServiceJobTemplateNode,
    },
    serviceJobs: {
        connectionHook: useQueryViewerServiceJobsConnection,
        nodeHook: useQueryServiceJobNode,
    },
    settingStore: {
        connectionHook: useQueryViewerSettingStoreConnection,
        nodeHook: useQuerySettingStoreNode,
    },
    shops: {
        connectionHook: useQueryViewerShopsConnection,
        nodeHook: useQueryFranchiseShopNode,
    },
    sites: {
        connectionHook: useQueryViewerSitesConnection,
        nodeHook: useQuerySiteNode,
    },
    staff: {
        connectionHook: useQueryViewerStaffConnection,
        nodeHook: useQueryStaffNode,
    },
    staffTeams: {
        connectionHook: useQueryViewerStaffTeamsConnection,
        nodeHook: useQueryStaffTeamNode,
    },
    staffValuesStore: {
        connectionHook: useQueryViewerStaffValuesStoreConnection,
        nodeHook: useQueryStaffValueStoreNode,
    },
    testCriteria: {
        connectionHook: useQueryViewerTestCriteriaConnection,
        nodeHook: useQueryTestCriteriaNode,
    },
    testProblems: {
        connectionHook: useQueryViewerTestProblemsConnection,
        nodeHook: useQueryTestProblemNode,
    },
    testTreatmentGroupForFranchises: {
        connectionHook: useQueryViewerTestTreatmentGroupForFranchisesConnection,
        nodeHook: useQueryTestTreatmentFranchiseGroupNode,
    },
    testTreatmentGroups: {
        connectionHook: useQueryViewerTestTreatmentGroupsConnection,
        nodeHook: useQueryTestTreatmentGroupNode,
    },
    testTreatments: {
        connectionHook: useQueryViewerTestTreatmentsConnection,
        nodeHook: useQueryTestTreatmentNode,
    },
    timezones: {
        connectionHook: useQueryViewerTimezonesConnection,
        nodeHook: useQueryTimezoneNode,
    },
    unitConversions: {
        connectionHook: useQueryViewerUnitConversionsConnection,
        nodeHook: useQueryUnitConversionNode,
    },
    unitConverters: {
        connectionHook: useQueryViewerUnitConvertersConnection,
        nodeHook: useQueryUnitConverterNode,
    },
    universalCriteria: {
        connectionHook: useQueryViewerUniversalCriteriaConnection,
        nodeHook: useQueryUniversalCriteriaNode,
    },
    users: {
        connectionHook: useQueryViewerUsersConnection,
        nodeHook: useQueryUserNode,
    },
    warranterClaimMessages: {
        connectionHook: useQueryViewerWarranterClaimMessagesConnection,
        nodeHook: useQueryWarrantyClaimMessageNode,
    },
    warrantyClaimCases: {
        connectionHook: useQueryViewerWarrantyClaimCasesConnection,
        nodeHook: useQueryWarrantyClaimCaseNode,
    },
    warrantyClaims: {
        connectionHook: useQueryViewerWarrantyClaimsConnection,
        nodeHook: useQueryWarrantyClaimNode,
    },
    workOrderFeed: {
        connectionHook: useQueryViewerWorkOrderFeedConnection,
        nodeHook: useQueryWorkOrderFeedItemNode,
    },
    workOrderTemplates: {
        connectionHook: useQueryViewerWorkOrderTemplatesConnection,
        nodeHook: useQueryWorkOrderTemplateNode,
    },
    workOrders: {
        connectionHook: useQueryViewerWorkOrdersConnection,
        nodeHook: useQueryWorkOrderNode,
    },
};
