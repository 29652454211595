import * as React from 'react';
import { NotFoundPage, useViewer } from '@poolware/app-shell';
import {
    ModuleNavLink,
    ModuleRedirect,
    ModuleRoot,
    ModuleRoute,
    ModuleSwitch,
    useAppNavigator,
} from '@poolware/react-app-navigator';
import { ServiceJobTemplateNew } from '../ServiceJobTemplatesManager/ServiceJobTemplate/New/Page';
import { ServiceJobTemplateList } from '../ServiceJobTemplatesManager/ServiceJobTemplate/List/Page';
import { ServiceJobTemplateView } from '../ServiceJobTemplatesManager/ServiceJobTemplate/ServiceJobTemplateView';
import { WorkOrderTemplateNew } from '../ServiceJobTemplatesManager/WorkOrderTemplate/WorkOrderTemplateNew';
import { WorkOrderTemplateView } from '../ServiceJobTemplatesManager/WorkOrderTemplate/WorkOrderTemplateView/WorkOrderTemplateView';
import { WorkOrderTemplateList } from '../ServiceJobTemplatesManager/WorkOrderTemplate/WorkOrderTemplateList';
import { Icon, MenuBar, PageLayout } from '@ez/components';
import {
    JobTodoTemplateEdit,
    JobTodoTemplateList,
    JobTodoTemplateNew,
    JobTodoTemplateView,
} from '../ServiceJobTemplatesManager/JobTodoTemplate';
import { ModuleIconNames } from '../constants';
import * as ServiceJobGroup from './ServiceJobGroup';
import * as Stages from './Stages';

const StubPage = () => {
    const nav = useAppNavigator();
    return (
        <div>
            Stub Page <button onClick={() => nav.history.goBack()}>go back</button>
        </div>
    );
};

// export const StyledModuleNavLink = styled(ModuleNavLink)`
//     //border-left-style: solid;
//     //border-left-width: 4px;
//     //padding-left: 1em;
//     //border-color: white;
//     //width: 100%;
//     //display: block;
//
//     &.active {
//         background-color: #2185d0;
//         font-weight: bold;
//     }
// `;

const SectionsNavbar = () => {
    const { AppNavigator } = useAppNavigator();
    const { isAdmin, modulesAccess } = useViewer();
    const allowSJTemplates = modulesAccess.FieldServices?.caseTemplates;
    const allowTaskTemplates = modulesAccess.FieldServices?.serviceJobTaskTemplates;

    if (AppNavigator.isModal || !allowSJTemplates) {
        return null;
    }

    return (
        <MenuBar.Root>
            <MenuBar.Section position={'left'}>
                <MenuBar.Item asChild={true}>
                    <ModuleNavLink to={'/sj-template'}>
                        <Icon name={ModuleIconNames.ServiceCase} />
                        Service Case Templates
                    </ModuleNavLink>
                </MenuBar.Item>
                <MenuBar.Item asChild={true}>
                    <ModuleNavLink to={'/job-todo-template'}>
                        <Icon name={ModuleIconNames.WorkOrderItem} /> Job Task Templates
                    </ModuleNavLink>
                </MenuBar.Item>
                <MenuBar.Item asChild={true}>
                    <ModuleNavLink to={'/sj-group'}>
                        <Icon name={ModuleIconNames.ServiceGroup} />
                        Service Groups
                    </ModuleNavLink>
                </MenuBar.Item>
                {false && (
                    <MenuBar.Item asChild={true}>
                        <ModuleNavLink to={'/wo-template'}>
                            <Icon name={ModuleIconNames.WorkOrder} />
                            Work Order Templates
                        </ModuleNavLink>
                    </MenuBar.Item>
                )}
                <MenuBar.Item asChild={true}>
                    <ModuleNavLink to={'/stages'}>
                        <Icon name={'th'} />
                        Stages
                    </ModuleNavLink>
                </MenuBar.Item>
            </MenuBar.Section>
        </MenuBar.Root>
    );
};

export const Router = () => {
    return (
        <PageLayout width={'screen-wide'}>
            <ModuleRoot>
                <ModuleRoute path={'/sj-template'} exact={true} component={SectionsNavbar} />
                <ModuleRoute path={'/job-todo-template'} exact={true} component={SectionsNavbar} />
                <ModuleRoute path={'/sj-group'} exact={true} component={SectionsNavbar} />
                <ModuleRoute path={'/wo-template'} exact={true} component={SectionsNavbar} />
                <ModuleRoute path={'/stages'} exact={true} component={SectionsNavbar} />

                <ModuleSwitch notFound={NotFoundPage}>
                    <ModuleRedirect path={'/'} exact to={'/sj-template'} />

                    <ModuleRoute path={'/sj-group/new'} component={ServiceJobGroup.New} />
                    <ModuleRoute path={'/sj-group/:id/edit'} component={ServiceJobGroup.Edit} />
                    <ModuleRoute path={'/sj-group'} component={ServiceJobGroup.List} />

                    <ModuleRoute path={'/sj-template/new'} component={ServiceJobTemplateNew} />
                    <ModuleRoute path={`/sj-template/:id`} component={ServiceJobTemplateView} />
                    <ModuleRoute path={'/sj-template'} component={ServiceJobTemplateList} />

                    <ModuleRoute path={'/wo-template/new'} component={WorkOrderTemplateNew} />
                    <ModuleRoute path={`/wo-template/:id`} component={WorkOrderTemplateView} />
                    <ModuleRoute path={'/wo-template'} component={WorkOrderTemplateList} />

                    <ModuleRoute path={`/job-todo-template/new`} component={JobTodoTemplateNew} />
                    <ModuleRoute path={`/job-todo-template/:id/edit`} component={JobTodoTemplateEdit} />
                    <ModuleRoute path={`/job-todo-template/:id`} component={JobTodoTemplateView} />
                    <ModuleRoute path={`/job-todo-template`} component={JobTodoTemplateList} />

                    <ModuleRoute path={`/stages`} component={Stages.Page} />

                    <ModuleRoute path={`/stub/:id`} component={StubPage} />
                </ModuleSwitch>
            </ModuleRoot>
        </PageLayout>
    );
};
