import * as React from 'react';
import { ModuleRootSwitch, ModuleRoute, ModuleSwitch } from '@poolware/react-app-navigator';
import { PageList } from './Page.List';
import { PageNew } from './Page.New';
import { PageView } from './Page.View';

const pages = () => {
    return (
        <ModuleSwitch>
            <ModuleRoute path="/new" component={PageNew} />
            <ModuleRoute path="/:id" component={PageView} />
        </ModuleSwitch>
    );
};

export const Router = () => {
    return (
        <ModuleRootSwitch>
            <ModuleRoute path={'/'} exact component={PageList} />
            <ModuleRoute path={'/'} component={pages} />
        </ModuleRootSwitch>
    );
};
