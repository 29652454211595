import * as React from 'react';
import { useState } from 'react';
import {
    ConnectionTableDef,
    DefaultConnectionTable,
    FormFieldInputDebounced,
    Icon,
    MenuBarHeaderItem,
    MenuBarItem,
    MenuBarSection,
    PageLayout,
    StickyMenuBar,
    TableRowClickable,
    TableRowDef,
} from '@ez/components';
import { NodeType, useQueryCustomerTagsConnection } from '@poolware/api';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { OwnershipLabel } from '../../components/OwneshipLabel';

export interface PageProps {}

export const PageList: React.FC<PageProps> = () => {
    const { AppNavigator } = useAppNavigator();
    const [filterName, setFilterName] = useState('');
    const [showSearch, setShowSearch] = useState(true);
    const { connectionData, connectionState } = useQueryCustomerTagsConnection({ searchName: filterName });

    const onView = (item) => {
        AppNavigator.navigateRelative(`/${item.id}`);
    };

    const onNew = () => {
        AppNavigator.navigateRelative(`/new`, { modal: true });
    };

    const tableDef: ConnectionTableDef<NodeType.CustomerTag> = [
        {
            header: 'Tag Name',
            sortKey: 'name',
            cell: (r) => (
                <>
                    <Icon name={'tag'} /> {r.name}
                </>
            ),
        },
        {
            header: 'Ownership',
            cellProps: { width: '6' },
            cell: (r) => {
                return (
                    <span>
                        <OwnershipLabel franchise={r.franchise} />
                    </span>
                );
            },
        },
    ];

    const tableRowProps: TableRowDef<NodeType.ServiceJob> = (connectionDataItem, connectionState) => {
        return {
            comp: (props) => (
                <TableRowClickable
                    onClick={() => {
                        onView(connectionDataItem);
                    }}
                    {...props}
                />
            ),
        };
    };

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem icon={'tags'}>Customer Tags</MenuBarHeaderItem>
                </MenuBarSection>
                <MenuBarSection position={'right'}>
                    <MenuBarItem
                        icon={'search'}
                        onClick={() => {
                            setShowSearch(!showSearch);
                        }}
                    />
                    <MenuBarItem onClick={onNew} icon={'plus'} color={'green'} title={'New'} />
                </MenuBarSection>
            </StickyMenuBar>

            {showSearch && (
                <FormFieldInputDebounced
                    variant={'search'}
                    value={filterName}
                    onChange={setFilterName}
                    className={'max-w-96'}
                />
            )}

            <DefaultConnectionTable
                tableRowDef={tableRowProps}
                tableDef={tableDef}
                connectionData={connectionData}
                connectionState={connectionState}
            />
        </PageLayout>
    );
};
