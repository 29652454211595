import * as React from 'react';
import { compose, mapProps } from '@ez/tools';
import gql from 'graphql-tag';
import { fromEdges, NodeType, RoleFlagMutatorAdded, withRoleFlagMutators } from '@poolware/api';
import { FeatureFlag } from '@poolware/app-shell';
import { graphql } from 'react-apollo';
import { Checkbox } from '@ez/components';
import { Icon } from '@ez/components';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';

class FlagCheckbox extends React.PureComponent<
    RoleFlagMutatorAdded & {
        franchise: NodeType.Franchise;
        flag: NodeType.RoleFlag;
        disabled?: boolean;
    }
> {
    state = {
        updating: false,
    };

    flipFlag = async (checked) => {
        const { franchise, flag } = this.props;
        this.setState({ updating: true });
        if (checked) {
            await this.props.RoleFlagMutator.grantFlagToFranchise(franchise, flag);
        } else {
            await this.props.RoleFlagMutator.removeFlagFromFranchise(franchise, flag);
        }
        this.setState({ updating: false });
    };

    render() {
        const { updating } = this.state;
        const { franchise, flag, disabled } = this.props;
        const roleFlagsIds = fromEdges(franchise.flags).map((f) => f.id);
        const isActive = roleFlagsIds.includes(flag.id);
        if (updating) {
            return <Icon name={'spinner'} loading />;
        }
        return <Checkbox disabled={disabled} checked={isActive} onClick={(e, { checked }) => this.flipFlag(checked)} />;
    }
}

interface PageProps extends IAppNavigatorProps, RoleFlagMutatorAdded {
    franchise: NodeType.Franchise;
    roleFlags: NodeType.RoleFlag[];
}

class Page extends React.Component<PageProps> {
    render() {
        const { RoleFlagMutator, franchise, roleFlags } = this.props;
        if (!franchise) return null;

        return (
            <ul className={'m-0 pl-0'}>
                {roleFlags.map((flag, index) => {
                    const isAdminFlag = flag.tag === FeatureFlag.ADMIN;
                    return (
                        <li key={index} className={'flex flex-row content-center space-x-1 h-8'}>
                            <span className={'w-6'}>
                                <FlagCheckbox
                                    disabled={isAdminFlag}
                                    RoleFlagMutator={RoleFlagMutator}
                                    franchise={franchise}
                                    flag={flag}
                                />
                            </span>
                            <span style={isAdminFlag ? { color: '#DDD' } : undefined}>{flag.tag}</span>
                        </li>
                    );
                })}
            </ul>
        );
    }
}

const Query = gql`
    query FranchiseViewQuery($franchiseId: ID!) {
        franchise: node(id: $franchiseId) {
            id
            ... on Franchise {
                id
                name
                flags {
                    edges {
                        node {
                            id
                            tag
                            description
                        }
                    }
                }
            }
        }
        viewer {
            roleFlags(first: 1000) {
                edges {
                    node {
                        id
                        tag
                        description
                    }
                }
            }
        }
    }
`;

export default compose(
    withAppNavigator(),
    graphql(Query, {
        options: (props: any) => ({
            variables: { franchiseId: props?.match?.params?.franchiseId || props?.franchiseId },
        }),
    }),
    withRoleFlagMutators(['FranchiseViewQuery']),
    mapProps((props) => {
        let franchise = props?.data?.franchise;
        let roleFlags: NodeType.RoleFlag[] = fromEdges(props?.data?.viewer?.roleFlags);
        roleFlags = roleFlags.sort((l, r) => {
            return l.tag > r.tag ? 1 : -1;
        });

        return {
            ...props,
            franchise,
            roleFlags,
        };
    })
)(Page);
