import { TableDefColumn, TruncatedDiv } from '@ez/components';
import { fromEdges } from '@poolware/api';
import * as _ from 'lodash';
import * as React from 'react';

export const tableDefColumn_ProductDeclarationsList = (input?: { header?: string }): TableDefColumn => {
    return {
        header: (input && input.header) || 'Categories',
        style: { width: '260px' },
        cellProps: {
            singleLine: true,
        },
        cell: (pd) => {
            const traits = fromEdges(_.get(pd, 'traits'));
            const text = traits.map((t) => _.get(t, 'declaration.name', '')).join(', ');

            // const text = _.get(traits.slice(0), '[0].declaration.name');
            return <TruncatedDiv maxWidth={260}>{text}</TruncatedDiv>;
        },
    };
};
