import gql from 'graphql-tag';
import { createUseQueryNodeHook, NodeType, productTraitFragment } from '@poolware/api';

const QL = gql`
    query QueryProductNode($id: ID!) {
        node(id: $id) {
            ... on Product {
                id
                name
                createdAt
                updatedAt
                organisationType {
                    id
                    name
                }
                franchise {
                    id
                    name
                }
                description
                identification
                sku
                customSku
                isOneOff
                isGrouping
                brand {
                    id
                    name
                    company {
                        id
                        name
                    }
                }
                supplier {
                    id
                    name
                }
                addedBy {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
                traits {
                    ...ProductTraitFragment
                }
            }
        }
    }
    ${productTraitFragment}
`;

export const useQueryProduct = createUseQueryNodeHook<NodeType.Product>(QL);
