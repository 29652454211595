import * as React from 'react';
import { useOrgSwitcher } from './OrgProvider';
import { Icon, Navbar, FormFieldSuggestInput } from '@ez/components';

export interface OrgSwitcherProps {}

export const SuggestInputOrgSwitcher: React.FC<OrgSwitcherProps> = () => {
    const { organisation, setOrg, orgs, loading } = useOrgSwitcher();
    return (
        <FormFieldSuggestInput
            value={organisation}
            options={orgs.map((o) => ({ value: o, text: o?.name }))}
            loading={loading}
            onItemSelect={setOrg}
            getOptionValue={(item) => item?.id}
            formatOptionLabel={(item) => item.name}
            className={'w-48'}
        />
    );
};
const ALL_ORGS = '__ALL_ORGS_KEY__';

export const NavbarOrgSwitcher: React.FC = () => {
    const { organisation, setOrg, orgs } = useOrgSwitcher();

    const orgOptions = orgs.map((o) => ({
        text: o.name,
        value: o?.id,
    }));

    const options = [
        {
            text: 'All',
            value: ALL_ORGS,
        },
        ...orgOptions,
    ];

    const onChangeOrg = ({ value }) => {
        setOrg(value === ALL_ORGS ? null : value);
    };

    return (
        <>
            <Navbar.Dropdown.Menu label={organisation?.name || 'All Orgs'} icon={<Icon name={'building'} />}>
                {options.map((option, index) => {
                    return (
                        <Navbar.Dropdown.Item
                            key={index}
                            icon={<Icon name={'building'} />}
                            content={option.text}
                            onClick={() => onChangeOrg(option)}
                        />
                    );
                })}
            </Navbar.Dropdown.Menu>
        </>
    );
};
