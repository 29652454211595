import { compose, withProps } from '@ez/tools';
import trim from 'lodash/trim';

import {
    IMutateProject,
    IMutateProjectVersion,
    NodeType,
    withProjectMutator,
    withProjectVersionMutator,
} from './api-types';

export interface ProjectCreateInput {
    title?: string;
    description?: string;
    index?: number;
    createdBy?: NodeType.ID;
    items?: any[];
    serviceJob: NodeType.ID;
}

export interface ProjectMutator {
    createProject: (input: NodeType.CreateProjectMutationInput) => Promise<any>;
    deleteProject: (project: NodeType.NodeOrId<NodeType.Project>) => Promise<any>;
    createVersion: (
        project: NodeType.NodeOrId<NodeType.Project>,
        input: Partial<NodeType.CreateProjectVersionMutationInput>
    ) => Promise<any>;
    updateVersion: (
        version: NodeType.NodeOrId<NodeType.ProjectVersion>,
        input: Partial<NodeType.UpdateProjectVersionMutationInput>
    ) => Promise<any>;
    deleteVersion: (version: NodeType.NodeOrId<NodeType.ProjectVersion>) => Promise<any>;
    setPinnedVersion: (
        version: NodeType.NodeOrId<NodeType.ProjectVersion>,
        project?: NodeType.NodeOrId<NodeType.Project>
    ) => Promise<any>;
    unpinVersion: (project: NodeType.NodeOrId<NodeType.Project>) => Promise<any>;
}

export interface IProjectMutatorsInjected extends IMutateProject, IMutateProjectVersion {}

export interface IProjectMutatorsAdded {
    ProjectMutator: ProjectMutator;
}

export interface IProjectMutatorsProps extends IProjectMutatorsInjected, IProjectMutatorsAdded {}

const sanitize = (key, value) => {
    const getSanitizers = (key) => {
        const noop = (v) => v;
        const s = {
            remoteFileURL: () => trim(value),
        };
        return s[key] || noop;
    };

    return getSanitizers(key)(value);
};

const createMutators = ({ mutateProject, mutateProjectVersion }: IProjectMutatorsInjected): IProjectMutatorsAdded => {
    const sanitizeProjectVersionInput = (
        input: Partial<NodeType.UpdateProjectVersionMutationInput>
    ): Partial<NodeType.UpdateProjectVersionMutationInput> => {
        const sanitized = {};
        Object.keys(input).forEach((key) => {
            sanitized[key] = sanitize(key, input[key]);
        });
        return sanitized;
    };

    const createProject = async (input: NodeType.CreateProjectMutationInput) => {
        const sanitized = {
            ...input,
            name: trim(input.name),
        };
        const resp = await mutateProject.create(sanitized);
        return resp?.data?.Project?.Project;
    };

    const deleteProject = async (project: NodeType.NodeOrId<NodeType.Project>) => {
        return await mutateProject.delete({ id: NodeType.extractId(project) });
    };

    const createVersion = async (
        project: NodeType.NodeOrId<NodeType.Project>,
        input: Partial<NodeType.CreateProjectVersionMutationInput>
    ) => {
        const sanitized = sanitizeProjectVersionInput(input);
        return mutateProjectVersion.create({
            project: NodeType.extractId(project),
            ...sanitized,
        });
    };
    const updateVersion = async (
        version: NodeType.NodeOrId<NodeType.ProjectVersion>,
        input: Partial<NodeType.UpdateProjectVersionMutationInput>
    ) => {
        const sanitized = sanitizeProjectVersionInput(input);

        return mutateProjectVersion.update({
            id: NodeType.extractId(version),
            ...sanitized,
        });
    };

    const deleteVersion = async (version: NodeType.NodeOrId<NodeType.ProjectVersion>) => {
        return mutateProjectVersion.delete({ id: NodeType.extractId(version) });
    };

    const unpinVersion = async (project: NodeType.NodeOrId<NodeType.Project>) => {
        if (!project) {
            throw new Error('Expected `project` to be provided');
        }

        let projId = NodeType.extractId(project);

        return mutateProject.update({
            id: projId,
            pinnedVersion: { id: null },
        });
    };

    const setPinnedVersion = async (
        version: NodeType.NodeOrId<NodeType.ProjectVersion>,
        project?: NodeType.NodeOrId<NodeType.Project>
    ) => {
        let projId: NodeType.ID;
        if (NodeType.isId(version)) {
            if (!project) {
                throw new Error('Expected `project` parameter to be provided if `version` parameter is of ID type');
            }
            projId = NodeType.extractId(project);
        } else {
            if (!version.project || !version.project.id) {
                throw new Error('Expected `version.project.id` if `version` is of type ProjectVersion');
            }
            projId = version.project.id;
        }

        return mutateProject.update({
            id: projId,
            pinnedVersion: { id: NodeType.extractId(version) },
        });
    };

    return {
        ProjectMutator: {
            createProject,
            deleteProject,
            createVersion,
            updateVersion,
            deleteVersion,
            setPinnedVersion,
            unpinVersion,
        },
    };
};

export const withProjectMutators = (refetchQueries) =>
    compose(withProjectMutator(refetchQueries), withProjectVersionMutator(refetchQueries), withProps(createMutators));
