import { mapConnectionSearchStateToSearchVars } from './ConnectionViewerSearchFilters';
import * as React from 'react';
import { Reducer, useContext, useReducer, useState } from 'react';
import { createReducer } from '@ez/tools';

export type ReducerSearchFieldType = {
    fieldKey: string;
    value: any;
    fieldTypeName: string;
};

const reducer = createReducer<any, any>({}, (state, action) => {
    switch (action.type) {
        case 'clear':
            return {};
        case 'set':
            const key = action.payload?.fieldKey;
            state[key] = action.payload;
            break;
        default:
            throw new Error();
    }
});

export const useConnectionViewSearchReducer = () => {
    const [searchVars, setSearchVars] = useState<any>();
    const [searchState, dispatch] = useReducer<Reducer<any, any>>(reducer, {});

    const setSearchField = (fieldKey: string, fieldTypeName: string) => (value) => {
        dispatch({
            type: 'set',
            payload: {
                fieldKey,
                value,
                fieldTypeName,
            },
        });
    };
    const clearAll = () => {
        dispatch({
            type: 'clear',
        });
        setSearchVars(null);
    };

    const getSearchVars = () => {
        return searchVars;
    };

    const commitSearch = () => {
        setSearchVars(mapConnectionSearchStateToSearchVars(searchState));
    };

    return {
        getSearchVars,
        searchState: searchState,
        setSearchField,
        clearAll,
        commitSearch,
    };
};

export type SearchContextType = ReturnType<typeof useConnectionViewSearchReducer>;
export const ConnectionViewerSearchContext = React.createContext<SearchContextType>(undefined);
export const useConexpSearchContext = () => useContext(ConnectionViewerSearchContext);

export const ConexpConnectionSearchProvider: React.FC = ({ children }) => {
    const ctx = useConnectionViewSearchReducer();
    return <ConnectionViewerSearchContext.Provider value={ctx}>{children}</ConnectionViewerSearchContext.Provider>;
};
