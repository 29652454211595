import Page from './Page';

import { compose, mapProps } from '@ez/tools';
import * as _ from 'lodash';
import invariant from 'invariant';

import { IProjectMutatorsProps, withProjectMutators } from '@poolware/api';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';

export default compose(
    withAppNavigator(),
    withProjectMutators(['QueryProjectDetails']),
    mapProps((props) => {
        const projectId = _.get(props, 'match.params.projectId');
        invariant(projectId, 'Expected projectId');
        return {
            ...props,
            projectId: projectId,
        };
    })
)(Page);

export interface PageControl extends IAppNavigatorProps, IProjectMutatorsProps {
    projectId?: string;
}
