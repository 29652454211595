import * as React from 'react';
import { useMemo } from 'react';
import { FormFieldSegmentedSelectButtons } from '@ez/components';
import { useQueryTreatmentGroupConnection } from '../common/use-query-treatment-groups-connection';

export interface TreatmentGroupFilterProps {
    orgId?: string;
    groupId?: string;
    onChangeGroup?: (groupId: string) => void;
}

export const TreatmentGroupFilter: React.FC<TreatmentGroupFilterProps> = ({ orgId, groupId, onChangeGroup }) => {
    const { connectionData, connectionState } = useQueryTreatmentGroupConnection({
        search: { organisationType: { id: orgId } },
    });

    const options = useMemo(() => {
        const options =
            connectionData?.map((group) => {
                return {
                    key: group.id,
                    text: group.name,
                    value: group.id,
                };
            }) || [];

        if (options?.length === 0) {
            return null;
        }

        options.unshift({ key: 'all', text: 'All', value: '___ALL__' });
        return options;
    }, [connectionData]);

    if (!options) {
        return null;
    }

    return (
        <FormFieldSegmentedSelectButtons
            label={'Treatment Group'}
            fluid={false}
            onChange={(o) => onChangeGroup(o.value !== '___ALL__' ? o.value : null)}
            value={groupId || '___ALL__'}
            options={options}
        />
    );
};
