import * as React from 'react';
import { useState } from 'react';

import { getAppConfig, SuggestInputOrganisationType, useViewer } from '@poolware/app-shell';
import {
    Alert,
    FormLabel,
    Icon,
    MenuBarHeaderItem,
    MenuBarSection,
    PageLayout,
    Panel,
    Segment,
    StickyMenuBar,
} from '@ez/components';

import { NodeType } from '@poolware/api';

const config = getAppConfig();

export const BulkDataExport: React.FC = () => {
    const [organisation, setOrganisation] = useState<NodeType.OrganisationType>(null);

    const { modulesAccess } = useViewer();
    const hasAccess = modulesAccess.Export?.enabled;

    const getCsvLink = () => {
        if (!organisation) return null;
        return `${config.api.restAPIBaseUrl}/export-organisation-csv/${organisation.id}`;
    };

    if (!hasAccess) {
        return <Alert type={'warning'}>Access denied</Alert>;
    }

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem icon={'share square'}>Data Export</MenuBarHeaderItem>
                </MenuBarSection>
            </StickyMenuBar>
            <PageLayout.BodySection width={'screen-md'}>
                <Segment>
                    <FormLabel>Organisation</FormLabel>
                    <SuggestInputOrganisationType onChange={setOrganisation} value={organisation} />
                </Segment>
                <Panel>
                    <Panel.Header content={'Bulk Export'} />
                    <Panel.Body>
                        <div className={'border rounded p-2'}>
                            <p className={'my-2'}>Export customers as CSV file</p>
                            <a className={'ez-btn-base p-2'} href={getCsvLink()}>
                                <Icon name={'download'} />
                                Download (CSV)
                            </a>
                        </div>
                    </Panel.Body>
                </Panel>
            </PageLayout.BodySection>
        </PageLayout>
    );
};
