import * as React from 'react';
import { Display, Panel } from '@ez/components';
import { PageControl } from './Page.Control';
import { NodeType } from '@poolware/api';
import { PageLayout } from '@ez/components';

const MeCard: React.FC<{ me: NodeType.Me }> = ({ me }) => {
    const user = me?.staff?.user || {};
    return (
        <div>
            <Panel.Item label="Name">
                <Display.Entity value={user} />
            </Panel.Item>
        </div>
    );
};

export default class extends React.Component<PageControl> {
    render() {
        const { me } = this.props;
        return (
            <PageLayout width={'screen-md'}>
                <Panel>
                    <Panel.Header>My Profile</Panel.Header>
                    <Panel.Body>
                        <MeCard me={me} />
                    </Panel.Body>
                </Panel>
            </PageLayout>
        );
    }
}
