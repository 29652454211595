import * as React from 'react';
import { useContext, useMemo } from 'react';
import { ConexpItemType } from './types';

export interface ConexpProviderContextType {
    getConexpItems: () => { [keys: string]: ConexpItemType };
    getConexpItemById: (connectionId: string) => ConexpItemType;
    availableConexpHooks: { [keys: string]: any };
    getConnectionUIConfigById: (connectionId: string) => { [keys: string]: any };
}

export const ConexpContext = React.createContext<ConexpProviderContextType>(undefined);
export const useConexp = () => useContext(ConexpContext);

export const ConexpProvider: React.FC<{ conexp: ConexpProviderContextType }> = ({ children, conexp }) => {
    const ctx = useMemo<ConexpProviderContextType>(
        () => ({
            availableConexpHooks: conexp.availableConexpHooks,
            getConexpItemById: conexp.getConexpItemById,
            getConnectionUIConfigById: conexp.getConnectionUIConfigById,
            getConexpItems: conexp.getConexpItems,
        }),
        [conexp]
    );
    return <ConexpContext.Provider value={ctx}>{children}</ConexpContext.Provider>;
};
