import Page from './Page';
import { compose, mapProps } from '@ez/tools';
import * as _ from 'lodash';
import { fromEdges, NodeType, RoleFlagMutatorProps, withRoleFlagMutators } from '@poolware/api';
import { withApolloLoading } from '@ez/components';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const QL = gql`
    query SecurityFlags {
        viewer {
            roleFlags(first: 1000) {
                edges {
                    node {
                        id
                        tag
                        description
                    }
                }
            }
            roles(first: 1000) {
                edges {
                    node {
                        id
                        name
                        flags(first: 1000) {
                            edges {
                                node {
                                    id
                                    tag
                                    description
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default compose(
    graphql(QL),
    withApolloLoading(),
    withRoleFlagMutators(['SecurityFlags', 'ListFranchises']),
    mapProps((props) => {
        let roles = fromEdges(_.get(props, 'data.viewer.roles'));
        let roleFlags: NodeType.RoleFlag[] = fromEdges(_.get(props, 'data.viewer.roleFlags'));
        roleFlags = roleFlags.sort((l, r) => {
            return l.tag > r.tag ? 1 : -1;
        });

        return {
            ...props,
            roles,
            roleFlags,
        };
    })
)(Page);

export interface PageControl extends RoleFlagMutatorProps {
    roles: NodeType.Role[];
    roleFlags: NodeType.RoleFlag[];
}
