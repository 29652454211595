import * as React from 'react';
import { FormikDefaultForm, FormikDefaultFormProps, FormikInputField, FormikTextareaField } from '@ez/components';
import * as Yup from 'yup';

export interface FormNewProps extends FormikDefaultFormProps {}

const validationSchema = Yup.object().shape({
    tag: Yup.string().min(2, 'Too Short!').max(150, 'Too Long!').required('Required'),
    description: Yup.string().min(2, 'Too Short!').max(1000, 'Too Long!').notRequired(),
});
export const FormEditFlag: React.FC<FormNewProps> = (props) => {
    return (
        <FormikDefaultForm
            header={'UI Flag'}
            submitButton={{ content: 'Submit' }}
            validationSchema={validationSchema}
            {...props}
        >
            <FormikInputField required={true} label={'Tag'} name={'tag'} />
            <FormikTextareaField label={'Description'} name={'description'} />
        </FormikDefaultForm>
    );
};
