import * as React from 'react';
import { useState } from 'react';

import { PageControlProps } from './View.Control';
import { getAppConfig, SuggestInputFranchise, useViewer } from '@poolware/app-shell';
import {
    Alert,
    FormLabel,
    Icon,
    MenuBarHeaderItem,
    MenuBarSection,
    PageLayout,
    Panel,
    Segment,
    StickyMenuBar,
} from '@ez/components';

const config = getAppConfig();

const Page: React.FC<PageControlProps> = ({ franchise }) => {
    const [_franchise, setFranchise] = useState(franchise);
    const { modulesAccess } = useViewer();
    const hasAccess = modulesAccess.Export?.enabled;

    const getCsvLink = () => {
        if (!_franchise) return null;
        return `${config.api.restAPIBaseUrl}/export-csv/${_franchise.id}`;
    };

    const onSelect = (item) => {
        setFranchise(item);
    };

    if (!hasAccess) {
        return <Alert type={'warning'}>Access denied</Alert>;
    }

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem icon={'share square'}>Data Export</MenuBarHeaderItem>
                </MenuBarSection>
            </StickyMenuBar>
            <PageLayout.BodySection width={'screen-md'}>
                <Segment>
                    <FormLabel>Franchise</FormLabel>
                    <SuggestInputFranchise onChange={onSelect} value={_franchise} />
                </Segment>
                <Panel>
                    <Panel.Body>
                        <Panel.Item label={'Franchise'}>{_franchise ? _franchise.name : 'Not selected'}</Panel.Item>
                        <Panel.Divider />
                        {_franchise && (
                            <Segment>
                                <p>Export customers as CSV file</p>
                                <a href={getCsvLink()}>
                                    <Icon name={'download'} />
                                    Download (CSV)
                                </a>
                            </Segment>
                        )}
                    </Panel.Body>
                </Panel>
            </PageLayout.BodySection>
        </PageLayout>
    );
};

export default Page;
