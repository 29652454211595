import * as React from 'react';
import { Link } from 'react-router-dom';
import { sidebarItemGroups } from '../top-route-config';
import { Icon, PageLayout, SectionHeader } from '@ez/components';

const Page: React.FC = () => (
    <PageLayout width={'screen-md'}>
        <>
            {sidebarItemGroups.map((group, index) => {
                if (group.items?.length == 0) {
                    return null;
                }

                return (
                    <React.Fragment key={index}>
                        <SectionHeader size={'small'}>{group.label}</SectionHeader>
                        <ul key={index}>
                            {group.items.map((route) => {
                                return (
                                    <li className={'flex flex-row'} key={route.path}>
                                        <Icon name={route.icon} />
                                        <Link to={route.path}>{route.label}</Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </React.Fragment>
                );
            })}
        </>
    </PageLayout>
);

export default Page;
