import { DocumentNode } from 'graphql';

export enum FieldTypeRenderName {
    ID = 'ID',
    JSON = 'JSON',
    Date = 'Date',
    Int = 'Int',
    String = 'String',
    Boolean = 'Boolean',
    Object = 'Object',
    Unknown = 'Unknown',
    Address = 'Address',
    Staff = 'Staff',
    Entity = 'Entity',
    Franchise = 'Franchise',
    Customer = 'Customer',
    CheckMutations = 'CheckMutations',
    Enum = 'Enum',
}

export type ConexpItemType = {
    connectionId: string;
    connectionTypeName: string;
    nodeTypeName: string;
    gqlConnectionQuery: DocumentNode;
    gqlNodeFragment: DocumentNode;
    gqlNodeQuery: DocumentNode;
};
