import gql from 'graphql-tag';
import { NodeType, QueryConnectionConfigProps, useDefaultConnectionState, useQueryConnection } from '@poolware/api';

const QL = gql`
    query QueryTreatmentGroups($pageSize: Int, $page: Int, $search: TestTreatmentGroupViewerTestTreatmentGroupsSearch) {
        viewer {
            testTreatmentGroups(first: $pageSize, page: $page, search: $search) {
                edges {
                    node {
                        id
                        name
                        active
                        priority
                        organisationType {
                            id
                            name
                        }
                    }
                }
                pageMeta {
                    totalCount
                    pageCount
                }
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                }
            }
        }
    }
`;

export interface QuerySettingStoreConnectionProps
    extends Partial<QueryConnectionConfigProps<NodeType.TestTreatmentGroupViewerTestTreatmentGroupsSearch>> {}

export function useQueryTreatmentGroupConnection(props?: QuerySettingStoreConnectionProps) {
    const { variables, connectionState } = useDefaultConnectionState({
        ...props,
    });

    return useQueryConnection({
        query: QL,
        // fetchPolicy: 'cache-first',
        connectionPath: 'viewer.testTreatmentGroups',
        connectionConfig: connectionState,
        variables: variables,
    });
}
