import {
    Button,
    confirmModalImperative,
    ConfirmProps,
    Panel,
    Pill,
    TablePickerPanel,
    useModalCtrl,
} from '@ez/components';
import * as React from 'react';
import { fromEdges, NodeType, useMutationTestTreatmentFranchiseGroup } from '@poolware/api';
import { Modal } from 'semantic-ui-react';
import { useQueryTreatmentGroupConnection } from '../common/use-query-treatment-groups-connection';

const TreatmentGroupPicker: React.FC<{
    onCancel: () => void;
    onSelect: (group: NodeType.TestTreatmentGroup) => any;
    orgId: string;
}> = ({ onSelect, onCancel, orgId }) => {
    const { connectionData, connectionState } = useQueryTreatmentGroupConnection({
        search: {
            organisationType: {
                id: orgId,
            },
        },
    });

    return (
        <TablePickerPanel
            multiSelect={false}
            panelHeader={{ content: 'Treatment Groups' }}
            onCancel={onCancel}
            onSubmit={(items) => onSelect(items[0])}
            connectionState={connectionState}
            connectionData={connectionData}
            tableDef={[
                {
                    header: 'Treatment Group',
                    cell: (item) => item.name,
                },
                {
                    header: 'Organisation',
                    cell: (item) => item.organisationType?.name || '--',
                },
            ]}
        />
    );
};

const confirmDelete: ConfirmProps = {
    cancelButton: { content: 'Keep' },
    negative: true,
    confirmButton: {
        content: 'Remove',
        icon: 'trash',
    },
    confirmMessage: {
        content: 'Remove Treatment Group?',
    },
};
export const PanelTreatmentGroups: React.FC<{ franchise: NodeType.Franchise; refetchQueries: any[] }> = ({
    franchise,
    refetchQueries,
}) => {
    const modalCtrl = useModalCtrl();

    const { activate, deactivate } = useMutationTestTreatmentFranchiseGroup({
        refetchQueries: refetchQueries,
    });

    const treatmentGroups = fromEdges(franchise.treatmentGroups);

    const onDelete = async (group: NodeType.TestTreatmentGroup) => {
        const res = await confirmModalImperative(confirmDelete);
        if (!res) {
            return;
        }
        await deactivate({ group: group.id, franchise: franchise.id });
    };

    const onSelectGroup = async (group: NodeType.TestTreatmentGroup) => {
        await activate({ group: group.id, franchise: franchise.id });
        modalCtrl.onClose();
    };

    return (
        <Panel>
            <Panel.Header
                button={{
                    content: 'Add',
                    icon: 'plus',
                    onClick: () => modalCtrl.onOpen(),
                }}
            >
                Treatment Groups
            </Panel.Header>
            <Panel.Body>
                <div className={'flex flex-col gap-1'}>
                    {treatmentGroups.map((group) => {
                        return (
                            <div
                                key={group.id}
                                className={'flex flex-row justify-between items-center rounded hover:bg-gray-100 px-2'}
                            >
                                <Pill color={'purple'}>{group.name}</Pill>
                                <Button
                                    size={'mini'}
                                    icon={'trash'}
                                    variant={'tertiary'}
                                    onClick={() => onDelete(group)}
                                />
                            </div>
                        );
                    })}
                </div>
                {modalCtrl.open && (
                    <Modal {...modalCtrl} size={'small'}>
                        <TreatmentGroupPicker
                            onSelect={onSelectGroup}
                            orgId={franchise.organisationType?.id}
                            onCancel={modalCtrl.onClose}
                        />
                    </Modal>
                )}
            </Panel.Body>
        </Panel>
    );
};
