import * as React from 'react';
import { useState } from 'react';
import { DefaultConnectionTable, MenuBar, StickyMenuBar, TableDef, VStack } from '@ez/components';
import { ModuleColorNames, ModuleIconNames } from '../../constants';
import { NodeType } from '@poolware/api';
import { useQueryServiceJobGroupConnection } from '../../queries/use-query-service-job-group-connection';
import { SuggestInputFranchise, SuggestInputOrganisationType } from '@poolware/app-shell';
import { useAppNavigator } from '@poolware/react-app-navigator';

export interface ListProps {}

export const List: React.FC<ListProps> = ({}) => {
    const { AppNavigator } = useAppNavigator();
    const [franchise, setFranchise] = useState<NodeType.Franchise>();
    const [org, setOrg] = useState<NodeType.OrganisationType>();
    const { connectionData, connectionState } = useQueryServiceJobGroupConnection({
        franchiseId: franchise?.id,
        organisationId: org?.id,
    });

    const tableDef: TableDef<NodeType.ServiceJobGroup> = [
        {
            header: 'Group Name',
            cell: (group) => {
                return <>{group.title}</>;
            },
        },
        {
            header: 'Franchise',
            cell: (group) => {
                return <>{group.franchise?.name}</>;
            },
        },
        {
            header: 'Organisation',
            cell: (group) => {
                return <>{group.organisationType?.name}</>;
            },
        },
    ];

    const onView = (group: NodeType.ServiceJobGroup) => {
        AppNavigator.navigateRelative(`/sj-group/${group.id}/edit`, { setOrigin: true, modal: true });
    };

    const onNew = () => {
        AppNavigator.navigateRelative(`/sj-group/new`, { setOrigin: true, modal: true });
    };

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBar.Section>
                    <MenuBar.HeaderItem color={ModuleColorNames.ServiceGroup} icon={ModuleIconNames.ServiceGroup}>
                        Service Groups
                    </MenuBar.HeaderItem>
                </MenuBar.Section>
                <MenuBar.Section position={'right'}>
                    <MenuBar.Item icon={'plus'} color={'green'} title={'New'} onClick={onNew} />
                </MenuBar.Section>
            </StickyMenuBar>
            <div className={'flex gap-2'}>
                <SuggestInputFranchise label={'Franchise'} value={franchise} onChange={setFranchise} />
                <SuggestInputOrganisationType label={'Organisation'} value={org} onChange={setOrg} />
            </div>

            <DefaultConnectionTable
                onRowClick={onView}
                tableDef={tableDef}
                connectionData={connectionData}
                connectionState={connectionState}
            />
        </VStack>
    );
};
