import Page from './View';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { withApolloLoading } from '@ez/components';
import * as _ from 'lodash';
import { compose, mapProps } from '@ez/tools';

import { NodeType } from '@poolware/api';

const QL = gql`
    query MyFranchiseId {
        viewer {
            me {
                staff {
                    id
                    root {
                        id
                        franchise {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;

export default compose(
    graphql(QL),
    withApolloLoading(),
    mapProps((props) => {
        let franchise = props?.data?.viewer?.me?.staff?.root?.franchise;
        return {
            ...props,
            franchise,
        };
    })
)(Page);

export interface PageControlProps {
    franchise: NodeType.Franchise;
}
