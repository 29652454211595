import * as React from 'react';
import { useState } from 'react';

import { IAppNavigatorProps } from '@poolware/react-app-navigator';
import {
    ConnectionTableDef,
    DefaultConnectionTable,
    Form,
    FormFieldInputDebounced,
    Icon,
    LinkButton,
    MenuBar,
    MenuBarHeaderItem,
    PageLayout,
    StickyMenuBar,
} from '@ez/components';
import { NodeType, useQueryFranchiseConnection } from '@poolware/api';
import { SuggestInputOrganisationType } from '@poolware/app-shell';
import { franchiseQL } from './query-franchises';
import { FranchisePropEditorModal } from './FranchisePropEditorForm';

export const Configurator: React.FC<IAppNavigatorProps> = () => {
    const [filterName, setFilterName] = useState('');
    const [filterOrg, setFilterOrg] = useState(null);
    const [isWarranter, setWarranter] = useState(false);
    const [selectedFranchise, setSelectedFranchise] = useState<NodeType.Franchise>(undefined);

    const { connectionState, connectionData, refetchQuery } = useQueryFranchiseConnection({
        query: franchiseQL,
        searchName: filterName,
        searchOrgId: filterOrg?.id,
        isWarranter: isWarranter ? true : undefined,
    });

    const hasFilters = !!filterName || !!filterOrg || isWarranter;
    const onClear = () => {
        setFilterName('');
        setFilterOrg(null);
        setWarranter(false);
    };

    const tableDef: ConnectionTableDef<NodeType.Franchise> = [
        {
            header: 'Exportable',
            width: 1,
            cellProps: { textAlign: 'center' },
            cell: (item) => {
                return item.contactExport ? (
                    <Icon name={'checkmark box'} color={'green'} />
                ) : (
                    <Icon name={'square outline'} color={'grey'} />
                );
            },
        },
        {
            header: 'Name',
            sortKey: 'name',
            cell: (item) => {
                return item.name;
            },
        },
        {
            header: 'Types',
            cell: (item) => {
                return item.types?.join(', ');
            },
        },
        {
            header: 'Organisation',
            cell: (item) => {
                return item?.organisationType?.name;
            },
        },
    ];

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBar.Section position={'left'}>
                    <MenuBarHeaderItem icon={'building'}>Franchises</MenuBarHeaderItem>
                </MenuBar.Section>
            </StickyMenuBar>

            <div className={'flex flex-row flex-wrap gap-2 md:max-w-screen-lg w-full'}>
                <FormFieldInputDebounced
                    label={'Franchise Name'}
                    variant={'search'}
                    value={filterName}
                    onChange={setFilterName}
                    className={'max-w-96'}
                />
                <>
                    <div className={'flex-1'}>
                        <SuggestInputOrganisationType
                            label={'Organisation'}
                            value={filterOrg}
                            onChange={setFilterOrg}
                        />
                    </div>
                    <div className={'flex-initial self-end pb-2'}>
                        <Form.Checkbox
                            label={'Warranters'}
                            checked={isWarranter}
                            onClick={(_, { checked }) => setWarranter(checked)}
                        />
                    </div>
                </>
                <div className={'flex-1 self-end w-4 pb-2 underline'}>
                    {hasFilters && <LinkButton onClick={onClear}>clear</LinkButton>}
                </div>
            </div>

            <DefaultConnectionTable
                tableDef={tableDef}
                connectionData={connectionData}
                connectionState={connectionState}
                onRowClick={setSelectedFranchise}
            />
            <FranchisePropEditorModal
                onClose={() => setSelectedFranchise(null)}
                franchise={selectedFranchise}
                refetchQuery={refetchQuery}
            />
        </PageLayout>
    );
};
