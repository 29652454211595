import * as React from 'react';
import { useEffect, useState } from 'react';

import { IAppNavigatorProps, useAppNavigator } from '@poolware/react-app-navigator';
import { Form, Menu } from 'semantic-ui-react';
import {
    ConnectionTableDef,
    DefaultConnectionTable,
    Display,
    FormFieldInputDebounced,
    LinkButton,
    MenuBar,
    PageLayout,
    Pill,
    StickyMenuBar,
} from '@ez/components';
import { NodeType, useQueryFranchiseConnection } from '@poolware/api';
import { useOrgSwitcher, useViewer } from '@poolware/app-shell';
import { franchiseQL } from './query-franchises';
import { fromEdges } from '@ez/api-core';
import { TreatmentGroupFilter } from './TreatmentGroupFilter';
import { SegmentedSelectOrgSwitcher } from '../../../components/OrgSwitcher';

export const PageList: React.FC<IAppNavigatorProps> = () => {
    const { AppNavigator } = useAppNavigator();
    const [filterName, setFilterName] = useState('');
    const { organisation } = useOrgSwitcher();
    const [isWarranter, setWarranter] = useState(false);
    const { viewer, modulesAccess } = useViewer();
    const [filterTreatmentGroupId, setFilterTreatmentGroupId] = useState<string | null>(null);

    useEffect(() => {
        setFilterTreatmentGroupId(null);
    }, [organisation?.id]);

    const canRegister = viewer?.Permissions?.Franchise?.create;

    const { connectionState, connectionData } = useQueryFranchiseConnection({
        query: franchiseQL,
        searchName: filterName,
        searchOrgId: organisation?.id,
        isWarranter: isWarranter ? true : undefined,
        treatmentGroupId: filterTreatmentGroupId,
    });

    const hasFilters = !!filterName || !!organisation || isWarranter;
    const onClear = () => {
        setFilterName('');
        setWarranter(false);
    };

    const tableDef: ConnectionTableDef<NodeType.Franchise> = [
        {
            header: 'Franchise',
            width: 2,
            sortKey: 'name',
            cell: (franchise) => {
                return franchise.name || '--';
            },
        },
        {
            header: 'Type',
            width: 2,
            cell: (r) => (
                <div className={'flex flex-wrap gap-2'}>
                    {r.types?.map((t) => {
                        return (
                            <Pill key={t} color={'gray'}>
                                {t}
                            </Pill>
                        );
                    })}
                </div>
            ),
        },

        {
            header: 'Parent HQ',
            cell: (item) => {
                return item.parentFranchise?.name;
            },
        },
        {
            header: 'Manager',
            cell: (item) => {
                return <Display.Entity value={item.manager} />;
            },
        },
        {
            header: 'Staff Count',
            cell: (item) => {
                return item.staffCount;
            },
        },
        {
            header: 'Last Customer',
            cell: (item) => {
                return (
                    <div className={'text-sm'}>
                        <Display.Date format={'ll'} value={item.activities?.lastCustomerDate} />
                    </div>
                );
            },
        },
        {
            header: 'Last Report',
            cell: (item) => {
                return (
                    <div className={'text-sm'}>
                        <Display.Date format={'ll'} value={item.activities?.lastReportDate} />
                    </div>
                );
            },
        },
        {
            header: 'Treatment Groups',
            width: 2,
            cell: (r) => (
                <div className={'flex flex-wrap gap-2'}>
                    {fromEdges(r.treatmentGroups).map((t) => {
                        return (
                            <Pill key={t.id} color={'purple'}>
                                {t.name}
                            </Pill>
                        );
                    })}
                </div>
            ),
        },
        modulesAccess.EZConnectIntegration?.enabled && {
            header: 'EZ Connect',
            cell: (item) => {
                return <Pill color={'gray'}>{item.ezcServiceProviderLink?.serviceProvider?.name}</Pill>;
            },
        },
        {
            header: 'Org',
            cell: (item) => {
                return item?.organisationType?.name;
            },
        },
    ];

    const onViewFranchise = (franchise: NodeType.Franchise) => {
        AppNavigator.navigateRelative(franchise.id);
    };

    return (
        <PageLayout>
            <StickyMenuBar>
                <Menu.Menu position={'left'}>
                    <MenuBar.HeaderItem icon={'building'}>Franchises</MenuBar.HeaderItem>
                </Menu.Menu>
                <MenuBar.Section position={'right'}>
                    <MenuBar.Item
                        icon={'plus'}
                        color={'green'}
                        // onClick={addNew}
                        disabled={!canRegister}
                        popup={!canRegister && { content: 'Not enough permission' }}
                    >
                        Register Franchise
                    </MenuBar.Item>
                </MenuBar.Section>
            </StickyMenuBar>

            <div className={'flex flex-col flex-wrap gap-2 md:max-w-screen-lg w-full'}>
                <SegmentedSelectOrgSwitcher />
                <div className={'flex flex-row gap-2'}>
                    <FormFieldInputDebounced
                        label={'Franchise Name'}
                        variant={'search'}
                        value={filterName}
                        placeholder={'Search by name...'}
                        onChange={setFilterName}
                        className={'max-w-96'}
                    />
                    <TreatmentGroupFilter
                        orgId={organisation?.id}
                        groupId={filterTreatmentGroupId}
                        onChangeGroup={setFilterTreatmentGroupId}
                    />
                    <div className={'flex-initial self-end mb-5'}>
                        <Form.Checkbox
                            label={'Warranters'}
                            checked={isWarranter}
                            onClick={(_, { checked }) => setWarranter(checked)}
                        />
                    </div>
                    <div className={'flex-1 self-end mb-5 underline'}>
                        {hasFilters && <LinkButton onClick={onClear}>clear</LinkButton>}
                    </div>
                </div>
            </div>

            <DefaultConnectionTable
                // debug={true}
                tableDef={tableDef}
                onRowClick={onViewFranchise}
                connectionData={connectionData}
                connectionState={connectionState}
            />
        </PageLayout>
    );
};
