import * as React from 'react';
import { MenuBar, Modal, PageLayout, Panel, StickyMenuBar, useModalCtrl } from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useQueryFranchise } from './use-franchise-query';
import { pageSpinner, useViewer } from '@poolware/app-shell';
import { NodeType } from '@poolware/api';
import { PanelStoreType } from './PanelStoreType';
import { PanelAccessKeys } from './PanelAccessKeys';
import { PanelParentChildRelationship } from './PanelParentChildRelationship';
import { PanelTreatmentGroups } from './PanelTreatmentGroups';
import { FormEditFranchiseDetails } from './FormEditFranchiseDetails';
import { PanelEzProviders } from './PanelEZProviders';

export interface PageViewProps {}

export interface FormNewProps {
    franchise: NodeType.Franchise;
    refetchQueries: any[];
    onDone?: () => void;
}

export const PageView: React.FC<PageViewProps> = ({}) => {
    const { AppNavigator } = useAppNavigator();
    const { modulesAccess } = useViewer();
    const { node: franchise, error, loading, refetchQuery } = useQueryFranchise(AppNavigator.params.id);
    const modalCtrl = useModalCtrl();

    const goBack = () => {
        AppNavigator.navigateToOrigin('/', { relativeToModule: true });
    };

    const spinner = pageSpinner(loading, error, franchise);
    if (spinner) return spinner;

    return (
        <PageLayout sectionWidth={'screen-md'}>
            <StickyMenuBar>
                <MenuBar.Section>
                    <MenuBar.Item onClick={goBack} icon={'chevron left'} title={'Back'} />
                    <MenuBar.HeaderItem>
                        {/*<Icon color={ModuleColorNames.F} name={ModuleIconNames.Staff} />*/}
                        Franchise - {franchise.name}
                    </MenuBar.HeaderItem>
                </MenuBar.Section>
                <MenuBar.Section position={'right'}></MenuBar.Section>
            </StickyMenuBar>
            <PageLayout.BodySection>
                <Panel>
                    <Panel.Header
                        content="Company Details"
                        icon="building"
                        button={{
                            content: 'Edit',
                            icon: 'edit',
                            onClick: modalCtrl.onOpen,
                        }}
                    />
                    <Panel.Body>
                        <Panel.Item label="Company Name" content={franchise.name} />
                        <Panel.Item label="Organisation" content={franchise?.organisationType?.name || '--'} />
                        <Panel.ItemEntity label="Manager" content={franchise.manager}></Panel.ItemEntity>
                        <Panel.ItemEmail label="Company email" content={franchise.email} />
                        <Panel.Item label="Company phone" content={franchise.phoneNumber}></Panel.Item>
                        <Panel.Item label="Staff Count" content={franchise.staffCount}></Panel.Item>
                    </Panel.Body>
                </Panel>
            </PageLayout.BodySection>
            <PageLayout.BodySection>
                <PanelStoreType franchise={franchise} refetchQueries={[refetchQuery]} />
            </PageLayout.BodySection>
            <PageLayout.BodySection>
                <PanelParentChildRelationship franchise={franchise} refetchQueries={[refetchQuery]} />
            </PageLayout.BodySection>
            <PageLayout.BodySection>
                <PanelAccessKeys franchise={franchise} refetchQueries={[refetchQuery]} />
            </PageLayout.BodySection>
            {modulesAccess.WaterTest?.enabled && (
                <PageLayout.BodySection>
                    <PanelTreatmentGroups franchise={franchise} refetchQueries={[refetchQuery]} />
                </PageLayout.BodySection>
            )}
            {modulesAccess.EZConnectIntegration?.enabled && (
                <PageLayout.BodySection>
                    <PanelEzProviders franchise={franchise} refetchQueries={[refetchQuery]} />
                </PageLayout.BodySection>
            )}
            <Modal {...modalCtrl}>
                <FormEditFranchiseDetails
                    franchise={franchise}
                    refetchQueries={[refetchQuery]}
                    onDone={modalCtrl.onClose}
                />
            </Modal>
        </PageLayout>
    );
};
