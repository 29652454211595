import { SemanticICONS } from '@ez/components';

export type PlatformOptionType = {
    icon: SemanticICONS;
    text: string;
    value: string;
};

export const platformOptions: PlatformOptionType[] = [
    {
        icon: 'windows',
        text: 'Windows',
        value: 'windows',
    },
    {
        icon: 'apple',
        text: 'macOS',
        value: 'macos',
    },
    {
        icon: 'app store ios',
        text: 'iOS',
        value: 'ios',
    },
    {
        icon: 'android',
        text: 'Android',
        value: 'android',
    },
    {
        icon: 'linux',
        text: 'Lunux',
        value: 'linux',
    },
    {
        icon: 'circle outline',
        text: 'Other',
        value: 'unknown',
    },
];
