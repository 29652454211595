import * as React from 'react';
import { useState } from 'react';
import { MenuBarItem, MenuBarSection, PageLayout, Panel, StickyMenuBar, SuspenseSkeletonLoader } from '@ez/components';
import { fromEdges, NodeType } from '@poolware/api';
import { NotFoundPage } from '@poolware/app-shell';
import { useQueryProduct } from './use-query-product';
import { useAppNavigator } from '@poolware/react-app-navigator';

const BrandLabel: React.FC<{ brand: NodeType.Brand }> = ({ brand }) => {
    if (!brand) return null;
    const brandName = brand?.name;
    const companyName = brand?.company?.name;

    return (
        <>
            {companyName ? (
                <>
                    <span style={{ fontStyle: 'italic' }}>{companyName}</span>
                    {' > '} <>{brandName}</>
                </>
            ) : (
                <>{brandName}</>
            )}
        </>
    );
};

const ProductDescriptionPanel: React.FC<{ product: NodeType.Product }> = ({ product }) => {
    const [expand, setExpand] = useState(false);

    const traits = fromEdges(product?.traits);
    const text = traits.map((t) => t?.declaration?.name || '').join(', ');

    return (
        <Panel>
            <Panel.Header
                content={'Product'}
                button={{
                    content: expand ? 'Hide Tech. data' : 'Show Tech. Data',
                    onClick: () => {
                        setExpand(!expand);
                    },
                }}
            />
            <Panel.Body>
                <Panel.Item label="SKU" content={product.sku || '--'} />
                <Panel.Item label="Name" content={product.name} />
                <Panel.Item label={'Brand'}>
                    <BrandLabel brand={product.brand} />
                </Panel.Item>
                <Panel.Item label={'Description'} content={product.description} />
                <Panel.Item label={'Categories'} content={text} />

                {expand && (
                    <>
                        <Panel.SectionHeader dividing={true}>Tech. Data</Panel.SectionHeader>
                        <Panel.Items content={product} />
                    </>
                )}
            </Panel.Body>
        </Panel>
    );
};

export const Page: React.FC<any> = () => {
    const { AppNavigator, params } = useAppNavigator<{ id?: string }>();

    const { node: product, error, loading } = useQueryProduct(params.id);

    const goToList = () => {
        AppNavigator.navigate('/', { relativeToModule: true });
    };

    if (loading || error) {
        return <SuspenseSkeletonLoader error={error} loading={loading} />;
    }

    if (!loading && !product) {
        return <NotFoundPage />;
    }

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBarSection position={'left'}>
                    <MenuBarItem icon={'chevron left'} color={'grey'} onClick={goToList}>
                        Products
                    </MenuBarItem>
                </MenuBarSection>
            </StickyMenuBar>
            <PageLayout.BodySection width={'screen-xl'}>
                <ProductDescriptionPanel product={product} />
            </PageLayout.BodySection>
        </PageLayout>
    );
};
