import * as React from 'react';
import { MenuBarHeaderItem, MenuBarSection, StickyMenuBar, toastError, VStack } from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useMutationFeatureFlagVariation } from '@poolware/api';
import { FeatureFlagValueType, FormNewFeatureFlagVariation, prepareMutationCreateVariation } from './FormFeatureFlag';
import { PageLayout } from '@ez/components';

export interface PageNewProps {}

export const PageNew: React.FC<PageNewProps> = ({}) => {
    const { AppNavigator } = useAppNavigator();

    const { create } = useMutationFeatureFlagVariation();

    const initialValues = {
        tag: '',
        valueType: FeatureFlagValueType.SET,
        value: '',
        franchise: null,
        organisation: null,
        role: null,
    };

    const onCancel = () => {
        AppNavigator.replaceToOrigin('/', { relativeToModule: true });
    };

    const onSubmit = async (values: typeof initialValues) => {
        try {
            const conf = prepareMutationCreateVariation(values);
            const res = await create(conf);
            AppNavigator.replace(`/`, { relativeToModule: true });
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem icon={'flag'}>New Feature Flag</MenuBarHeaderItem>
                </MenuBarSection>
                <MenuBarSection position={'right'} />
            </StickyMenuBar>

            <PageLayout.BodySection width={'screen-md'}>
                <FormNewFeatureFlagVariation
                    debug={true}
                    header={'New Feature Flag'}
                    initialValues={initialValues}
                    submitButton={{ content: 'Create' }}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                />
            </PageLayout.BodySection>
        </PageLayout>
    );
};
