import * as React from 'react';
import { useMemo } from 'react';

import { SidebarSecondaryMenuItemNavLink, useAppConfig, useTheme, useViewer } from '@poolware/app-shell';
import { AppSidebarConfig, AppSidebarMenuItem, AppSidebarSecondary, SemanticICONS } from '@ez/components';

export interface SidebarProps {}

export const Sidebar: React.FC<SidebarProps> = ({}) => {
    const { ThemeState } = useTheme();
    const { viewer, modulesAccess } = useViewer();
    const appConfig = useAppConfig();

    const createItemConf = (input: {
        to: string;
        icon?: SemanticICONS;
        secondaryIcon?: SemanticICONS;
        label: string | React.ReactNode;
        sub?: boolean;
        exact?: boolean;
        as?: any;
    }): AppSidebarMenuItem => {
        const { exact, icon, to, sub, label, secondaryIcon, as } = input;
        return {
            to,
            icon,
            exact,
            label: label,
            secondaryIcon: secondaryIcon,
            as: as || SidebarSecondaryMenuItemNavLink,
            sub: sub,
        };
    };

    const config: AppSidebarConfig = useMemo(
        () => ({
            menu: [
                {
                    header: 'Export',
                    items: [
                        modulesAccess.Export?.enabled &&
                            createItemConf({
                                to: '/data-export/bulk',
                                icon: 'download',
                                label: 'Bulk Export',
                            }),
                        modulesAccess.Export?.enabled &&
                            createItemConf({
                                to: '/data-export/single',
                                icon: 'download',
                                label: 'Single Franchise',
                            }),
                        createItemConf({
                            to: '/data-export/import',
                            icon: 'upload',
                            label: 'Import',
                        }),
                    ],
                },
            ],
        }),
        [appConfig, ThemeState, viewer]
    );

    return <AppSidebarSecondary config={config} />;
};
