import { ModuleRootSwitch, ModuleRoute } from '@poolware/react-app-navigator';
import * as React from 'react';
import New from './New';
import Root from './Root';

export default () => {
    return (
        <ModuleRootSwitch>
            <ModuleRoute path={'/new'} exact component={New} />
            <ModuleRoute path={'/'} component={Root} />
        </ModuleRootSwitch>
    );
};
