import * as React from 'react';
import { useQueryPoolDeclarationFlagFieldConnection } from './use-query-pool-declaration-flag-fields';
import {
    AsyncCheckbox,
    ButtonWithDropdownMenu,
    confirmModalImperative,
    DefaultConnectionTable,
    DropdownMenuOptionType,
    FormikCheckboxField,
    FormikDefaultForm,
    FormikInputField,
    FormikTextareaField,
    MenuBar,
    Modal,
    PageLayout,
    StickyMenuBar,
    TableDef,
    useModalCtrl,
} from '@ez/components';
import { NodeType, useMutationPoolDeclarationFlagField } from '@poolware/api';
import { FormikSuggestInputOrganisationType } from '@poolware/app-shell';
import * as Yup from 'yup';

const formValidationSchema = Yup.object().shape({
    organisation: Yup.object().required('Required').nullable(),
    name: Yup.string().required('Required').min(1).max(50),
    description: Yup.string().notRequired().nullable().min(1).max(255),
    useInExport: Yup.boolean().notRequired().nullable(),
    exportName: Yup.string().notRequired().nullable().min(1).max(50),
    tag: Yup.string().notRequired().nullable().min(1).max(50),
});

export interface AttachedFieldsProps {}

const NewPoolFlagFieldForm: React.FC<{ refetchQueries: any[]; onDone: () => void }> = ({ refetchQueries, onDone }) => {
    const { create } = useMutationPoolDeclarationFlagField({
        refetchQueries,
        awaitRefetchQueries: true,
    });

    const initialValues = {
        name: '',
        description: '',
        priority: 0,
        useInExport: false,
        exportName: '',
        tag: '',
        organisation: null,
    };

    const onSubmit = async (values: typeof initialValues) => {
        await create({
            name: values.name,
            description: values.description,
            exportName: values.exportName,
            priority: values.priority,
            tag: values.tag,
            useInExport: values.useInExport,
            organisation: values.organisation?.id,
        });
        onDone();
    };

    return (
        <div>
            <FormikDefaultForm
                validationSchema={formValidationSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}
                onCancel={onDone}
                header={'New Pool Declaration Flag Field'}
            >
                <FormikSuggestInputOrganisationType name={'organisation'} label={'Organisation'} required />
                <FormikInputField name={'name'} label={'Name'} required={true} />
                <FormikTextareaField name={'description'} label={'Description'} />
                <FormikCheckboxField name={'useInExport'} label={'Use in export'} />
                <FormikInputField name={'exportName'} label={'Export name'} />
                <FormikInputField name={'tag'} label={'Tag'} />
            </FormikDefaultForm>
        </div>
    );
};

const EditPoolFlagFieldForm: React.FC<{
    poolFlagField: NodeType.PoolDeclarationFlagField;
    refetchQueries: any[];
    onDone: () => void;
}> = ({ poolFlagField, refetchQueries, onDone }) => {
    const { update } = useMutationPoolDeclarationFlagField({
        refetchQueries,
        awaitRefetchQueries: true,
    });

    const initialValues = {
        ...poolFlagField,
    };

    const onSubmit = async (values: typeof initialValues) => {
        await update({
            id: poolFlagField.id,
            name: values.name,
            description: values.description,
            exportName: values.exportName,
            priority: values.priority,
            tag: values.tag,
            useInExport: values.useInExport,
        });
        onDone();
    };

    return (
        <div>
            <FormikDefaultForm
                validationSchema={formValidationSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}
                onCancel={onDone}
                header={'Edit Pool Declaration Flag Field'}
            >
                <FormikInputField name={'name'} label={'Name'} required={true} />
                <FormikTextareaField name={'description'} label={'Description'} />
                <FormikCheckboxField name={'useInExport'} label={'Use in export'} />
                <FormikInputField name={'exportName'} label={'Export name'} />
                <FormikInputField name={'tag'} label={'Tag'} />
            </FormikDefaultForm>
        </div>
    );
};

export const AttachedFields: React.FC<AttachedFieldsProps> = ({}) => {
    const modalCtrlNew = useModalCtrl();
    const modalCtrlEdit = useModalCtrl<NodeType.PoolDeclarationFlagField>();
    const { connectionData, connectionState, refetchQuery } = useQueryPoolDeclarationFlagFieldConnection();
    const { delete: deletePD, setActive } = useMutationPoolDeclarationFlagField({
        refetchQueries: [refetchQuery],
        awaitRefetchQueries: true,
    });

    const onDelete = async (pd: NodeType.PoolDeclarationFlagField) => {
        const res = await confirmModalImperative({
            negative: true,
            confirmButton: { content: 'Delete', icon: 'trash' },
            confirmMessage: { content: 'Delete permanently?' },
        });
        if (!res) return;
        await deletePD({ id: pd.id });
    };

    const onSetActive = async (pd: NodeType.PoolDeclarationFlagField, active: boolean) => {
        await setActive({ id: pd.id, state: active });
    };

    const tableDef: TableDef<NodeType.PoolDeclarationFlagField> = [
        {
            header: 'Active',
            width: 1,
            cell: (pd) => {
                return (
                    <>
                        <AsyncCheckbox
                            toggle={true}
                            onToggle={async (checked) => {
                                return await onSetActive(pd, checked);
                            }}
                            checked={!!pd.activatedAt}
                        />
                    </>
                );
            },
        },
        {
            header: 'Name',
            cell: (item) => item.name,
        },
        {
            header: 'Exportable',
            cell: (item) => (item.useInExport ? 'Yes' : 'No'),
        },
        {
            header: 'Export Name',
            cell: (item) => item.exportName,
        },
        // {
        //     header: 'Priority',
        //     sortKey: 'priority',
        //     cell: (item) => item.priority,
        // },
        {
            header: 'Organisation',
            cell: (item) => item.organisation?.name,
        },
        {
            header: '',
            width: 1,
            cell: (pd) => {
                const menuItems: DropdownMenuOptionType[] = [
                    {
                        content: 'Edit',
                        icon: 'edit',
                        onClick: () => {
                            modalCtrlEdit.onOpen(pd);
                        },
                    },
                    {
                        content: 'Delete',
                        icon: 'trash',
                        onClick: () => onDelete(pd),
                    },
                ];

                return <ButtonWithDropdownMenu size={'xs'} iconRight={'ellipsis vertical'} options={menuItems} />;
            },
        },
    ];

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBar.Section>
                    <MenuBar.HeaderItem>Pool Attached Flags</MenuBar.HeaderItem>
                </MenuBar.Section>
                <MenuBar.Section position={'right'}>
                    <MenuBar.Item icon={'plus'} color={'green'} onClick={modalCtrlNew.onOpen}>
                        New
                    </MenuBar.Item>
                </MenuBar.Section>
            </StickyMenuBar>
            <DefaultConnectionTable
                tableDef={tableDef}
                connectionData={connectionData}
                connectionState={connectionState}
            />
            <Modal size={'small'} open={modalCtrlNew.open} centered={false}>
                <NewPoolFlagFieldForm refetchQueries={[refetchQuery]} onDone={modalCtrlNew.onClose} />
            </Modal>
            <Modal size={'small'} open={modalCtrlEdit.open} centered={false}>
                <EditPoolFlagFieldForm
                    refetchQueries={[refetchQuery]}
                    onDone={modalCtrlEdit.onClose}
                    poolFlagField={modalCtrlEdit.payload}
                />
            </Modal>
        </PageLayout>
    );
};
