import gql from 'graphql-tag';
import { createUseQueryNodeHook, NodeType, addressFragment } from '@poolware/api';

const QL = gql`
    query QueryFranchiseDetails_Admin($id: ID!) {
        node(id: $id) {
            id
            ... on Franchise {
                createdAt
                id
                name
                email
                phoneNumber
                parentFranchise {
                    id
                    name
                }
                address {
                    id
                    ...AddressFragment
                }
                organisationType {
                    id
                    name
                    logo
                }
                manager {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
                staffCount
                createdAt
                types
                childFranchises {
                    edges {
                        node {
                            id
                            name
                        }
                    }
                }
                staffAccessTokens(search: { staff: { isSystemHidden: {} } }) {
                    edges {
                        node {
                            id
                            token
                            staff {
                                id
                                user {
                                    id
                                    firstName
                                    lastName
                                }
                                isSystemHidden
                                role {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
                treatmentGroups {
                    edges {
                        node {
                            id
                            name
                        }
                    }
                }
                ezcServiceProviderLink {
                    id
                    serviceProvider {
                        id
                        name
                    }
                }
            }
        }
    }
    ${addressFragment}
`;

export const useQueryFranchise = createUseQueryNodeHook<NodeType.Franchise>(QL);
