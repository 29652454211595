import * as React from 'react';
import { ModuleRootSwitch, ModuleRoute } from '@poolware/react-app-navigator';
import { PageList } from './Page.List';
import { PageView } from './Page.View';
import { PageNew } from './Page.New';

export const Router: React.FC = () => {
    return (
        <>
            <ModuleRootSwitch>
                <ModuleRoute path={'/'} exact={true} component={PageList} />
                <ModuleRoute path={'/new'} exact={true} component={PageNew} />
                <ModuleRoute path={'/:id'} exact={true} component={PageView} />
            </ModuleRootSwitch>
        </>
    );
};
