import * as React from 'react';
import { fromEdges, NodeType, useMutationFranchise } from '@poolware/api';
import {
    ButtonWithConfirm,
    ConfirmProps,
    FormikDefaultForm,
    Modal,
    Panel,
    Table,
    toastError,
    useModalCtrl,
} from '@ez/components';
import { FormikSuggestInputFranchise } from '@poolware/app-shell';

const FranchisePicker: React.FC<{
    value?: NodeType.Franchise;
    onSelect: (franchise: NodeType.Franchise | null) => Promise<void>;
    onCancel: () => void;
}> = ({ value, onSelect, onCancel }) => {
    const initialValues = {
        franchise: value,
    };

    const onSubmit = async (values?: typeof initialValues) => {
        await onSelect(values.franchise);
    };

    return (
        <FormikDefaultForm
            header={'Franchise'}
            initialValues={initialValues}
            submitButton={{ content: 'Submit' }}
            onSubmit={onSubmit}
            onCancel={onCancel}
        >
            <FormikSuggestInputFranchise name={'franchise'} label={'Franchise'} />
        </FormikDefaultForm>
    );
};

const PanelChildFranchise: React.FC<{ franchise: NodeType.Franchise; refetchQueries: any[] }> = ({
    franchise,
    refetchQueries,
}) => {
    const modalCtrl = useModalCtrl();

    const { setParentFranchise } = useMutationFranchise({ refetchQueries });

    const onSelect = async (newParentFranchise: NodeType.Franchise | null) => {
        try {
            await setParentFranchise({ parentFranchise: newParentFranchise?.id, id: franchise.id });
            modalCtrl.onClose();
        } catch (e) {
            toastError(e);
        }
    };
    const parentFranchise = franchise.parentFranchise;

    return (
        <Panel>
            <Panel.Header content={'Parent Franchise'} button={{ content: 'Edit', onClick: modalCtrl.onOpen }} />
            <Panel.Body>
                {franchise.parentFranchise && <Panel.Item label={'Parent Store'} content={parentFranchise?.name} />}
            </Panel.Body>
            <Modal {...modalCtrl}>
                <FranchisePicker value={parentFranchise} onCancel={modalCtrl.onClose} onSelect={onSelect} />
            </Modal>
        </Panel>
    );
};

const confirmDelete: ConfirmProps = {
    cancelButton: { content: 'Keep' },
    negative: true,
    confirmButton: {
        content: 'Remove',
        icon: 'cancel',
    },
    confirmMessage: {
        content: 'Remove child franchise?',
    },
};

const PanelParentHQ: React.FC<{ franchise: NodeType.Franchise; refetchQueries: any[] }> = ({
    franchise,
    refetchQueries,
}) => {
    const modalCtrl = useModalCtrl();
    const { setParentFranchise } = useMutationFranchise({ refetchQueries });

    const onRemoveFranchise = (franchiseToRemove: NodeType.Franchise) => {
        setParentFranchise({ id: franchiseToRemove.id, parentFranchise: null });
        modalCtrl.onClose();
    };

    const onAddChild = async (newChild: NodeType.Franchise | null) => {
        try {
            await setParentFranchise({ parentFranchise: franchise?.id, id: newChild.id });
            modalCtrl.onClose();
        } catch (e) {
            toastError(e);
        }
    };

    const childFranchises = fromEdges(franchise.childFranchises);
    const hasChildren = childFranchises.length > 0;
    return (
        <Panel>
            <Panel.Header button={{ content: 'Add Child', onClick: modalCtrl.onOpen }}>
                Dependent Franchises
            </Panel.Header>
            <Panel.Body>
                {hasChildren && (
                    <Table size={'small'} striped={true}>
                        <Table.Body>
                            {childFranchises.map((f) => {
                                return (
                                    <Table.Row>
                                        <Table.Cell>{f.name}</Table.Cell>
                                        <Table.Cell width={1}>
                                            <ButtonWithConfirm
                                                size={'mini'}
                                                confirm={confirmDelete}
                                                icon={'cancel'}
                                                onClick={() => onRemoveFranchise(f)}
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                )}
            </Panel.Body>
            <Modal {...modalCtrl}>
                <FranchisePicker value={null} onCancel={modalCtrl.onClose} onSelect={onAddChild} />
            </Modal>
        </Panel>
    );
};

export const PanelParentChildRelationship: React.FC<{ franchise: NodeType.Franchise; refetchQueries: any[] }> = ({
    franchise,
    refetchQueries,
}) => {
    const isHQ = franchise?.types?.includes(NodeType.FranchiseType.StoreHQ);

    if (isHQ) {
        return <PanelParentHQ refetchQueries={refetchQueries} franchise={franchise} />;
    } else {
        return <PanelChildFranchise refetchQueries={refetchQueries} franchise={franchise} />;
    }
};
