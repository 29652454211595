import { useMemo } from 'react';
import invariant from 'invariant';
import { useConexp } from './ConexpProvider';
import { ConexpItemType } from './types';

export const useConexpHook = (connectionId) => {
    const conexp = useConexp();
    invariant(conexp, 'Must be wrapped in ConexpProvider');
    return useMemo(() => {
        invariant(connectionId, 'connection id is required');
        const conexpItem = conexp.getConexpItemById(connectionId);
        const hooks = conexp.availableConexpHooks[conexpItem.connectionId];
        const { uiConfig } = conexp.getConnectionUIConfigById(connectionId);
        return {
            nodeHook: hooks.nodeHook,
            connectionHook: hooks.connectionHook,
            conexpItem,
            uiConfig,
        };
    }, [connectionId, conexp]);
};

export const useConexpAvailableConnections = () => {
    const conexp = useConexp();
    invariant(conexp, 'Must be wrapped in ConexpProvider');

    const availableConnections = useMemo<ConexpItemType[]>(() => {
        const lut = conexp.getConexpItems();
        return Object.keys(lut).map((key) => lut[key]);
    }, [conexp]);
    return availableConnections;
};
