import * as React from 'react';
import { useState } from 'react';
import { Checkbox } from '@ez/components';
import { fromEdges, NodeType, RoleFlagMutatorAdded } from '@poolware/api';
import { FeatureFlag } from '@poolware/app-shell';
import { Icon, Table, toastError } from '@ez/components';

const FlagCheckbox: React.FC<
    RoleFlagMutatorAdded & {
        role: NodeType.Role;
        flag: NodeType.RoleFlag;
        disabled?: boolean;
    }
> = (props) => {
    const [updating, setUpdating] = useState(false);

    const flipFlag = async (e, { checked }) => {
        const { role, flag } = props;
        setUpdating(true);
        try {
            if (checked) {
                await props.RoleFlagMutator.grantFlagToRole(role, flag);
            } else {
                await props.RoleFlagMutator.removeFlagFromRole(role, flag);
            }
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to change flag', description: e.message });
        }
        setUpdating(false);
    };

    const { role, flag } = props;
    const roleFlagsIds = fromEdges(role.flags).map((f) => f.id);
    const isActive = roleFlagsIds.includes(flag.id);

    if (updating) {
        return <Icon name={'spinner'} loading />;
    }

    return <Checkbox disabled={props.disabled} checked={isActive} onChange={flipFlag} />;
};

const RoleFlagAssignmentTable: React.SFC<
    {
        roles: NodeType.Role[];
        roleFlags: NodeType.RoleFlag[];
    } & RoleFlagMutatorAdded
> = ({ roles, roleFlags, RoleFlagMutator }) => {
    return (
        <Table color="grey" size="small">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Flag</Table.HeaderCell>
                    {roles.map((role) => {
                        return <Table.HeaderCell key={role.id}>{role.name}</Table.HeaderCell>;
                    })}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {roleFlags.map((flag) => {
                    const isAdminFlag = flag.tag === FeatureFlag.ADMIN;
                    return (
                        <Table.Row key={flag.id} warning={isAdminFlag}>
                            <Table.Cell>{flag.tag}</Table.Cell>
                            {roles.map((role) => {
                                return (
                                    <Table.Cell key={role.id} textAlign={'center'}>
                                        <FlagCheckbox
                                            disabled={isAdminFlag}
                                            role={role}
                                            flag={flag}
                                            RoleFlagMutator={RoleFlagMutator}
                                        />
                                    </Table.Cell>
                                );
                            })}
                        </Table.Row>
                    );
                })}
            </Table.Body>
        </Table>
    );
};

export default RoleFlagAssignmentTable;
