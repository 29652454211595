import * as React from 'react';
import { useState } from 'react';
import { useQuerySettingStoreConnection } from './use-query-setting-store-connection';
import {
    Button,
    ConnectionTableDef,
    DefaultConnectionTable,
    FormikDefaultForm,
    FormikInputField,
    MenuBarHeaderItem,
    MenuBarItem,
    MenuBarSection,
    Modal,
    PageLayout,
    StickyMenuBar,
    toastError,
    VStack,
} from '@ez/components';
import { NodeType, useMutationSettingStore } from '@poolware/api';
import { ButtonWithConfirm } from '@ez/components';

export interface EditFormProps {
    settingItem: NodeType.SettingStore;
    onClose: () => any;
    mutator: ReturnType<typeof useMutationSettingStore>;
}

export const EditForm: React.FC<EditFormProps> = ({ settingItem, onClose }) => {
    const mutator = useMutationSettingStore({ awaitRefetchQueries: true, refetchQueries: ['QuerySettingStore'] });

    const onSubmit = async (values: NodeType.SettingStore) => {
        try {
            await mutator.update({
                id: settingItem.id,
                value: values.value?.trim() || '',
            });
            onClose();
        } catch (e) {
            toastError({ title: 'Failed', description: e.message });
        }
    };

    const initialValues = { ...settingItem };

    return (
        <FormikDefaultForm initialValues={initialValues} onSubmit={onSubmit} onCancel={onClose}>
            <FormikInputField name={'name'} label={'Name'} readOnly />
            <FormikInputField name={'group'} label={'Group'} readOnly />
            <FormikInputField name={'value'} label={'Value'} />
        </FormikDefaultForm>
    );
};

export const NewForm: React.FC<EditFormProps> = ({ onClose, mutator }) => {
    const onSubmit = async (values: NodeType.SettingStore) => {
        try {
            await mutator.create({
                name: values.name?.trim(),
                group: values.group?.trim(),
                value: values.value?.trim(),
            });
            onClose();
        } catch (e) {
            toastError({ title: 'Failed', description: e.message });
        }
    };

    const initialValues = {
        name: '',
        group: 'frontend',
        value: '',
    };

    return (
        <FormikDefaultForm initialValues={initialValues} onSubmit={onSubmit} onCancel={onClose}>
            <FormikInputField name={'name'} label={'Name'} required />
            <FormikInputField name={'group'} label={'Group'} required />
            <FormikInputField name={'value'} label={'Value'} required />
        </FormikDefaultForm>
    );
};

export const SettingStore: React.FC = () => {
    const { connectionData, connectionState } = useQuerySettingStoreConnection();
    const mutator = useMutationSettingStore({ awaitRefetchQueries: true, refetchQueries: ['QuerySettingStore'] });
    const [settingItem, setEditItem] = useState(null);
    const [newItem, setNewItem] = useState(false);

    const onEdit = (item) => {
        setEditItem(item);
    };

    const onNew = () => {
        setNewItem(true);
    };

    const onDelete = async (item) => {
        try {
            await mutator.delete({ id: item.id });
        } catch (e) {
            toastError({ title: 'Failed to delete', description: e.message });
        }
    };

    const tableDef: ConnectionTableDef<NodeType.SettingStore> = [
        {
            header: 'Name',
            cell: (item) => {
                return item.name;
            },
        },
        {
            header: 'Value',
            cell: (item) => {
                return item.value;
            },
        },
        {
            header: 'Group',
            cell: (item) => {
                return item.group;
            },
        },
        {
            header: '',
            width: 1,
            cell: (item) => {
                return (
                    <div className={'gap-2 flex'}>
                        <Button content={'Edit'} size={'xs'} onClick={() => onEdit(item)} />
                        <ButtonWithConfirm
                            icon={'trash'}
                            color={'orange'}
                            size={'xs'}
                            onClick={() => onDelete(item)}
                            confirm={{
                                negative: true,
                                confirmMessage: {
                                    header: 'Delete Setting?',
                                    content: 'There is no undo! This setting will be permanently deleted! ',
                                },
                                confirmButton: {
                                    content: 'Delete',
                                    icon: 'trash',
                                },
                            }}
                        />
                    </div>
                );
            },
        },
    ];

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem>Settings Store</MenuBarHeaderItem>
                </MenuBarSection>
                <MenuBarSection position={'right'}>
                    <MenuBarItem icon={'plus'} color={'green'} onClick={onNew}>
                        New
                    </MenuBarItem>
                </MenuBarSection>
            </StickyMenuBar>
            <DefaultConnectionTable
                tableDef={tableDef}
                connectionData={connectionData}
                connectionState={connectionState}
            />
            <Modal size={'small'} open={!!settingItem} centered={false}>
                <EditForm settingItem={settingItem} onClose={() => setEditItem(null)} mutator={mutator} />
            </Modal>

            <Modal size={'small'} open={!!newItem} centered={false}>
                <NewForm settingItem={null} onClose={() => setNewItem(null)} mutator={mutator} />
            </Modal>
        </PageLayout>
    );
};
