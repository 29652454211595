import * as React from 'react';
import {
    MenuBarDropdownItemWithConfirm,
    MenuBarGroup,
    MenuBarGroupMODE,
    MenuBarGroupProps,
    MenuBarHeaderItem,
    MenuBarSection,
    PageLayout,
    StickyMenuBar,
    SuspenseSkeletonLoader,
    toastError,
} from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useMutationFranchiseWarranter } from '@poolware/api';
import { NotFoundPage } from '@poolware/app-shell';
import { useQueryFranchiseWarranter } from './use-query-franchise-warranter';
import { WarranterForm } from './WarranterForm';
import * as _ from 'lodash';

export interface PageProps {}

export const PageEdit: React.FC<PageProps> = () => {
    const { AppNavigator, params } = useAppNavigator<{ id?: string }>();
    const { node, error, loading, refetchQuery } = useQueryFranchiseWarranter(params.id);
    const { update, delete: deleteMutation } = useMutationFranchiseWarranter({
        refetchQueries: [refetchQuery],
    });

    if (loading || error) {
        return <SuspenseSkeletonLoader error={error} loading={loading} />;
    }

    if (!loading && !node) {
        return <NotFoundPage />;
    }

    const goToList = () => {
        AppNavigator.navigateRelative(`/`);
    };

    const onSubmit = async (values) => {
        await update({
            id: node.id,
            name: _.trim(values.name),
            email: _.trim(values.email),
            actingFranchise: values.actingFranchise?.id || null,
        });

        goToList();
    };

    const canDelete = node.checkMutations?.delete;

    const onDelete = async () => {
        try {
            await deleteMutation({ id: params.id });
            goToList();
        } catch (e) {
            toastError(e);
        }
    };

    const barGroupItemConf: MenuBarGroupProps = !canDelete
        ? undefined
        : {
              menu: { menuMode: MenuBarGroupMODE.DROPDOWN, icon: 'bars', color: 'red' },
              items: [
                  {
                      render: (
                          <MenuBarDropdownItemWithConfirm
                              icon={'trash'}
                              color="orange"
                              content="Delete"
                              onClick={onDelete}
                              popup={{ content: 'Delete Warranty Office' }}
                              confirm={{
                                  confirmMessage: {
                                      header: 'Delete?',
                                      content: 'There is no undo! This Offices will be deleted forever! ',
                                  },
                                  negative: true,
                                  confirmButton: {
                                      content: 'Delete',
                                      icon: 'trash',
                                  },
                              }}
                          />
                      ),
                  },
              ],
          };

    const initialValues = {
        name: node.name,
        email: node.email,
        actingFranchise: node.actingFranchise,
    };

    return (
        <PageLayout width={'screen-wide'}>
            <StickyMenuBar>
                <MenuBarSection>
                    {/*<MenuBarItem onClick={goToView} icon={'chevron left'} title={'Back to list'} />*/}
                    <MenuBarHeaderItem icon={'certificate'}>Warranty Office</MenuBarHeaderItem>
                </MenuBarSection>
                <MenuBarSection position={'right'}>
                    {barGroupItemConf && <MenuBarGroup {...barGroupItemConf} />}
                </MenuBarSection>
            </StickyMenuBar>

            <PageLayout.BodySection width={'screen-md'}>
                <WarranterForm debug={true} initialValues={initialValues} onSubmit={onSubmit} onCancel={goToList} />
            </PageLayout.BodySection>
        </PageLayout>
    );
};
