import {
    ConnectionTableDef,
    DefaultConnectionTable,
    FormFieldInputDebounced,
    LinkButton,
    Modal,
    useModalCtrl,
    VStack,
} from '@ez/components';
import * as React from 'react';
import { useState } from 'react';
import { fromEdges, NodeType, useQueryFranchiseConnection } from '@poolware/api';
import { queryFranchisesConnectionQL } from './use-query-franchises-connection';
import { SuggestInputOrganisationType } from '@poolware/app-shell';
import { PanelFranchiseApiKeys } from './PanelFranchiseApiKeys';

export interface FranchiseListProps {}

export const FranchiseList: React.FC<FranchiseListProps> = ({}) => {
    const [filterName, setFilterName] = useState('');
    const [filterOrg, setFilterOrg] = useState(null);

    const { connectionState, connectionData } = useQueryFranchiseConnection({
        query: queryFranchisesConnectionQL,
        searchName: filterName,
        searchOrgId: filterOrg?.id,
    });
    const [franchise, setFranchise] = useState<NodeType.Franchise>();

    const hasFilters = !!filterName || !!filterOrg;
    const onClear = () => {
        setFilterName('');
        setFilterOrg(null);
    };

    const modalCtrl = useModalCtrl();

    const onEdit = (franchise) => {
        setFranchise(franchise);
        modalCtrl.onOpen();
    };

    const tableDef: ConnectionTableDef<NodeType.Franchise> = [
        {
            header: 'Name',
            sortKey: 'name',
            width: 3,
            cell: (item) => {
                return <>{item.name}</>;
            },
        },
        {
            header: 'API Key',
            cell: (item) => {
                const tokens = fromEdges(item.staffAccessTokens)
                    .map((t) => t.token?.slice(0, 15) + '...')
                    .join(', ');
                return <>{tokens}</>;
            },
        },
        {
            header: 'Types',
            cell: (item) => {
                return item.types?.join(', ');
            },
        },
        {
            header: 'Org',
            cell: (item) => {
                return item?.organisationType?.name;
            },
        },
    ];

    return (
        <VStack>
            <h3>Franchise API Keys</h3>
            <div className={'flex flex-row flex-wrap gap-2 md:max-w-screen-lg w-full'}>
                <FormFieldInputDebounced
                    label={'Franchise Name'}
                    variant={'search'}
                    value={filterName}
                    onChange={setFilterName}
                />
                <div className={'flex-1'}>
                    <SuggestInputOrganisationType label={'Organisation'} value={filterOrg} onChange={setFilterOrg} />
                </div>
                <div className={'flex-1 self-end w-4 pb-2 underline'}>
                    {hasFilters && <LinkButton onClick={onClear}>clear</LinkButton>}
                </div>
            </div>

            <DefaultConnectionTable
                onRowClick={onEdit}
                tableDef={tableDef}
                connectionData={connectionData}
                connectionState={connectionState}
            />

            {modalCtrl.open && (
                <Modal {...modalCtrl} closeIcon={true}>
                    <Modal.Content>
                        <PanelFranchiseApiKeys id={franchise?.id} />
                    </Modal.Content>
                </Modal>
            )}
        </VStack>
    );
};
