import { ModuleRootSwitch, ModuleRoute } from '@poolware/react-app-navigator';
import * as React from 'react';
import NewProductCompany from './NewProductCompany';
import ViewCompany from './ViewCompany';
import ListProductCompanies from './ListProductCompanies/ListProductCompanies';
import { ListBrands } from './ListBrands/ListBrands';
import NewBrand from './NewBrand';
import { ViewCompanyBrandDetails } from './ViewCompany/ViewCompanyBrand';

export default () => {
    return (
        <ModuleRootSwitch>
            <ModuleRoute path={'/new-brand'} component={NewBrand} />
            <ModuleRoute path={'/new-company'} exact component={NewProductCompany} />
            <ModuleRoute path={'/brands'} component={ListBrands} />
            <ModuleRoute path={`/:companyId/brands/:brandId`} component={ViewCompanyBrandDetails} />
            <ModuleRoute path={'/:id'} component={ViewCompany} />
            <ModuleRoute path={'/'} exact component={ListProductCompanies} />
        </ModuleRootSwitch>
    );
};
