import * as React from 'react';
import {
    fromEdges,
    NodeType,
    useMutationStaffAccessToken,
    useMutationStaffAccessTokenRegistration,
} from '@poolware/api';
import {
    Button,
    confirmModalImperative,
    ConfirmProps,
    ConnectionErrorMessage,
    PageSkeletonLoader,
    Panel,
} from '@ez/components';
import { NotFoundPage } from '@poolware/app-shell';
import { mutateStaffAccessTokenRegistrationQuery, useQueryFranchise } from './use-query-franchises-connection';

export interface FranchisePageProps {
    id: NodeType.ID;
}

const spinner = (loading: Boolean, error, data) => {
    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    } else if (!loading && !data) {
        return <NotFoundPage />;
    }

    return null;
};

const confirmDelete: ConfirmProps = {
    cancelButton: { content: 'Keep' },
    negative: true,
    confirmButton: {
        content: 'Delete',
        icon: 'trash',
    },
    confirmMessage: {
        content: 'Delete API key?',
    },
};

const confirmCreate: ConfirmProps = {
    confirmButton: {
        content: 'Create',
    },
    confirmMessage: {
        content: 'Create API key?',
    },
};

const confirmRegen: ConfirmProps = {
    confirmButton: {
        content: 'Regenerate',
    },
    confirmMessage: {
        content: 'Current API Key will be invalidated, and new key will be created?',
    },
};

export const PanelFranchiseApiKeys: React.FC<FranchisePageProps> = ({ id }) => {
    const { node, loading, error, refetchQuery } = useQueryFranchise(id);
    const { resolveAndRefreshManagerToken } = useMutationStaffAccessTokenRegistration({
        refetchQueries: [refetchQuery],
        mutation: mutateStaffAccessTokenRegistrationQuery,
    });
    const { delete: deleteToken } = useMutationStaffAccessToken({ refetchQueries: [refetchQuery] });

    const l = spinner(loading, error, node);
    if (l) return l;

    const tokens = fromEdges(node?.staffAccessTokens);

    const onDelete = async (token: NodeType.StaffAccessToken) => {
        const res = await confirmModalImperative(confirmDelete);
        if (!res) {
            return;
        }
        await deleteToken({ id: token.id });
    };

    const onCreate = async () => {
        const res = await confirmModalImperative(confirmCreate);
        if (!res) {
            return;
        }
        await resolveAndRefreshManagerToken({ franchise: node.id });
    };

    const onRegenerate = async () => {
        const res = await confirmModalImperative(confirmRegen);
        if (!res) {
            return;
        }
        await resolveAndRefreshManagerToken({ franchise: node.id });
    };

    return (
        <div>
            {tokens.length === 0 && (
                <div>
                    <Button content={'Register API Key'} onClick={onCreate} />
                </div>
            )}
            {tokens.map((t) => {
                return (
                    <Panel key={t.id}>
                        <Panel.Header
                            content={'API Token'}
                            button={[
                                {
                                    content: 'Edit',
                                    icon: 'edit',
                                    menuItems: [
                                        {
                                            content: 'Regenerate Token',
                                            icon: 'refresh',
                                            onClick: () => onRegenerate(),
                                        },
                                        {
                                            content: 'Delete',
                                            icon: 'trash',
                                            onClick: () => onDelete(t),
                                        },
                                    ],
                                },
                            ]}
                        />
                        <Panel.Body>
                            <Panel.ItemEntity label={'Staff'} content={t.staff} />
                            <Panel.Item label={'Role'} content={t.staff?.role?.name || '--'} />
                            <Panel.Item
                                label={'Is Hidden user'}
                                content={t.staff?.isSystemHidden ? 'System Hidden' : 'No'}
                            />
                            <Panel.ItemText label={'Token'} content={t.token} />
                        </Panel.Body>
                    </Panel>
                );
            })}
        </div>
    );
};
