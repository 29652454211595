//
// DO NOT EDIT!!!
// THIS IS AN AUTOMATICALLY GENERATED FILE.
// Date:  Tue Aug 13 2024 16:10:05 GMT+1000 (Australian Eastern Standard Time)
//

import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';

// ************** abstractDocuments ***************** //

// abstractDocuments: gql query

export const fragmentAbstractDocumentNode = gql`
    fragment AbstractDocumentConexpFragment on AbstractDocument {
        addedBy {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        addedBy {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        checkMutations {
            delete
            update
        }
        completedAt
        completedById {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        completedById {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        completionNote
        createdAt
        customer {
            addedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            assignedTo {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            billAddress {
                id
            }
            companyName
            contactName
            createdAt
            crn
            id
            note
            primaryAddress {
                id
            }
            refId
            registeredAt
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        dateDue
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        id
        issuedAt
        jsonBody
        refNumber
        serviceJob {
            address {
                id
            }
            archivedAt
            closedAt
            createdAt
            createdBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            description
            dueDate
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            id
            isImplicit
            isRecurring
            isSimpleMode
            lastAppointmentDate
            serviceJobNumber
            staff {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            stopsAt
            title
            updatedAt
        }
        statusTag
        typeTag
        updatedAt
        workOrder {
            address {
                id
            }
            archivedAt
            assignedTo {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            checkedInAt
            checkedOutAt
            createdAt
            createdBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            description
            dueRangeEnd
            dueRangeStart
            id
            paymentUrl
            requiresAddress
            requiresAppointment
            requiresAssignedTo
            requiresPool
            requiresPreferredContact
            requiresTestReport
            requiresWorkFor
            statusClosedAt
            title
            updatedAt
            workFor {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            workOrderNumber
        }
    }
`;

export const createGqlQueryAbstractDocumentsConnection = (_nodeFragment = fragmentAbstractDocumentNode) => gql`
    query QueryAbstractDocumentsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: AbstractDocumentViewerAbstractDocumentsSearch
        $sort: AbstractDocumentViewerAbstractDocumentsSort
    ) {
        viewer {
            abstractDocuments(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...AbstractDocumentConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryAbstractDocumentsConnectionGql =
    createGqlQueryAbstractDocumentsConnection(fragmentAbstractDocumentNode);

export const queryAbstractDocumentNode = gql`
    query queryConexpNodeAbstractDocument($id: ID!) {
        node(id: $id) {
            id
            ...AbstractDocumentConexpFragment
        }
    }
    ${fragmentAbstractDocumentNode}
`;

// ************** announcements ***************** //

// announcements: gql query

export const fragmentAnnouncementNode = gql`
    fragment AnnouncementConexpFragment on Announcement {
        addedBy {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        addedBy {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        authorName
        body
        checkMutations {
            delete
            publish
            unpublish
            update
        }
        createdAt
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        id
        link
        organisationType {
            id
            locales
            logo
            name
            timeZone
            token
        }
        preview
        publishedAt
        subject
    }
`;

export const createGqlQueryAnnouncementsConnection = (_nodeFragment = fragmentAnnouncementNode) => gql`
    query QueryAnnouncementsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: AnnouncementViewerAnnouncementsSearch
    ) {
        viewer {
            announcements(after: $after, before: $before, first: $first, last: $last, page: $page, search: $search) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...AnnouncementConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryAnnouncementsConnectionGql = createGqlQueryAnnouncementsConnection(fragmentAnnouncementNode);

export const queryAnnouncementNode = gql`
    query queryConexpNodeAnnouncement($id: ID!) {
        node(id: $id) {
            id
            ...AnnouncementConexpFragment
        }
    }
    ${fragmentAnnouncementNode}
`;

// ************** appointmentGroups ***************** //

// appointmentGroups: gql query

export const fragmentAppointmentGroupNode = gql`
    fragment AppointmentGroupConexpFragment on AppointmentGroup {
        checkMutations {
            delete
            update
        }
        colorPalette
        createdAt
        defaultColor
        description
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        id
        priority
        statusTitleCustom
        statusTitleFailed
        statusTitleFinished
        statusTitlePending
        statusTitleStarted
        statusVisibilityCustom
        statusVisibilityStarted
        title
        updatedAt
    }
`;

export const createGqlQueryAppointmentGroupsConnection = (_nodeFragment = fragmentAppointmentGroupNode) => gql`
    query QueryAppointmentGroupsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: AppointmentGroupViewerAppointmentGroupsSearch
        $sort: AppointmentGroupViewerAppointmentGroupsSort
    ) {
        viewer {
            appointmentGroups(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...AppointmentGroupConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryAppointmentGroupsConnectionGql =
    createGqlQueryAppointmentGroupsConnection(fragmentAppointmentGroupNode);

export const queryAppointmentGroupNode = gql`
    query queryConexpNodeAppointmentGroup($id: ID!) {
        node(id: $id) {
            id
            ...AppointmentGroupConexpFragment
        }
    }
    ${fragmentAppointmentGroupNode}
`;

// ************** appointmentItems ***************** //

// appointmentItems: gql query

export const fragmentAppointmentItemNode = gql`
    fragment AppointmentItemConexpFragment on AppointmentItem {
        address {
            id
        }
        address {
            buildingName
            city
            country
            createdAt
            destinationName
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            id
            isBill
            isPrimary
            poBoxNumber
            postCode
            refId
            state
            streetName
            streetNumber
            suburb
            uuid
        }
        adhoc
        appointment {
            addedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            endPatternDate
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            patternBlockId
            startDate
        }
        checkMutations {
            addSale
            changeRecurrence
            changeRecurrenceFrom
            delete
            startWorkOrder
            update
            updateFuture
            updateSingle
        }
        color
        customer {
            addedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            assignedTo {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            billAddress {
                id
            }
            companyName
            contactName
            createdAt
            crn
            id
            note
            primaryAddress {
                id
            }
            refId
            registeredAt
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        deleted
        duration
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        group {
            colorPalette
            createdAt
            defaultColor
            description
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            priority
            statusTitleCustom
            statusTitleFailed
            statusTitleFinished
            statusTitlePending
            statusTitleStarted
            statusVisibilityCustom
            statusVisibilityStarted
            title
            updatedAt
        }
        id
        isEnd
        isRecurrentOrigin
        isRecurring
        isStart
        isWorkOrderPending
        note
        originPattern {
            dayOfMonth
            interval
        }
        originalDate
        patternBlockId
        paymentUrl
        pool {
            address {
                id
            }
            bottleNumber
            createdAt
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            name
            note
            notePlain
            refId
            revision
            uuid
        }
        serviceJob {
            address {
                id
            }
            archivedAt
            closedAt
            createdAt
            createdBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            description
            dueDate
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            id
            isImplicit
            isRecurring
            isSimpleMode
            lastAppointmentDate
            serviceJobNumber
            staff {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            stopsAt
            title
            updatedAt
        }
        splitAppointment {
            addedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            endPatternDate
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            patternBlockId
            startDate
        }
        staff {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        staff {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        startDate
        status
        vendSale {
            historyUrl
            id
            webRegistryUrl
        }
        workOrder {
            address {
                id
            }
            archivedAt
            assignedTo {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            checkedInAt
            checkedOutAt
            createdAt
            createdBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            description
            dueRangeEnd
            dueRangeStart
            id
            paymentUrl
            requiresAddress
            requiresAppointment
            requiresAssignedTo
            requiresPool
            requiresPreferredContact
            requiresTestReport
            requiresWorkFor
            statusClosedAt
            title
            updatedAt
            workFor {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            workOrderNumber
        }
    }
`;

export const createGqlQueryAppointmentItemsConnection = (_nodeFragment = fragmentAppointmentItemNode) => gql`
    query QueryAppointmentItemsConnection(
        $addedBy: ID
        $after: String
        $before: String
        $customer: ID
        $endDate: Date
        $first: Int
        $franchise: ID
        $group: ID
        $isRecurrent: Boolean
        $last: Int
        $patternBlockId: String
        $pivotDate: Date
        $pool: ID
        $reverse: Boolean
        $search: AppointmentItemSearchCommon
        $serviceJob: ID
        $staff: ID
        $startDate: Date
    ) {
        viewer {
            appointmentItems(
                addedBy: $addedBy
                after: $after
                before: $before
                customer: $customer
                endDate: $endDate
                first: $first
                franchise: $franchise
                group: $group
                isRecurrent: $isRecurrent
                last: $last
                patternBlockId: $patternBlockId
                pivotDate: $pivotDate
                pool: $pool
                reverse: $reverse
                search: $search
                serviceJob: $serviceJob
                staff: $staff
                startDate: $startDate
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }

                edges {
                    node {
                        ...AppointmentItemConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryAppointmentItemsConnectionGql = createGqlQueryAppointmentItemsConnection(fragmentAppointmentItemNode);

export const queryAppointmentItemNode = gql`
    query queryConexpNodeAppointmentItem($id: ID!) {
        node(id: $id) {
            id
            ...AppointmentItemConexpFragment
        }
    }
    ${fragmentAppointmentItemNode}
`;

// ************** appointments ***************** //

// appointments: gql query

export const fragmentAppointmentNode = gql`
    fragment AppointmentConexpFragment on Appointment {
        addedBy {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        addedBy {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        baseInstance {
            address {
                id
            }
            color
            completed
            createdAt
            duration
            id
            note
            originalDate
            patternExcepted
            paymentUrl
            selectedColor
            staff {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            startDate
        }
        checkMutations {
            changeEndDate
            delete
            split
            update
            updateTimeZone
        }
        endPatternDate
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        id
        parentAppointment {
            addedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            endPatternDate
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            patternBlockId
            startDate
        }
        patternBlockId
        startDate
        workOrderTemplate {
            addedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            archivedAt
            createdAt
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            hideStageActionRequired
            hideStageInProgress
            hideStageSuspended
            id
            onlyOwnedStages
            providedDescription
            providedTitle
            requiresAddress
            requiresContact
            requiresCustomer
            requiresPool
            requiresStaff
            tag
            templateDescription
            templateTitle
            updatedAt
        }
    }
`;

export const createGqlQueryAppointmentsConnection = (_nodeFragment = fragmentAppointmentNode) => gql`
    query QueryAppointmentsConnection(
        $after: String
        $before: String
        $endDate: Date
        $first: Int
        $last: Int
        $search: AppointmentViewerAppointmentsSearch
        $startDate: Date
    ) {
        viewer {
            appointments(
                after: $after
                before: $before
                endDate: $endDate
                first: $first
                last: $last
                search: $search
                startDate: $startDate
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }

                edges {
                    node {
                        ...AppointmentConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryAppointmentsConnectionGql = createGqlQueryAppointmentsConnection(fragmentAppointmentNode);

export const queryAppointmentNode = gql`
    query queryConexpNodeAppointment($id: ID!) {
        node(id: $id) {
            id
            ...AppointmentConexpFragment
        }
    }
    ${fragmentAppointmentNode}
`;

// ************** brands ***************** //

// brands: gql query

export const fragmentBrandNode = gql`
    fragment BrandConexpFragment on Brand {
        checkMutations {
            delete
            update
        }
        company {
            createdAt
            description
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            name
            updatedAt
        }
        createdAt
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        id
        identification
        name
        updatedAt
    }
`;

export const createGqlQueryBrandsConnection = (_nodeFragment = fragmentBrandNode) => gql`
    query QueryBrandsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: BrandViewerBrandsSearch
        $searchTraits: BrandViewerBrandsSearchTraits
        $sort: BrandViewerBrandsSort
    ) {
        viewer {
            brands(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                searchTraits: $searchTraits
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...BrandConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryBrandsConnectionGql = createGqlQueryBrandsConnection(fragmentBrandNode);

export const queryBrandNode = gql`
    query queryConexpNodeBrand($id: ID!) {
        node(id: $id) {
            id
            ...BrandConexpFragment
        }
    }
    ${fragmentBrandNode}
`;

// ************** consumableProducts ***************** //

// consumableProducts: gql query

export const fragmentConsumableProductNode = gql`
    fragment ConsumableProductConexpFragment on ConsumableProduct {
        checkMutations {
            delete
            update
        }
        id
        product {
            addedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            createdAt
            customSku
            description
            familyCode
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            identification
            isGrouping
            isOneOff
            name
            retiredAt
            retiredBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            sku
            updatedAt
        }
    }
`;

export const createGqlQueryConsumableProductsConnection = (_nodeFragment = fragmentConsumableProductNode) => gql`
    query QueryConsumableProductsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: ConsumableProductViewerConsumableProductsSearch
        $sort: ConsumableProductViewerConsumableProductsSort
    ) {
        viewer {
            consumableProducts(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...ConsumableProductConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryConsumableProductsConnectionGql =
    createGqlQueryConsumableProductsConnection(fragmentConsumableProductNode);

export const queryConsumableProductNode = gql`
    query queryConexpNodeConsumableProduct($id: ID!) {
        node(id: $id) {
            id
            ...ConsumableProductConexpFragment
        }
    }
    ${fragmentConsumableProductNode}
`;

// ************** contactTypes ***************** //

// contactTypes: gql query

export const fragmentContactTypeNode = gql`
    fragment ContactTypeConexpFragment on ContactType {
        checkMutations {
            update
        }
        iconPath
        id
        identificationTag
        name
        refId
        uuid
        validatorTag
    }
`;

export const createGqlQueryContactTypesConnection = (_nodeFragment = fragmentContactTypeNode) => gql`
    query QueryContactTypesConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: ContactTypeViewerContactTypesSearch
        $sort: ContactTypeViewerContactTypesSort
    ) {
        viewer {
            contactTypes(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...ContactTypeConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryContactTypesConnectionGql = createGqlQueryContactTypesConnection(fragmentContactTypeNode);

export const queryContactTypeNode = gql`
    query queryConexpNodeContactType($id: ID!) {
        node(id: $id) {
            id
            ...ContactTypeConexpFragment
        }
    }
    ${fragmentContactTypeNode}
`;

// ************** converters ***************** //

// converters: gql query

export const fragmentSampleConverterNode = gql`
    fragment SampleConverterConexpFragment on SampleConverter {
        checkMutations {
            delete
            update
        }
        id
        name
        tag
    }
`;

export const createGqlQueryConvertersConnection = (_nodeFragment = fragmentSampleConverterNode) => gql`
    query QueryConvertersConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $search: SampleConverterViewerConvertersSearch
    ) {
        viewer {
            converters(after: $after, before: $before, first: $first, last: $last, search: $search) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }

                edges {
                    node {
                        ...SampleConverterConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryConvertersConnectionGql = createGqlQueryConvertersConnection(fragmentSampleConverterNode);

export const querySampleConverterNode = gql`
    query queryConexpNodeSampleConverter($id: ID!) {
        node(id: $id) {
            id
            ...SampleConverterConexpFragment
        }
    }
    ${fragmentSampleConverterNode}
`;

// ************** customContracts ***************** //

// customContracts: gql query

export const fragmentCustomContractNode = gql`
    fragment CustomContractConexpFragment on CustomContract {
        addedBy {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        addedBy {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        assignedTo {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        assignedTo {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        checkMutations {
            delete
            update
        }
        contractNumber
        createdAt
        endDate
        entity {
            ... on Entity {
                id
                user {
                    id
                    firstName
                    lastName
                }
            }
        }
        entity {
            active
            chainUpdate
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            lastReportActivity
            uuid
        }
        frequency
        id
        note
        rate
        startDate
        status
        term
        terminationDate
        updatedAt
    }
`;

export const createGqlQueryCustomContractsConnection = (_nodeFragment = fragmentCustomContractNode) => gql`
    query QueryCustomContractsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: CustomContractViewerCustomContractsSearch
        $sort: CustomContractViewerCustomContractsSort
    ) {
        viewer {
            customContracts(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...CustomContractConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryCustomContractsConnectionGql = createGqlQueryCustomContractsConnection(fragmentCustomContractNode);

export const queryCustomContractNode = gql`
    query queryConexpNodeCustomContract($id: ID!) {
        node(id: $id) {
            id
            ...CustomContractConexpFragment
        }
    }
    ${fragmentCustomContractNode}
`;

// ************** customerTags ***************** //

// customerTags: gql query

export const fragmentCustomerTagNode = gql`
    fragment CustomerTagConexpFragment on CustomerTag {
        addedBy {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        addedBy {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        checkMutations {
            delete
            update
        }
        createdAt
        description
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        id
        name
        organisationType {
            id
            locales
            logo
            name
            timeZone
            token
        }
    }
`;

export const createGqlQueryCustomerTagsConnection = (_nodeFragment = fragmentCustomerTagNode) => gql`
    query QueryCustomerTagsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: CustomerTagViewerCustomerTagsSearch
        $sort: CustomerTagViewerCustomerTagsSort
    ) {
        viewer {
            customerTags(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...CustomerTagConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryCustomerTagsConnectionGql = createGqlQueryCustomerTagsConnection(fragmentCustomerTagNode);

export const queryCustomerTagNode = gql`
    query queryConexpNodeCustomerTag($id: ID!) {
        node(id: $id) {
            id
            ...CustomerTagConexpFragment
        }
    }
    ${fragmentCustomerTagNode}
`;

// ************** customers ***************** //

// customers: gql query

export const fragmentCustomerNode = gql`
    fragment CustomerConexpFragment on Customer {
        addedBy {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        addedBy {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        assignedTo {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        assignedTo {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        billAddress {
            id
        }
        billAddress {
            buildingName
            city
            country
            createdAt
            destinationName
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            id
            isBill
            isPrimary
            poBoxNumber
            postCode
            refId
            state
            streetName
            streetNumber
            suburb
            uuid
        }
        checkMutations {
            delete
            update
        }
        companyName
        contactName
        createdAt
        crn
        id
        nextAppointment {
            address {
                id
            }
            adhoc
            color
            deleted
            duration
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            isEnd
            isRecurrentOrigin
            isRecurring
            isStart
            isWorkOrderPending
            note
            originalDate
            patternBlockId
            paymentUrl
            staff {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            startDate
        }
        note
        primaryAddress {
            id
        }
        primaryAddress {
            buildingName
            city
            country
            createdAt
            destinationName
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            id
            isBill
            isPrimary
            poBoxNumber
            postCode
            refId
            state
            streetName
            streetNumber
            suburb
            uuid
        }
        primaryEmail {
            createdAt
            data
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            id
            isPrimary
            label
            refId
            updatedAt
            uuid
        }
        primaryPhone {
            createdAt
            data
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            id
            isPrimary
            label
            refId
            updatedAt
            uuid
        }
        refId
        registeredAt
        root {
            ... on Entity {
                id
                user {
                    id
                    firstName
                    lastName
                }
            }
        }
        root {
            active
            chainUpdate
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            lastReportActivity
            uuid
        }
        user {
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            firstName
            id
            lastName
            staff {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            title
        }
        vendLink {
            id
            isDeleted
            vendURL
            vendUUID
        }
    }
`;

export const createGqlQueryCustomersConnection = (_nodeFragment = fragmentCustomerNode) => gql`
    query QueryCustomersConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: CustomerViewerCustomersSearch
        $sort: CustomerViewerCustomersSort
    ) {
        viewer {
            customers(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...CustomerConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryCustomersConnectionGql = createGqlQueryCustomersConnection(fragmentCustomerNode);

export const queryCustomerNode = gql`
    query queryConexpNodeCustomer($id: ID!) {
        node(id: $id) {
            id
            ...CustomerConexpFragment
        }
    }
    ${fragmentCustomerNode}
`;

// ************** emailGlobalTemplateSelections ***************** //

// emailGlobalTemplateSelections: gql query

export const fragmentEmailGlobalSelectionNode = gql`
    fragment EmailGlobalSelectionConexpFragment on EmailGlobalSelection {
        checkMutations {
            delete
        }
        id
        template {
            createdAt
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            isSelectedForOwnFranchise
            isSelectedForOwnOrganisation
            isSelectedGlobally
            name
            selectedForFranchises {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            templateBody
            templateMetadata
            updatedAt
        }
        type {
            defaultBody
            id
            name
            tagName
        }
    }
`;

export const createGqlQueryEmailGlobalTemplateSelectionsConnection = (
    _nodeFragment = fragmentEmailGlobalSelectionNode
) => gql`
    query QueryEmailGlobalTemplateSelectionsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: EmailGlobalSelectionViewerEmailGlobalTemplateSelectionsSearch
    ) {
        viewer {
            emailGlobalTemplateSelections(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...EmailGlobalSelectionConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryEmailGlobalTemplateSelectionsConnectionGql = createGqlQueryEmailGlobalTemplateSelectionsConnection(
    fragmentEmailGlobalSelectionNode
);

export const queryEmailGlobalSelectionNode = gql`
    query queryConexpNodeEmailGlobalSelection($id: ID!) {
        node(id: $id) {
            id
            ...EmailGlobalSelectionConexpFragment
        }
    }
    ${fragmentEmailGlobalSelectionNode}
`;

// ************** emailOrganisationTemplateSelections ***************** //

// emailOrganisationTemplateSelections: gql query

export const fragmentEmailTemplateOrganisationSelectionNode = gql`
    fragment EmailTemplateOrganisationSelectionConexpFragment on EmailTemplateOrganisationSelection {
        checkMutations {
            delete
        }
        id
        organisation {
            id
            locales
            logo
            name
            timeZone
            token
        }
        template {
            createdAt
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            isSelectedForOwnFranchise
            isSelectedForOwnOrganisation
            isSelectedGlobally
            name
            selectedForFranchises {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            templateBody
            templateMetadata
            updatedAt
        }
        type {
            defaultBody
            id
            name
            tagName
        }
    }
`;

export const createGqlQueryEmailOrganisationTemplateSelectionsConnection = (
    _nodeFragment = fragmentEmailTemplateOrganisationSelectionNode
) => gql`
    query QueryEmailOrganisationTemplateSelectionsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: EmailTemplateOrganisationSelectionViewerEmailOrganisationTemplateSelectionsSearch
        $sort: EmailTemplateOrganisationSelectionViewerEmailOrganisationTemplateSelectionsSort
    ) {
        viewer {
            emailOrganisationTemplateSelections(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...EmailTemplateOrganisationSelectionConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryEmailOrganisationTemplateSelectionsConnectionGql =
    createGqlQueryEmailOrganisationTemplateSelectionsConnection(fragmentEmailTemplateOrganisationSelectionNode);

export const queryEmailTemplateOrganisationSelectionNode = gql`
    query queryConexpNodeEmailTemplateOrganisationSelection($id: ID!) {
        node(id: $id) {
            id
            ...EmailTemplateOrganisationSelectionConexpFragment
        }
    }
    ${fragmentEmailTemplateOrganisationSelectionNode}
`;

// ************** emailTemplateSelections ***************** //

// emailTemplateSelections: gql query

export const fragmentEmailTemplateSelectionNode = gql`
    fragment EmailTemplateSelectionConexpFragment on EmailTemplateSelection {
        checkMutations {
            delete
        }
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        id
        template {
            createdAt
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            isSelectedForOwnFranchise
            isSelectedForOwnOrganisation
            isSelectedGlobally
            name
            selectedForFranchises {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            templateBody
            templateMetadata
            updatedAt
        }
        type {
            defaultBody
            id
            name
            tagName
        }
    }
`;

export const createGqlQueryEmailTemplateSelectionsConnection = (
    _nodeFragment = fragmentEmailTemplateSelectionNode
) => gql`
    query QueryEmailTemplateSelectionsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: EmailTemplateSelectionViewerEmailTemplateSelectionsSearch
        $sort: EmailTemplateSelectionViewerEmailTemplateSelectionsSort
    ) {
        viewer {
            emailTemplateSelections(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...EmailTemplateSelectionConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryEmailTemplateSelectionsConnectionGql = createGqlQueryEmailTemplateSelectionsConnection(
    fragmentEmailTemplateSelectionNode
);

export const queryEmailTemplateSelectionNode = gql`
    query queryConexpNodeEmailTemplateSelection($id: ID!) {
        node(id: $id) {
            id
            ...EmailTemplateSelectionConexpFragment
        }
    }
    ${fragmentEmailTemplateSelectionNode}
`;

// ************** emailTemplates ***************** //

// emailTemplates: gql query

export const fragmentEmailTemplateNode = gql`
    fragment EmailTemplateConexpFragment on EmailTemplate {
        checkMutations {
            delete
            selectForFranchise
            selectForOrganisation
            selectGlobally
            unselectForFranchise
            unselectForOrganisation
            unselectGlobally
            update
        }
        createdAt
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        id
        isSelectedForOwnFranchise
        isSelectedForOwnOrganisation
        isSelectedGlobally
        name
        organisation {
            id
            locales
            logo
            name
            timeZone
            token
        }
        preview {
            emailBody
            error
        }
        provider
        selectedForFranchises {
            id
            name
            organisationType {
                id
                name
            }
        }
        selectedForFranchises {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        selectedForOrganisations {
            id
            locales
            logo
            name
            timeZone
            token
        }
        templateBody
        templateMetadata
        type {
            defaultBody
            id
            name
            tagName
        }
        updatedAt
    }
`;

export const createGqlQueryEmailTemplatesConnection = (_nodeFragment = fragmentEmailTemplateNode) => gql`
    query QueryEmailTemplatesConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: EmailTemplateViewerEmailTemplatesSearch
        $sort: EmailTemplateViewerEmailTemplatesSort
    ) {
        viewer {
            emailTemplates(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...EmailTemplateConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryEmailTemplatesConnectionGql = createGqlQueryEmailTemplatesConnection(fragmentEmailTemplateNode);

export const queryEmailTemplateNode = gql`
    query queryConexpNodeEmailTemplate($id: ID!) {
        node(id: $id) {
            id
            ...EmailTemplateConexpFragment
        }
    }
    ${fragmentEmailTemplateNode}
`;

// ************** emailTypes ***************** //

// emailTypes: gql query

export const fragmentEmailTypeNode = gql`
    fragment EmailTypeConexpFragment on EmailType {
        defaultBody
        id
        name
        tagName
    }
`;

export const createGqlQueryEmailTypesConnection = (_nodeFragment = fragmentEmailTypeNode) => gql`
    query QueryEmailTypesConnection($after: String, $before: String, $first: Int, $last: Int) {
        viewer {
            emailTypes(after: $after, before: $before, first: $first, last: $last) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }

                edges {
                    node {
                        ...EmailTypeConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryEmailTypesConnectionGql = createGqlQueryEmailTypesConnection(fragmentEmailTypeNode);

export const queryEmailTypeNode = gql`
    query queryConexpNodeEmailType($id: ID!) {
        node(id: $id) {
            id
            ...EmailTypeConexpFragment
        }
    }
    ${fragmentEmailTypeNode}
`;

// ************** entities ***************** //

// entities: gql query

export const fragmentEntityNode = gql`
    fragment EntityConexpFragment on Entity {
        active
        chainUpdate
        checkMutations {
            superDelete
            update
        }
        dataOwnership
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        id
        lastReportActivity
        serviceJob {
            address {
                id
            }
            archivedAt
            closedAt
            createdAt
            createdBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            description
            dueDate
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            id
            isImplicit
            isRecurring
            isSimpleMode
            lastAppointmentDate
            serviceJobNumber
            staff {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            stopsAt
            title
            updatedAt
        }
        shop {
            address {
                id
            }
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
        }
        user {
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            firstName
            id
            lastName
            staff {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            title
        }
        uuid
        warrantyClaim {
            addedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            address {
                id
            }
            assetInstallDate
            assetLocation
            assetName
            assetSerial
            claimNumber
            claimedAt
            comment
            confirmedAt
            createdAt
            customerCompanyName
            customerEmail
            customerFirstName
            customerLastName
            customerPhone
            emailCC
            emailReplyTo
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            externalRMA
            externalSO
            externalSRN
            faultDescription
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            installerName
            receiverEmail
            receiverStaff {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            siteAccessKey
            statusChangedAt
            statusMessage
            submittedAt
            updatedAt
            warrantyClaimNumber
        }
    }
`;

export const createGqlQueryEntitiesConnection = (_nodeFragment = fragmentEntityNode) => gql`
    query QueryEntitiesConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: EntityViewerEntitiesSearch
    ) {
        viewer {
            entities(after: $after, before: $before, first: $first, last: $last, page: $page, search: $search) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...EntityConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryEntitiesConnectionGql = createGqlQueryEntitiesConnection(fragmentEntityNode);

export const queryEntityNode = gql`
    query queryConexpNodeEntity($id: ID!) {
        node(id: $id) {
            id
            ...EntityConexpFragment
        }
    }
    ${fragmentEntityNode}
`;

// ************** ezcDeviceDefinitions ***************** //

// ezcDeviceDefinitions: gql query

export const fragmentEZCDeviceDefinitionNode = gql`
    fragment EZCDeviceDefinitionConexpFragment on EZCDeviceDefinition {
        definitionId
        definitionVersion
        familyId
        familyVariation
        id
        name
        openAPIURL
        presentationId
        setupStrategyType
    }
`;

export const createGqlQueryEzcDeviceDefinitionsConnection = (_nodeFragment = fragmentEZCDeviceDefinitionNode) => gql`
    query QueryEzcDeviceDefinitionsConnection($after: String, $before: String, $first: Int, $last: Int) {
        viewer {
            ezcDeviceDefinitions(after: $after, before: $before, first: $first, last: $last) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }

                edges {
                    node {
                        ...EZCDeviceDefinitionConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryEzcDeviceDefinitionsConnectionGql = createGqlQueryEzcDeviceDefinitionsConnection(
    fragmentEZCDeviceDefinitionNode
);

export const queryEZCDeviceDefinitionNode = gql`
    query queryConexpNodeEZCDeviceDefinition($id: ID!) {
        node(id: $id) {
            id
            ...EZCDeviceDefinitionConexpFragment
        }
    }
    ${fragmentEZCDeviceDefinitionNode}
`;

// ************** ezcDeviceLinks ***************** //

// ezcDeviceLinks: gql query

export const fragmentEZCDeviceLinkNode = gql`
    fragment EZCDeviceLinkConexpFragment on EZCDeviceLink {
        archivedAt
        checkMutations {
            delete
            update
        }
        createdAt
        device {
            createdAt
            deviceId
            id
            title
            version
        }
        deviceName
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        id
        item {
            createdAt
            id
            installedAt
            installedByStaff {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            label
            note
            owner {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            serial
            updatedAt
            uuid
        }
        label
        owner {
            addedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            assignedTo {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            billAddress {
                id
            }
            companyName
            contactName
            createdAt
            crn
            id
            note
            primaryAddress {
                id
            }
            refId
            registeredAt
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        product {
            addedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            createdAt
            customSku
            description
            familyCode
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            identification
            isGrouping
            isOneOff
            name
            retiredAt
            retiredBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            sku
            updatedAt
        }
        updatedAt
    }
`;

export const createGqlQueryEzcDeviceLinksConnection = (_nodeFragment = fragmentEZCDeviceLinkNode) => gql`
    query QueryEzcDeviceLinksConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: EZCDeviceLinkViewerEzcDeviceLinksSearch
        $sort: EZCDeviceLinkViewerEzcDeviceLinksSort
    ) {
        viewer {
            ezcDeviceLinks(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...EZCDeviceLinkConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryEzcDeviceLinksConnectionGql = createGqlQueryEzcDeviceLinksConnection(fragmentEZCDeviceLinkNode);

export const queryEZCDeviceLinkNode = gql`
    query queryConexpNodeEZCDeviceLink($id: ID!) {
        node(id: $id) {
            id
            ...EZCDeviceLinkConexpFragment
        }
    }
    ${fragmentEZCDeviceLinkNode}
`;

// ************** ezcDevices ***************** //

// ezcDevices: gql query

export const fragmentEZCDeviceNode = gql`
    fragment EZCDeviceConexpFragment on EZCDevice {
        createdAt
        definition {
            definitionId
            definitionVersion
            familyId
            familyVariation
            id
            name
            openAPIURL
            presentationId
            setupStrategyType
        }
        deviceId
        id
        title
        version
    }
`;

export const createGqlQueryEzcDevicesConnection = (_nodeFragment = fragmentEZCDeviceNode) => gql`
    query QueryEzcDevicesConnection(
        $after: String
        $before: String
        $first: Int
        $franchise: ID
        $last: Int
        $page: Int
    ) {
        viewer {
            ezcDevices(after: $after, before: $before, first: $first, franchise: $franchise, last: $last, page: $page) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }

                edges {
                    node {
                        ...EZCDeviceConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryEzcDevicesConnectionGql = createGqlQueryEzcDevicesConnection(fragmentEZCDeviceNode);

export const queryEZCDeviceNode = gql`
    query queryConexpNodeEZCDevice($id: ID!) {
        node(id: $id) {
            id
            ...EZCDeviceConexpFragment
        }
    }
    ${fragmentEZCDeviceNode}
`;

// ************** ezcServiceProviderLinks ***************** //

// ezcServiceProviderLinks: gql query

export const fragmentEZCFranchiseLinkNode = gql`
    fragment EZCFranchiseLinkConexpFragment on EZCFranchiseLink {
        addedBy {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        addedBy {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        checkMutations {
            delete
        }
        createdAt
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        id
        label
        serviceProvider {
            createdAt
            id
            name
            updatedAt
        }
        updatedAt
    }
`;

export const createGqlQueryEzcServiceProviderLinksConnection = (_nodeFragment = fragmentEZCFranchiseLinkNode) => gql`
    query QueryEzcServiceProviderLinksConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: EZCFranchiseLinkViewerEzcServiceProviderLinksSearch
        $sort: EZCFranchiseLinkViewerEzcServiceProviderLinksSort
    ) {
        viewer {
            ezcServiceProviderLinks(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...EZCFranchiseLinkConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryEzcServiceProviderLinksConnectionGql =
    createGqlQueryEzcServiceProviderLinksConnection(fragmentEZCFranchiseLinkNode);

export const queryEZCFranchiseLinkNode = gql`
    query queryConexpNodeEZCFranchiseLink($id: ID!) {
        node(id: $id) {
            id
            ...EZCFranchiseLinkConexpFragment
        }
    }
    ${fragmentEZCFranchiseLinkNode}
`;

// ************** ezcServiceProviders ***************** //

// ezcServiceProviders: gql query

export const fragmentEZCServiceProviderNode = gql`
    fragment EZCServiceProviderConexpFragment on EZCServiceProvider {
        createdAt
        id
        name
        updatedAt
    }
`;

export const createGqlQueryEzcServiceProvidersConnection = (_nodeFragment = fragmentEZCServiceProviderNode) => gql`
    query QueryEzcServiceProvidersConnection($after: String, $before: String, $first: Int, $last: Int) {
        viewer {
            ezcServiceProviders(after: $after, before: $before, first: $first, last: $last) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }

                edges {
                    node {
                        ...EZCServiceProviderConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryEzcServiceProvidersConnectionGql =
    createGqlQueryEzcServiceProvidersConnection(fragmentEZCServiceProviderNode);

export const queryEZCServiceProviderNode = gql`
    query queryConexpNodeEZCServiceProvider($id: ID!) {
        node(id: $id) {
            id
            ...EZCServiceProviderConexpFragment
        }
    }
    ${fragmentEZCServiceProviderNode}
`;

// ************** featureFlagVariations ***************** //

// featureFlagVariations: gql query

export const fragmentFeatureFlagVariationNode = gql`
    fragment FeatureFlagVariationConexpFragment on FeatureFlagVariation {
        active
        checkMutations {
            delete
            update
        }
        createdAt
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        id
        organisation {
            id
            locales
            logo
            name
            timeZone
            token
        }
        role {
            id
            name
        }
        tag
        value
    }
`;

export const createGqlQueryFeatureFlagVariationsConnection = (_nodeFragment = fragmentFeatureFlagVariationNode) => gql`
    query QueryFeatureFlagVariationsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: FeatureFlagVariationViewerFeatureFlagVariationsSearch
        $sort: FeatureFlagVariationViewerFeatureFlagVariationsSort
    ) {
        viewer {
            featureFlagVariations(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...FeatureFlagVariationConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryFeatureFlagVariationsConnectionGql = createGqlQueryFeatureFlagVariationsConnection(
    fragmentFeatureFlagVariationNode
);

export const queryFeatureFlagVariationNode = gql`
    query queryConexpNodeFeatureFlagVariation($id: ID!) {
        node(id: $id) {
            id
            ...FeatureFlagVariationConexpFragment
        }
    }
    ${fragmentFeatureFlagVariationNode}
`;

// ************** featureFlags ***************** //

// featureFlags: gql query

export const fragmentFeatureFlagNode = gql`
    fragment FeatureFlagConexpFragment on FeatureFlag {
        checkMutations {
            delete
            update
        }
        description
        id
        tag
        variations {
            active
            createdAt
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            tag
            value
        }
    }
`;

export const createGqlQueryFeatureFlagsConnection = (_nodeFragment = fragmentFeatureFlagNode) => gql`
    query QueryFeatureFlagsConnection($after: String, $before: String, $first: Int, $last: Int) {
        viewer {
            featureFlags(after: $after, before: $before, first: $first, last: $last) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }

                edges {
                    node {
                        ...FeatureFlagConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryFeatureFlagsConnectionGql = createGqlQueryFeatureFlagsConnection(fragmentFeatureFlagNode);

export const queryFeatureFlagNode = gql`
    query queryConexpNodeFeatureFlag($id: ID!) {
        node(id: $id) {
            id
            ...FeatureFlagConexpFragment
        }
    }
    ${fragmentFeatureFlagNode}
`;

// ************** files ***************** //

// files: gql query

export const fragmentFileUploadNode = gql`
    fragment FileUploadConexpFragment on FileUpload {
        checkMutations {
            delete
            update
        }
        createdAt
        fileName
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        id
        imageUrl
        isImage
        isPublic
        location
        mimeType
        note
        organisationType {
            id
            locales
            logo
            name
            timeZone
            token
        }
        uploadedBy {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        uploadedBy {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        url
    }
`;

export const createGqlQueryFilesConnection = (_nodeFragment = fragmentFileUploadNode) => gql`
    query QueryFilesConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: FileUploadViewerFilesSearch
        $sort: FileUploadViewerFilesSort
    ) {
        viewer {
            files(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...FileUploadConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryFilesConnectionGql = createGqlQueryFilesConnection(fragmentFileUploadNode);

export const queryFileUploadNode = gql`
    query queryConexpNodeFileUpload($id: ID!) {
        node(id: $id) {
            id
            ...FileUploadConexpFragment
        }
    }
    ${fragmentFileUploadNode}
`;

// ************** franchiseSequenceStore ***************** //

// franchiseSequenceStore: gql query

export const fragmentFranchiseSequenceStoreNode = gql`
    fragment FranchiseSequenceStoreConexpFragment on FranchiseSequenceStore {
        checkMutations {
            update
        }
        createdAt
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        group
        id
        key
        sequence
        updatedAt
    }
`;

export const createGqlQueryFranchiseSequenceStoreConnection = (
    _nodeFragment = fragmentFranchiseSequenceStoreNode
) => gql`
    query QueryFranchiseSequenceStoreConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: FranchiseSequenceStoreViewerFranchiseSequenceStoreSearch
        $sort: FranchiseSequenceStoreViewerFranchiseSequenceStoreSort
    ) {
        viewer {
            franchiseSequenceStore(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...FranchiseSequenceStoreConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryFranchiseSequenceStoreConnectionGql = createGqlQueryFranchiseSequenceStoreConnection(
    fragmentFranchiseSequenceStoreNode
);

export const queryFranchiseSequenceStoreNode = gql`
    query queryConexpNodeFranchiseSequenceStore($id: ID!) {
        node(id: $id) {
            id
            ...FranchiseSequenceStoreConexpFragment
        }
    }
    ${fragmentFranchiseSequenceStoreNode}
`;

// ************** franchiseValuesStore ***************** //

// franchiseValuesStore: gql query

export const fragmentFranchiseValueStoreNode = gql`
    fragment FranchiseValueStoreConexpFragment on FranchiseValueStore {
        checkMutations {
            delete
            update
        }
        createdAt
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        group
        id
        key
        updatedAt
        value
    }
`;

export const createGqlQueryFranchiseValuesStoreConnection = (_nodeFragment = fragmentFranchiseValueStoreNode) => gql`
    query QueryFranchiseValuesStoreConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: FranchiseValueStoreViewerFranchiseValuesStoreSearch
        $sort: FranchiseValueStoreViewerFranchiseValuesStoreSort
    ) {
        viewer {
            franchiseValuesStore(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...FranchiseValueStoreConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryFranchiseValuesStoreConnectionGql = createGqlQueryFranchiseValuesStoreConnection(
    fragmentFranchiseValueStoreNode
);

export const queryFranchiseValueStoreNode = gql`
    query queryConexpNodeFranchiseValueStore($id: ID!) {
        node(id: $id) {
            id
            ...FranchiseValueStoreConexpFragment
        }
    }
    ${fragmentFranchiseValueStoreNode}
`;

// ************** franchiseWarranters ***************** //

// franchiseWarranters: gql query

export const fragmentFranchiseWarranterNode = gql`
    fragment FranchiseWarranterConexpFragment on FranchiseWarranter {
        actingFranchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        actingFranchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        checkMutations {
            delete
            update
        }
        createdAt
        disabled
        email
        id
        name
        updatedAt
    }
`;

export const createGqlQueryFranchiseWarrantersConnection = (_nodeFragment = fragmentFranchiseWarranterNode) => gql`
    query QueryFranchiseWarrantersConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: FranchiseWarranterViewerFranchiseWarrantersSearch
        $sort: FranchiseWarranterViewerFranchiseWarrantersSort
    ) {
        viewer {
            franchiseWarranters(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...FranchiseWarranterConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryFranchiseWarrantersConnectionGql =
    createGqlQueryFranchiseWarrantersConnection(fragmentFranchiseWarranterNode);

export const queryFranchiseWarranterNode = gql`
    query queryConexpNodeFranchiseWarranter($id: ID!) {
        node(id: $id) {
            id
            ...FranchiseWarranterConexpFragment
        }
    }
    ${fragmentFranchiseWarranterNode}
`;

// ************** franchises ***************** //

// franchises: gql query

export const fragmentFranchiseNode = gql`
    fragment FranchiseConexpFragment on Franchise {
        activities {
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            lastCustomerDate
            lastReportDate
        }
        address {
            id
        }
        address {
            buildingName
            city
            country
            createdAt
            destinationName
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            id
            isBill
            isPrimary
            poBoxNumber
            postCode
            refId
            state
            streetName
            streetNumber
            suburb
            uuid
        }
        checkMutations {
            delete
            setParentFranchise
            update
        }
        contactExport
        createdAt
        currentLocales {
            id
            locale
        }
        currentTimezone {
            id
            localiseTime
            timeZone
        }
        customerCount
        email
        ezcServiceProviderLink {
            addedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            createdAt
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            label
            updatedAt
        }
        fileSafeName
        headOffice {
            address {
                id
            }
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
        }
        id
        locales {
            id
            locale
        }
        manager {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        manager {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        name
        organisationType {
            id
            locales
            logo
            name
            timeZone
            token
        }
        parentFranchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        parentFranchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        phoneNumber
        poolCount
        refId
        staffCount
        timeZone {
            id
            localiseTime
            timeZone
        }
        types
        updatedAt
        uuid
        vend {
            disabledAt
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            key
            shopUri
            vendTest
        }
        waterTestBranding
    }
`;

export const createGqlQueryFranchisesConnection = (_nodeFragment = fragmentFranchiseNode) => gql`
    query QueryFranchisesConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: FranchiseViewerFranchisesSearch
        $sort: FranchiseViewerFranchisesSort
    ) {
        viewer {
            franchises(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...FranchiseConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryFranchisesConnectionGql = createGqlQueryFranchisesConnection(fragmentFranchiseNode);

export const queryFranchiseNode = gql`
    query queryConexpNodeFranchise($id: ID!) {
        node(id: $id) {
            id
            ...FranchiseConexpFragment
        }
    }
    ${fragmentFranchiseNode}
`;

// ************** heaterRecommendations ***************** //

// heaterRecommendations: gql query

export const fragmentHeaterRecommendationNode = gql`
    fragment HeaterRecommendationConexpFragment on HeaterRecommendation {
        addedBy {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        addedBy {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        checkMutations {
            delete
            update
        }
        createdAt
        entity {
            ... on Entity {
                id
                user {
                    id
                    firstName
                    lastName
                }
            }
        }
        entity {
            active
            chainUpdate
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            lastReportActivity
            uuid
        }
        expiryDate
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        id
        note
        payload
        product {
            addedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            createdAt
            customSku
            description
            familyCode
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            identification
            isGrouping
            isOneOff
            name
            retiredAt
            retiredBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            sku
            updatedAt
        }
        recNumber
        status
        updatedAt
        urlPdf
    }
`;

export const createGqlQueryHeaterRecommendationsConnection = (_nodeFragment = fragmentHeaterRecommendationNode) => gql`
    query QueryHeaterRecommendationsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: HeaterRecommendationViewerHeaterRecommendationsSearch
        $sort: HeaterRecommendationViewerHeaterRecommendationsSort
    ) {
        viewer {
            heaterRecommendations(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...HeaterRecommendationConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryHeaterRecommendationsConnectionGql = createGqlQueryHeaterRecommendationsConnection(
    fragmentHeaterRecommendationNode
);

export const queryHeaterRecommendationNode = gql`
    query queryConexpNodeHeaterRecommendation($id: ID!) {
        node(id: $id) {
            id
            ...HeaterRecommendationConexpFragment
        }
    }
    ${fragmentHeaterRecommendationNode}
`;

// ************** installableProducts ***************** //

// installableProducts: gql query

export const fragmentInstallableProductNode = gql`
    fragment InstallableProductConexpFragment on InstallableProduct {
        checkMutations {
            delete
            update
        }
        id
        product {
            addedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            createdAt
            customSku
            description
            familyCode
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            identification
            isGrouping
            isOneOff
            name
            retiredAt
            retiredBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            sku
            updatedAt
        }
    }
`;

export const createGqlQueryInstallableProductsConnection = (_nodeFragment = fragmentInstallableProductNode) => gql`
    query QueryInstallableProductsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: InstallableProductViewerInstallableProductsSearch
        $sort: InstallableProductViewerInstallableProductsSort
    ) {
        viewer {
            installableProducts(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...InstallableProductConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryInstallableProductsConnectionGql =
    createGqlQueryInstallableProductsConnection(fragmentInstallableProductNode);

export const queryInstallableProductNode = gql`
    query queryConexpNodeInstallableProduct($id: ID!) {
        node(id: $id) {
            id
            ...InstallableProductConexpFragment
        }
    }
    ${fragmentInstallableProductNode}
`;

// ************** jobTodoTemplates ***************** //

// jobTodoTemplates: gql query

export const fragmentJobTodoTemplateNode = gql`
    fragment JobTodoTemplateConexpFragment on JobTodoTemplate {
        archivedAt
        checkMutations {
            archive
            delete
            update
        }
        description
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        group {
            createdAt
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            priority
            tag
            title
        }
        id
        maxTimeEstimate
        minTimeEstimate
        organisation {
            id
            locales
            logo
            name
            timeZone
            token
        }
        ownedByWorkOrderTemplate {
            addedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            archivedAt
            createdAt
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            hideStageActionRequired
            hideStageInProgress
            hideStageSuspended
            id
            onlyOwnedStages
            providedDescription
            providedTitle
            requiresAddress
            requiresContact
            requiresCustomer
            requiresPool
            requiresStaff
            tag
            templateDescription
            templateTitle
            updatedAt
        }
        recommendedPrice
        timeEstimate
        title
    }
`;

export const createGqlQueryJobTodoTemplatesConnection = (_nodeFragment = fragmentJobTodoTemplateNode) => gql`
    query QueryJobTodoTemplatesConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: JobTodoTemplateViewerJobTodoTemplatesSearch
    ) {
        viewer {
            jobTodoTemplates(after: $after, before: $before, first: $first, last: $last, page: $page, search: $search) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...JobTodoTemplateConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryJobTodoTemplatesConnectionGql = createGqlQueryJobTodoTemplatesConnection(fragmentJobTodoTemplateNode);

export const queryJobTodoTemplateNode = gql`
    query queryConexpNodeJobTodoTemplate($id: ID!) {
        node(id: $id) {
            id
            ...JobTodoTemplateConexpFragment
        }
    }
    ${fragmentJobTodoTemplateNode}
`;

// ************** locales ***************** //

// locales: gql query

export const fragmentLocalesNode = gql`
    fragment LocalesConexpFragment on Locales {
        id
        locale
    }
`;

export const createGqlQueryLocalesConnection = (_nodeFragment = fragmentLocalesNode) => gql`
    query QueryLocalesConnection($after: String, $before: String, $first: Int, $last: Int) {
        viewer {
            locales(after: $after, before: $before, first: $first, last: $last) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }

                edges {
                    node {
                        ...LocalesConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryLocalesConnectionGql = createGqlQueryLocalesConnection(fragmentLocalesNode);

export const queryLocalesNode = gql`
    query queryConexpNodeLocales($id: ID!) {
        node(id: $id) {
            id
            ...LocalesConexpFragment
        }
    }
    ${fragmentLocalesNode}
`;

// ************** manualProblems ***************** //

// manualProblems: gql query

export const fragmentManualProblemNode = gql`
    fragment ManualProblemConexpFragment on ManualProblem {
        checkMutations {
            delete
            update
        }
        createdAt
        description
        id
        name
        organisationType {
            id
            locales
            logo
            name
            timeZone
            token
        }
        solution
    }
`;

export const createGqlQueryManualProblemsConnection = (_nodeFragment = fragmentManualProblemNode) => gql`
    query QueryManualProblemsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: ManualProblemViewerManualProblemsSearch
        $sort: ManualProblemViewerManualProblemsSort
    ) {
        viewer {
            manualProblems(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...ManualProblemConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryManualProblemsConnectionGql = createGqlQueryManualProblemsConnection(fragmentManualProblemNode);

export const queryManualProblemNode = gql`
    query queryConexpNodeManualProblem($id: ID!) {
        node(id: $id) {
            id
            ...ManualProblemConexpFragment
        }
    }
    ${fragmentManualProblemNode}
`;

// ************** measurementTypes ***************** //

// measurementTypes: gql query

export const fragmentMeasurementNode = gql`
    fragment MeasurementConexpFragment on Measurement {
        checkMutations {
            delete
            update
        }
        description
        id
        linearConversion {
            id
        }
        linearForm {
            description
            id
            name
            priority
            tagIdentifier
        }
        name
        priority
        tagIdentifier
        unit {
            id
            name
        }
    }
`;

export const createGqlQueryMeasurementTypesConnection = (_nodeFragment = fragmentMeasurementNode) => gql`
    query QueryMeasurementTypesConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: MeasurementViewerMeasurementTypesSearch
        $sort: MeasurementViewerMeasurementTypesSort
    ) {
        viewer {
            measurementTypes(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...MeasurementConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryMeasurementTypesConnectionGql = createGqlQueryMeasurementTypesConnection(fragmentMeasurementNode);

export const queryMeasurementNode = gql`
    query queryConexpNodeMeasurement($id: ID!) {
        node(id: $id) {
            id
            ...MeasurementConexpFragment
        }
    }
    ${fragmentMeasurementNode}
`;

// ************** measurementUnits ***************** //

// measurementUnits: gql query

export const fragmentUnitNode = gql`
    fragment UnitConexpFragment on Unit {
        checkMutations {
            deleteUnit
            update
        }
        id
        linearConversion {
            id
        }
        linearForm {
            id
            name
        }
        name
    }
`;

export const createGqlQueryMeasurementUnitsConnection = (_nodeFragment = fragmentUnitNode) => gql`
    query QueryMeasurementUnitsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: UnitViewerMeasurementUnitsSearch
        $sort: UnitViewerMeasurementUnitsSort
    ) {
        viewer {
            measurementUnits(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...UnitConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryMeasurementUnitsConnectionGql = createGqlQueryMeasurementUnitsConnection(fragmentUnitNode);

export const queryUnitNode = gql`
    query queryConexpNodeUnit($id: ID!) {
        node(id: $id) {
            id
            ...UnitConexpFragment
        }
    }
    ${fragmentUnitNode}
`;

// ************** organisationTypes ***************** //

// organisationTypes: gql query

export const fragmentOrganisationTypeNode = gql`
    fragment OrganisationTypeConexpFragment on OrganisationType {
        id
        locales
        logo
        name
        timeZone
        token
    }
`;

export const createGqlQueryOrganisationTypesConnection = (_nodeFragment = fragmentOrganisationTypeNode) => gql`
    query QueryOrganisationTypesConnection($after: String, $before: String, $first: Int, $last: Int) {
        viewer {
            organisationTypes(after: $after, before: $before, first: $first, last: $last) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }

                edges {
                    node {
                        ...OrganisationTypeConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryOrganisationTypesConnectionGql =
    createGqlQueryOrganisationTypesConnection(fragmentOrganisationTypeNode);

export const queryOrganisationTypeNode = gql`
    query queryConexpNodeOrganisationType($id: ID!) {
        node(id: $id) {
            id
            ...OrganisationTypeConexpFragment
        }
    }
    ${fragmentOrganisationTypeNode}
`;

// ************** permissions ***************** //

// permissions: gql query

export const fragmentPermissionNode = gql`
    fragment PermissionConexpFragment on Permission {
        checkMutations {
            update
        }
        description
        id
        tag
    }
`;

export const createGqlQueryPermissionsConnection = (_nodeFragment = fragmentPermissionNode) => gql`
    query QueryPermissionsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $search: PermissionViewerPermissionsSearch
    ) {
        viewer {
            permissions(after: $after, before: $before, first: $first, last: $last, search: $search) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }

                edges {
                    node {
                        ...PermissionConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryPermissionsConnectionGql = createGqlQueryPermissionsConnection(fragmentPermissionNode);

export const queryPermissionNode = gql`
    query queryConexpNodePermission($id: ID!) {
        node(id: $id) {
            id
            ...PermissionConexpFragment
        }
    }
    ${fragmentPermissionNode}
`;

// ************** poolBottleLeases ***************** //

// poolBottleLeases: gql query

export const fragmentPoolBottleLeaseNode = gql`
    fragment PoolBottleLeaseConexpFragment on PoolBottleLease {
        bottle {
            createdAt
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            scanCode
        }
        checkMutations {
            processReturn
        }
        id
        leaseTo {
            ... on Entity {
                id
                user {
                    id
                    firstName
                    lastName
                }
            }
        }
        leaseTo {
            active
            chainUpdate
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            lastReportActivity
            uuid
        }
        leasedAt
        leasedBy {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        leasedBy {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        pool {
            address {
                id
            }
            bottleNumber
            createdAt
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            name
            note
            notePlain
            refId
            revision
            uuid
        }
        receivedBy {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        receivedBy {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        returnedAt
    }
`;

export const createGqlQueryPoolBottleLeasesConnection = (_nodeFragment = fragmentPoolBottleLeaseNode) => gql`
    query QueryPoolBottleLeasesConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: PoolBottleLeaseViewerPoolBottleLeasesSearch
    ) {
        viewer {
            poolBottleLeases(after: $after, before: $before, first: $first, last: $last, page: $page, search: $search) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...PoolBottleLeaseConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryPoolBottleLeasesConnectionGql = createGqlQueryPoolBottleLeasesConnection(fragmentPoolBottleLeaseNode);

export const queryPoolBottleLeaseNode = gql`
    query queryConexpNodePoolBottleLease($id: ID!) {
        node(id: $id) {
            id
            ...PoolBottleLeaseConexpFragment
        }
    }
    ${fragmentPoolBottleLeaseNode}
`;

// ************** poolBottles ***************** //

// poolBottles: gql query

export const fragmentPoolBottleNode = gql`
    fragment PoolBottleConexpFragment on PoolBottle {
        checkMutations {
            processReturn
        }
        createdAt
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        id
        lastLease {
            id
            leaseTo {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            leasedAt
            leasedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            receivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            returnedAt
        }
        scanCode
    }
`;

export const createGqlQueryPoolBottlesConnection = (_nodeFragment = fragmentPoolBottleNode) => gql`
    query QueryPoolBottlesConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: PoolBottleViewerPoolBottlesSearch
    ) {
        viewer {
            poolBottles(after: $after, before: $before, first: $first, last: $last, page: $page, search: $search) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...PoolBottleConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryPoolBottlesConnectionGql = createGqlQueryPoolBottlesConnection(fragmentPoolBottleNode);

export const queryPoolBottleNode = gql`
    query queryConexpNodePoolBottle($id: ID!) {
        node(id: $id) {
            id
            ...PoolBottleConexpFragment
        }
    }
    ${fragmentPoolBottleNode}
`;

// ************** poolDeclarationFlagFields ***************** //

// poolDeclarationFlagFields: gql query

export const fragmentPoolDeclarationFlagFieldNode = gql`
    fragment PoolDeclarationFlagFieldConexpFragment on PoolDeclarationFlagField {
        activatedAt
        checkMutations {
            delete
            setActive
            update
        }
        createdAt
        description
        exportName
        id
        name
        organisation {
            id
            locales
            logo
            name
            timeZone
            token
        }
        priority
        tag
        updatedAt
        useInExport
    }
`;

export const createGqlQueryPoolDeclarationFlagFieldsConnection = (
    _nodeFragment = fragmentPoolDeclarationFlagFieldNode
) => gql`
    query QueryPoolDeclarationFlagFieldsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: PoolDeclarationFlagFieldViewerPoolDeclarationFlagFieldsSearch
        $sort: PoolDeclarationFlagFieldViewerPoolDeclarationFlagFieldsSort
    ) {
        viewer {
            poolDeclarationFlagFields(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...PoolDeclarationFlagFieldConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryPoolDeclarationFlagFieldsConnectionGql = createGqlQueryPoolDeclarationFlagFieldsConnection(
    fragmentPoolDeclarationFlagFieldNode
);

export const queryPoolDeclarationFlagFieldNode = gql`
    query queryConexpNodePoolDeclarationFlagField($id: ID!) {
        node(id: $id) {
            id
            ...PoolDeclarationFlagFieldConexpFragment
        }
    }
    ${fragmentPoolDeclarationFlagFieldNode}
`;

// ************** poolProductDeclarations ***************** //

// poolProductDeclarations: gql query

export const fragmentPoolProductDeclarationNode = gql`
    fragment PoolProductDeclarationConexpFragment on PoolProductDeclaration {
        checkMutations {
            delete
            update
        }
        declaration {
            createdAt
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            isCategory
            name
            tag
            updatedAt
        }
        id
        name
        optional
        organisationType {
            id
            locales
            logo
            name
            timeZone
            token
        }
        priority
    }
`;

export const createGqlQueryPoolProductDeclarationsConnection = (
    _nodeFragment = fragmentPoolProductDeclarationNode
) => gql`
    query QueryPoolProductDeclarationsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: PoolProductDeclarationViewerPoolProductDeclarationsSearch
        $sort: PoolProductDeclarationViewerPoolProductDeclarationsSort
    ) {
        viewer {
            poolProductDeclarations(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...PoolProductDeclarationConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryPoolProductDeclarationsConnectionGql = createGqlQueryPoolProductDeclarationsConnection(
    fragmentPoolProductDeclarationNode
);

export const queryPoolProductDeclarationNode = gql`
    query queryConexpNodePoolProductDeclaration($id: ID!) {
        node(id: $id) {
            id
            ...PoolProductDeclarationConexpFragment
        }
    }
    ${fragmentPoolProductDeclarationNode}
`;

// ************** poolSanitisers ***************** //

// poolSanitisers: gql query

export const fragmentSanitiserNode = gql`
    fragment SanitiserConexpFragment on Sanitiser {
        checkMutations {
            changePublishedStatus
            delete
            update
        }
        id
        name
        organisationType {
            id
            locales
            logo
            name
            timeZone
            token
        }
        priority
        publishedAt
        refId
        reportName
        uuid
    }
`;

export const createGqlQueryPoolSanitisersConnection = (_nodeFragment = fragmentSanitiserNode) => gql`
    query QueryPoolSanitisersConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: SanitiserViewerPoolSanitisersSearch
        $sort: SanitiserViewerPoolSanitisersSort
    ) {
        viewer {
            poolSanitisers(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...SanitiserConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryPoolSanitisersConnectionGql = createGqlQueryPoolSanitisersConnection(fragmentSanitiserNode);

export const querySanitiserNode = gql`
    query queryConexpNodeSanitiser($id: ID!) {
        node(id: $id) {
            id
            ...SanitiserConexpFragment
        }
    }
    ${fragmentSanitiserNode}
`;

// ************** poolTransfers ***************** //

// poolTransfers: gql query

export const fragmentPoolOwnershipHistoryNode = gql`
    fragment PoolOwnershipHistoryConexpFragment on PoolOwnershipHistory {
        createdAt
        id
        pool {
            address {
                id
            }
            bottleNumber
            createdAt
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            name
            note
            notePlain
            refId
            revision
            uuid
        }
        transferredBy {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        transferredBy {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        transferredFrom {
            ... on Entity {
                id
                user {
                    id
                    firstName
                    lastName
                }
            }
        }
        transferredFrom {
            active
            chainUpdate
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            lastReportActivity
            uuid
        }
        transferredTo {
            ... on Entity {
                id
                user {
                    id
                    firstName
                    lastName
                }
            }
        }
        transferredTo {
            active
            chainUpdate
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            lastReportActivity
            uuid
        }
    }
`;

export const createGqlQueryPoolTransfersConnection = (_nodeFragment = fragmentPoolOwnershipHistoryNode) => gql`
    query QueryPoolTransfersConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: PoolOwnershipHistoryViewerPoolTransfersSearch
        $sort: PoolOwnershipHistoryViewerPoolTransfersSort
    ) {
        viewer {
            poolTransfers(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...PoolOwnershipHistoryConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryPoolTransfersConnectionGql = createGqlQueryPoolTransfersConnection(fragmentPoolOwnershipHistoryNode);

export const queryPoolOwnershipHistoryNode = gql`
    query queryConexpNodePoolOwnershipHistory($id: ID!) {
        node(id: $id) {
            id
            ...PoolOwnershipHistoryConexpFragment
        }
    }
    ${fragmentPoolOwnershipHistoryNode}
`;

// ************** poolTypes ***************** //

// poolTypes: gql query

export const fragmentPoolTypeNode = gql`
    fragment PoolTypeConexpFragment on PoolType {
        checkMutations {
            changePublishedStatus
            delete
            update
        }
        createdAt
        description
        id
        name
        organisationType {
            id
            locales
            logo
            name
            timeZone
            token
        }
        priority
        publishedAt
        refId
        updatedAt
        uuid
    }
`;

export const createGqlQueryPoolTypesConnection = (_nodeFragment = fragmentPoolTypeNode) => gql`
    query QueryPoolTypesConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: PoolTypeViewerPoolTypesSearch
        $sort: PoolTypeViewerPoolTypesSort
    ) {
        viewer {
            poolTypes(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...PoolTypeConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryPoolTypesConnectionGql = createGqlQueryPoolTypesConnection(fragmentPoolTypeNode);

export const queryPoolTypeNode = gql`
    query queryConexpNodePoolType($id: ID!) {
        node(id: $id) {
            id
            ...PoolTypeConexpFragment
        }
    }
    ${fragmentPoolTypeNode}
`;

// ************** pools ***************** //

// pools: gql query

export const fragmentPoolNode = gql`
    fragment PoolConexpFragment on Pool {
        address {
            id
        }
        address {
            buildingName
            city
            country
            createdAt
            destinationName
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            id
            isBill
            isPrimary
            poBoxNumber
            postCode
            refId
            state
            streetName
            streetNumber
            suburb
            uuid
        }
        attachedFlagFields {
            createdAt
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            setBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            updatedAt
            value
        }
        bottleNumber
        checkMutations {
            delete
            transferToSite
            update
        }
        createdAt
        dataOwnership
        entity {
            ... on Entity {
                id
                user {
                    id
                    firstName
                    lastName
                }
            }
        }
        entity {
            active
            chainUpdate
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            lastReportActivity
            uuid
        }
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        id
        installedSanitisers {
            createdAt
            id
            installedAt
            installedByStaff {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            label
            note
            owner {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            serial
            updatedAt
            uuid
        }
        name
        note
        notePlain
        refId
        revision
        sanitiser {
            id
            name
            priority
            publishedAt
            refId
            reportName
            uuid
        }
        site {
            accessKeyLocation
            address {
                id
            }
            createdAt
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            id
            name
            refId
            updatedAt
            uuid
        }
        type {
            createdAt
            description
            id
            name
            priority
            publishedAt
            refId
            updatedAt
            uuid
        }
        uuid
    }
`;

export const createGqlQueryPoolsConnection = (_nodeFragment = fragmentPoolNode) => gql`
    query QueryPoolsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: PoolViewerPoolsSearch
        $sort: PoolViewerPoolsSort
    ) {
        viewer {
            pools(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...PoolConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryPoolsConnectionGql = createGqlQueryPoolsConnection(fragmentPoolNode);

export const queryPoolNode = gql`
    query queryConexpNodePool($id: ID!) {
        node(id: $id) {
            id
            ...PoolConexpFragment
        }
    }
    ${fragmentPoolNode}
`;

// ************** productCompany ***************** //

// productCompany: gql query

export const fragmentProductCompanyNode = gql`
    fragment ProductCompanyConexpFragment on ProductCompany {
        checkMutations {
            delete
            update
        }
        createdAt
        description
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        id
        name
        parentCompany {
            createdAt
            description
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            name
            updatedAt
        }
        updatedAt
    }
`;

export const createGqlQueryProductCompanyConnection = (_nodeFragment = fragmentProductCompanyNode) => gql`
    query QueryProductCompanyConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: ProductCompanyViewerProductCompanySearch
        $searchTraits: ProductCompanyViewerProductCompanySearchTraits
        $sort: ProductCompanyViewerProductCompanySort
    ) {
        viewer {
            productCompany(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                searchTraits: $searchTraits
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...ProductCompanyConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryProductCompanyConnectionGql = createGqlQueryProductCompanyConnection(fragmentProductCompanyNode);

export const queryProductCompanyNode = gql`
    query queryConexpNodeProductCompany($id: ID!) {
        node(id: $id) {
            id
            ...ProductCompanyConexpFragment
        }
    }
    ${fragmentProductCompanyNode}
`;

// ************** productDeclarations ***************** //

// productDeclarations: gql query

export const fragmentProductTraitDeclarationNode = gql`
    fragment ProductTraitDeclarationConexpFragment on ProductTraitDeclaration {
        checkMutations {
            delete
            update
        }
        createdAt
        directChildren {
            createdAt
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            isCategory
            name
            tag
            updatedAt
        }
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        id
        isCategory
        name
        organisationType {
            id
            locales
            logo
            name
            timeZone
            token
        }
        parent {
            createdAt
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            isCategory
            name
            tag
            updatedAt
        }
        subDeclarations {
            createdAt
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            isCategory
            name
            tag
            updatedAt
        }
        tag
        updatedAt
    }
`;

export const createGqlQueryProductDeclarationsConnection = (_nodeFragment = fragmentProductTraitDeclarationNode) => gql`
    query QueryProductDeclarationsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: ProductTraitDeclarationViewerProductDeclarationsSearch
        $searchTraitOverlap: ProductTraitDeclarationViewerProductDeclarationsSearchTraitOverlap
        $sort: ProductTraitDeclarationViewerProductDeclarationsSort
    ) {
        viewer {
            productDeclarations(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                searchTraitOverlap: $searchTraitOverlap
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...ProductTraitDeclarationConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryProductDeclarationsConnectionGql = createGqlQueryProductDeclarationsConnection(
    fragmentProductTraitDeclarationNode
);

export const queryProductTraitDeclarationNode = gql`
    query queryConexpNodeProductTraitDeclaration($id: ID!) {
        node(id: $id) {
            id
            ...ProductTraitDeclarationConexpFragment
        }
    }
    ${fragmentProductTraitDeclarationNode}
`;

// ************** productDocuments ***************** //

// productDocuments: gql query

export const fragmentProductDocumentNode = gql`
    fragment ProductDocumentConexpFragment on ProductDocument {
        brand {
            createdAt
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            identification
            name
            updatedAt
        }
        checkMutations {
            delete
            update
        }
        company {
            createdAt
            description
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            name
            updatedAt
        }
        createdAt
        fileName
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        id
        imageUrl
        isImage
        isPublic
        location
        mimeType
        note
        organisationType {
            id
            locales
            logo
            name
            timeZone
            token
        }
        title
        type
        updatedAt
        uploadedBy {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        uploadedBy {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        url
    }
`;

export const createGqlQueryProductDocumentsConnection = (_nodeFragment = fragmentProductDocumentNode) => gql`
    query QueryProductDocumentsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: ProductDocumentViewerProductDocumentsSearch
        $sort: ProductDocumentViewerProductDocumentsSort
    ) {
        viewer {
            productDocuments(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...ProductDocumentConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryProductDocumentsConnectionGql = createGqlQueryProductDocumentsConnection(fragmentProductDocumentNode);

export const queryProductDocumentNode = gql`
    query queryConexpNodeProductDocument($id: ID!) {
        node(id: $id) {
            id
            ...ProductDocumentConexpFragment
        }
    }
    ${fragmentProductDocumentNode}
`;

// ************** products ***************** //

// products: gql query

export const fragmentProductNode = gql`
    fragment ProductConexpFragment on Product {
        addedBy {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        addedBy {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        brand {
            createdAt
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            identification
            name
            updatedAt
        }
        brochure {
            createdAt
            fileName
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            imageUrl
            isImage
            isPublic
            location
            mimeType
            note
            title
            updatedAt
            uploadedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            url
        }
        checkMutations {
            delete
            retire
            unretire
            update
        }
        consumable {
            id
        }
        createdAt
        customSku
        description
        familyCode
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        id
        identification
        installable {
            id
        }
        isGrouping
        isOneOff
        name
        organisationType {
            id
            locales
            logo
            name
            timeZone
            token
        }
        retiredAt
        retiredBy {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        retiredBy {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        sku
        supplier {
            createdAt
            description
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            name
            updatedAt
        }
        updatedAt
    }
`;

export const createGqlQueryProductsConnection = (_nodeFragment = fragmentProductNode) => gql`
    query QueryProductsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: ProductViewerProductsSearch
        $searchTraits: ProductViewerProductsSearchTraits
        $sort: ProductViewerProductsSort
    ) {
        viewer {
            products(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                searchTraits: $searchTraits
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...ProductConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryProductsConnectionGql = createGqlQueryProductsConnection(fragmentProductNode);

export const queryProductNode = gql`
    query queryConexpNodeProduct($id: ID!) {
        node(id: $id) {
            id
            ...ProductConexpFragment
        }
    }
    ${fragmentProductNode}
`;

// ************** projectHosting ***************** //

// projectHosting: gql query

export const fragmentProjectNode = gql`
    fragment ProjectConexpFragment on Project {
        checkMutations {
            delete
            update
        }
        createdAt
        description
        documentationProject {
            createdAt
            description
            enabled
            enabledFrom
            fileURL
            franchiseRestricted
            id
            name
            platformKey
            priority
            releaseCount
            remoteThumbnailURL
            thumbnailURL
            updatedAt
            uuid
        }
        enabled
        enabledFrom
        fileURL
        franchiseRestricted
        id
        latestRelease {
            changelogSummary
            description
            enabled
            enabledFrom
            fileURL
            id
            isPinned
            releaseNumber
            remoteFileURL
            requirements
            versionNumber
        }
        name
        organisation {
            id
            locales
            logo
            name
            timeZone
            token
        }
        pinnedVersion {
            changelogSummary
            description
            enabled
            enabledFrom
            fileURL
            id
            isPinned
            releaseNumber
            remoteFileURL
            requirements
            versionNumber
        }
        platformKey
        priority
        recommendedVersion {
            changelogSummary
            description
            enabled
            enabledFrom
            fileURL
            id
            isPinned
            releaseNumber
            remoteFileURL
            requirements
            versionNumber
        }
        releaseCount
        remoteThumbnailURL
        thumbnailFile {
            createdAt
            fileName
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            imageUrl
            isImage
            isPublic
            location
            mimeType
            note
            uploadedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            url
        }
        thumbnailURL
        updatedAt
        uuid
    }
`;

export const createGqlQueryProjectHostingConnection = (_nodeFragment = fragmentProjectNode) => gql`
    query QueryProjectHostingConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: ProjectViewerProjectHostingSearch
        $sort: ProjectViewerProjectHostingSort
    ) {
        viewer {
            projectHosting(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...ProjectConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryProjectHostingConnectionGql = createGqlQueryProjectHostingConnection(fragmentProjectNode);

export const queryProjectNode = gql`
    query queryConexpNodeProject($id: ID!) {
        node(id: $id) {
            id
            ...ProjectConexpFragment
        }
    }
    ${fragmentProjectNode}
`;

// ************** reports ***************** //

// reports: gql query

export const fragmentTestReportNode = gql`
    fragment TestReportConexpFragment on TestReport {
        checkMutations {
            archiveReport
            delete
            finaliseReport
            update
        }
        createdAt
        dataOwnership
        docVersion
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        id
        isArchived
        isFinalised
        note
        pdfUrl
        pool {
            address {
                id
            }
            bottleNumber
            createdAt
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            name
            note
            notePlain
            refId
            revision
            uuid
        }
        poolRevision
        previewPdfUrl
        previousReport {
            createdAt
            docVersion
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            isArchived
            isFinalised
            note
            pdfUrl
            poolRevision
            previewPdfUrl
            refId
            reportBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            reportTo {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            testingRevision
            testingVersion
            updatedAt
        }
        refId
        reportBy {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        reportBy {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        reportTo {
            ... on Entity {
                id
                user {
                    id
                    firstName
                    lastName
                }
            }
        }
        reportTo {
            active
            chainUpdate
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            lastReportActivity
            uuid
        }
        sampleSet {
            addedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            createdAt
            hasTestReports
            id
            toPlain
        }
        submissionMetadata {
            deviceVersion
            method
            methodVersion
            reagentsContext
            submittedAt
            toolContext
            typeContext
        }
        testingRevision
        testingVersion
        updatedAt
        workOrder {
            address {
                id
            }
            archivedAt
            assignedTo {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            checkedInAt
            checkedOutAt
            createdAt
            createdBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            description
            dueRangeEnd
            dueRangeStart
            id
            paymentUrl
            requiresAddress
            requiresAppointment
            requiresAssignedTo
            requiresPool
            requiresPreferredContact
            requiresTestReport
            requiresWorkFor
            statusClosedAt
            title
            updatedAt
            workFor {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            workOrderNumber
        }
    }
`;

export const createGqlQueryReportsConnection = (_nodeFragment = fragmentTestReportNode) => gql`
    query QueryReportsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: TestReportViewerReportsSearch
        $sort: TestReportViewerReportsSort
    ) {
        viewer {
            reports(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...TestReportConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryReportsConnectionGql = createGqlQueryReportsConnection(fragmentTestReportNode);

export const queryTestReportNode = gql`
    query queryConexpNodeTestReport($id: ID!) {
        node(id: $id) {
            id
            ...TestReportConexpFragment
        }
    }
    ${fragmentTestReportNode}
`;

// ************** roleFlags ***************** //

// roleFlags: gql query

export const fragmentRoleFlagNode = gql`
    fragment RoleFlagConexpFragment on RoleFlag {
        checkMutations {
            delete
            update
        }
        description
        id
        tag
    }
`;

export const createGqlQueryRoleFlagsConnection = (_nodeFragment = fragmentRoleFlagNode) => gql`
    query QueryRoleFlagsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $search: RoleFlagViewerRoleFlagsSearch
    ) {
        viewer {
            roleFlags(after: $after, before: $before, first: $first, last: $last, search: $search) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }

                edges {
                    node {
                        ...RoleFlagConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryRoleFlagsConnectionGql = createGqlQueryRoleFlagsConnection(fragmentRoleFlagNode);

export const queryRoleFlagNode = gql`
    query queryConexpNodeRoleFlag($id: ID!) {
        node(id: $id) {
            id
            ...RoleFlagConexpFragment
        }
    }
    ${fragmentRoleFlagNode}
`;

// ************** roles ***************** //

// roles: gql query

export const fragmentRoleNode = gql`
    fragment RoleConexpFragment on Role {
        checkMutations {
            update
        }
        id
        name
    }
`;

export const createGqlQueryRolesConnection = (_nodeFragment = fragmentRoleNode) => gql`
    query QueryRolesConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: RoleViewerRolesSearch
    ) {
        viewer {
            roles(after: $after, before: $before, first: $first, last: $last, page: $page, search: $search) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...RoleConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryRolesConnectionGql = createGqlQueryRolesConnection(fragmentRoleNode);

export const queryRoleNode = gql`
    query queryConexpNodeRole($id: ID!) {
        node(id: $id) {
            id
            ...RoleConexpFragment
        }
    }
    ${fragmentRoleNode}
`;

// ************** sampleSets ***************** //

// sampleSets: gql query

export const fragmentSampleSetNode = gql`
    fragment SampleSetConexpFragment on SampleSet {
        addedBy {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        addedBy {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        checkMutations {
            update
        }
        createdAt
        hasTestReports
        id
        pool {
            address {
                id
            }
            bottleNumber
            createdAt
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            name
            note
            notePlain
            refId
            revision
            uuid
        }
        toPlain
    }
`;

export const createGqlQuerySampleSetsConnection = (_nodeFragment = fragmentSampleSetNode) => gql`
    query QuerySampleSetsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: SampleSetViewerSampleSetsSearch
        $sort: SampleSetViewerSampleSetsSort
    ) {
        viewer {
            sampleSets(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...SampleSetConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QuerySampleSetsConnectionGql = createGqlQuerySampleSetsConnection(fragmentSampleSetNode);

export const querySampleSetNode = gql`
    query queryConexpNodeSampleSet($id: ID!) {
        node(id: $id) {
            id
            ...SampleSetConexpFragment
        }
    }
    ${fragmentSampleSetNode}
`;

// ************** sampleSources ***************** //

// sampleSources: gql query

export const fragmentSampleSourceNode = gql`
    fragment SampleSourceConexpFragment on SampleSource {
        checkMutations {
            update
        }
        id
        name
    }
`;

export const createGqlQuerySampleSourcesConnection = (_nodeFragment = fragmentSampleSourceNode) => gql`
    query QuerySampleSourcesConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $search: SampleSourceViewerSampleSourcesSearch
    ) {
        viewer {
            sampleSources(after: $after, before: $before, first: $first, last: $last, search: $search) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }

                edges {
                    node {
                        ...SampleSourceConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QuerySampleSourcesConnectionGql = createGqlQuerySampleSourcesConnection(fragmentSampleSourceNode);

export const querySampleSourceNode = gql`
    query queryConexpNodeSampleSource($id: ID!) {
        node(id: $id) {
            id
            ...SampleSourceConexpFragment
        }
    }
    ${fragmentSampleSourceNode}
`;

// ************** sentEmails ***************** //

// sentEmails: gql query

export const fragmentEmailMessageNode = gql`
    fragment EmailMessageConexpFragment on EmailMessage {
        claim {
            addedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            address {
                id
            }
            assetInstallDate
            assetLocation
            assetName
            assetSerial
            claimNumber
            claimedAt
            comment
            confirmedAt
            createdAt
            customerCompanyName
            customerEmail
            customerFirstName
            customerLastName
            customerPhone
            emailCC
            emailReplyTo
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            externalRMA
            externalSO
            externalSRN
            faultDescription
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            installerName
            receiverEmail
            receiverStaff {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            siteAccessKey
            statusChangedAt
            statusMessage
            submittedAt
            updatedAt
            warrantyClaimNumber
        }
        createdAt
        emailCC
        id
        isSent
        mailTo {
            ... on Entity {
                id
                user {
                    id
                    firstName
                    lastName
                }
            }
        }
        mailTo {
            active
            chainUpdate
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            lastReportActivity
            uuid
        }
        messageBody
        note
        previewBody
        recipientEmail
        replyTo
        report {
            createdAt
            docVersion
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            isArchived
            isFinalised
            note
            pdfUrl
            poolRevision
            previewPdfUrl
            refId
            reportBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            reportTo {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            testingRevision
            testingVersion
            updatedAt
        }
        subject
        submittedBy {
            ... on Entity {
                id
                user {
                    id
                    firstName
                    lastName
                }
            }
        }
        submittedBy {
            active
            chainUpdate
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            lastReportActivity
            uuid
        }
        template {
            createdAt
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            isSelectedForOwnFranchise
            isSelectedForOwnOrganisation
            isSelectedGlobally
            name
            selectedForFranchises {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            templateBody
            templateMetadata
            updatedAt
        }
        type {
            defaultBody
            id
            name
            tagName
        }
    }
`;

export const createGqlQuerySentEmailsConnection = (_nodeFragment = fragmentEmailMessageNode) => gql`
    query QuerySentEmailsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: EmailMessageViewerSentEmailsSearch
        $sort: EmailMessageViewerSentEmailsSort
    ) {
        viewer {
            sentEmails(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...EmailMessageConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QuerySentEmailsConnectionGql = createGqlQuerySentEmailsConnection(fragmentEmailMessageNode);

export const queryEmailMessageNode = gql`
    query queryConexpNodeEmailMessage($id: ID!) {
        node(id: $id) {
            id
            ...EmailMessageConexpFragment
        }
    }
    ${fragmentEmailMessageNode}
`;

// ************** serviceJobGroups ***************** //

// serviceJobGroups: gql query

export const fragmentServiceJobGroupNode = gql`
    fragment ServiceJobGroupConexpFragment on ServiceJobGroup {
        checkMutations {
            delete
            update
        }
        createdAt
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        id
        organisationType {
            id
            locales
            logo
            name
            timeZone
            token
        }
        parent {
            createdAt
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            priority
            tag
            title
        }
        priority
        tag
        title
    }
`;

export const createGqlQueryServiceJobGroupsConnection = (_nodeFragment = fragmentServiceJobGroupNode) => gql`
    query QueryServiceJobGroupsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: ServiceJobGroupViewerServiceJobGroupsSearch
        $sort: ServiceJobGroupViewerServiceJobGroupsSort
    ) {
        viewer {
            serviceJobGroups(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...ServiceJobGroupConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryServiceJobGroupsConnectionGql = createGqlQueryServiceJobGroupsConnection(fragmentServiceJobGroupNode);

export const queryServiceJobGroupNode = gql`
    query queryConexpNodeServiceJobGroup($id: ID!) {
        node(id: $id) {
            id
            ...ServiceJobGroupConexpFragment
        }
    }
    ${fragmentServiceJobGroupNode}
`;

// ************** serviceJobStages ***************** //

// serviceJobStages: gql query

export const fragmentServiceJobStageNode = gql`
    fragment ServiceJobStageConexpFragment on ServiceJobStage {
        archivedAt
        checkMutations {
            archive
            delete
            update
        }
        color
        createdAt
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        hidden
        id
        isDefault
        isFallback
        organisationType {
            id
            locales
            logo
            name
            timeZone
            token
        }
        priority
        status
        tag
        title
        type
        updatedAt
    }
`;

export const createGqlQueryServiceJobStagesConnection = (_nodeFragment = fragmentServiceJobStageNode) => gql`
    query QueryServiceJobStagesConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: ServiceJobStageViewerServiceJobStagesSearch
    ) {
        viewer {
            serviceJobStages(after: $after, before: $before, first: $first, last: $last, page: $page, search: $search) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...ServiceJobStageConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryServiceJobStagesConnectionGql = createGqlQueryServiceJobStagesConnection(fragmentServiceJobStageNode);

export const queryServiceJobStageNode = gql`
    query queryConexpNodeServiceJobStage($id: ID!) {
        node(id: $id) {
            id
            ...ServiceJobStageConexpFragment
        }
    }
    ${fragmentServiceJobStageNode}
`;

// ************** serviceJobTemplates ***************** //

// serviceJobTemplates: gql query

export const fragmentServiceJobTemplateNode = gql`
    fragment ServiceJobTemplateConexpFragment on ServiceJobTemplate {
        addedBy {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        addedBy {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        archivedAt
        canBeRecurring
        checkMutations {
            archive
            delete
            setAsDefault
            update
        }
        createdAt
        defaultStage {
            archivedAt
            color
            createdAt
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            hidden
            id
            isDefault
            isFallback
            priority
            tag
            title
            updatedAt
        }
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        group {
            createdAt
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            priority
            tag
            title
        }
        hideStageActionRequired
        hideStageInProgress
        hideStageSuspended
        id
        isDefault
        isPublished
        organisationType {
            id
            locales
            logo
            name
            timeZone
            token
        }
        priority
        providedDescription
        providedTitle
        requiresAddress
        requiresContact
        requiresCustomer
        requiresPool
        requiresStaff
        stageReport {
            isActive
        }
        tag
        templateDescription
        templateTitle
        updatedAt
    }
`;

export const createGqlQueryServiceJobTemplatesConnection = (_nodeFragment = fragmentServiceJobTemplateNode) => gql`
    query QueryServiceJobTemplatesConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: ServiceJobTemplateViewerServiceJobTemplatesSearch
        $sort: ServiceJobTemplateViewerServiceJobTemplatesSort
    ) {
        viewer {
            serviceJobTemplates(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...ServiceJobTemplateConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryServiceJobTemplatesConnectionGql =
    createGqlQueryServiceJobTemplatesConnection(fragmentServiceJobTemplateNode);

export const queryServiceJobTemplateNode = gql`
    query queryConexpNodeServiceJobTemplate($id: ID!) {
        node(id: $id) {
            id
            ...ServiceJobTemplateConexpFragment
        }
    }
    ${fragmentServiceJobTemplateNode}
`;

// ************** serviceJobs ***************** //

// serviceJobs: gql query

export const fragmentServiceJobNode = gql`
    fragment ServiceJobConexpFragment on ServiceJob {
        address {
            id
        }
        address {
            buildingName
            city
            country
            createdAt
            destinationName
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            id
            isBill
            isPrimary
            poBoxNumber
            postCode
            refId
            state
            streetName
            streetNumber
            suburb
            uuid
        }
        archivedAt
        checkMutations {
            archive
            delete
            setStopDate
            unArchive
            update
            updateRecurringJob
        }
        closedAt
        createdAt
        createdBy {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        createdBy {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        customer {
            addedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            assignedTo {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            billAddress {
                id
            }
            companyName
            contactName
            createdAt
            crn
            id
            note
            primaryAddress {
                id
            }
            refId
            registeredAt
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        description
        dueDate
        entity {
            ... on Entity {
                id
                user {
                    id
                    firstName
                    lastName
                }
            }
        }
        entity {
            active
            chainUpdate
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            lastReportActivity
            uuid
        }
        followedFrom {
            address {
                id
            }
            archivedAt
            closedAt
            createdAt
            createdBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            description
            dueDate
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            id
            isImplicit
            isRecurring
            isSimpleMode
            lastAppointmentDate
            serviceJobNumber
            staff {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            stopsAt
            title
            updatedAt
        }
        group {
            createdAt
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            priority
            tag
            title
        }
        id
        implicitType
        isImplicit
        isRecurring
        isSimpleMode
        lastAppointment {
            addedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            endPatternDate
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            patternBlockId
            startDate
        }
        lastAppointmentDate
        lastRecurrentAppointment {
            addedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            endPatternDate
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            patternBlockId
            startDate
        }
        pool {
            address {
                id
            }
            bottleNumber
            createdAt
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            name
            note
            notePlain
            refId
            revision
            uuid
        }
        preferredContact {
            createdAt
            data
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            id
            isPrimary
            label
            refId
            updatedAt
            uuid
        }
        serviceJobNumber
        staff {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        staff {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        stage {
            archivedAt
            color
            createdAt
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            hidden
            id
            isDefault
            isFallback
            priority
            tag
            title
            updatedAt
        }
        stageCandidates {
            archivedAt
            color
            createdAt
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            hidden
            id
            isDefault
            isFallback
            priority
            tag
            title
            updatedAt
        }
        stageStatus
        stopsAt
        title
        updatedAt
        usedTemplate {
            addedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            archivedAt
            canBeRecurring
            createdAt
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            hideStageActionRequired
            hideStageInProgress
            hideStageSuspended
            id
            isDefault
            isPublished
            priority
            providedDescription
            providedTitle
            requiresAddress
            requiresContact
            requiresCustomer
            requiresPool
            requiresStaff
            tag
            templateDescription
            templateTitle
            updatedAt
        }
    }
`;

export const createGqlQueryServiceJobsConnection = (_nodeFragment = fragmentServiceJobNode) => gql`
    query QueryServiceJobsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: ServiceJobViewerServiceJobsSearch
        $sort: ServiceJobViewerServiceJobsSort
    ) {
        viewer {
            serviceJobs(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...ServiceJobConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryServiceJobsConnectionGql = createGqlQueryServiceJobsConnection(fragmentServiceJobNode);

export const queryServiceJobNode = gql`
    query queryConexpNodeServiceJob($id: ID!) {
        node(id: $id) {
            id
            ...ServiceJobConexpFragment
        }
    }
    ${fragmentServiceJobNode}
`;

// ************** settingStore ***************** //

// settingStore: gql query

export const fragmentSettingStoreNode = gql`
    fragment SettingStoreConexpFragment on SettingStore {
        checkMutations {
            delete
            update
        }
        group
        id
        name
        value
    }
`;

export const createGqlQuerySettingStoreConnection = (_nodeFragment = fragmentSettingStoreNode) => gql`
    query QuerySettingStoreConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: SettingStoreViewerSettingStoreSearch
        $sort: SettingStoreViewerSettingStoreSort
    ) {
        viewer {
            settingStore(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...SettingStoreConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QuerySettingStoreConnectionGql = createGqlQuerySettingStoreConnection(fragmentSettingStoreNode);

export const querySettingStoreNode = gql`
    query queryConexpNodeSettingStore($id: ID!) {
        node(id: $id) {
            id
            ...SettingStoreConexpFragment
        }
    }
    ${fragmentSettingStoreNode}
`;

// ************** shops ***************** //

// shops: gql query

export const fragmentFranchiseShopNode = gql`
    fragment FranchiseShopConexpFragment on FranchiseShop {
        address {
            id
        }
        address {
            buildingName
            city
            country
            createdAt
            destinationName
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            id
            isBill
            isPrimary
            poBoxNumber
            postCode
            refId
            state
            streetName
            streetNumber
            suburb
            uuid
        }
        checkMutations {
            delete
            update
        }
        entity {
            ... on Entity {
                id
                user {
                    id
                    firstName
                    lastName
                }
            }
        }
        entity {
            active
            chainUpdate
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            lastReportActivity
            uuid
        }
        id
        manager {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        manager {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
    }
`;

export const createGqlQueryShopsConnection = (_nodeFragment = fragmentFranchiseShopNode) => gql`
    query QueryShopsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: FranchiseShopViewerShopsSearch
        $sort: FranchiseShopViewerShopsSort
    ) {
        viewer {
            shops(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...FranchiseShopConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryShopsConnectionGql = createGqlQueryShopsConnection(fragmentFranchiseShopNode);

export const queryFranchiseShopNode = gql`
    query queryConexpNodeFranchiseShop($id: ID!) {
        node(id: $id) {
            id
            ...FranchiseShopConexpFragment
        }
    }
    ${fragmentFranchiseShopNode}
`;

// ************** sites ***************** //

// sites: gql query

export const fragmentSiteNode = gql`
    fragment SiteConexpFragment on Site {
        accessKeyLocation
        address {
            id
        }
        address {
            buildingName
            city
            country
            createdAt
            destinationName
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            id
            isBill
            isPrimary
            poBoxNumber
            postCode
            refId
            state
            streetName
            streetNumber
            suburb
            uuid
        }
        checkMutations {
            delete
            transferTo
            update
        }
        createdAt
        dataOwnership
        entity {
            ... on Entity {
                id
                user {
                    id
                    firstName
                    lastName
                }
            }
        }
        entity {
            active
            chainUpdate
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            lastReportActivity
            uuid
        }
        id
        name
        refId
        updatedAt
        uuid
    }
`;

export const createGqlQuerySitesConnection = (_nodeFragment = fragmentSiteNode) => gql`
    query QuerySitesConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: SiteViewerSitesSearch
    ) {
        viewer {
            sites(after: $after, before: $before, first: $first, last: $last, page: $page, search: $search) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...SiteConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QuerySitesConnectionGql = createGqlQuerySitesConnection(fragmentSiteNode);

export const querySiteNode = gql`
    query queryConexpNodeSite($id: ID!) {
        node(id: $id) {
            id
            ...SiteConexpFragment
        }
    }
    ${fragmentSiteNode}
`;

// ************** staff ***************** //

// staff: gql query

export const fragmentStaffNode = gql`
    fragment StaffConexpFragment on Staff {
        archivedAt
        archivedBy {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        archivedBy {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        checkMutations {
            archive
            unarchive
            update
        }
        color
        id
        isSystemHidden
        managedBy {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        managedBy {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        registration {
            disabledAt
            disabledBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            email
            id
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            staff {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
        }
        role {
            id
            name
        }
        root {
            ... on Entity {
                id
                user {
                    id
                    firstName
                    lastName
                }
            }
        }
        root {
            active
            chainUpdate
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            lastReportActivity
            uuid
        }
        user {
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            firstName
            id
            lastName
            staff {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            title
        }
    }
`;

export const createGqlQueryStaffConnection = (_nodeFragment = fragmentStaffNode) => gql`
    query QueryStaffConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: StaffViewerStaffSearch
        $sort: StaffViewerStaffSort
    ) {
        viewer {
            staff(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...StaffConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryStaffConnectionGql = createGqlQueryStaffConnection(fragmentStaffNode);

export const queryStaffNode = gql`
    query queryConexpNodeStaff($id: ID!) {
        node(id: $id) {
            id
            ...StaffConexpFragment
        }
    }
    ${fragmentStaffNode}
`;

// ************** staffTeams ***************** //

// staffTeams: gql query

export const fragmentStaffTeamNode = gql`
    fragment StaffTeamConexpFragment on StaffTeam {
        addedBy {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        addedBy {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        checkMutations {
            addStaff
            delete
            update
        }
        color
        createdAt
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        id
        tag
        title
        updatedAt
    }
`;

export const createGqlQueryStaffTeamsConnection = (_nodeFragment = fragmentStaffTeamNode) => gql`
    query QueryStaffTeamsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: StaffTeamViewerStaffTeamsSearch
        $sort: StaffTeamViewerStaffTeamsSort
    ) {
        viewer {
            staffTeams(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...StaffTeamConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryStaffTeamsConnectionGql = createGqlQueryStaffTeamsConnection(fragmentStaffTeamNode);

export const queryStaffTeamNode = gql`
    query queryConexpNodeStaffTeam($id: ID!) {
        node(id: $id) {
            id
            ...StaffTeamConexpFragment
        }
    }
    ${fragmentStaffTeamNode}
`;

// ************** staffValuesStore ***************** //

// staffValuesStore: gql query

export const fragmentStaffValueStoreNode = gql`
    fragment StaffValueStoreConexpFragment on StaffValueStore {
        checkMutations {
            delete
            update
        }
        createdAt
        group
        id
        key
        staff {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        staff {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        updatedAt
        value
    }
`;

export const createGqlQueryStaffValuesStoreConnection = (_nodeFragment = fragmentStaffValueStoreNode) => gql`
    query QueryStaffValuesStoreConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: StaffValueStoreViewerStaffValuesStoreSearch
        $sort: StaffValueStoreViewerStaffValuesStoreSort
    ) {
        viewer {
            staffValuesStore(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...StaffValueStoreConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryStaffValuesStoreConnectionGql = createGqlQueryStaffValuesStoreConnection(fragmentStaffValueStoreNode);

export const queryStaffValueStoreNode = gql`
    query queryConexpNodeStaffValueStore($id: ID!) {
        node(id: $id) {
            id
            ...StaffValueStoreConexpFragment
        }
    }
    ${fragmentStaffValueStoreNode}
`;

// ************** testCriteria ***************** //

// testCriteria: gql query

export const fragmentTestCriteriaNode = gql`
    fragment TestCriteriaConexpFragment on TestCriteria {
        archivedAt
        checkMutations {
            delete
            update
        }
        countReportsAffected
        description
        id
        isUniversal
        measurementType {
            description
            id
            name
            priority
            tagIdentifier
        }
        name
        organisationType {
            id
            locales
            logo
            name
            timeZone
            token
        }
        toHighValueProblem {
            archivedAt
            countReportsAffected
            createdAt
            description
            id
            name
            updatedAt
        }
        toLowValueProblem {
            archivedAt
            countReportsAffected
            createdAt
            description
            id
            name
            updatedAt
        }
        universal {
            id
        }
    }
`;

export const createGqlQueryTestCriteriaConnection = (_nodeFragment = fragmentTestCriteriaNode) => gql`
    query QueryTestCriteriaConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: TestCriteriaViewerTestCriteriaSearch
        $sort: TestCriteriaViewerTestCriteriaSort
    ) {
        viewer {
            testCriteria(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...TestCriteriaConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryTestCriteriaConnectionGql = createGqlQueryTestCriteriaConnection(fragmentTestCriteriaNode);

export const queryTestCriteriaNode = gql`
    query queryConexpNodeTestCriteria($id: ID!) {
        node(id: $id) {
            id
            ...TestCriteriaConexpFragment
        }
    }
    ${fragmentTestCriteriaNode}
`;

// ************** testProblems ***************** //

// testProblems: gql query

export const fragmentTestProblemNode = gql`
    fragment TestProblemConexpFragment on TestProblem {
        archivedAt
        checkMutations {
            delete
            update
        }
        countReportsAffected
        createdAt
        description
        id
        name
        organisationType {
            id
            locales
            logo
            name
            timeZone
            token
        }
        updatedAt
    }
`;

export const createGqlQueryTestProblemsConnection = (_nodeFragment = fragmentTestProblemNode) => gql`
    query QueryTestProblemsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: TestProblemViewerTestProblemsSearch
        $sort: TestProblemViewerTestProblemsSort
    ) {
        viewer {
            testProblems(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...TestProblemConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryTestProblemsConnectionGql = createGqlQueryTestProblemsConnection(fragmentTestProblemNode);

export const queryTestProblemNode = gql`
    query queryConexpNodeTestProblem($id: ID!) {
        node(id: $id) {
            id
            ...TestProblemConexpFragment
        }
    }
    ${fragmentTestProblemNode}
`;

// ************** testTreatmentGroupForFranchises ***************** //

// testTreatmentGroupForFranchises: gql query

export const fragmentTestTreatmentFranchiseGroupNode = gql`
    fragment TestTreatmentFranchiseGroupConexpFragment on TestTreatmentFranchiseGroup {
        active
        checkMutations {
            delete
        }
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        id
        priority
        treatmentGroup {
            active
            createdAt
            id
            name
            priority
            updatedAt
        }
    }
`;

export const createGqlQueryTestTreatmentGroupForFranchisesConnection = (
    _nodeFragment = fragmentTestTreatmentFranchiseGroupNode
) => gql`
    query QueryTestTreatmentGroupForFranchisesConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: TestTreatmentFranchiseGroupViewerTestTreatmentGroupForFranchisesSearch
        $sort: TestTreatmentFranchiseGroupViewerTestTreatmentGroupForFranchisesSort
    ) {
        viewer {
            testTreatmentGroupForFranchises(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...TestTreatmentFranchiseGroupConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryTestTreatmentGroupForFranchisesConnectionGql =
    createGqlQueryTestTreatmentGroupForFranchisesConnection(fragmentTestTreatmentFranchiseGroupNode);

export const queryTestTreatmentFranchiseGroupNode = gql`
    query queryConexpNodeTestTreatmentFranchiseGroup($id: ID!) {
        node(id: $id) {
            id
            ...TestTreatmentFranchiseGroupConexpFragment
        }
    }
    ${fragmentTestTreatmentFranchiseGroupNode}
`;

// ************** testTreatmentGroups ***************** //

// testTreatmentGroups: gql query

export const fragmentTestTreatmentGroupNode = gql`
    fragment TestTreatmentGroupConexpFragment on TestTreatmentGroup {
        active
        checkMutations {
            delete
            update
        }
        createdAt
        id
        name
        organisationType {
            id
            locales
            logo
            name
            timeZone
            token
        }
        priority
        updatedAt
    }
`;

export const createGqlQueryTestTreatmentGroupsConnection = (_nodeFragment = fragmentTestTreatmentGroupNode) => gql`
    query QueryTestTreatmentGroupsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: TestTreatmentGroupViewerTestTreatmentGroupsSearch
        $sort: TestTreatmentGroupViewerTestTreatmentGroupsSort
    ) {
        viewer {
            testTreatmentGroups(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...TestTreatmentGroupConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryTestTreatmentGroupsConnectionGql =
    createGqlQueryTestTreatmentGroupsConnection(fragmentTestTreatmentGroupNode);

export const queryTestTreatmentGroupNode = gql`
    query queryConexpNodeTestTreatmentGroup($id: ID!) {
        node(id: $id) {
            id
            ...TestTreatmentGroupConexpFragment
        }
    }
    ${fragmentTestTreatmentGroupNode}
`;

// ************** testTreatments ***************** //

// testTreatments: gql query

export const fragmentTestTreatmentNode = gql`
    fragment TestTreatmentConexpFragment on TestTreatment {
        archivedAt
        checkMutations {
            erase
            setBlacklistForFranchise
            setPriorityForFranchise
            update
        }
        consumableProduct {
            id
        }
        countReportsAffected
        createdAt
        decimalPlaces
        group {
            active
            createdAt
            id
            name
            priority
            updatedAt
        }
        id
        instruction
        instructionPlain
        isRadical
        name
        organisationType {
            id
            locales
            logo
            name
            timeZone
            token
        }
        priority
        unit {
            id
            name
        }
        updatedAt
    }
`;

export const createGqlQueryTestTreatmentsConnection = (_nodeFragment = fragmentTestTreatmentNode) => gql`
    query QueryTestTreatmentsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: TestTreatmentViewerTestTreatmentsSearch
        $sort: TestTreatmentViewerTestTreatmentsSort
    ) {
        viewer {
            testTreatments(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...TestTreatmentConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryTestTreatmentsConnectionGql = createGqlQueryTestTreatmentsConnection(fragmentTestTreatmentNode);

export const queryTestTreatmentNode = gql`
    query queryConexpNodeTestTreatment($id: ID!) {
        node(id: $id) {
            id
            ...TestTreatmentConexpFragment
        }
    }
    ${fragmentTestTreatmentNode}
`;

// ************** timezones ***************** //

// timezones: gql query

export const fragmentTimezoneNode = gql`
    fragment TimezoneConexpFragment on Timezone {
        id
        localiseTime
        timeZone
    }
`;

export const createGqlQueryTimezonesConnection = (_nodeFragment = fragmentTimezoneNode) => gql`
    query QueryTimezonesConnection($after: String, $before: String, $first: Int, $last: Int) {
        viewer {
            timezones(after: $after, before: $before, first: $first, last: $last) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }

                edges {
                    node {
                        ...TimezoneConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryTimezonesConnectionGql = createGqlQueryTimezonesConnection(fragmentTimezoneNode);

export const queryTimezoneNode = gql`
    query queryConexpNodeTimezone($id: ID!) {
        node(id: $id) {
            id
            ...TimezoneConexpFragment
        }
    }
    ${fragmentTimezoneNode}
`;

// ************** unitConversions ***************** //

// unitConversions: gql query

export const fragmentUnitConversionNode = gql`
    fragment UnitConversionConexpFragment on UnitConversion {
        checkMutations {
            delete
            update
        }
        destination {
            id
            name
        }
        id
        method {
            id
            name
            requiresFactors
            tag
        }
        source {
            id
            name
        }
    }
`;

export const createGqlQueryUnitConversionsConnection = (_nodeFragment = fragmentUnitConversionNode) => gql`
    query QueryUnitConversionsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: UnitConversionViewerUnitConversionsSearch
    ) {
        viewer {
            unitConversions(after: $after, before: $before, first: $first, last: $last, page: $page, search: $search) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...UnitConversionConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryUnitConversionsConnectionGql = createGqlQueryUnitConversionsConnection(fragmentUnitConversionNode);

export const queryUnitConversionNode = gql`
    query queryConexpNodeUnitConversion($id: ID!) {
        node(id: $id) {
            id
            ...UnitConversionConexpFragment
        }
    }
    ${fragmentUnitConversionNode}
`;

// ************** unitConverters ***************** //

// unitConverters: gql query

export const fragmentUnitConverterNode = gql`
    fragment UnitConverterConexpFragment on UnitConverter {
        id
        name
        requiresFactors
        tag
    }
`;

export const createGqlQueryUnitConvertersConnection = (_nodeFragment = fragmentUnitConverterNode) => gql`
    query QueryUnitConvertersConnection($after: String, $before: String, $first: Int, $last: Int) {
        viewer {
            unitConverters(after: $after, before: $before, first: $first, last: $last) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }

                edges {
                    node {
                        ...UnitConverterConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryUnitConvertersConnectionGql = createGqlQueryUnitConvertersConnection(fragmentUnitConverterNode);

export const queryUnitConverterNode = gql`
    query queryConexpNodeUnitConverter($id: ID!) {
        node(id: $id) {
            id
            ...UnitConverterConexpFragment
        }
    }
    ${fragmentUnitConverterNode}
`;

// ************** universalCriteria ***************** //

// universalCriteria: gql query

export const fragmentUniversalCriteriaNode = gql`
    fragment UniversalCriteriaConexpFragment on UniversalCriteria {
        checkMutations {
            delete
        }
        criterion {
            archivedAt
            countReportsAffected
            description
            id
            isUniversal
            name
        }
        id
    }
`;

export const createGqlQueryUniversalCriteriaConnection = (_nodeFragment = fragmentUniversalCriteriaNode) => gql`
    query QueryUniversalCriteriaConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $search: UniversalCriteriaViewerUniversalCriteriaSearch
    ) {
        viewer {
            universalCriteria(after: $after, before: $before, first: $first, last: $last, search: $search) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }

                edges {
                    node {
                        ...UniversalCriteriaConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryUniversalCriteriaConnectionGql =
    createGqlQueryUniversalCriteriaConnection(fragmentUniversalCriteriaNode);

export const queryUniversalCriteriaNode = gql`
    query queryConexpNodeUniversalCriteria($id: ID!) {
        node(id: $id) {
            id
            ...UniversalCriteriaConexpFragment
        }
    }
    ${fragmentUniversalCriteriaNode}
`;

// ************** users ***************** //

// users: gql query

export const fragmentUserNode = gql`
    fragment UserConexpFragment on User {
        checkMutations {
            update
        }
        customer {
            addedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            assignedTo {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            billAddress {
                id
            }
            companyName
            contactName
            createdAt
            crn
            id
            note
            primaryAddress {
                id
            }
            refId
            registeredAt
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        entity {
            ... on Entity {
                id
                user {
                    id
                    firstName
                    lastName
                }
            }
        }
        entity {
            active
            chainUpdate
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            lastReportActivity
            uuid
        }
        firstName
        id
        lastName
        staff {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        staff {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        title
    }
`;

export const createGqlQueryUsersConnection = (_nodeFragment = fragmentUserNode) => gql`
    query QueryUsersConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: UserViewerUsersSearch
        $sort: UserViewerUsersSort
    ) {
        viewer {
            users(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...UserConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryUsersConnectionGql = createGqlQueryUsersConnection(fragmentUserNode);

export const queryUserNode = gql`
    query queryConexpNodeUser($id: ID!) {
        node(id: $id) {
            id
            ...UserConexpFragment
        }
    }
    ${fragmentUserNode}
`;

// ************** warranterClaimMessages ***************** //

// warranterClaimMessages: gql query

export const fragmentWarrantyClaimMessageNode = gql`
    fragment WarrantyClaimMessageConexpFragment on WarrantyClaimMessage {
        addedBy {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        addedBy {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        checkMutations {
            delete
            update
        }
        claim {
            addedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            address {
                id
            }
            assetInstallDate
            assetLocation
            assetName
            assetSerial
            claimNumber
            claimedAt
            comment
            confirmedAt
            createdAt
            customerCompanyName
            customerEmail
            customerFirstName
            customerLastName
            customerPhone
            emailCC
            emailReplyTo
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            externalRMA
            externalSO
            externalSRN
            faultDescription
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            installerName
            receiverEmail
            receiverStaff {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            siteAccessKey
            statusChangedAt
            statusMessage
            submittedAt
            updatedAt
            warrantyClaimNumber
        }
        createdAt
        id
        isRead
        message
        updatedAt
    }
`;

export const createGqlQueryWarranterClaimMessagesConnection = (_nodeFragment = fragmentWarrantyClaimMessageNode) => gql`
    query QueryWarranterClaimMessagesConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: WarrantyClaimMessageViewerWarranterClaimMessagesSearch
        $sort: WarrantyClaimMessageViewerWarranterClaimMessagesSort
    ) {
        viewer {
            warranterClaimMessages(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...WarrantyClaimMessageConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryWarranterClaimMessagesConnectionGql = createGqlQueryWarranterClaimMessagesConnection(
    fragmentWarrantyClaimMessageNode
);

export const queryWarrantyClaimMessageNode = gql`
    query queryConexpNodeWarrantyClaimMessage($id: ID!) {
        node(id: $id) {
            id
            ...WarrantyClaimMessageConexpFragment
        }
    }
    ${fragmentWarrantyClaimMessageNode}
`;

// ************** warrantyClaimCases ***************** //

// warrantyClaimCases: gql query

export const fragmentWarrantyClaimCaseNode = gql`
    fragment WarrantyClaimCaseConexpFragment on WarrantyClaimCase {
        assignedTo {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        assignedTo {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        checkMutations {
            delete
            markMessagesAsRead
            update
            updateStatus
        }
        claim {
            addedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            address {
                id
            }
            assetInstallDate
            assetLocation
            assetName
            assetSerial
            claimNumber
            claimedAt
            comment
            confirmedAt
            createdAt
            customerCompanyName
            customerEmail
            customerFirstName
            customerLastName
            customerPhone
            emailCC
            emailReplyTo
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            externalRMA
            externalSO
            externalSRN
            faultDescription
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            installerName
            receiverEmail
            receiverStaff {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            siteAccessKey
            statusChangedAt
            statusMessage
            submittedAt
            updatedAt
            warrantyClaimNumber
        }
        createdAt
        externalRMA
        externalSO
        externalSRN
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        id
        lastMessageAt
        lastMessageReadAt
        note
        serviceJob {
            address {
                id
            }
            archivedAt
            closedAt
            createdAt
            createdBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            description
            dueDate
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            id
            isImplicit
            isRecurring
            isSimpleMode
            lastAppointmentDate
            serviceJobNumber
            staff {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            stopsAt
            title
            updatedAt
        }
        updatedAt
    }
`;

export const createGqlQueryWarrantyClaimCasesConnection = (_nodeFragment = fragmentWarrantyClaimCaseNode) => gql`
    query QueryWarrantyClaimCasesConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: WarrantyClaimCaseViewerWarrantyClaimCasesSearch
        $sort: WarrantyClaimCaseViewerWarrantyClaimCasesSort
    ) {
        viewer {
            warrantyClaimCases(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...WarrantyClaimCaseConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryWarrantyClaimCasesConnectionGql =
    createGqlQueryWarrantyClaimCasesConnection(fragmentWarrantyClaimCaseNode);

export const queryWarrantyClaimCaseNode = gql`
    query queryConexpNodeWarrantyClaimCase($id: ID!) {
        node(id: $id) {
            id
            ...WarrantyClaimCaseConexpFragment
        }
    }
    ${fragmentWarrantyClaimCaseNode}
`;

// ************** warrantyClaims ***************** //

// warrantyClaims: gql query

export const fragmentWarrantyClaimNode = gql`
    fragment WarrantyClaimConexpFragment on WarrantyClaim {
        addedBy {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        addedBy {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        address {
            id
        }
        address {
            buildingName
            city
            country
            createdAt
            destinationName
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            id
            isBill
            isPrimary
            poBoxNumber
            postCode
            refId
            state
            streetName
            streetNumber
            suburb
            uuid
        }
        assetInstallDate
        assetLocation
        assetName
        assetSerial
        case {
            assignedTo {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            createdAt
            externalRMA
            externalSO
            externalSRN
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            lastMessageAt
            lastMessageReadAt
            note
            updatedAt
        }
        checkMutations {
            delete
            executeWarrantyClaimRequest
            forceClaimCase
            update
        }
        claimNumber
        claimedAt
        comment
        confirmedAt
        createdAt
        customerCompanyName
        customerEmail
        customerFirstName
        customerLastName
        customerPhone
        emailCC
        emailReplyTo
        entity {
            ... on Entity {
                id
                user {
                    id
                    firstName
                    lastName
                }
            }
        }
        entity {
            active
            chainUpdate
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            lastReportActivity
            uuid
        }
        externalRMA
        externalSO
        externalSRN
        faultDescription
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        id
        installerName
        receiverEmail
        receiverStaff {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        receiverStaff {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        receiverWarranter {
            actingFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            createdAt
            disabled
            email
            id
            name
            updatedAt
        }
        refAsset {
            createdAt
            id
            installedAt
            installedByStaff {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            label
            note
            owner {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            serial
            updatedAt
            uuid
        }
        refCustomer {
            addedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            assignedTo {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            billAddress {
                id
            }
            companyName
            contactName
            createdAt
            crn
            id
            note
            primaryAddress {
                id
            }
            refId
            registeredAt
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        refSite {
            accessKeyLocation
            address {
                id
            }
            createdAt
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            id
            name
            refId
            updatedAt
            uuid
        }
        siteAccessKey
        status
        statusChangedAt
        statusGroup
        statusMessage
        submittedAt
        updatedAt
        warrantyClaimNumber
    }
`;

export const createGqlQueryWarrantyClaimsConnection = (_nodeFragment = fragmentWarrantyClaimNode) => gql`
    query QueryWarrantyClaimsConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: WarrantyClaimViewerWarrantyClaimsSearch
        $sort: WarrantyClaimViewerWarrantyClaimsSort
    ) {
        viewer {
            warrantyClaims(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...WarrantyClaimConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryWarrantyClaimsConnectionGql = createGqlQueryWarrantyClaimsConnection(fragmentWarrantyClaimNode);

export const queryWarrantyClaimNode = gql`
    query queryConexpNodeWarrantyClaim($id: ID!) {
        node(id: $id) {
            id
            ...WarrantyClaimConexpFragment
        }
    }
    ${fragmentWarrantyClaimNode}
`;

// ************** workOrderFeed ***************** //

// workOrderFeed: gql query

export const fragmentWorkOrderFeedItemNode = gql`
    fragment WorkOrderFeedItemConexpFragment on WorkOrderFeedItem {
        address {
            id
        }
        address {
            buildingName
            city
            country
            createdAt
            destinationName
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            id
            isBill
            isPrimary
            poBoxNumber
            postCode
            refId
            state
            streetName
            streetNumber
            suburb
            uuid
        }
        appointmentItem {
            address {
                id
            }
            adhoc
            color
            deleted
            duration
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            isEnd
            isRecurrentOrigin
            isRecurring
            isStart
            isWorkOrderPending
            note
            originalDate
            patternBlockId
            paymentUrl
            staff {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            startDate
        }
        checkMutations {
            startWorkOrder
        }
        customer {
            addedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            assignedTo {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            billAddress {
                id
            }
            companyName
            contactName
            createdAt
            crn
            id
            note
            primaryAddress {
                id
            }
            refId
            registeredAt
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        date
        id
        pool {
            address {
                id
            }
            bottleNumber
            createdAt
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            name
            note
            notePlain
            refId
            revision
            uuid
        }
        serviceJob {
            address {
                id
            }
            archivedAt
            closedAt
            createdAt
            createdBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            description
            dueDate
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            id
            isImplicit
            isRecurring
            isSimpleMode
            lastAppointmentDate
            serviceJobNumber
            staff {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            stopsAt
            title
            updatedAt
        }
        staff {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        staff {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        title
        workOrder {
            address {
                id
            }
            archivedAt
            assignedTo {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            checkedInAt
            checkedOutAt
            createdAt
            createdBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            description
            dueRangeEnd
            dueRangeStart
            id
            paymentUrl
            requiresAddress
            requiresAppointment
            requiresAssignedTo
            requiresPool
            requiresPreferredContact
            requiresTestReport
            requiresWorkFor
            statusClosedAt
            title
            updatedAt
            workFor {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            workOrderNumber
        }
    }
`;

export const createGqlQueryWorkOrderFeedConnection = (_nodeFragment = fragmentWorkOrderFeedItemNode) => gql`
    query QueryWorkOrderFeedConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $reverse: Boolean
        $search: WorkOrderFeedItemViewerWorkOrderFeedSearch
    ) {
        viewer {
            workOrderFeed(
                after: $after
                before: $before
                first: $first
                last: $last
                reverse: $reverse
                search: $search
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }

                edges {
                    node {
                        ...WorkOrderFeedItemConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryWorkOrderFeedConnectionGql = createGqlQueryWorkOrderFeedConnection(fragmentWorkOrderFeedItemNode);

export const queryWorkOrderFeedItemNode = gql`
    query queryConexpNodeWorkOrderFeedItem($id: ID!) {
        node(id: $id) {
            id
            ...WorkOrderFeedItemConexpFragment
        }
    }
    ${fragmentWorkOrderFeedItemNode}
`;

// ************** workOrderTemplates ***************** //

// workOrderTemplates: gql query

export const fragmentWorkOrderTemplateNode = gql`
    fragment WorkOrderTemplateConexpFragment on WorkOrderTemplate {
        addedBy {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        addedBy {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        archivedAt
        checkMutations {
            archive
            delete
            update
        }
        createdAt
        defaultStage {
            archivedAt
            color
            createdAt
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            hidden
            id
            isDefault
            isFallback
            priority
            tag
            title
            updatedAt
        }
        franchise {
            id
            name
            organisationType {
                id
                name
            }
        }
        franchise {
            address {
                id
            }
            contactExport
            createdAt
            customerCount
            email
            fileSafeName
            id
            manager {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            name
            parentFranchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            phoneNumber
            poolCount
            refId
            staffCount
            updatedAt
            uuid
        }
        group {
            createdAt
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            priority
            tag
            title
        }
        hideStageActionRequired
        hideStageInProgress
        hideStageSuspended
        id
        onlyOwnedStages
        organisationType {
            id
            locales
            logo
            name
            timeZone
            token
        }
        ownedByServiceJobTemplate {
            addedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            archivedAt
            canBeRecurring
            createdAt
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            hideStageActionRequired
            hideStageInProgress
            hideStageSuspended
            id
            isDefault
            isPublished
            priority
            providedDescription
            providedTitle
            requiresAddress
            requiresContact
            requiresCustomer
            requiresPool
            requiresStaff
            tag
            templateDescription
            templateTitle
            updatedAt
        }
        providedDescription
        providedTitle
        requiresAddress
        requiresContact
        requiresCustomer
        requiresPool
        requiresStaff
        stageReport {
            isActive
        }
        tag
        templateDescription
        templateTitle
        updatedAt
    }
`;

export const createGqlQueryWorkOrderTemplatesConnection = (_nodeFragment = fragmentWorkOrderTemplateNode) => gql`
    query QueryWorkOrderTemplatesConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: WorkOrderTemplateViewerWorkOrderTemplatesSearch
        $sort: WorkOrderTemplateViewerWorkOrderTemplatesSort
    ) {
        viewer {
            workOrderTemplates(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...WorkOrderTemplateConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryWorkOrderTemplatesConnectionGql =
    createGqlQueryWorkOrderTemplatesConnection(fragmentWorkOrderTemplateNode);

export const queryWorkOrderTemplateNode = gql`
    query queryConexpNodeWorkOrderTemplate($id: ID!) {
        node(id: $id) {
            id
            ...WorkOrderTemplateConexpFragment
        }
    }
    ${fragmentWorkOrderTemplateNode}
`;

// ************** workOrders ***************** //

// workOrders: gql query

export const fragmentWorkOrderNode = gql`
    fragment WorkOrderConexpFragment on WorkOrder {
        address {
            id
        }
        address {
            buildingName
            city
            country
            createdAt
            destinationName
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            id
            isBill
            isPrimary
            poBoxNumber
            postCode
            refId
            state
            streetName
            streetNumber
            suburb
            uuid
        }
        appointmentItem {
            address {
                id
            }
            adhoc
            color
            deleted
            duration
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            isEnd
            isRecurrentOrigin
            isRecurring
            isStart
            isWorkOrderPending
            note
            originalDate
            patternBlockId
            paymentUrl
            staff {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            startDate
        }
        archivedAt
        assignedTo {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        assignedTo {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        checkMutations {
            addJobTodoFromTemplate
            addVendSale
            appendWorkOrderTemplate
            assignAppointmentItem
            delete
            replaceWorkOrderTemplate
            update
        }
        checkedInAt
        checkedOutAt
        createdAt
        createdBy {
            ... on Staff {
                id
                user {
                    id
                    firstName
                    lastName
                }
                role {
                    id
                    name
                }
            }
        }
        createdBy {
            archivedAt
            archivedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            color
            id
            isSystemHidden
            managedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        customer {
            addedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            assignedTo {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            billAddress {
                id
            }
            companyName
            contactName
            createdAt
            crn
            id
            note
            primaryAddress {
                id
            }
            refId
            registeredAt
            root {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        description
        dueRangeEnd
        dueRangeStart
        followUpFrom {
            address {
                id
            }
            archivedAt
            assignedTo {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            checkedInAt
            checkedOutAt
            createdAt
            createdBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            description
            dueRangeEnd
            dueRangeStart
            id
            paymentUrl
            requiresAddress
            requiresAppointment
            requiresAssignedTo
            requiresPool
            requiresPreferredContact
            requiresTestReport
            requiresWorkFor
            statusClosedAt
            title
            updatedAt
            workFor {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            workOrderNumber
        }
        id
        paymentUrl
        pool {
            address {
                id
            }
            bottleNumber
            createdAt
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            name
            note
            notePlain
            refId
            revision
            uuid
        }
        preferredContact {
            createdAt
            data
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            id
            isPrimary
            label
            refId
            updatedAt
            uuid
        }
        requiresAddress
        requiresAppointment
        requiresAssignedTo
        requiresPool
        requiresPreferredContact
        requiresTestReport
        requiresWorkFor
        serviceJob {
            address {
                id
            }
            archivedAt
            closedAt
            createdAt
            createdBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            description
            dueDate
            entity {
                ... on Entity {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            id
            isImplicit
            isRecurring
            isSimpleMode
            lastAppointmentDate
            serviceJobNumber
            staff {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            stopsAt
            title
            updatedAt
        }
        stage {
            archivedAt
            color
            createdAt
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            hidden
            id
            isDefault
            isFallback
            priority
            tag
            title
            updatedAt
        }
        stageCandidates {
            archivedAt
            color
            createdAt
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            hidden
            id
            isDefault
            isFallback
            priority
            tag
            title
            updatedAt
        }
        statusClosedAt
        title
        updatedAt
        usedTemplate {
            addedBy {
                ... on Staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
            }
            archivedAt
            createdAt
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            hideStageActionRequired
            hideStageInProgress
            hideStageSuspended
            id
            onlyOwnedStages
            providedDescription
            providedTitle
            requiresAddress
            requiresContact
            requiresCustomer
            requiresPool
            requiresStaff
            tag
            templateDescription
            templateTitle
            updatedAt
        }
        workFor {
            ... on Entity {
                id
                user {
                    id
                    firstName
                    lastName
                }
            }
        }
        workFor {
            active
            chainUpdate
            franchise {
                id
                name
                organisationType {
                    id
                    name
                }
            }
            id
            lastReportActivity
            uuid
        }
        workOrderNumber
    }
`;

export const createGqlQueryWorkOrdersConnection = (_nodeFragment = fragmentWorkOrderNode) => gql`
    query QueryWorkOrdersConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: WorkOrderViewerWorkOrdersSearch
        $sort: WorkOrderViewerWorkOrdersSort
    ) {
        viewer {
            workOrders(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        ...WorkOrderConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

export const QueryWorkOrdersConnectionGql = createGqlQueryWorkOrdersConnection(fragmentWorkOrderNode);

export const queryWorkOrderNode = gql`
    query queryConexpNodeWorkOrder($id: ID!) {
        node(id: $id) {
            id
            ...WorkOrderConexpFragment
        }
    }
    ${fragmentWorkOrderNode}
`;

// Mutation Query Lookup table

export type ConexpItemType = {
    connectionId: string;
    connectionTypeName: string;
    nodeTypeName: string;
    gqlConnectionQuery: DocumentNode;
    gqlNodeFragment: DocumentNode;
    gqlNodeQuery: DocumentNode;
};

const __availableConnections: { [keys: string]: ConexpItemType } = {
    abstractDocuments: {
        connectionId: 'abstractDocuments',
        connectionTypeName: 'ViewerAbstractDocumentsConnection',
        nodeTypeName: 'AbstractDocument',
        gqlConnectionQuery: QueryAbstractDocumentsConnectionGql,
        gqlNodeFragment: fragmentAbstractDocumentNode,
        gqlNodeQuery: queryAbstractDocumentNode,
    },
    announcements: {
        connectionId: 'announcements',
        connectionTypeName: 'ViewerAnnouncementsConnection',
        nodeTypeName: 'Announcement',
        gqlConnectionQuery: QueryAnnouncementsConnectionGql,
        gqlNodeFragment: fragmentAnnouncementNode,
        gqlNodeQuery: queryAnnouncementNode,
    },
    appointmentGroups: {
        connectionId: 'appointmentGroups',
        connectionTypeName: 'ViewerAppointmentGroupsConnection',
        nodeTypeName: 'AppointmentGroup',
        gqlConnectionQuery: QueryAppointmentGroupsConnectionGql,
        gqlNodeFragment: fragmentAppointmentGroupNode,
        gqlNodeQuery: queryAppointmentGroupNode,
    },
    appointmentItems: {
        connectionId: 'appointmentItems',
        connectionTypeName: 'ViewerAppointmentItemsConnection',
        nodeTypeName: 'AppointmentItem',
        gqlConnectionQuery: QueryAppointmentItemsConnectionGql,
        gqlNodeFragment: fragmentAppointmentItemNode,
        gqlNodeQuery: queryAppointmentItemNode,
    },
    appointments: {
        connectionId: 'appointments',
        connectionTypeName: 'ViewerAppointmentsConnection',
        nodeTypeName: 'Appointment',
        gqlConnectionQuery: QueryAppointmentsConnectionGql,
        gqlNodeFragment: fragmentAppointmentNode,
        gqlNodeQuery: queryAppointmentNode,
    },
    brands: {
        connectionId: 'brands',
        connectionTypeName: 'ViewerBrandsConnection',
        nodeTypeName: 'Brand',
        gqlConnectionQuery: QueryBrandsConnectionGql,
        gqlNodeFragment: fragmentBrandNode,
        gqlNodeQuery: queryBrandNode,
    },
    consumableProducts: {
        connectionId: 'consumableProducts',
        connectionTypeName: 'ViewerConsumableProductsConnection',
        nodeTypeName: 'ConsumableProduct',
        gqlConnectionQuery: QueryConsumableProductsConnectionGql,
        gqlNodeFragment: fragmentConsumableProductNode,
        gqlNodeQuery: queryConsumableProductNode,
    },
    contactTypes: {
        connectionId: 'contactTypes',
        connectionTypeName: 'ViewerContactTypesConnection',
        nodeTypeName: 'ContactType',
        gqlConnectionQuery: QueryContactTypesConnectionGql,
        gqlNodeFragment: fragmentContactTypeNode,
        gqlNodeQuery: queryContactTypeNode,
    },
    converters: {
        connectionId: 'converters',
        connectionTypeName: 'ViewerConvertersConnection',
        nodeTypeName: 'SampleConverter',
        gqlConnectionQuery: QueryConvertersConnectionGql,
        gqlNodeFragment: fragmentSampleConverterNode,
        gqlNodeQuery: querySampleConverterNode,
    },
    customContracts: {
        connectionId: 'customContracts',
        connectionTypeName: 'ViewerCustomContractsConnection',
        nodeTypeName: 'CustomContract',
        gqlConnectionQuery: QueryCustomContractsConnectionGql,
        gqlNodeFragment: fragmentCustomContractNode,
        gqlNodeQuery: queryCustomContractNode,
    },
    customerTags: {
        connectionId: 'customerTags',
        connectionTypeName: 'ViewerCustomerTagsConnection',
        nodeTypeName: 'CustomerTag',
        gqlConnectionQuery: QueryCustomerTagsConnectionGql,
        gqlNodeFragment: fragmentCustomerTagNode,
        gqlNodeQuery: queryCustomerTagNode,
    },
    customers: {
        connectionId: 'customers',
        connectionTypeName: 'ViewerCustomersConnection',
        nodeTypeName: 'Customer',
        gqlConnectionQuery: QueryCustomersConnectionGql,
        gqlNodeFragment: fragmentCustomerNode,
        gqlNodeQuery: queryCustomerNode,
    },
    emailGlobalTemplateSelections: {
        connectionId: 'emailGlobalTemplateSelections',
        connectionTypeName: 'ViewerEmailGlobalTemplateSelectionsConnection',
        nodeTypeName: 'EmailGlobalSelection',
        gqlConnectionQuery: QueryEmailGlobalTemplateSelectionsConnectionGql,
        gqlNodeFragment: fragmentEmailGlobalSelectionNode,
        gqlNodeQuery: queryEmailGlobalSelectionNode,
    },
    emailOrganisationTemplateSelections: {
        connectionId: 'emailOrganisationTemplateSelections',
        connectionTypeName: 'ViewerEmailOrganisationTemplateSelectionsConnection',
        nodeTypeName: 'EmailTemplateOrganisationSelection',
        gqlConnectionQuery: QueryEmailOrganisationTemplateSelectionsConnectionGql,
        gqlNodeFragment: fragmentEmailTemplateOrganisationSelectionNode,
        gqlNodeQuery: queryEmailTemplateOrganisationSelectionNode,
    },
    emailTemplateSelections: {
        connectionId: 'emailTemplateSelections',
        connectionTypeName: 'ViewerEmailTemplateSelectionsConnection',
        nodeTypeName: 'EmailTemplateSelection',
        gqlConnectionQuery: QueryEmailTemplateSelectionsConnectionGql,
        gqlNodeFragment: fragmentEmailTemplateSelectionNode,
        gqlNodeQuery: queryEmailTemplateSelectionNode,
    },
    emailTemplates: {
        connectionId: 'emailTemplates',
        connectionTypeName: 'ViewerEmailTemplatesConnection',
        nodeTypeName: 'EmailTemplate',
        gqlConnectionQuery: QueryEmailTemplatesConnectionGql,
        gqlNodeFragment: fragmentEmailTemplateNode,
        gqlNodeQuery: queryEmailTemplateNode,
    },
    emailTypes: {
        connectionId: 'emailTypes',
        connectionTypeName: 'ViewerEmailTypesConnection',
        nodeTypeName: 'EmailType',
        gqlConnectionQuery: QueryEmailTypesConnectionGql,
        gqlNodeFragment: fragmentEmailTypeNode,
        gqlNodeQuery: queryEmailTypeNode,
    },
    entities: {
        connectionId: 'entities',
        connectionTypeName: 'ViewerEntitiesConnection',
        nodeTypeName: 'Entity',
        gqlConnectionQuery: QueryEntitiesConnectionGql,
        gqlNodeFragment: fragmentEntityNode,
        gqlNodeQuery: queryEntityNode,
    },
    ezcDeviceDefinitions: {
        connectionId: 'ezcDeviceDefinitions',
        connectionTypeName: 'ViewerEzcDeviceDefinitionsConnection',
        nodeTypeName: 'EZCDeviceDefinition',
        gqlConnectionQuery: QueryEzcDeviceDefinitionsConnectionGql,
        gqlNodeFragment: fragmentEZCDeviceDefinitionNode,
        gqlNodeQuery: queryEZCDeviceDefinitionNode,
    },
    ezcDeviceLinks: {
        connectionId: 'ezcDeviceLinks',
        connectionTypeName: 'ViewerEzcDeviceLinksConnection',
        nodeTypeName: 'EZCDeviceLink',
        gqlConnectionQuery: QueryEzcDeviceLinksConnectionGql,
        gqlNodeFragment: fragmentEZCDeviceLinkNode,
        gqlNodeQuery: queryEZCDeviceLinkNode,
    },
    ezcDevices: {
        connectionId: 'ezcDevices',
        connectionTypeName: 'ViewerEzcDevicesConnection',
        nodeTypeName: 'EZCDevice',
        gqlConnectionQuery: QueryEzcDevicesConnectionGql,
        gqlNodeFragment: fragmentEZCDeviceNode,
        gqlNodeQuery: queryEZCDeviceNode,
    },
    ezcServiceProviderLinks: {
        connectionId: 'ezcServiceProviderLinks',
        connectionTypeName: 'ViewerEzcServiceProviderLinksConnection',
        nodeTypeName: 'EZCFranchiseLink',
        gqlConnectionQuery: QueryEzcServiceProviderLinksConnectionGql,
        gqlNodeFragment: fragmentEZCFranchiseLinkNode,
        gqlNodeQuery: queryEZCFranchiseLinkNode,
    },
    ezcServiceProviders: {
        connectionId: 'ezcServiceProviders',
        connectionTypeName: 'ViewerEzcServiceProvidersConnection',
        nodeTypeName: 'EZCServiceProvider',
        gqlConnectionQuery: QueryEzcServiceProvidersConnectionGql,
        gqlNodeFragment: fragmentEZCServiceProviderNode,
        gqlNodeQuery: queryEZCServiceProviderNode,
    },
    featureFlagVariations: {
        connectionId: 'featureFlagVariations',
        connectionTypeName: 'ViewerFeatureFlagVariationsConnection',
        nodeTypeName: 'FeatureFlagVariation',
        gqlConnectionQuery: QueryFeatureFlagVariationsConnectionGql,
        gqlNodeFragment: fragmentFeatureFlagVariationNode,
        gqlNodeQuery: queryFeatureFlagVariationNode,
    },
    featureFlags: {
        connectionId: 'featureFlags',
        connectionTypeName: 'ViewerFeatureFlagsConnection',
        nodeTypeName: 'FeatureFlag',
        gqlConnectionQuery: QueryFeatureFlagsConnectionGql,
        gqlNodeFragment: fragmentFeatureFlagNode,
        gqlNodeQuery: queryFeatureFlagNode,
    },
    files: {
        connectionId: 'files',
        connectionTypeName: 'ViewerFilesConnection',
        nodeTypeName: 'FileUpload',
        gqlConnectionQuery: QueryFilesConnectionGql,
        gqlNodeFragment: fragmentFileUploadNode,
        gqlNodeQuery: queryFileUploadNode,
    },
    franchiseSequenceStore: {
        connectionId: 'franchiseSequenceStore',
        connectionTypeName: 'ViewerFranchiseSequenceStoreConnection',
        nodeTypeName: 'FranchiseSequenceStore',
        gqlConnectionQuery: QueryFranchiseSequenceStoreConnectionGql,
        gqlNodeFragment: fragmentFranchiseSequenceStoreNode,
        gqlNodeQuery: queryFranchiseSequenceStoreNode,
    },
    franchiseValuesStore: {
        connectionId: 'franchiseValuesStore',
        connectionTypeName: 'ViewerFranchiseValuesStoreConnection',
        nodeTypeName: 'FranchiseValueStore',
        gqlConnectionQuery: QueryFranchiseValuesStoreConnectionGql,
        gqlNodeFragment: fragmentFranchiseValueStoreNode,
        gqlNodeQuery: queryFranchiseValueStoreNode,
    },
    franchiseWarranters: {
        connectionId: 'franchiseWarranters',
        connectionTypeName: 'ViewerFranchiseWarrantersConnection',
        nodeTypeName: 'FranchiseWarranter',
        gqlConnectionQuery: QueryFranchiseWarrantersConnectionGql,
        gqlNodeFragment: fragmentFranchiseWarranterNode,
        gqlNodeQuery: queryFranchiseWarranterNode,
    },
    franchises: {
        connectionId: 'franchises',
        connectionTypeName: 'ViewerFranchisesConnection',
        nodeTypeName: 'Franchise',
        gqlConnectionQuery: QueryFranchisesConnectionGql,
        gqlNodeFragment: fragmentFranchiseNode,
        gqlNodeQuery: queryFranchiseNode,
    },
    heaterRecommendations: {
        connectionId: 'heaterRecommendations',
        connectionTypeName: 'ViewerHeaterRecommendationsConnection',
        nodeTypeName: 'HeaterRecommendation',
        gqlConnectionQuery: QueryHeaterRecommendationsConnectionGql,
        gqlNodeFragment: fragmentHeaterRecommendationNode,
        gqlNodeQuery: queryHeaterRecommendationNode,
    },
    installableProducts: {
        connectionId: 'installableProducts',
        connectionTypeName: 'ViewerInstallableProductsConnection',
        nodeTypeName: 'InstallableProduct',
        gqlConnectionQuery: QueryInstallableProductsConnectionGql,
        gqlNodeFragment: fragmentInstallableProductNode,
        gqlNodeQuery: queryInstallableProductNode,
    },
    jobTodoTemplates: {
        connectionId: 'jobTodoTemplates',
        connectionTypeName: 'ViewerJobTodoTemplatesConnection',
        nodeTypeName: 'JobTodoTemplate',
        gqlConnectionQuery: QueryJobTodoTemplatesConnectionGql,
        gqlNodeFragment: fragmentJobTodoTemplateNode,
        gqlNodeQuery: queryJobTodoTemplateNode,
    },
    locales: {
        connectionId: 'locales',
        connectionTypeName: 'ViewerLocalesConnection',
        nodeTypeName: 'Locales',
        gqlConnectionQuery: QueryLocalesConnectionGql,
        gqlNodeFragment: fragmentLocalesNode,
        gqlNodeQuery: queryLocalesNode,
    },
    manualProblems: {
        connectionId: 'manualProblems',
        connectionTypeName: 'ViewerManualProblemsConnection',
        nodeTypeName: 'ManualProblem',
        gqlConnectionQuery: QueryManualProblemsConnectionGql,
        gqlNodeFragment: fragmentManualProblemNode,
        gqlNodeQuery: queryManualProblemNode,
    },
    measurementTypes: {
        connectionId: 'measurementTypes',
        connectionTypeName: 'ViewerMeasurementTypesConnection',
        nodeTypeName: 'Measurement',
        gqlConnectionQuery: QueryMeasurementTypesConnectionGql,
        gqlNodeFragment: fragmentMeasurementNode,
        gqlNodeQuery: queryMeasurementNode,
    },
    measurementUnits: {
        connectionId: 'measurementUnits',
        connectionTypeName: 'ViewerMeasurementUnitsConnection',
        nodeTypeName: 'Unit',
        gqlConnectionQuery: QueryMeasurementUnitsConnectionGql,
        gqlNodeFragment: fragmentUnitNode,
        gqlNodeQuery: queryUnitNode,
    },
    organisationTypes: {
        connectionId: 'organisationTypes',
        connectionTypeName: 'ViewerOrganisationTypesConnection',
        nodeTypeName: 'OrganisationType',
        gqlConnectionQuery: QueryOrganisationTypesConnectionGql,
        gqlNodeFragment: fragmentOrganisationTypeNode,
        gqlNodeQuery: queryOrganisationTypeNode,
    },
    permissions: {
        connectionId: 'permissions',
        connectionTypeName: 'ViewerPermissionsConnection',
        nodeTypeName: 'Permission',
        gqlConnectionQuery: QueryPermissionsConnectionGql,
        gqlNodeFragment: fragmentPermissionNode,
        gqlNodeQuery: queryPermissionNode,
    },
    poolBottleLeases: {
        connectionId: 'poolBottleLeases',
        connectionTypeName: 'ViewerPoolBottleLeasesConnection',
        nodeTypeName: 'PoolBottleLease',
        gqlConnectionQuery: QueryPoolBottleLeasesConnectionGql,
        gqlNodeFragment: fragmentPoolBottleLeaseNode,
        gqlNodeQuery: queryPoolBottleLeaseNode,
    },
    poolBottles: {
        connectionId: 'poolBottles',
        connectionTypeName: 'ViewerPoolBottlesConnection',
        nodeTypeName: 'PoolBottle',
        gqlConnectionQuery: QueryPoolBottlesConnectionGql,
        gqlNodeFragment: fragmentPoolBottleNode,
        gqlNodeQuery: queryPoolBottleNode,
    },
    poolDeclarationFlagFields: {
        connectionId: 'poolDeclarationFlagFields',
        connectionTypeName: 'ViewerPoolDeclarationFlagFieldsConnection',
        nodeTypeName: 'PoolDeclarationFlagField',
        gqlConnectionQuery: QueryPoolDeclarationFlagFieldsConnectionGql,
        gqlNodeFragment: fragmentPoolDeclarationFlagFieldNode,
        gqlNodeQuery: queryPoolDeclarationFlagFieldNode,
    },
    poolProductDeclarations: {
        connectionId: 'poolProductDeclarations',
        connectionTypeName: 'ViewerPoolProductDeclarationsConnection',
        nodeTypeName: 'PoolProductDeclaration',
        gqlConnectionQuery: QueryPoolProductDeclarationsConnectionGql,
        gqlNodeFragment: fragmentPoolProductDeclarationNode,
        gqlNodeQuery: queryPoolProductDeclarationNode,
    },
    poolSanitisers: {
        connectionId: 'poolSanitisers',
        connectionTypeName: 'ViewerPoolSanitisersConnection',
        nodeTypeName: 'Sanitiser',
        gqlConnectionQuery: QueryPoolSanitisersConnectionGql,
        gqlNodeFragment: fragmentSanitiserNode,
        gqlNodeQuery: querySanitiserNode,
    },
    poolTransfers: {
        connectionId: 'poolTransfers',
        connectionTypeName: 'ViewerPoolTransfersConnection',
        nodeTypeName: 'PoolOwnershipHistory',
        gqlConnectionQuery: QueryPoolTransfersConnectionGql,
        gqlNodeFragment: fragmentPoolOwnershipHistoryNode,
        gqlNodeQuery: queryPoolOwnershipHistoryNode,
    },
    poolTypes: {
        connectionId: 'poolTypes',
        connectionTypeName: 'ViewerPoolTypesConnection',
        nodeTypeName: 'PoolType',
        gqlConnectionQuery: QueryPoolTypesConnectionGql,
        gqlNodeFragment: fragmentPoolTypeNode,
        gqlNodeQuery: queryPoolTypeNode,
    },
    pools: {
        connectionId: 'pools',
        connectionTypeName: 'ViewerPoolsConnection',
        nodeTypeName: 'Pool',
        gqlConnectionQuery: QueryPoolsConnectionGql,
        gqlNodeFragment: fragmentPoolNode,
        gqlNodeQuery: queryPoolNode,
    },
    productCompany: {
        connectionId: 'productCompany',
        connectionTypeName: 'ViewerProductCompanyConnection',
        nodeTypeName: 'ProductCompany',
        gqlConnectionQuery: QueryProductCompanyConnectionGql,
        gqlNodeFragment: fragmentProductCompanyNode,
        gqlNodeQuery: queryProductCompanyNode,
    },
    productDeclarations: {
        connectionId: 'productDeclarations',
        connectionTypeName: 'ViewerProductDeclarationsConnection',
        nodeTypeName: 'ProductTraitDeclaration',
        gqlConnectionQuery: QueryProductDeclarationsConnectionGql,
        gqlNodeFragment: fragmentProductTraitDeclarationNode,
        gqlNodeQuery: queryProductTraitDeclarationNode,
    },
    productDocuments: {
        connectionId: 'productDocuments',
        connectionTypeName: 'ViewerProductDocumentsConnection',
        nodeTypeName: 'ProductDocument',
        gqlConnectionQuery: QueryProductDocumentsConnectionGql,
        gqlNodeFragment: fragmentProductDocumentNode,
        gqlNodeQuery: queryProductDocumentNode,
    },
    products: {
        connectionId: 'products',
        connectionTypeName: 'ViewerProductsConnection',
        nodeTypeName: 'Product',
        gqlConnectionQuery: QueryProductsConnectionGql,
        gqlNodeFragment: fragmentProductNode,
        gqlNodeQuery: queryProductNode,
    },
    projectHosting: {
        connectionId: 'projectHosting',
        connectionTypeName: 'ViewerProjectHostingConnection',
        nodeTypeName: 'Project',
        gqlConnectionQuery: QueryProjectHostingConnectionGql,
        gqlNodeFragment: fragmentProjectNode,
        gqlNodeQuery: queryProjectNode,
    },
    reports: {
        connectionId: 'reports',
        connectionTypeName: 'ViewerReportsConnection',
        nodeTypeName: 'TestReport',
        gqlConnectionQuery: QueryReportsConnectionGql,
        gqlNodeFragment: fragmentTestReportNode,
        gqlNodeQuery: queryTestReportNode,
    },
    roleFlags: {
        connectionId: 'roleFlags',
        connectionTypeName: 'ViewerRoleFlagsConnection',
        nodeTypeName: 'RoleFlag',
        gqlConnectionQuery: QueryRoleFlagsConnectionGql,
        gqlNodeFragment: fragmentRoleFlagNode,
        gqlNodeQuery: queryRoleFlagNode,
    },
    roles: {
        connectionId: 'roles',
        connectionTypeName: 'ViewerRolesConnection',
        nodeTypeName: 'Role',
        gqlConnectionQuery: QueryRolesConnectionGql,
        gqlNodeFragment: fragmentRoleNode,
        gqlNodeQuery: queryRoleNode,
    },
    sampleSets: {
        connectionId: 'sampleSets',
        connectionTypeName: 'ViewerSampleSetsConnection',
        nodeTypeName: 'SampleSet',
        gqlConnectionQuery: QuerySampleSetsConnectionGql,
        gqlNodeFragment: fragmentSampleSetNode,
        gqlNodeQuery: querySampleSetNode,
    },
    sampleSources: {
        connectionId: 'sampleSources',
        connectionTypeName: 'ViewerSampleSourcesConnection',
        nodeTypeName: 'SampleSource',
        gqlConnectionQuery: QuerySampleSourcesConnectionGql,
        gqlNodeFragment: fragmentSampleSourceNode,
        gqlNodeQuery: querySampleSourceNode,
    },
    sentEmails: {
        connectionId: 'sentEmails',
        connectionTypeName: 'ViewerSentEmailsConnection',
        nodeTypeName: 'EmailMessage',
        gqlConnectionQuery: QuerySentEmailsConnectionGql,
        gqlNodeFragment: fragmentEmailMessageNode,
        gqlNodeQuery: queryEmailMessageNode,
    },
    serviceJobGroups: {
        connectionId: 'serviceJobGroups',
        connectionTypeName: 'ViewerServiceJobGroupsConnection',
        nodeTypeName: 'ServiceJobGroup',
        gqlConnectionQuery: QueryServiceJobGroupsConnectionGql,
        gqlNodeFragment: fragmentServiceJobGroupNode,
        gqlNodeQuery: queryServiceJobGroupNode,
    },
    serviceJobStages: {
        connectionId: 'serviceJobStages',
        connectionTypeName: 'ViewerServiceJobStagesConnection',
        nodeTypeName: 'ServiceJobStage',
        gqlConnectionQuery: QueryServiceJobStagesConnectionGql,
        gqlNodeFragment: fragmentServiceJobStageNode,
        gqlNodeQuery: queryServiceJobStageNode,
    },
    serviceJobTemplates: {
        connectionId: 'serviceJobTemplates',
        connectionTypeName: 'ViewerServiceJobTemplatesConnection',
        nodeTypeName: 'ServiceJobTemplate',
        gqlConnectionQuery: QueryServiceJobTemplatesConnectionGql,
        gqlNodeFragment: fragmentServiceJobTemplateNode,
        gqlNodeQuery: queryServiceJobTemplateNode,
    },
    serviceJobs: {
        connectionId: 'serviceJobs',
        connectionTypeName: 'ViewerServiceJobsConnection',
        nodeTypeName: 'ServiceJob',
        gqlConnectionQuery: QueryServiceJobsConnectionGql,
        gqlNodeFragment: fragmentServiceJobNode,
        gqlNodeQuery: queryServiceJobNode,
    },
    settingStore: {
        connectionId: 'settingStore',
        connectionTypeName: 'ViewerSettingStoreConnection',
        nodeTypeName: 'SettingStore',
        gqlConnectionQuery: QuerySettingStoreConnectionGql,
        gqlNodeFragment: fragmentSettingStoreNode,
        gqlNodeQuery: querySettingStoreNode,
    },
    shops: {
        connectionId: 'shops',
        connectionTypeName: 'ViewerShopsConnection',
        nodeTypeName: 'FranchiseShop',
        gqlConnectionQuery: QueryShopsConnectionGql,
        gqlNodeFragment: fragmentFranchiseShopNode,
        gqlNodeQuery: queryFranchiseShopNode,
    },
    sites: {
        connectionId: 'sites',
        connectionTypeName: 'ViewerSitesConnection',
        nodeTypeName: 'Site',
        gqlConnectionQuery: QuerySitesConnectionGql,
        gqlNodeFragment: fragmentSiteNode,
        gqlNodeQuery: querySiteNode,
    },
    staff: {
        connectionId: 'staff',
        connectionTypeName: 'ViewerStaffConnection',
        nodeTypeName: 'Staff',
        gqlConnectionQuery: QueryStaffConnectionGql,
        gqlNodeFragment: fragmentStaffNode,
        gqlNodeQuery: queryStaffNode,
    },
    staffTeams: {
        connectionId: 'staffTeams',
        connectionTypeName: 'ViewerStaffTeamsConnection',
        nodeTypeName: 'StaffTeam',
        gqlConnectionQuery: QueryStaffTeamsConnectionGql,
        gqlNodeFragment: fragmentStaffTeamNode,
        gqlNodeQuery: queryStaffTeamNode,
    },
    staffValuesStore: {
        connectionId: 'staffValuesStore',
        connectionTypeName: 'ViewerStaffValuesStoreConnection',
        nodeTypeName: 'StaffValueStore',
        gqlConnectionQuery: QueryStaffValuesStoreConnectionGql,
        gqlNodeFragment: fragmentStaffValueStoreNode,
        gqlNodeQuery: queryStaffValueStoreNode,
    },
    testCriteria: {
        connectionId: 'testCriteria',
        connectionTypeName: 'ViewerTestCriteriaConnection',
        nodeTypeName: 'TestCriteria',
        gqlConnectionQuery: QueryTestCriteriaConnectionGql,
        gqlNodeFragment: fragmentTestCriteriaNode,
        gqlNodeQuery: queryTestCriteriaNode,
    },
    testProblems: {
        connectionId: 'testProblems',
        connectionTypeName: 'ViewerTestProblemsConnection',
        nodeTypeName: 'TestProblem',
        gqlConnectionQuery: QueryTestProblemsConnectionGql,
        gqlNodeFragment: fragmentTestProblemNode,
        gqlNodeQuery: queryTestProblemNode,
    },
    testTreatmentGroupForFranchises: {
        connectionId: 'testTreatmentGroupForFranchises',
        connectionTypeName: 'ViewerTestTreatmentGroupForFranchisesConnection',
        nodeTypeName: 'TestTreatmentFranchiseGroup',
        gqlConnectionQuery: QueryTestTreatmentGroupForFranchisesConnectionGql,
        gqlNodeFragment: fragmentTestTreatmentFranchiseGroupNode,
        gqlNodeQuery: queryTestTreatmentFranchiseGroupNode,
    },
    testTreatmentGroups: {
        connectionId: 'testTreatmentGroups',
        connectionTypeName: 'ViewerTestTreatmentGroupsConnection',
        nodeTypeName: 'TestTreatmentGroup',
        gqlConnectionQuery: QueryTestTreatmentGroupsConnectionGql,
        gqlNodeFragment: fragmentTestTreatmentGroupNode,
        gqlNodeQuery: queryTestTreatmentGroupNode,
    },
    testTreatments: {
        connectionId: 'testTreatments',
        connectionTypeName: 'ViewerTestTreatmentsConnection',
        nodeTypeName: 'TestTreatment',
        gqlConnectionQuery: QueryTestTreatmentsConnectionGql,
        gqlNodeFragment: fragmentTestTreatmentNode,
        gqlNodeQuery: queryTestTreatmentNode,
    },
    timezones: {
        connectionId: 'timezones',
        connectionTypeName: 'ViewerTimezonesConnection',
        nodeTypeName: 'Timezone',
        gqlConnectionQuery: QueryTimezonesConnectionGql,
        gqlNodeFragment: fragmentTimezoneNode,
        gqlNodeQuery: queryTimezoneNode,
    },
    unitConversions: {
        connectionId: 'unitConversions',
        connectionTypeName: 'ViewerUnitConversionsConnection',
        nodeTypeName: 'UnitConversion',
        gqlConnectionQuery: QueryUnitConversionsConnectionGql,
        gqlNodeFragment: fragmentUnitConversionNode,
        gqlNodeQuery: queryUnitConversionNode,
    },
    unitConverters: {
        connectionId: 'unitConverters',
        connectionTypeName: 'ViewerUnitConvertersConnection',
        nodeTypeName: 'UnitConverter',
        gqlConnectionQuery: QueryUnitConvertersConnectionGql,
        gqlNodeFragment: fragmentUnitConverterNode,
        gqlNodeQuery: queryUnitConverterNode,
    },
    universalCriteria: {
        connectionId: 'universalCriteria',
        connectionTypeName: 'ViewerUniversalCriteriaConnection',
        nodeTypeName: 'UniversalCriteria',
        gqlConnectionQuery: QueryUniversalCriteriaConnectionGql,
        gqlNodeFragment: fragmentUniversalCriteriaNode,
        gqlNodeQuery: queryUniversalCriteriaNode,
    },
    users: {
        connectionId: 'users',
        connectionTypeName: 'ViewerUsersConnection',
        nodeTypeName: 'User',
        gqlConnectionQuery: QueryUsersConnectionGql,
        gqlNodeFragment: fragmentUserNode,
        gqlNodeQuery: queryUserNode,
    },
    warranterClaimMessages: {
        connectionId: 'warranterClaimMessages',
        connectionTypeName: 'ViewerWarranterClaimMessagesConnection',
        nodeTypeName: 'WarrantyClaimMessage',
        gqlConnectionQuery: QueryWarranterClaimMessagesConnectionGql,
        gqlNodeFragment: fragmentWarrantyClaimMessageNode,
        gqlNodeQuery: queryWarrantyClaimMessageNode,
    },
    warrantyClaimCases: {
        connectionId: 'warrantyClaimCases',
        connectionTypeName: 'ViewerWarrantyClaimCasesConnection',
        nodeTypeName: 'WarrantyClaimCase',
        gqlConnectionQuery: QueryWarrantyClaimCasesConnectionGql,
        gqlNodeFragment: fragmentWarrantyClaimCaseNode,
        gqlNodeQuery: queryWarrantyClaimCaseNode,
    },
    warrantyClaims: {
        connectionId: 'warrantyClaims',
        connectionTypeName: 'ViewerWarrantyClaimsConnection',
        nodeTypeName: 'WarrantyClaim',
        gqlConnectionQuery: QueryWarrantyClaimsConnectionGql,
        gqlNodeFragment: fragmentWarrantyClaimNode,
        gqlNodeQuery: queryWarrantyClaimNode,
    },
    workOrderFeed: {
        connectionId: 'workOrderFeed',
        connectionTypeName: 'ViewerWorkOrderFeedConnection',
        nodeTypeName: 'WorkOrderFeedItem',
        gqlConnectionQuery: QueryWorkOrderFeedConnectionGql,
        gqlNodeFragment: fragmentWorkOrderFeedItemNode,
        gqlNodeQuery: queryWorkOrderFeedItemNode,
    },
    workOrderTemplates: {
        connectionId: 'workOrderTemplates',
        connectionTypeName: 'ViewerWorkOrderTemplatesConnection',
        nodeTypeName: 'WorkOrderTemplate',
        gqlConnectionQuery: QueryWorkOrderTemplatesConnectionGql,
        gqlNodeFragment: fragmentWorkOrderTemplateNode,
        gqlNodeQuery: queryWorkOrderTemplateNode,
    },
    workOrders: {
        connectionId: 'workOrders',
        connectionTypeName: 'ViewerWorkOrdersConnection',
        nodeTypeName: 'WorkOrder',
        gqlConnectionQuery: QueryWorkOrdersConnectionGql,
        gqlNodeFragment: fragmentWorkOrderNode,
        gqlNodeQuery: queryWorkOrderNode,
    },
};

export const getAvailableConnectionItems = () => {
    return __availableConnections;
};

export const getConexpItemById = (connectionQueryName: keyof typeof __availableConnections): ConexpItemType => {
    return __availableConnections[connectionQueryName];
};
