import Dashboard from './Dashboard';
import SecurityFlags from './SecurityFlags';
import MyProfile from './MyProfile';
import Downloadables from './Downloadables';
import { Module as GraphiqlExplorer } from './GraphiqlExplorer';
import { Router as DataExport } from './DataExport/Router';
import { Module as EmailModule } from './Email';
import { Router as DataImport } from './DataExport/DataImport';
import { Router as OneOffProduct } from './OneOffProducts';
import { Router as SentEmails } from './SentEmails';
import { Router as CustomerTags } from './CustomerTags';
import { Router as FeatureFlags } from './FeatureFlags';
import { Router as FranchiseApiKeys } from './Franchises/FranchiseApiKeys';
import { SemanticICONS } from '@ez/components';
import { ProductCatalogConfigurator, ProductCatalogViewer } from '@poolware/app-catalog';
import { WarrantyClaimsAdminRouter } from '@poolware/app-warranty-claims';
import { ServiceJobsAdminRouter } from '@poolware/app-service-jobs';
import { AdminRouter as CalcAdminRouter, AdminRouterModuleID } from '@poolware/app-calculators';
import { ModulesAccess } from '@poolware/app-shell';
import { Router as ConexpRouter } from './Conexp/Router';
import { Router as FranchisesRouter } from './Franchises/Router';
import { AttachedFields } from './AttachedFields';

interface IRouteConfig {
    path: string;
    exact?: boolean;
    component: any;
    moduleID?: string;
}

export interface IMenuItem {
    icon?: SemanticICONS;
    label: string;
    path?: string;
    exact?: boolean;
    access?: (modulesAccess: ModulesAccess) => boolean;
}

export interface IMenuItemGroup {
    label: string;
    items: IMenuItem[];
}

export const sidebarItemGroups: IMenuItemGroup[] = [
    {
        label: 'System Config',
        items: [
            {
                path: '/',
                label: 'Home',
                exact: true,
                icon: 'home',
                access: (ma) => ma.SuperAdmin?.enabled,
            },
            {
                path: '/feature-flags',
                label: 'Feature Flags',
                icon: 'flag checkered',
                access: (ma) => ma.SuperAdmin?.enabled,
            },
            // {
            //     path: '/ui-flags',
            //     label: 'UI Flags',
            //     exact: true,
            //     icon: 'flag',
            //     access: (ma) => ma.SuperAdmin?.enabled,
            // },
            {
                path: '/ui-flags/setting-store',
                label: 'Settings Store',
                exact: true,
                icon: 'flag',
                access: (ma) => ma.SuperAdmin?.enabled,
            },
            {
                path: '/attached-fields',
                label: 'Attached Fields',
                exact: true,
                icon: 'flag',
                access: (ma) => ma.SuperAdmin?.enabled,
            },
        ],
    },
    {
        label: 'Franchises',
        items: [
            {
                path: '/franchises',
                label: 'Franchises',
                icon: 'certificate',
                access: (ma) => ma.SuperAdmin?.enabled,
            },
            {
                path: '/warranty-offices',
                label: 'Warranty Offices',
                icon: 'certificate',
                access: (ma) => ma.SuperAdmin?.enabled,
            },
            // {
            //     path: '/franchise-api-keys',
            //     label: 'Franchise Keys',
            //     exact: true,
            //     icon: 'key',
            //     access: (ma) => ma.SuperAdmin.enabled,
            // },
        ],
    },
    {
        label: 'Data',
        items: [
            {
                path: '/app-hosting',
                label: 'Downloads',
                icon: 'cloud download',
                access: (ma) => ma.SuperAdmin?.enabled,
            },
            {
                path: '/service-jobs',
                label: 'Job Templates',
                icon: 'clone outline',
                access: (ma) => ma.SuperAdmin?.enabled,
            },
            {
                path: '/customer-tags',
                label: 'Customer Tags',
                icon: 'tags',
                access: (ma) => ma.Customers?.enabled,
            },

            {
                path: '/calcs-admin',
                label: 'Heat. Calculators',
                icon: 'calculator',
                access: (ma) => ma.Calculators?.admin,
            },
        ],
    },
    {
        label: 'Products',
        items: [
            {
                path: '/product-catalog',
                label: 'Product Catalog',
                icon: 'boxes',
                access: (ma) => ma.ProductCatalog?.admin,
            },
            {
                path: '/one-off-products-viewer',
                label: 'One-off products',
                icon: 'boxes',
                access: (ma) => ma.SuperAdmin?.enabled,
            },
        ],
    },
    {
        label: 'Emails',
        items: [
            {
                path: '/email',
                label: 'Email Templates',
                icon: 'envelope',
                access: (ma) => ma.SuperAdmin?.enabled,
            },
            {
                path: '/sent-emails',
                label: 'Email Logs',
                icon: 'paper plane outline',
                access: (ma) => ma.SuperAdmin?.enabled,
            },
        ],
    },
    {
        label: 'Tools',
        items: [
            {
                path: '/graphiql',
                label: 'GraphiQL',
                icon: 'briefcase',
                access: (ma) => ma.SuperAdmin?.enabled,
            },
            {
                path: '/data-export',
                label: 'Data Export',
                icon: 'download',
                access: (ma) => ma.SuperAdmin?.enabled,
            },
        ],
    },
    {
        label: 'Lab',
        items: [
            {
                path: '/conexp',
                label: 'Data Explorer',
                icon: 'circle outline',
                access: (ma) => ma.SuperAdmin?.enabled,
            },
        ],
    },
];

export const routes: IRouteConfig[] = [
    {
        path: '/',
        exact: true,
        component: Dashboard,
    },
    {
        path: '/feature-flags',
        component: FeatureFlags,
    },
    {
        path: '/ui-flags',
        component: SecurityFlags,
    },
    {
        path: '/attached-fields',
        component: AttachedFields,
    },
    {
        path: '/my-profile',
        component: MyProfile,
    },
    {
        path: '/app-hosting',
        component: Downloadables,
    },
    {
        path: '/email',
        component: EmailModule.Router,
    },
    {
        path: '/product-catalog',
        moduleID: 'PRODUCT_CATALOG',
        component: ProductCatalogConfigurator,
    },
    {
        path: '/product-catalog-viewer',
        component: ProductCatalogViewer,
    },
    {
        path: '/data-export',
        component: DataExport,
    },
    {
        path: '/data-import',
        component: DataImport,
    },
    {
        path: '/graphiql',
        component: GraphiqlExplorer.Router,
    },
    {
        path: '/one-off-products-viewer',
        component: OneOffProduct,
    },
    {
        path: '/sent-emails',
        component: SentEmails,
    },
    {
        path: '/service-jobs',
        component: ServiceJobsAdminRouter,
    },
    {
        path: '/customer-tags',
        component: CustomerTags,
    },
    {
        path: '/warranty-offices',
        component: WarrantyClaimsAdminRouter,
    },
    {
        path: '/calcs-admin',
        moduleID: AdminRouterModuleID,
        component: CalcAdminRouter,
    },
    {
        path: '/franchise-api-keys',
        component: FranchiseApiKeys,
    },
    {
        path: '/franchises',
        component: FranchisesRouter,
    },
    {
        path: '/conexp',
        component: ConexpRouter,
    },
];
