import * as React from 'react';
import { FormikDefaultForm, FormikInputField } from '@ez/components';
import { ProjectsURLBuilder } from '../index';
import * as Yup from 'yup';
import { useMutationProject } from '@poolware/api';
import { useAppNavigator } from '@poolware/react-app-navigator';

const validationSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Required'),
});

const Page: React.FC = () => {
    const { AppNavigator } = useAppNavigator();
    const { create } = useMutationProject({ refetchQueries: ['QueryProjectList'] });

    const onSubmit = async (values) => {
        const { name } = values;
        const project = await create({ name: name?.trim() });
        const id = project.data?.Project?.Project?.id;
        AppNavigator.navigate(ProjectsURLBuilder.ViewProject(id), { relativeToModule: true });
    };

    const onCancel = () => {
        AppNavigator.navigateToOrigin();
    };

    return (
        <FormikDefaultForm
            header={'New Project'}
            initialValues={{ name: '' }}
            submitButton={{ content: 'Create' }}
            onSubmit={onSubmit}
            onCancel={onCancel}
            validationSchema={validationSchema}
        >
            <FormikInputField label={'Project Name'} name={'name'} required={true} />
        </FormikDefaultForm>
    );
};

export default Page;
