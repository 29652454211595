import * as React from 'react';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useMutationFranchiseWarranter } from '@poolware/api';
import { WarranterForm } from './WarranterForm';
import * as _ from 'lodash';
import { PageLayout } from '@ez/components';

export interface PageProps {}

export const PageNew: React.FC<PageProps> = () => {
    const { AppNavigator } = useAppNavigator<{ id?: string }>();
    const { create } = useMutationFranchiseWarranter({ refetchQueries: ['QueryFranchiseWarrantersConnection'] });

    const goBack = () => {
        AppNavigator.navigateRelative('/');
    };

    const onSubmit = async (values) => {
        const res = await create({
            name: _.trim(values.name),
            email: _.trim(values.email),
            actingFranchise: values.actingFranchise?.id || null,
        });
        // const id = res?.data?.FranchiseWarranter?.FranchiseWarranter?.id;
        // goBack();
        AppNavigator.navigateRelative(`/`);
    };

    const initialValues = {
        name: '',
        email: '',
        actingFranchise: null,
    };

    return (
        <PageLayout>
            <PageLayout.BodySection width={'screen-md'}>
                <WarranterForm
                    header={'New Warranty Office'}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    onCancel={goBack}
                />
            </PageLayout.BodySection>
        </PageLayout>
    );
};
