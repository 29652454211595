import * as React from 'react';
import { NodeType, useMutationFranchise } from '@poolware/api';
import { FormikHelpers as FormikActions } from 'formik/dist/types';
import { FormikCheckboxField, FormikPanelForm, Panel, toastError } from '@ez/components';

export const PanelStoreType: React.FC<{ franchise: NodeType.Franchise; refetchQueries: any[] }> = ({
    franchise,
    refetchQueries,
}) => {
    const { update } = useMutationFranchise({ refetchQueries });

    const initialValues = franchise?.types?.reduce((acc, curr) => {
        acc[curr] = true;
        return acc;
    }, {});

    const onSubmitType = async (values: Record<keyof NodeType.FranchiseType, boolean>, actions: FormikActions<any>) => {
        try {
            const newType = Object.keys(values).reduce((acc, key) => {
                if (values[key]) {
                    acc.push(key);
                }
                return acc;
            }, []);
            await update({ id: franchise.id, types: newType });
            console.log(values);
            return { ...values };
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to update', description: e.message });
        }
    };

    return (
        <FormikPanelForm
            enableReinitialize={false}
            debug={false}
            initialValues={initialValues}
            onSubmit={onSubmitType}
            header={'Franchise Type'}
        >
            <Panel.Item label={'Type'}>
                <div>
                    <div className={'flex flex-row gap-4'}>
                        {Object.keys(NodeType.FranchiseType).map((key) => {
                            return <FormikCheckboxField key={key} name={key} label={key} />;
                        })}
                    </div>
                    <div className={'text-sm'}>At least one type must be selected</div>
                </div>
            </Panel.Item>
        </FormikPanelForm>
    );
};
