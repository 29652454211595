import Page from './Page';
import * as _ from 'lodash';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { withApolloLoading } from '@ez/components';
import { NodeType, staffFragment } from '@poolware/api';
import { compose, mapProps } from '@ez/tools';

export const MeQuery = gql`
    query MeQuery {
        viewer {
            me {
                staff {
                    id
                    ...StaffFragment
                }
            }
        }
    }
    ${staffFragment}
`;

export default compose(
    graphql(MeQuery),
    withApolloLoading({ show404ForPath: 'data.viewer.me' }),
    mapProps((props) => {
        let me = _.get(props, 'data.viewer.me');
        return {
            ...props,
            me,
        };
    })
)(Page);

export interface PageControl {
    me: NodeType.Me;
}
