import Page from './Page';

import * as _ from 'lodash';
import { compose, mapProps } from '@ez/tools';
import { withApolloLoading } from '@ez/components';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import { IProjectMutatorsProps, NodeType, withProjectMutators } from '@poolware/api';

const projectVersionFragment = gql`
    fragment ProjectVersionFragment on ProjectVersion {
        id
        releaseNumber
        description
        enabled
        enabledFrom
        versionNumber
        remoteFileURL
        changelogSummary
        isPinned
        fileURL
        project {
            id
        }
        fileUpload {
            id
        }
    }
`;

const QL = gql`
    query QueryProjectDetails($id: ID!) {
        project: node(id: $id) {
            id
            ... on Project {
                uuid
                name
                description
                createdAt
                enabled
                enabledFrom
                platformKey
                fileURL
                remoteThumbnailURL
                thumbnailFile {
                    id
                }
                thumbnailURL
                versions {
                    edges {
                        node {
                            ...ProjectVersionFragment
                        }
                    }
                }
                pinnedVersion {
                    ...ProjectVersionFragment
                }
                latestRelease {
                    ...ProjectVersionFragment
                }
                recommendedVersion {
                    ...ProjectVersionFragment
                }
            }
        }
    }
    ${projectVersionFragment}
`;

export default compose(
    graphql(QL, {
        options: (props) => ({
            variables: { id: _.get(props, 'match.params.projectId') },
        }),
    }),
    withApolloLoading({ show404ForPath: 'data.project' }),
    mapProps((props) => {
        let project = _.get(props, 'data.project');
        return {
            ...props,
            project,
        };
    }),
    withAppNavigator(),
    withProjectMutators(['QueryProjectList', 'QueryProjectDetails'])
)(Page);

export interface PageControl extends IAppNavigatorProps, IProjectMutatorsProps {
    project: NodeType.Project;
}
