import * as React from 'react';
import { ModuleRootSwitch, ModuleRoute } from '@poolware/react-app-navigator';
import { ConnectionViewer } from './ConnectionViewer';
import { NodeViewer } from './NodeViewer';
import { PageList } from './PageList';

export const Router: React.FC = () => {
    return (
        <ModuleRootSwitch>
            <ModuleRoute path={'/'} exact component={PageList} />
            <ModuleRoute path={'/:connectionId/:nodeId'} exact component={NodeViewer} />
            <ModuleRoute path={'/:connectionId'} exact component={ConnectionViewer} />
        </ModuleRootSwitch>
    );
};
