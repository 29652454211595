import * as React from 'react';
import { SingleExport } from './SingleExport';
import { ModuleRedirect, ModuleRoot, ModuleRoute, ModuleSwitch } from '@poolware/react-app-navigator';
import { Router as BulkExportRouter } from './BulkExport';
import { PageLayout } from '@ez/components';
import { Sidebar } from './Sidebar';
import { Router as DataImport } from './DataImport';

const Index = () => {
    return <ModuleRedirect to={'/bulk'} />;
};

export const RouterExports = () => {
    return (
        <ModuleRoot>
            <ModuleSwitch>
                <ModuleRoute path={'/'} exact={true} component={Index} />
                <ModuleRoute path={'/bulk'} component={BulkExportRouter} />
                <ModuleRoute path={'/single'} component={SingleExport} />
                <ModuleRoute path={'/import'} component={DataImport} />
            </ModuleSwitch>
        </ModuleRoot>
    );
};

export const Router = () => {
    return (
        <PageLayout.SideBarLayout
            leftSidebar={
                <div>
                    <Sidebar />
                </div>
            }
            main={
                <div>
                    <RouterExports />
                </div>
            }
        />
    );
};
