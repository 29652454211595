import { addressFragment } from '@poolware/api';
import gql from 'graphql-tag';

export const franchiseQL = gql`
    query QueryFranchisesConnection(
        $first: Int
        $page: Int
        $sort: FranchiseViewerFranchisesSort
        $search: FranchiseViewerFranchisesSearch
    ) {
        viewer {
            franchises(first: $first, page: $page, search: $search, sort: $sort) {
                edges {
                    node {
                        id
                        name
                        contactExport
                        email
                        phoneNumber
                        address {
                            id
                            ...AddressFragment
                        }
                        organisationType {
                            id
                            name
                            logo
                        }
                        manager {
                            id
                            user {
                                id
                                firstName
                                lastName
                            }
                        }
                        staffCount
                        createdAt
                        types
                    }
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                }
            }
        }
    }
    ${addressFragment}
`;
