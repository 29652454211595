import * as React from 'react';
import { ModuleRootSwitch, ModuleRoute } from '@poolware/react-app-navigator';
import { PageList } from './Page.List';
import { PageView } from './Page.View';

export interface RouterProps {}

export const Router: React.FC<RouterProps> = () => {
    return (
        <ModuleRootSwitch>
            <ModuleRoute path={'/'} exact={true} component={PageList} />
            <ModuleRoute path={'/:id'} exact={true} component={PageView} />
        </ModuleRootSwitch>
    );
};
