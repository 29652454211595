import * as React from 'react';
import ListProjects from './ListProjects';
import ViewProject from './ViewProject';
import NewProject from './NewProject';
import NewVersion from './NewVersion';
import VersionDetails from './VersionDetails';
import { ModuleRoot, ModuleRoute, ModuleSwitch } from '@poolware/react-app-navigator';
import { PageLayout } from '@ez/components';

export const ProjectsURLBuilder = {
    ListProjects: () => '/',
    NewProject: () => `/new-project`,
    NewVersion: (projectId: String) => `/${projectId}/new-version`,
    ViewProject: (projectId: String) => `/${projectId}`,
    ViewVersion: (projectId: String, versionId: String) => `/${projectId}/${versionId}`,
};

export default () => {
    return (
        <PageLayout>
            <ModuleRoot>
                <ModuleSwitch>
                    <ModuleRoute path={'/'} exact component={ListProjects} />
                    <ModuleRoute path={'/new-project'} exact component={NewProject} />
                    <ModuleRoute path={'/:projectId/new-version'} component={NewVersion} />
                    <ModuleRoute path={'/:projectId/:versionId'} component={VersionDetails} />
                    <ModuleRoute path={'/:projectId'} component={ViewProject} />
                </ModuleSwitch>
            </ModuleRoot>
        </PageLayout>
    );
};
