import {
    addressFragment,
    NodeType,
    QueryConnectionConfigProps,
    useDefaultConnectionState,
    useQueryConnection,
} from '@poolware/api';
import gql from 'graphql-tag';

const QL = gql`
    query QueryFranchisesConnection(
        $page: Int
        $sort: FranchiseViewerFranchisesSort
        $search: FranchiseViewerFranchisesSearch
    ) {
        viewer {
            franchises(first: 25, page: $page, search: $search, sort: $sort) {
                edges {
                    node {
                        id
                        name
                        id
                        name
                        flags {
                            edges {
                                node {
                                    id
                                    tag
                                    description
                                }
                            }
                        }
                        email
                        phoneNumber
                        address {
                            id
                            ...AddressFragment
                        }
                        organisationType {
                            id
                            name
                        }
                        createdAt
                        types
                    }
                }
                pageMeta {
                    pageCount
                    totalCount
                }
            }
        }
    }
    ${addressFragment}
`;

export interface QueryFranchisesConnectionProps extends Partial<QueryConnectionConfigProps> {
    name?: string;
}

const mapPropsToSearchQuery = (props: QueryFranchisesConnectionProps): NodeType.FranchiseViewerFranchisesSearch => {
    if (props.name) {
        return { name: { like: props.name } };
    }
    return {};
};

export function useQueryFranchisesConnection(props?: QueryFranchisesConnectionProps) {
    const connectionPath = 'viewer.franchises';
    const { variables, connectionState } = useDefaultConnectionState({
        ...props,
        search: mapPropsToSearchQuery(props),
    });

    return useQueryConnection({
        query: QL,
        // fetchPolicy: 'cache-first',
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
}
