import * as React from 'react';
import { useMemo, useState } from 'react';
import {
    ConnectionTableDef,
    DefaultConnectionTable,
    MenuBarHeaderItem,
    MenuBarItem,
    MenuBarSection,
    Optionable,
    PageLayout,
    ScrollableLayout,
    SegmentedSelectButtons,
    SlideInDock,
    StickyMenuBar,
    useModalCtrl,
} from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { fromEdges, NodeType } from '@poolware/api';
import { useQueryFeatureFlagsConnection } from './use-query-feature-flag-connection';
import styled from 'styled-components';
import { IconFeatureStatus } from './components';
import _groupBy from 'lodash/groupBy';
import { usePersistedString } from '@ez/tools';
import { ModuleAccessViewer, SuggestInputOrganisationType, useOrgSwitcher, useViewer } from '@poolware/app-shell';
import { SegmentedSelectOrgSwitcher } from '../../components/OrgSwitcher';

export interface PageListProps {}

const StyledCont = styled.div<{ active?: boolean }>`
    padding-left: 0.5em;
    padding-right: 0.5em;
    color: ${({ active }) => !active && '#888888'};
    min-width: 30%;
    display: inline-block;

    & > span {
        display: inline-block;
        font-size: 1.1em;
        font-weight: ${({ active }) => (active ? 'bold' : undefined)};
        //color: ${({ active }) => (active ? '#274a85' : '#888888')};
    }
`;

const ConstraintItem: React.FC<{ value: string; label?: string; active?: boolean }> = ({ label, value, active }) => {
    if (!value) return null;

    return (
        <StyledCont active={active} className={active && 'text-primary'}>
            {label ? `${label}: ` : null} <span>{value}</span>
        </StyledCont>
    );
};

const __ALL__ = '__ALL__';
const __UNGROUPPED__ = 'Ungroupped';

type FeatureFlagItem = {
    featureFlag: NodeType.FeatureFlag;
    group: string;
    subGroup: string;
    level: number;
};

const groupFlags = (connectionData: NodeType.FeatureFlag[]) => {
    connectionData = connectionData.sort((a, b) => (a.tag < b.tag ? -1 : 1));

    const groupsSummary = _groupBy(connectionData, (item) => {
        const parts = item.tag?.split('/');
        return parts[0];
    });

    const flagItems = connectionData.map<FeatureFlagItem>((f) => {
        const parts = f.tag?.split('/');
        const rootGroup = parts[0];
        const hasl2 = groupsSummary[rootGroup]?.length > 1;
        if (parts.length === 1) {
            return {
                group: !hasl2 ? __UNGROUPPED__ : parts[0],
                subGroup: parts[0],
                level: 0,
                featureFlag: f,
            };
        } else {
            return {
                group: parts[0],
                subGroup: parts.slice(1).join('/'),
                level: 1,
                featureFlag: f,
            };
        }
    });

    const groupedFlags = _groupBy(flagItems, (flagItems) => {
        return flagItems.group;
    });

    return {
        items: flagItems,
        groups: groupedFlags,
    };
};

const ListItem: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
    return <div role={'listitem'} className={'item h-6'} {...props} />;
};

const useTableDef = () => {
    const tableDef: ConnectionTableDef<FeatureFlagItem> = [
        {
            header: 'Tag',
            width: 4,
            cell: (r) => {
                if (r.level === 0) {
                    return <span className={'py-1 font-bold'}>{r.featureFlag.tag}</span>;
                } else {
                    return (
                        <span className={'py-1 pl-1'}>
                            <span className={'text-sm text-gray-400 pr-1'}>&gt; {r.group}/</span>
                            <span className={'font-bold'}>{r.subGroup}</span>
                        </span>
                    );
                }
            },
        },
        {
            header: 'Variations',
            width: 1,
            cell: (i) => {
                const featureFlag = i.featureFlag;
                const variations = fromEdges(featureFlag.variations);
                return (
                    <div className={'py-1'}>
                        <ul>
                            {variations.map((v) => {
                                return (
                                    <ListItem key={v.id}>
                                        <span>
                                            <IconFeatureStatus active={v.active} />
                                            <code>{v?.value === null ? 'unset' : v?.value || 'empty'}</code>
                                        </span>
                                    </ListItem>
                                );
                            })}
                        </ul>
                    </div>
                );
            },
        },
        {
            header: 'Variation Constraints',
            cell: (i) => {
                const featureFlag = i.featureFlag;
                const variations = fromEdges(featureFlag.variations);
                return (
                    <div className={'py-1 w-full'}>
                        <ul>
                            {variations.map((v) => {
                                return (
                                    <ListItem key={v.id}>
                                        <ConstraintItem
                                            active={v.active}
                                            label={'O'}
                                            value={v.organisation?.name || 'any'}
                                        />
                                        <ConstraintItem
                                            active={v.active}
                                            label={'F'}
                                            value={v.franchise?.name || 'any'}
                                        />
                                        <ConstraintItem active={v.active} label={'R'} value={v.role?.name || 'any'} />
                                    </ListItem>
                                );
                            })}
                        </ul>
                    </div>
                );
            },
        },
    ];

    return tableDef;
};

export const PageList: React.FC<PageListProps> = ({}) => {
    const moduleAccessCtrl = useModalCtrl();
    const { modulesAccess, viewer } = useViewer();
    const { AppNavigator } = useAppNavigator();
    const { connectionState, connectionData } = useQueryFeatureFlagsConnection();
    const [selectedGroup, setSelectedGroup] = usePersistedString('pw.admin.feature-flag.filter.group', __ALL__);
    // const [selectedOrg, setSelectedOrg] = useState<NodeType.OrganisationType>(undefined);
    const { organisation } = useOrgSwitcher();

    const tableDef = useTableDef();
    const { groups } = useMemo(() => groupFlags(connectionData), [connectionState.loading]);

    const filterOptions = useMemo(() => {
        const filterOptions = Object.keys(groups)
            .sort((a, b) => {
                if (a === __UNGROUPPED__) return -1;
                if (b === __UNGROUPPED__) return 1;
                return a > b ? 1 : -1;
            })
            .map<Optionable<string>>((key) => {
                return {
                    value: key,
                    text: key,
                };
            });
        filterOptions.unshift({ value: __ALL__, text: 'ALL' });
        return filterOptions;
    }, [groups]);

    const onNew = () => {
        AppNavigator.navigateRelative('/new', { setOrigin: true });
    };

    const onView = (item: FeatureFlagItem) => {
        AppNavigator.navigateRelative(`/${item.featureFlag.id}`, { setOrigin: true });
    };

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem icon={'flag checkered'}>Feature Flags</MenuBarHeaderItem>
                </MenuBarSection>
                <MenuBarSection position={'right'}>
                    <MenuBarItem onClick={onNew} icon={'plus'} color={'green'} title={'New'} />
                    <MenuBarItem onClick={() => moduleAccessCtrl.onOpen()} icon={'key'} title={'Mod. Access'} />
                </MenuBarSection>
            </StickyMenuBar>

            <SegmentedSelectOrgSwitcher />

            {filterOptions.length > 1 && (
                <div className={'mt-2'}>
                    <SegmentedSelectButtons
                        separated={true}
                        color={'blue'}
                        size={'xs'}
                        fluid={false}
                        onChange={(o) => setSelectedGroup(o.value)}
                        value={selectedGroup}
                        options={filterOptions}
                    />
                    {/*<SuggestInputOrganisationType value={selectedOrg} onChange={setSelectedOrg} />*/}
                </div>
            )}

            <PageLayout.BodySection>
                {Object.keys(groups).map((key) => {
                    let items = groups[key];
                    if (selectedGroup && selectedGroup !== __ALL__ && selectedGroup !== key) {
                        return null;
                    }
                    if (organisation) {
                        items = items?.filter((item) => {
                            return !!item.featureFlag?.variations?.find(
                                (v) => v.organisation?.id === organisation.id || v.organisation === null
                            );
                        });
                    }

                    return (
                        <div key={key}>
                            <div className={'mb-2 mt-4 text-blue-900'}>{key}</div>
                            <DefaultConnectionTable
                                key={key}
                                debug={false}
                                tableProps={{ color: 'grey', striped: false }}
                                tableCellProps={{
                                    style: {
                                        borderTop: '1px solid hsl(24, 20%, 55%)',
                                    },
                                }}
                                tableDef={tableDef}
                                connectionData={items}
                                connectionState={connectionState}
                                onRowClick={onView}
                            />
                        </div>
                    );
                })}
            </PageLayout.BodySection>
            <SlideInDock open={moduleAccessCtrl.open} preferredWidth={'740px'} onClose={moduleAccessCtrl.onClose}>
                <ScrollableLayout>
                    <ScrollableLayout.BodyScroll>
                        <div className={'p-2'}>
                            <ModuleAccessViewer modulesAccess={modulesAccess} viewer={viewer} />
                        </div>
                    </ScrollableLayout.BodyScroll>
                </ScrollableLayout>
            </SlideInDock>
        </PageLayout>
    );
};
