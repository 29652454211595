import * as React from 'react';
import { useMemo } from 'react';
import { useAppNavigator } from '@poolware/react-app-navigator';
import {
    ConnectionErrorMessage,
    MenuBar,
    PageSkeletonLoader,
    Panel,
    StickyMenuBar,
    useModalCtrl,
    ViewJSON,
    VStack,
} from '@ez/components';
import { getFieldRenderer } from './field-renderer';
import { Modal } from '@ez/components';
import { useConexpHook } from './hooks';

export interface ListProps {}

export const NodeViewer: React.FC<ListProps> = ({}) => {
    const modalCtrl = useModalCtrl();
    const { AppNavigator, params } = useAppNavigator();

    const { nodeHook, conexpItem, uiConfig } = useConexpHook(params.connectionId);

    const { loading, error, node } = nodeHook(params.nodeId);

    const panelConfig = useMemo(() => {
        const nodeConfig = uiConfig.node;
        const nodeMeta = uiConfig.meta;
        if (!nodeConfig) {
            throw new Error('failed to load config for ' + params.connectionId);
        }

        const panelItems = [];
        for (const [key, fieldType] of Object.entries(nodeConfig)) {
            panelItems.push({
                label: key,
                content: (connectionItem) => {
                    const fieldValue = connectionItem[key];
                    const Render = getFieldRenderer(fieldType as any, true);
                    return <Render value={fieldValue} expandNodes={true} />;
                },
            });
        }
        return panelItems;
    }, [params.connectionId, uiConfig]);

    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    } else if (!node) {
        return <div>Not found</div>;
    }

    const goBack = () => {
        AppNavigator.navigateToOrigin('/', { relativeToModule: true });
    };

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBar.Section>
                    <MenuBar.Item onClick={goBack} icon={'chevron left'} title={'Back'} />
                    <MenuBar.HeaderItem icon={'file alternate outline'}>{conexpItem.nodeTypeName}</MenuBar.HeaderItem>
                </MenuBar.Section>
            </StickyMenuBar>
            <Panel>
                <Panel.Header button={{ icon: 'info', onClick: modalCtrl.onOpen }}>
                    {conexpItem.nodeTypeName}
                </Panel.Header>
                <Panel.Body>
                    {panelConfig.map((pc, index) => {
                        return <Panel.Item label={pc.label} key={index} content={pc.content(node)} />;
                    })}
                </Panel.Body>
            </Panel>
            <Modal {...modalCtrl}>
                <ViewJSON data={{ node, conexpItem, uiConfig }} />
            </Modal>
        </VStack>
    );
};
