//
// DO NOT EDIT!!!
// THIS IS AN AUTOMATICALLY GENERATED FILE.
// Date:  Tue Aug 13 2024 16:10:05 GMT+1000 (Australian Eastern Standard Time)
//

// range all

import { availableConexpHooksPartial1 } from './connection-query-hooks-1';
import { availableConexpHooksPartial2 } from './connection-query-hooks-2';

export const availableConexpHooks = {
    ...availableConexpHooksPartial1,
    ...availableConexpHooksPartial2,
};
