import * as React from 'react';
import {
    MenuBarHeaderItem,
    MenuBarItem,
    MenuBarSection,
    PageLayout,
    Panel,
    StickyMenuBar,
    SuspenseSkeletonLoader,
} from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useQueryEmailMessage } from './use-query-email-message';
import { TemplateRendererFrame } from '../Email/components/TemplateRendererFrame';

export interface PageProps {}

export const PageView: React.FC<PageProps> = () => {
    const { AppNavigator, params } = useAppNavigator<{ id?: string }>();
    const { node: email, error, loading } = useQueryEmailMessage(params.id);

    const goBack = () => {
        AppNavigator.navigateRelative('/');
    };

    return (
        <SuspenseSkeletonLoader error={error} loading={loading}>
            <PageLayout>
                <StickyMenuBar>
                    <MenuBarSection>
                        <MenuBarItem onClick={goBack} icon={'chevron left'} title={'Back to list'} />
                        <MenuBarHeaderItem icon={'mail'}>Email Message</MenuBarHeaderItem>
                    </MenuBarSection>
                </StickyMenuBar>

                <Panel>
                    <Panel.Header content={'Email'} />
                    <Panel.Body>
                        <Panel.Item label={'Subject'} content={email?.subject} />
                        <Panel.Item label={'Type'} content={email?.type?.name} />
                        <Panel.ItemDate label={'Created'} content={email?.createdAt} />
                        <Panel.Item label={'To'} content={email?.recipientEmail} />
                        <Panel.ItemEntity label={'Send By'} content={email?.submittedBy} />

                        <Panel.SectionHeader>Message</Panel.SectionHeader>

                        <div style={{ backgroundColor: '#fefefe', padding: '1rem' }}>
                            <TemplateRendererFrame style={{ minHeight: '600px' }} content={email?.messageBody} />
                        </div>
                    </Panel.Body>
                </Panel>
            </PageLayout>
        </SuspenseSkeletonLoader>
    );
};
