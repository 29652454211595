import * as React from 'react';
import {
    FormikDefaultForm,
    FormikInputField,
    FormikTextareaField,
    Icon,
    MenuBarDropdown,
    MenuBarDropdownItemWithConfirm,
    MenuBarItem,
    MenuBarSection,
    Modal,
    Panel,
    Stack,
    StickyMenuBar,
    toastError,
    useModalCtrl,
} from '@ez/components';
import { Checkbox } from '@ez/components';

import { PageControl } from './Page.Control';
import { ProjectsURLBuilder } from '../index';

const Page: React.FC<PageControl> = ({ version, AppNavigator, ...props }) => {
    const {
        versionNumber,
        description,
        requirements,
        changelogSummary,
        fileURL,
        remoteFileURL,
        releaseNumber,
        isPinned,
        enabledFrom,
        enabled,
    } = version;

    const modalCtrl = useModalCtrl();

    const onDelete = async () => {
        try {
            const projectId = version.project.id;
            await props.mutateProjectVersion.delete({ id: version.id });
            AppNavigator.replace(ProjectsURLBuilder.ViewProject(projectId), { relativeToModule: true });
        } catch (e) {
            toastError(e);
        }
    };

    const pinVersion = async () => {
        const { isPinned } = version;
        if (!isPinned) {
            await props.ProjectMutator.setPinnedVersion(version);
        } else {
            const { project } = version;
            if (!project) {
                console.error('Unable to unpin version, because version.project is null');
                return;
            }
            await props.ProjectMutator.unpinVersion(version.project);
        }
    };

    const enableVersion = async () => {
        const { enabled } = version;
        await props.ProjectMutator.updateVersion(version, {
            enabled: !enabled,
            enabledFrom: !enabled ? new Date() : null,
        });
    };

    const onSubmit = async (values: typeof version) => {
        try {
            await props.ProjectMutator.updateVersion(version, {
                versionNumber: values.versionNumber?.trim() || '',
                changelogSummary: values.changelogSummary?.trim() || '',
                remoteFileURL: values.remoteFileURL?.trim() || '',
                description: values.description?.trim() || '',
                requirements: values.requirements?.trim() || '',
            });
            modalCtrl.onClose();
        } catch (e) {
            toastError(e);
        }
    };

    const goBack = () => {
        const projectId = version.project.id;
        AppNavigator.navigate(ProjectsURLBuilder.ViewProject(projectId), { relativeToModule: true });
    };
    return (
        <Stack>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarItem onClick={goBack} icon={'chevron left'} title={'Back to Project'} />
                </MenuBarSection>
                <MenuBarSection position={'right'}>
                    <MenuBarDropdown color={'red'} icon={'bars'}>
                        <MenuBarDropdownItemWithConfirm
                            icon={'trash'}
                            color="orange"
                            content="Delete"
                            onClick={onDelete}
                            popup={{ content: 'Will be deleted' }}
                            confirm={{
                                confirmMessage: {
                                    header: 'Delete?',
                                    content: (
                                        <span>
                                            Delete Version <b>permanently</b>?
                                        </span>
                                    ),
                                },
                                negative: true,
                                confirmButton: {
                                    content: 'Delete',
                                    icon: 'trash',
                                },
                            }}
                        />
                    </MenuBarDropdown>
                </MenuBarSection>
            </StickyMenuBar>
            <Panel>
                <Panel.Header
                    content={`Version ${versionNumber}`}
                    button={[
                        {
                            content: 'Edit',
                            icon: 'edit',
                            onClick: modalCtrl.onOpen,
                        },
                    ]}
                />
                <Panel.Body>
                    <Panel.Item label={'Version Number'}>{version.versionNumber}</Panel.Item>
                    <Panel.Item label={'Release Number'}>{version.releaseNumber}</Panel.Item>
                    <Panel.ItemDate label={'Release Date'} content={enabledFrom} />
                    <Panel.Item label={'File URL'}>
                        <a rel="noopener noreferrer" href={fileURL} target={'_blank'}>
                            <Icon name={'external'} /> {fileURL}{' '}
                        </a>
                    </Panel.Item>

                    <Panel.Divider />
                    <Panel.Item label={<>Enabled</>}>
                        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                            <Icon
                                style={{ paddingRight: '8px' }}
                                name={enabled ? 'eye' : 'hide'}
                                color={enabled ? 'green' : 'grey'}
                            />
                            <Checkbox toggle={true} checked={enabled} onChange={enableVersion} />
                        </div>
                    </Panel.Item>
                    <Panel.Item label={<>Pinned</>}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Icon style={{ paddingRight: '8px' }} name={'pin'} color={isPinned ? 'pink' : 'grey'} />
                            <Checkbox toggle={true} checked={isPinned} onChange={pinVersion} />
                        </div>
                    </Panel.Item>

                    <Panel.Divider />

                    <Panel.ItemText labelWidth={'100%'} label={'Description'} content={description} />
                    <Panel.ItemText labelWidth={'100%'} label={'Version Requirements'} content={requirements} />
                    <Panel.ItemText labelWidth={'100%'} label={'Changelog Summary'} content={changelogSummary} />
                </Panel.Body>
            </Panel>

            <Modal {...modalCtrl}>
                <FormikDefaultForm
                    header={`Version - ${versionNumber}`}
                    onSubmit={onSubmit}
                    initialValues={version}
                    onCancel={modalCtrl.onClose}
                >
                    <FormikInputField label={'Version Number'} name={'versionNumber'} />
                    <FormikInputField label={'Remote File URL'} name={'remoteFileURL'} />
                    <FormikTextareaField label={'Requirements'} name={'requirements'} />
                    <FormikTextareaField label={'Description'} name={'description'} />
                    <FormikTextareaField label={'Changelog Summary'} name={'changelogSummary'} />
                </FormikDefaultForm>
            </Modal>
        </Stack>
    );
};

export default Page;
