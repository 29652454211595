import * as React from 'react';
import { compose } from '@ez/tools';

import { connectWithTheme, SidebarMenuItemNavLink, ThemeStateProps, useViewer } from '@poolware/app-shell';
import { AppSidebar, AppSidebarConfig, AppSidebarMenuItem, LayoutControlProps, SemanticICONS } from '@ez/components';

import { sidebarItemGroups } from '../Pages/top-route-config';

interface AppSidebarContainerProps extends ThemeStateProps, LayoutControlProps {}

const createItemConf = (to: string, icon: SemanticICONS, label: string, exact?: boolean): AppSidebarMenuItem => ({
    to,
    icon,
    label,
    exact,
    as: SidebarMenuItemNavLink,
});

const AppSidebarContainer: React.FC<AppSidebarContainerProps> = (props) => {
    const { modulesAccess } = useViewer();

    const { ThemeState, layoutState } = props;

    const themeLogoPath = ThemeState.theme.logoPath;
    const themeIconPath = ThemeState.theme.iconPath;

    const menuGroups = sidebarItemGroups.map((group) => {
        return {
            header: group.label,
            items: group.items.map((menuItem) => {
                if (!menuItem?.access(modulesAccess)) {
                    return false;
                }
                return createItemConf(menuItem.path, menuItem.icon, menuItem.label, menuItem.exact);
            }),
        };
    });

    const config: AppSidebarConfig = {
        logo: {
            icon: themeIconPath,
            image: themeLogoPath,
        },
        menu: menuGroups,
    };

    return (
        <AppSidebar
            layoutState={layoutState}
            config={config}
            onClick={props.onSidebarMenuClick}
            onDimmerClick={() => props.onToggleMenu(true)}
        />
    );
};

export default compose(connectWithTheme())(AppSidebarContainer);
