import * as React from 'react';
import { useMemo } from 'react';
import { DebugJSON, FormInputDate, FormLabel, VStack, Button, Form } from '@ez/components';
import { ReducerSearchFieldType, useConexpSearchContext } from './ConexpConnectionSearchProvider';
import {
    SuggestInputFranchise,
    SuggestInputOrganisationType,
    SuggestInputRole,
    SuggestInputStaff,
} from '@poolware/app-shell';

const _Input = ({ onChange, value, ...props }) => (
    <Form.Input value={value || ''} onChange={(_, { value }) => onChange(value)} {...props} />
);

const _TriStateCheckbox = ({ onChange, value, label, ...props }) => {
    return (
        <div className={'flex flex-col justify-between flex-grow h-full pb-1'}>
            <FormLabel>{label}</FormLabel>
            <div className={'flex flex-row gap-4'}>
                <Form.Checkbox
                    label={'Y'}
                    checked={value === true}
                    onClick={(_, { checked }) => onChange(true)}
                    {...props}
                />

                <Form.Checkbox
                    label={'N'}
                    checked={value === false}
                    onClick={(_, { checked }) => onChange(false)}
                    {...props}
                />
                <Form.Checkbox
                    label={'any'}
                    checked={value !== true && value !== false}
                    onClick={(_, { checked }) => onChange(null)}
                    {...props}
                />
            </div>
        </div>
    );
};

const _DateRange = ({ onChange, value, label, ...props }) => {
    return (
        <div className={'flex flex-row flex-nowrap gap-2'}>
            <FormInputDate
                label={`${label} (After)`}
                value={value?.after}
                onChange={(newValue) => onChange?.({ ...value, after: newValue })}
                {...props}
            />
            <FormInputDate
                label={`${label} (Before)`}
                value={value?.before}
                onChange={(newValue) => onChange?.({ ...value, before: newValue })}
                {...props}
            />
        </div>
    );
};

const getSearchComponent = (fieldType: string) => {
    switch (fieldType) {
        case 'DateRangeSearchCommon':
            return _DateRange;
        case 'RoleNodeMatchSearchCommon':
            return SuggestInputRole;
        case 'OrganisationTypeNodeMatchSearchCommon':
            return SuggestInputOrganisationType;
        case 'StaffNodeMatchSearchCommon':
            return SuggestInputStaff;
        case 'FranchiseNodeMatchSearchCommon':
            return SuggestInputFranchise;
        case `String`:
        case 'StringMatchSearchCommon':
        case 'StringLikeOrMatchSearchCommon':
            return _Input;
        case 'BooleanMatchSearchCommon':
        case 'Boolean':
            return _TriStateCheckbox;
    }
};

export const mapConnectionSearchStateToSearchVars = (searchState: { [key: string]: ReducerSearchFieldType }) => {
    if (!searchState) return undefined;

    const searchVars = {};
    for (const [key, fieldState] of Object.entries(searchState)) {
        switch (fieldState.fieldTypeName) {
            case 'DateRangeSearchCommon':
                searchVars[key] = {
                    before: fieldState.value?.before,
                    after: fieldState.value?.after,
                };
                break;
            case 'OrganisationTypeNodeMatchSearchCommon':
            case 'FranchiseNodeMatchSearchCommon':
            case 'RoleNodeMatchSearchCommon':
            case 'StaffNodeMatchSearchCommon':
                searchVars[key] = { id: fieldState.value?.id };
                break;
            case 'BooleanMatchSearchCommon':
                if (fieldState.value === true) {
                    searchVars[key] = { is: true };
                } else if (fieldState.value === false) {
                    searchVars[key] = { is: false };
                } else {
                    searchVars[key] = undefined;
                }
                break;
            case 'Boolean':
                if (fieldState.value === true) {
                    searchVars[key] = true;
                } else if (fieldState.value === false) {
                    searchVars[key] = false;
                } else {
                    searchVars[key] = undefined;
                }
                break;
            case 'String':
                searchVars[key] = fieldState.value;
                break;
            case 'StringMatchSearchCommon':
                searchVars[key] = { is: fieldState.value };
                break;
            case 'StringLikeOrMatchSearchCommon':
                searchVars[key] = { like: fieldState.value };
                break;
        }
    }
    return searchVars;
};

export const ConnectionViewerSearchFilters: React.FC<{ uiConfig }> = ({ uiConfig }) => {
    const searchMeta = useMemo(() => uiConfig.meta?.search, [uiConfig.meta?.search]);
    const ctx = useConexpSearchContext();

    return (
        <VStack>
            {searchMeta && (
                <Form size={'small'}>
                    <div className={'flex flex-row flex-wrap gap-4'}>
                        {Object.keys(searchMeta).map((key) => {
                            const fieldTypeName = searchMeta[key];
                            const value = ctx.searchState?.[key]?.value;
                            const Comp = getSearchComponent(fieldTypeName);
                            if (!Comp) return null;
                            return (
                                <div className={'py-1 px-2 shadow rounded bg-gray-50'}>
                                    <Comp
                                        key={key}
                                        onChange={ctx.setSearchField(key, fieldTypeName)}
                                        value={value}
                                        label={key}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className={'my-4'}>
                        <Button size={'small'} type={'button'} content={'Clear'} onClick={ctx.clearAll} />
                        <Button
                            type={'submit'}
                            size={'small'}
                            variant={'primary'}
                            content={'Search'}
                            icon={'search'}
                            onClick={ctx.commitSearch}
                        />
                    </div>
                </Form>
            )}
            {false && (
                <DebugJSON
                    hidden={true}
                    data={{
                        searchMeta: uiConfig?.meta?.search,
                        searchState: ctx.searchState,
                        searchVars: ctx.getSearchVars(),
                    }}
                />
            )}
        </VStack>
    );
};
