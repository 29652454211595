import Page from './Page';

import { compose, mapProps } from '@ez/tools';

import * as _ from 'lodash';
import { fromEdges, NodeType } from '@poolware/api';
import { withApolloLoading } from '@ez/components';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';

const QL = gql`
    query QueryProjectList {
        viewer {
            projectHosting {
                pageInfo {
                    endCursor
                    hasNextPage
                    hasPreviousPage
                    startCursor
                }
                edges {
                    node {
                        id
                        uuid
                        name
                        description
                        priority
                        enabled
                        enabledFrom
                        releaseCount
                        platformKey
                        franchiseRestricted
                        remoteThumbnailURL
                        updatedAt
                        createdAt
                        recommendedVersion {
                            id
                            versionNumber
                            releaseNumber
                        }
                        thumbnailURL
                        fileURL
                    }
                }
            }
        }
    }
`;

export default compose(
    graphql(QL, {
        options: (props) => ({
            fetchPolicy: 'cache-and-network',
        }),
    }),
    withApolloLoading(),
    mapProps((props: any) => {
        let projects: any = fromEdges(_.get(props, 'data.viewer.projectHosting'));

        return {
            ...props,
            projects,
        };
    }),
    withAppNavigator()
)(Page);

export interface PageControl extends IAppNavigatorProps {
    projects: NodeType.Project[];
}
