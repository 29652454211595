import * as React from 'react';
import { ModuleNavLink, ModuleRoot, ModuleRoute, ModuleSwitch } from '@poolware/react-app-navigator';
import { BulkDataExport } from './BulkDataExport';
import { Configurator } from './Configurator/Configurator';
import { PageLayout } from '@ez/components';

const SectionsNavbar = () => (
    <div className={'flex flex-row bg-white rounded p-2 mb-2 gap-2'}>
        <div className={'p-2 px-4 has-[.active]:bg-gray-300 rounded'}>
            <ModuleNavLink to={'/'} exact={true} className={'p-2'}>
                Bulk Export
            </ModuleNavLink>
        </div>
        <div className={'p-2 px-4 has-[.active]:bg-gray-300 rounded'}>
            <ModuleNavLink to={'/config'} exact={true}>
                Configurator
            </ModuleNavLink>
        </div>
    </div>
);

export const Router = () => {
    return (
        <ModuleRoot>
            <SectionsNavbar />
            <PageLayout>
                <ModuleSwitch>
                    <ModuleRoute path={'/'} exact={true} component={BulkDataExport} />
                    <ModuleRoute path={'/config'} component={Configurator} />
                </ModuleSwitch>
            </PageLayout>
        </ModuleRoot>
    );
};
