import * as React from 'react';
import { NodeType, useMutationEZCFranchiseLink } from '@poolware/api';
import {
    ButtonWithConfirm,
    ConfirmProps,
    Panel,
    Pill,
    TablePickerPanel,
    toastError,
    useModalCtrl,
} from '@ez/components';
import { useQueryViewerEzcServiceProvidersConnection } from '../../Conexp/conexp-generated/connection-query-hooks-1';
import { Modal } from 'semantic-ui-react';

const EZConnectProviderPicker: React.FC<{
    onCancel: () => void;
    onSelect: (serviceProvider: NodeType.EZCServiceProvider) => void;
}> = ({ onCancel, onSelect }) => {
    const { connectionData, connectionState } = useQueryViewerEzcServiceProvidersConnection();

    return (
        <TablePickerPanel
            multiSelect={false}
            panelHeader={{ content: 'EZ Connect Providers' }}
            onCancel={onCancel}
            onSubmit={(items) => onSelect(items[0])}
            connectionState={connectionState}
            connectionData={connectionData}
            tableDef={[
                {
                    header: 'Name',
                    cell: (item) => item.name,
                },
            ]}
        />
    );
};

export interface PanelEzProvidersProps {
    franchise: NodeType.Franchise;
    refetchQueries: any[];
}

export const PanelEzProviders: React.FC<PanelEzProvidersProps> = ({ franchise, refetchQueries }) => {
    const modalCtrl = useModalCtrl();

    const serviceProvider = franchise?.ezcServiceProviderLink?.serviceProvider;

    const mutator = useMutationEZCFranchiseLink({ refetchQueries });

    const onDeleteProvider = async () => {
        try {
            await mutator.delete({ id: franchise.ezcServiceProviderLink.id });
        } catch (e) {
            toastError(e);
        }
    };

    const onSelectProvider = async (serviceProvider: NodeType.EZCServiceProvider) => {
        try {
            await mutator.link({
                franchise: franchise.id,
                serviceProvider: serviceProvider.id,
            });
            modalCtrl.onClose();
        } catch (e) {
            toastError(e);
        }
    };

    return (
        <Panel>
            <Panel.Header
                content={'EZ Connect Provider'}
                button={
                    // !serviceProvider &&
                    { content: 'Add', icon: 'plus', onClick: () => modalCtrl.onOpen() }
                }
            />
            <Panel.Body>
                {serviceProvider && (
                    <div className={'flex flex-row justify-between items-center rounded hover:bg-gray-100 px-2'}>
                        <Pill color={'gray'}>{serviceProvider.name}</Pill>
                        <ButtonWithConfirm
                            icon={'trash'}
                            variant={'tertiary'}
                            size={'mini'}
                            onClick={onDeleteProvider}
                            confirm={confirm}
                        />
                    </div>
                )}
            </Panel.Body>
            {modalCtrl.open && (
                <Modal {...modalCtrl} size={'small'}>
                    <EZConnectProviderPicker onSelect={onSelectProvider} onCancel={modalCtrl.onClose} />
                </Modal>
            )}
        </Panel>
    );
};

const confirm: ConfirmProps = {
    cancelButton: { content: 'Keep' },
    negative: true,
    confirmButton: {
        content: 'Remove',
        icon: 'trash',
    },
    confirmMessage: { header: 'Do you want to remove this provider?' },
};
