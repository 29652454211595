import * as React from 'react';
import { useMemo } from 'react';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { ConnectionTableDef, DefaultConnectionTable, MenuBar, StickyMenuBar, VStack } from '@ez/components';
import { getFieldRenderer } from './field-renderer';
import { ConnectionViewerSearchFilters } from './ConnectionViewerSearchFilters';
import { ConexpConnectionSearchProvider, useConexpSearchContext } from './ConexpConnectionSearchProvider';
import { useConexpHook } from './hooks';

type AnyNode = any;

export interface ConnectionViewerPageProps {}

const ConnectionViewerPage: React.FC<ConnectionViewerPageProps> = ({}) => {
    const { AppNavigator, params } = useAppNavigator();
    const ctx = useConexpSearchContext();

    const { connectionHook, conexpItem, uiConfig } = useConexpHook(params.connectionId);

    const searchVars = ctx.getSearchVars();
    // console.log(searchVars);

    const { connectionData, connectionState } = connectionHook({
        search: searchVars,
    });

    const tableDef: ConnectionTableDef<AnyNode> = useMemo(() => {
        const nodeConfig = uiConfig.node;
        const nodeMeta = uiConfig.meta;
        if (!nodeConfig) {
            throw new Error('failed to load config for ' + params.connectionId);
        }

        const td: ConnectionTableDef<AnyNode> = [];
        for (const [key, fieldType] of Object.entries(nodeConfig)) {
            let sortKey = undefined;
            const fieldSortMeta = nodeMeta?.sort?.[key];
            if (fieldSortMeta === 'Boolean') {
                sortKey = key;
            }
            td.push({
                header: key,
                sortKey: sortKey,
                cell: (connectionItem) => {
                    const Render = getFieldRenderer(fieldType as any);
                    return <Render value={connectionItem[key]} />;
                },
            });
        }
        return td;
    }, [params.connectionId]);

    const goBack = () => {
        AppNavigator.navigateRelative('/');
    };

    const onViewNode = (item: AnyNode) => {
        AppNavigator.navigateRelative(`/${conexpItem.connectionId}/${item.id}`, { setOrigin: true });
    };

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBar.Section>
                    <MenuBar.Item onClick={goBack} icon={'chevron left'} title={'Back'} />
                    <MenuBar.HeaderItem icon={'list'}>{conexpItem.nodeTypeName}</MenuBar.HeaderItem>
                </MenuBar.Section>
            </StickyMenuBar>

            <ConnectionViewerSearchFilters uiConfig={uiConfig} />

            <DefaultConnectionTable
                onRowClick={onViewNode}
                tableDef={tableDef}
                connectionData={connectionData}
                connectionState={connectionState}
            />
        </VStack>
    );
};

export const ConnectionViewer = () => {
    return (
        <ConexpConnectionSearchProvider>
            <ConnectionViewerPage />
        </ConexpConnectionSearchProvider>
    );
};
