import gql from 'graphql-tag';
import { createUseQueryNodeHook, NodeType } from '@poolware/api';

const QL = gql`
    query QueryCustomerTag($id: ID!) {
        node(id: $id) {
            id
            ... on CustomerTag {
                id
                name
                description
                createdAt
                franchise {
                    id
                    name
                }
                organisationType {
                    id
                    name
                }
                addedBy {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
                checkMutations {
                    delete
                    update
                }
            }
        }
    }
`;

export const useQueryCustomerTag = createUseQueryNodeHook<NodeType.CustomerTag>(QL);
