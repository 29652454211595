import * as React from 'react';
import {
    FilterByBrand,
    FilterByProductCompany,
    FilterByProductName,
    FilterBySku,
    FilterByTraitDeclaration_Tree,
} from '../../../components-redux-connected';
import { useOrgSwitcher } from '@poolware/app-shell';

const SearchSideBar: React.FC = () => {
    const { organisation } = useOrgSwitcher();

    return (
        <div className={'flex flex-col px-2'}>
            <FilterByProductName />
            <FilterBySku />
            <FilterByBrand label={'Brand'} />
            <FilterByProductCompany label={'Product Company'} />
            {/*<FilterBySupplier label={'Search By Supplier'} />*/}
            <FilterByTraitDeclaration_Tree organisationType={organisation} />
        </div>
    );
};

export default SearchSideBar;
