import * as React from 'react';
import View from './View';
import { SettingStore } from './SettingsStore';
import FranchiseView from './View/FranchiseFlags/Franchise.Page';
import { Route, Switch } from 'react-router-dom';

const PageRouter = ({ match }) => (
    <Switch>
        <Route path={`${match.path}/`} exact component={View} />
        <Route path={`${match.path}/franchise/:franchiseId`} component={FranchiseView} />
        <Route path={`${match.path}/setting-store`} component={SettingStore} />
    </Switch>
);

export default PageRouter;
