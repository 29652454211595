import * as React from 'react';
import { useState } from 'react';
import {
    Button,
    FormikDefaultForm,
    FormikInputField,
    FormikSelectField,
    FormikTextareaField,
    Icon,
    MenuBarDropdown,
    MenuBarDropdownItemWithConfirm,
    MenuBarItem,
    MenuBarSection,
    Modal,
    Panel,
    Stack,
    StickyMenuBar,
    Table,
    toastError,
    useModalCtrl,
    Checkbox,
} from '@ez/components';

import { PageControl } from './Page.Control';
import { fromEdges, IProjectMutatorsProps, NodeType } from '@poolware/api';
import { ProjectsURLBuilder } from '../index';
import { platformOptions } from '../utils';
import moment from 'moment';

const ProjectEditForm: React.FC<
    { project: NodeType.Project; onDone; onCancel } & Pick<IProjectMutatorsProps, 'mutateProject'>
> = ({ onDone, mutateProject, onCancel, project }) => {
    const initialValues = { ...project };
    const onSubmit = async (values: typeof initialValues) => {
        try {
            await mutateProject.update({
                id: project.id,
                name: values.name?.trim() || '',
                remoteThumbnailURL: values.remoteThumbnailURL?.trim() || null,
                description: values.description?.trim() || '',
                platformKey: values.platformKey?.trim() || '',
            });
            onDone();
        } catch (e) {
            toastError(e);
        }
    };

    return (
        <FormikDefaultForm
            header={'Project'}
            initialValues={initialValues}
            submitButton={{ content: 'Update' }}
            onSubmit={onSubmit}
            onCancel={onCancel}
        >
            <FormikInputField label={'Name'} name={'name'} />
            <FormikInputField label={'Thumbnail URL'} name={'remoteThumbnailURL'} />
            <FormikSelectField label={'Platform Name'} options={platformOptions} name={'platformKey'} />
            <FormikTextareaField label={'Description'} name={'description'} />
        </FormikDefaultForm>
    );
};

export const Page: React.FC<PageControl> = (props) => {
    const [isEnabling, setIsEnabling] = useState(false);
    const { project } = props;
    const { recommendedVersion, enabled } = project;
    const versions = fromEdges(project.versions);
    const recommendedVersionId = recommendedVersion ? recommendedVersion.id : null;

    const editModalCtrl = useModalCtrl(false);

    const onDeleteProject = async () => {
        await props.ProjectMutator.deleteProject(project);
        props.AppNavigator.navigate(ProjectsURLBuilder.ListProjects(), { relativeToModule: true });
    };

    const onNewVersion = () => {
        props.AppNavigator.navigate(ProjectsURLBuilder.NewVersion(props.project.id), {
            setOrigin: true,
            relativeToModule: true,
        });
    };

    const onViewVersion = (version: NodeType.ProjectVersion) => {
        const link = ProjectsURLBuilder.ViewVersion(project.id, version.id);
        props.AppNavigator.navigate(link, { relativeToModule: true });
    };

    const enableVersion = async () => {
        try {
            const { enabled } = project;
            setIsEnabling(true);
            await props.mutateProject.update({ id: project.id, enabled: !enabled });
            setIsEnabling(false);
        } catch (e) {
            toastError(e);
        }
    };
    const goBack = () => {
        props.AppNavigator.navigateRelative(ProjectsURLBuilder.ListProjects());
    };

    const onEdit = () => {
        editModalCtrl.onOpen();
    };

    return (
        <Stack>
            <Modal {...editModalCtrl}>
                <ProjectEditForm
                    mutateProject={props.mutateProject}
                    project={project}
                    onDone={editModalCtrl.onClose}
                    onCancel={editModalCtrl.onClose}
                />
            </Modal>

            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarItem onClick={goBack} icon={'chevron left'} title={'Back'} />
                </MenuBarSection>
                <MenuBarSection position={'right'}>
                    <MenuBarDropdown color={'red'} icon={'bars'}>
                        <MenuBarDropdownItemWithConfirm
                            icon={'trash'}
                            color="orange"
                            content="Delete"
                            onClick={onDeleteProject}
                            popup={{ content: 'Will be deleted' }}
                            confirm={{
                                confirmMessage: {
                                    header: 'Delete project?',
                                    content: 'Will be deleted forever!',
                                },
                                negative: true,
                                confirmButton: {
                                    content: 'Delete',
                                    icon: 'trash',
                                },
                            }}
                        />
                    </MenuBarDropdown>
                </MenuBarSection>
            </StickyMenuBar>
            <div>
                <div className={'flex flex-row gap-4'}>
                    <div className={'w-60'}>
                        <div className={'bg-white p-2 rounded h-full flex flex-col items-center justify-center'}>
                            <img src={project.thumbnailURL} />
                        </div>
                    </div>
                    <div className={'flex-grow'}>
                        <Panel>
                            <Panel.Header
                                content={'Project Information'}
                                button={[
                                    {
                                        content: 'Edit',
                                        icon: 'edit',
                                        onClick: onEdit,
                                        disabled: isEnabling,
                                    },
                                ]}
                            />
                            <Panel.Body>
                                <Panel.Item label={'UUID'}>
                                    <code>{project.uuid}</code>
                                </Panel.Item>
                                <Panel.Item label={'Name'} content={project.name} />
                                <Panel.Item label={'Platform'} content={project.platformKey} />
                                <Panel.Item label={'Thumbnail URL'} content={project.remoteThumbnailURL} />
                                <Panel.ItemText
                                    labelWidth={'100%'}
                                    label={'Description'}
                                    content={project.description}
                                />
                                <Panel.Divider />
                                <Panel.Item label={<>Enabled</>}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Checkbox
                                            toggle={true}
                                            disabled={isEnabling}
                                            checked={enabled}
                                            onChange={enableVersion}
                                        />
                                        <Icon
                                            style={{ paddingLeft: '8px' }}
                                            name={enabled ? 'eye' : 'hide'}
                                            color={enabled ? 'green' : 'grey'}
                                        />
                                    </div>
                                </Panel.Item>
                            </Panel.Body>
                        </Panel>
                    </div>
                </div>
            </div>

            <Panel>
                <Panel.Header
                    content={'Versions'}
                    button={{
                        content: 'New',
                        onClick: onNewVersion,
                    }}
                />
                <Panel.Body>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan={1}>Version</Table.HeaderCell>
                                <Table.HeaderCell colSpan={2}>Availability</Table.HeaderCell>
                                <Table.HeaderCell colSpan={2}>Download Link</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {versions.map((version) => {
                                const {
                                    id,
                                    isPinned,
                                    enabled,
                                    versionNumber,
                                    releaseNumber,
                                    fileURL,
                                    remoteFileURL,
                                    enabledFrom,
                                } = version;

                                const isRecommended = id === recommendedVersionId;

                                return (
                                    <Table.Row key={id}>
                                        <Table.Cell singleLine>
                                            <span>
                                                #{releaseNumber} (v{versionNumber}){' '}
                                            </span>
                                        </Table.Cell>
                                        <Table.Cell singleLine>
                                            <Icon name={enabled ? 'eye' : 'hide'} color={enabled ? 'black' : 'grey'} />
                                            {isRecommended && <Icon name={'star'} color={'yellow'} />}
                                            {isPinned && <Icon name={'pin'} color={'purple'} />}
                                        </Table.Cell>
                                        <Table.Cell singleLine>
                                            {enabledFrom ? moment(enabledFrom).format('LLL') : '--'}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <a rel="noopener noreferrer" href={remoteFileURL} target={'_blank'}>
                                                <Icon name={'external'} /> {fileURL}{' '}
                                            </a>
                                        </Table.Cell>
                                        <Table.Cell textAlign={'right'}>
                                            <Button basic size={'tiny'} onClick={() => onViewVersion(version)}>
                                                Details
                                            </Button>
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </Panel.Body>
            </Panel>
        </Stack>
    );
};

export default Page;
