import * as React from 'react';
import { NodeType, useMutationFranchise } from '@poolware/api';
import { FormGroup, FormikDefaultForm, FormikInputField, toastError } from '@ez/components';

import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
    phoneNumber: Yup.string().notRequired(),
    email: Yup.string().email('Invalid email').notRequired(),
});

export interface FormEditFranchiseDetailsProps {
    franchise: NodeType.Franchise;
    onDone?: () => void;
    refetchQueries: any[];
}

export const FormEditFranchiseDetails: React.FC<FormEditFranchiseDetailsProps> = ({
    franchise,
    refetchQueries,
    onDone,
}) => {
    const { update } = useMutationFranchise({ refetchQueries });

    const initialValues = {
        ...franchise,
    };

    const onCancel = () => {
        onDone?.();
    };

    const onSubmit = async (values: typeof initialValues) => {
        try {
            const res = await update({
                id: franchise.id,
                phoneNumber: values.phoneNumber?.trim() || undefined,
                name: values.name?.trim() || undefined,
                email: values.email?.trim() || undefined,
            });
            onDone?.();
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    return (
        <FormikDefaultForm
            header={'Edit Franchise Details'}
            initialValues={initialValues}
            submitButton={{ content: 'Update' }}
            onSubmit={onSubmit}
            onCancel={onCancel}
        >
            <FormikInputField label={'Name'} name={'name'} required={true} />
            <FormGroup>
                <FormikInputField label={'Email'} name={'email'} />
                <FormikInputField label={'Phone Number'} name={'phoneNumber'} />
            </FormGroup>
        </FormikDefaultForm>
    );
};
