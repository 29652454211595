//
// DO NOT EDIT!!!
// THIS IS AN AUTOMATICALLY GENERATED FILE.
// Date:  Tue Aug 13 2024 16:10:05 GMT+1000 (Australian Eastern Standard Time)
//

// range 0-50

import { createUseQueryConnectionHook, createUseQueryNodeHook, NodeType } from '@poolware/api';
import { getConexpItemById } from './connection-queries';

// abstractDocuments: hook

export const useQueryViewerAbstractDocumentsConnection = createUseQueryConnectionHook<
    NodeType.AbstractDocument,
    NodeType.AbstractDocumentViewerAbstractDocumentsSearch
>({
    connectionPath: 'viewer.abstractDocuments',
    query: getConexpItemById('abstractDocuments')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryAbstractDocumentNode = createUseQueryNodeHook<NodeType.AbstractDocument>(
    getConexpItemById('abstractDocuments')?.gqlNodeQuery
);

// announcements: hook

export const useQueryViewerAnnouncementsConnection = createUseQueryConnectionHook<
    NodeType.Announcement,
    NodeType.AnnouncementViewerAnnouncementsSearch
>({
    connectionPath: 'viewer.announcements',
    query: getConexpItemById('announcements')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryAnnouncementNode = createUseQueryNodeHook<NodeType.Announcement>(
    getConexpItemById('announcements')?.gqlNodeQuery
);

// appointmentGroups: hook

export const useQueryViewerAppointmentGroupsConnection = createUseQueryConnectionHook<
    NodeType.AppointmentGroup,
    NodeType.AppointmentGroupViewerAppointmentGroupsSearch
>({
    connectionPath: 'viewer.appointmentGroups',
    query: getConexpItemById('appointmentGroups')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryAppointmentGroupNode = createUseQueryNodeHook<NodeType.AppointmentGroup>(
    getConexpItemById('appointmentGroups')?.gqlNodeQuery
);

// appointmentItems: hook

export const useQueryViewerAppointmentItemsConnection = createUseQueryConnectionHook<
    NodeType.AppointmentItem,
    NodeType.AppointmentItemSearchCommon
>({
    connectionPath: 'viewer.appointmentItems',
    query: getConexpItemById('appointmentItems')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryAppointmentItemNode = createUseQueryNodeHook<NodeType.AppointmentItem>(
    getConexpItemById('appointmentItems')?.gqlNodeQuery
);

// appointments: hook

export const useQueryViewerAppointmentsConnection = createUseQueryConnectionHook<
    NodeType.Appointment,
    NodeType.AppointmentViewerAppointmentsSearch
>({
    connectionPath: 'viewer.appointments',
    query: getConexpItemById('appointments')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryAppointmentNode = createUseQueryNodeHook<NodeType.Appointment>(
    getConexpItemById('appointments')?.gqlNodeQuery
);

// brands: hook

export const useQueryViewerBrandsConnection = createUseQueryConnectionHook<
    NodeType.Brand,
    NodeType.BrandViewerBrandsSearch
>({
    connectionPath: 'viewer.brands',
    query: getConexpItemById('brands')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryBrandNode = createUseQueryNodeHook<NodeType.Brand>(getConexpItemById('brands')?.gqlNodeQuery);

// consumableProducts: hook

export const useQueryViewerConsumableProductsConnection = createUseQueryConnectionHook<
    NodeType.ConsumableProduct,
    NodeType.ConsumableProductViewerConsumableProductsSearch
>({
    connectionPath: 'viewer.consumableProducts',
    query: getConexpItemById('consumableProducts')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryConsumableProductNode = createUseQueryNodeHook<NodeType.ConsumableProduct>(
    getConexpItemById('consumableProducts')?.gqlNodeQuery
);

// contactTypes: hook

export const useQueryViewerContactTypesConnection = createUseQueryConnectionHook<
    NodeType.ContactType,
    NodeType.ContactTypeViewerContactTypesSearch
>({
    connectionPath: 'viewer.contactTypes',
    query: getConexpItemById('contactTypes')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryContactTypeNode = createUseQueryNodeHook<NodeType.ContactType>(
    getConexpItemById('contactTypes')?.gqlNodeQuery
);

// converters: hook

export const useQueryViewerConvertersConnection = createUseQueryConnectionHook<
    NodeType.SampleConverter,
    NodeType.SampleConverterViewerConvertersSearch
>({
    connectionPath: 'viewer.converters',
    query: getConexpItemById('converters')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQuerySampleConverterNode = createUseQueryNodeHook<NodeType.SampleConverter>(
    getConexpItemById('converters')?.gqlNodeQuery
);

// customContracts: hook

export const useQueryViewerCustomContractsConnection = createUseQueryConnectionHook<
    NodeType.CustomContract,
    NodeType.CustomContractViewerCustomContractsSearch
>({
    connectionPath: 'viewer.customContracts',
    query: getConexpItemById('customContracts')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryCustomContractNode = createUseQueryNodeHook<NodeType.CustomContract>(
    getConexpItemById('customContracts')?.gqlNodeQuery
);

// customerTags: hook

export const useQueryViewerCustomerTagsConnection = createUseQueryConnectionHook<
    NodeType.CustomerTag,
    NodeType.CustomerTagViewerCustomerTagsSearch
>({
    connectionPath: 'viewer.customerTags',
    query: getConexpItemById('customerTags')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryCustomerTagNode = createUseQueryNodeHook<NodeType.CustomerTag>(
    getConexpItemById('customerTags')?.gqlNodeQuery
);

// customers: hook

export const useQueryViewerCustomersConnection = createUseQueryConnectionHook<
    NodeType.Customer,
    NodeType.CustomerViewerCustomersSearch
>({
    connectionPath: 'viewer.customers',
    query: getConexpItemById('customers')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryCustomerNode = createUseQueryNodeHook<NodeType.Customer>(
    getConexpItemById('customers')?.gqlNodeQuery
);

// emailGlobalTemplateSelections: hook

export const useQueryViewerEmailGlobalTemplateSelectionsConnection = createUseQueryConnectionHook<
    NodeType.EmailGlobalSelection,
    NodeType.EmailGlobalSelectionViewerEmailGlobalTemplateSelectionsSearch
>({
    connectionPath: 'viewer.emailGlobalTemplateSelections',
    query: getConexpItemById('emailGlobalTemplateSelections')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryEmailGlobalSelectionNode = createUseQueryNodeHook<NodeType.EmailGlobalSelection>(
    getConexpItemById('emailGlobalTemplateSelections')?.gqlNodeQuery
);

// emailOrganisationTemplateSelections: hook

export const useQueryViewerEmailOrganisationTemplateSelectionsConnection = createUseQueryConnectionHook<
    NodeType.EmailTemplateOrganisationSelection,
    NodeType.EmailTemplateOrganisationSelectionViewerEmailOrganisationTemplateSelectionsSearch
>({
    connectionPath: 'viewer.emailOrganisationTemplateSelections',
    query: getConexpItemById('emailOrganisationTemplateSelections')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryEmailTemplateOrganisationSelectionNode =
    createUseQueryNodeHook<NodeType.EmailTemplateOrganisationSelection>(
        getConexpItemById('emailOrganisationTemplateSelections')?.gqlNodeQuery
    );

// emailTemplateSelections: hook

export const useQueryViewerEmailTemplateSelectionsConnection = createUseQueryConnectionHook<
    NodeType.EmailTemplateSelection,
    NodeType.EmailTemplateSelectionViewerEmailTemplateSelectionsSearch
>({
    connectionPath: 'viewer.emailTemplateSelections',
    query: getConexpItemById('emailTemplateSelections')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryEmailTemplateSelectionNode = createUseQueryNodeHook<NodeType.EmailTemplateSelection>(
    getConexpItemById('emailTemplateSelections')?.gqlNodeQuery
);

// emailTemplates: hook

export const useQueryViewerEmailTemplatesConnection = createUseQueryConnectionHook<
    NodeType.EmailTemplate,
    NodeType.EmailTemplateViewerEmailTemplatesSearch
>({
    connectionPath: 'viewer.emailTemplates',
    query: getConexpItemById('emailTemplates')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryEmailTemplateNode = createUseQueryNodeHook<NodeType.EmailTemplate>(
    getConexpItemById('emailTemplates')?.gqlNodeQuery
);

// emailTypes: hook

export const useQueryViewerEmailTypesConnection = createUseQueryConnectionHook<NodeType.EmailType>({
    connectionPath: 'viewer.emailTypes',
    query: getConexpItemById('emailTypes')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryEmailTypeNode = createUseQueryNodeHook<NodeType.EmailType>(
    getConexpItemById('emailTypes')?.gqlNodeQuery
);

// entities: hook

export const useQueryViewerEntitiesConnection = createUseQueryConnectionHook<
    NodeType.Entity,
    NodeType.EntityViewerEntitiesSearch
>({
    connectionPath: 'viewer.entities',
    query: getConexpItemById('entities')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryEntityNode = createUseQueryNodeHook<NodeType.Entity>(getConexpItemById('entities')?.gqlNodeQuery);

// ezcDeviceDefinitions: hook

export const useQueryViewerEzcDeviceDefinitionsConnection = createUseQueryConnectionHook<NodeType.EZCDeviceDefinition>({
    connectionPath: 'viewer.ezcDeviceDefinitions',
    query: getConexpItemById('ezcDeviceDefinitions')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryEZCDeviceDefinitionNode = createUseQueryNodeHook<NodeType.EZCDeviceDefinition>(
    getConexpItemById('ezcDeviceDefinitions')?.gqlNodeQuery
);

// ezcDeviceLinks: hook

export const useQueryViewerEzcDeviceLinksConnection = createUseQueryConnectionHook<
    NodeType.EZCDeviceLink,
    NodeType.EZCDeviceLinkViewerEzcDeviceLinksSearch
>({
    connectionPath: 'viewer.ezcDeviceLinks',
    query: getConexpItemById('ezcDeviceLinks')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryEZCDeviceLinkNode = createUseQueryNodeHook<NodeType.EZCDeviceLink>(
    getConexpItemById('ezcDeviceLinks')?.gqlNodeQuery
);

// ezcDevices: hook

export const useQueryViewerEzcDevicesConnection = createUseQueryConnectionHook<NodeType.EZCDevice>({
    connectionPath: 'viewer.ezcDevices',
    query: getConexpItemById('ezcDevices')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryEZCDeviceNode = createUseQueryNodeHook<NodeType.EZCDevice>(
    getConexpItemById('ezcDevices')?.gqlNodeQuery
);

// ezcServiceProviderLinks: hook

export const useQueryViewerEzcServiceProviderLinksConnection = createUseQueryConnectionHook<
    NodeType.EZCFranchiseLink,
    NodeType.EZCFranchiseLinkViewerEzcServiceProviderLinksSearch
>({
    connectionPath: 'viewer.ezcServiceProviderLinks',
    query: getConexpItemById('ezcServiceProviderLinks')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryEZCFranchiseLinkNode = createUseQueryNodeHook<NodeType.EZCFranchiseLink>(
    getConexpItemById('ezcServiceProviderLinks')?.gqlNodeQuery
);

// ezcServiceProviders: hook

export const useQueryViewerEzcServiceProvidersConnection = createUseQueryConnectionHook<NodeType.EZCServiceProvider>({
    connectionPath: 'viewer.ezcServiceProviders',
    query: getConexpItemById('ezcServiceProviders')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryEZCServiceProviderNode = createUseQueryNodeHook<NodeType.EZCServiceProvider>(
    getConexpItemById('ezcServiceProviders')?.gqlNodeQuery
);

// featureFlagVariations: hook

export const useQueryViewerFeatureFlagVariationsConnection = createUseQueryConnectionHook<
    NodeType.FeatureFlagVariation,
    NodeType.FeatureFlagVariationViewerFeatureFlagVariationsSearch
>({
    connectionPath: 'viewer.featureFlagVariations',
    query: getConexpItemById('featureFlagVariations')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryFeatureFlagVariationNode = createUseQueryNodeHook<NodeType.FeatureFlagVariation>(
    getConexpItemById('featureFlagVariations')?.gqlNodeQuery
);

// featureFlags: hook

export const useQueryViewerFeatureFlagsConnection = createUseQueryConnectionHook<NodeType.FeatureFlag>({
    connectionPath: 'viewer.featureFlags',
    query: getConexpItemById('featureFlags')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryFeatureFlagNode = createUseQueryNodeHook<NodeType.FeatureFlag>(
    getConexpItemById('featureFlags')?.gqlNodeQuery
);

// files: hook

export const useQueryViewerFilesConnection = createUseQueryConnectionHook<
    NodeType.FileUpload,
    NodeType.FileUploadViewerFilesSearch
>({
    connectionPath: 'viewer.files',
    query: getConexpItemById('files')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryFileUploadNode = createUseQueryNodeHook<NodeType.FileUpload>(
    getConexpItemById('files')?.gqlNodeQuery
);

// franchiseSequenceStore: hook

export const useQueryViewerFranchiseSequenceStoreConnection = createUseQueryConnectionHook<
    NodeType.FranchiseSequenceStore,
    NodeType.FranchiseSequenceStoreViewerFranchiseSequenceStoreSearch
>({
    connectionPath: 'viewer.franchiseSequenceStore',
    query: getConexpItemById('franchiseSequenceStore')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryFranchiseSequenceStoreNode = createUseQueryNodeHook<NodeType.FranchiseSequenceStore>(
    getConexpItemById('franchiseSequenceStore')?.gqlNodeQuery
);

// franchiseValuesStore: hook

export const useQueryViewerFranchiseValuesStoreConnection = createUseQueryConnectionHook<
    NodeType.FranchiseValueStore,
    NodeType.FranchiseValueStoreViewerFranchiseValuesStoreSearch
>({
    connectionPath: 'viewer.franchiseValuesStore',
    query: getConexpItemById('franchiseValuesStore')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryFranchiseValueStoreNode = createUseQueryNodeHook<NodeType.FranchiseValueStore>(
    getConexpItemById('franchiseValuesStore')?.gqlNodeQuery
);

// franchiseWarranters: hook

export const useQueryViewerFranchiseWarrantersConnection = createUseQueryConnectionHook<
    NodeType.FranchiseWarranter,
    NodeType.FranchiseWarranterViewerFranchiseWarrantersSearch
>({
    connectionPath: 'viewer.franchiseWarranters',
    query: getConexpItemById('franchiseWarranters')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryFranchiseWarranterNode = createUseQueryNodeHook<NodeType.FranchiseWarranter>(
    getConexpItemById('franchiseWarranters')?.gqlNodeQuery
);

// franchises: hook

export const useQueryViewerFranchisesConnection = createUseQueryConnectionHook<
    NodeType.Franchise,
    NodeType.FranchiseViewerFranchisesSearch
>({
    connectionPath: 'viewer.franchises',
    query: getConexpItemById('franchises')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryFranchiseNode = createUseQueryNodeHook<NodeType.Franchise>(
    getConexpItemById('franchises')?.gqlNodeQuery
);

// heaterRecommendations: hook

export const useQueryViewerHeaterRecommendationsConnection = createUseQueryConnectionHook<
    NodeType.HeaterRecommendation,
    NodeType.HeaterRecommendationViewerHeaterRecommendationsSearch
>({
    connectionPath: 'viewer.heaterRecommendations',
    query: getConexpItemById('heaterRecommendations')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryHeaterRecommendationNode = createUseQueryNodeHook<NodeType.HeaterRecommendation>(
    getConexpItemById('heaterRecommendations')?.gqlNodeQuery
);

// installableProducts: hook

export const useQueryViewerInstallableProductsConnection = createUseQueryConnectionHook<
    NodeType.InstallableProduct,
    NodeType.InstallableProductViewerInstallableProductsSearch
>({
    connectionPath: 'viewer.installableProducts',
    query: getConexpItemById('installableProducts')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryInstallableProductNode = createUseQueryNodeHook<NodeType.InstallableProduct>(
    getConexpItemById('installableProducts')?.gqlNodeQuery
);

// jobTodoTemplates: hook

export const useQueryViewerJobTodoTemplatesConnection = createUseQueryConnectionHook<
    NodeType.JobTodoTemplate,
    NodeType.JobTodoTemplateViewerJobTodoTemplatesSearch
>({
    connectionPath: 'viewer.jobTodoTemplates',
    query: getConexpItemById('jobTodoTemplates')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryJobTodoTemplateNode = createUseQueryNodeHook<NodeType.JobTodoTemplate>(
    getConexpItemById('jobTodoTemplates')?.gqlNodeQuery
);

// locales: hook

export const useQueryViewerLocalesConnection = createUseQueryConnectionHook<NodeType.Locales>({
    connectionPath: 'viewer.locales',
    query: getConexpItemById('locales')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryLocalesNode = createUseQueryNodeHook<NodeType.Locales>(getConexpItemById('locales')?.gqlNodeQuery);

// manualProblems: hook

export const useQueryViewerManualProblemsConnection = createUseQueryConnectionHook<
    NodeType.ManualProblem,
    NodeType.ManualProblemViewerManualProblemsSearch
>({
    connectionPath: 'viewer.manualProblems',
    query: getConexpItemById('manualProblems')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryManualProblemNode = createUseQueryNodeHook<NodeType.ManualProblem>(
    getConexpItemById('manualProblems')?.gqlNodeQuery
);

// measurementTypes: hook

export const useQueryViewerMeasurementTypesConnection = createUseQueryConnectionHook<
    NodeType.Measurement,
    NodeType.MeasurementViewerMeasurementTypesSearch
>({
    connectionPath: 'viewer.measurementTypes',
    query: getConexpItemById('measurementTypes')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryMeasurementNode = createUseQueryNodeHook<NodeType.Measurement>(
    getConexpItemById('measurementTypes')?.gqlNodeQuery
);

// measurementUnits: hook

export const useQueryViewerMeasurementUnitsConnection = createUseQueryConnectionHook<
    NodeType.Unit,
    NodeType.UnitViewerMeasurementUnitsSearch
>({
    connectionPath: 'viewer.measurementUnits',
    query: getConexpItemById('measurementUnits')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryUnitNode = createUseQueryNodeHook<NodeType.Unit>(
    getConexpItemById('measurementUnits')?.gqlNodeQuery
);

// organisationTypes: hook

export const useQueryViewerOrganisationTypesConnection = createUseQueryConnectionHook<NodeType.OrganisationType>({
    connectionPath: 'viewer.organisationTypes',
    query: getConexpItemById('organisationTypes')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryOrganisationTypeNode = createUseQueryNodeHook<NodeType.OrganisationType>(
    getConexpItemById('organisationTypes')?.gqlNodeQuery
);

// permissions: hook

export const useQueryViewerPermissionsConnection = createUseQueryConnectionHook<
    NodeType.Permission,
    NodeType.PermissionViewerPermissionsSearch
>({
    connectionPath: 'viewer.permissions',
    query: getConexpItemById('permissions')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryPermissionNode = createUseQueryNodeHook<NodeType.Permission>(
    getConexpItemById('permissions')?.gqlNodeQuery
);

// poolBottleLeases: hook

export const useQueryViewerPoolBottleLeasesConnection = createUseQueryConnectionHook<
    NodeType.PoolBottleLease,
    NodeType.PoolBottleLeaseViewerPoolBottleLeasesSearch
>({
    connectionPath: 'viewer.poolBottleLeases',
    query: getConexpItemById('poolBottleLeases')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryPoolBottleLeaseNode = createUseQueryNodeHook<NodeType.PoolBottleLease>(
    getConexpItemById('poolBottleLeases')?.gqlNodeQuery
);

// poolBottles: hook

export const useQueryViewerPoolBottlesConnection = createUseQueryConnectionHook<
    NodeType.PoolBottle,
    NodeType.PoolBottleViewerPoolBottlesSearch
>({
    connectionPath: 'viewer.poolBottles',
    query: getConexpItemById('poolBottles')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryPoolBottleNode = createUseQueryNodeHook<NodeType.PoolBottle>(
    getConexpItemById('poolBottles')?.gqlNodeQuery
);

// poolDeclarationFlagFields: hook

export const useQueryViewerPoolDeclarationFlagFieldsConnection = createUseQueryConnectionHook<
    NodeType.PoolDeclarationFlagField,
    NodeType.PoolDeclarationFlagFieldViewerPoolDeclarationFlagFieldsSearch
>({
    connectionPath: 'viewer.poolDeclarationFlagFields',
    query: getConexpItemById('poolDeclarationFlagFields')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryPoolDeclarationFlagFieldNode = createUseQueryNodeHook<NodeType.PoolDeclarationFlagField>(
    getConexpItemById('poolDeclarationFlagFields')?.gqlNodeQuery
);

// poolProductDeclarations: hook

export const useQueryViewerPoolProductDeclarationsConnection = createUseQueryConnectionHook<
    NodeType.PoolProductDeclaration,
    NodeType.PoolProductDeclarationViewerPoolProductDeclarationsSearch
>({
    connectionPath: 'viewer.poolProductDeclarations',
    query: getConexpItemById('poolProductDeclarations')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryPoolProductDeclarationNode = createUseQueryNodeHook<NodeType.PoolProductDeclaration>(
    getConexpItemById('poolProductDeclarations')?.gqlNodeQuery
);

// poolSanitisers: hook

export const useQueryViewerPoolSanitisersConnection = createUseQueryConnectionHook<
    NodeType.Sanitiser,
    NodeType.SanitiserViewerPoolSanitisersSearch
>({
    connectionPath: 'viewer.poolSanitisers',
    query: getConexpItemById('poolSanitisers')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQuerySanitiserNode = createUseQueryNodeHook<NodeType.Sanitiser>(
    getConexpItemById('poolSanitisers')?.gqlNodeQuery
);

// poolTransfers: hook

export const useQueryViewerPoolTransfersConnection = createUseQueryConnectionHook<
    NodeType.PoolOwnershipHistory,
    NodeType.PoolOwnershipHistoryViewerPoolTransfersSearch
>({
    connectionPath: 'viewer.poolTransfers',
    query: getConexpItemById('poolTransfers')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryPoolOwnershipHistoryNode = createUseQueryNodeHook<NodeType.PoolOwnershipHistory>(
    getConexpItemById('poolTransfers')?.gqlNodeQuery
);

// poolTypes: hook

export const useQueryViewerPoolTypesConnection = createUseQueryConnectionHook<
    NodeType.PoolType,
    NodeType.PoolTypeViewerPoolTypesSearch
>({
    connectionPath: 'viewer.poolTypes',
    query: getConexpItemById('poolTypes')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryPoolTypeNode = createUseQueryNodeHook<NodeType.PoolType>(
    getConexpItemById('poolTypes')?.gqlNodeQuery
);

// pools: hook

export const useQueryViewerPoolsConnection = createUseQueryConnectionHook<
    NodeType.Pool,
    NodeType.PoolViewerPoolsSearch
>({
    connectionPath: 'viewer.pools',
    query: getConexpItemById('pools')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryPoolNode = createUseQueryNodeHook<NodeType.Pool>(getConexpItemById('pools')?.gqlNodeQuery);

// productCompany: hook

export const useQueryViewerProductCompanyConnection = createUseQueryConnectionHook<
    NodeType.ProductCompany,
    NodeType.ProductCompanyViewerProductCompanySearch
>({
    connectionPath: 'viewer.productCompany',
    query: getConexpItemById('productCompany')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryProductCompanyNode = createUseQueryNodeHook<NodeType.ProductCompany>(
    getConexpItemById('productCompany')?.gqlNodeQuery
);

// productDeclarations: hook

export const useQueryViewerProductDeclarationsConnection = createUseQueryConnectionHook<
    NodeType.ProductTraitDeclaration,
    NodeType.ProductTraitDeclarationViewerProductDeclarationsSearch
>({
    connectionPath: 'viewer.productDeclarations',
    query: getConexpItemById('productDeclarations')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryProductTraitDeclarationNode = createUseQueryNodeHook<NodeType.ProductTraitDeclaration>(
    getConexpItemById('productDeclarations')?.gqlNodeQuery
);

// productDocuments: hook

export const useQueryViewerProductDocumentsConnection = createUseQueryConnectionHook<
    NodeType.ProductDocument,
    NodeType.ProductDocumentViewerProductDocumentsSearch
>({
    connectionPath: 'viewer.productDocuments',
    query: getConexpItemById('productDocuments')?.gqlConnectionQuery,
    connectionConfig: {
        defaultPageSize: 30,
    },
});

export const useQueryProductDocumentNode = createUseQueryNodeHook<NodeType.ProductDocument>(
    getConexpItemById('productDocuments')?.gqlNodeQuery
);

export const availableConexpHooksPartial1: { [keys: string]: any } = {
    abstractDocuments: {
        connectionHook: useQueryViewerAbstractDocumentsConnection,
        nodeHook: useQueryAbstractDocumentNode,
    },
    announcements: {
        connectionHook: useQueryViewerAnnouncementsConnection,
        nodeHook: useQueryAnnouncementNode,
    },
    appointmentGroups: {
        connectionHook: useQueryViewerAppointmentGroupsConnection,
        nodeHook: useQueryAppointmentGroupNode,
    },
    appointmentItems: {
        connectionHook: useQueryViewerAppointmentItemsConnection,
        nodeHook: useQueryAppointmentItemNode,
    },
    appointments: {
        connectionHook: useQueryViewerAppointmentsConnection,
        nodeHook: useQueryAppointmentNode,
    },
    brands: {
        connectionHook: useQueryViewerBrandsConnection,
        nodeHook: useQueryBrandNode,
    },
    consumableProducts: {
        connectionHook: useQueryViewerConsumableProductsConnection,
        nodeHook: useQueryConsumableProductNode,
    },
    contactTypes: {
        connectionHook: useQueryViewerContactTypesConnection,
        nodeHook: useQueryContactTypeNode,
    },
    converters: {
        connectionHook: useQueryViewerConvertersConnection,
        nodeHook: useQuerySampleConverterNode,
    },
    customContracts: {
        connectionHook: useQueryViewerCustomContractsConnection,
        nodeHook: useQueryCustomContractNode,
    },
    customerTags: {
        connectionHook: useQueryViewerCustomerTagsConnection,
        nodeHook: useQueryCustomerTagNode,
    },
    customers: {
        connectionHook: useQueryViewerCustomersConnection,
        nodeHook: useQueryCustomerNode,
    },
    emailGlobalTemplateSelections: {
        connectionHook: useQueryViewerEmailGlobalTemplateSelectionsConnection,
        nodeHook: useQueryEmailGlobalSelectionNode,
    },
    emailOrganisationTemplateSelections: {
        connectionHook: useQueryViewerEmailOrganisationTemplateSelectionsConnection,
        nodeHook: useQueryEmailTemplateOrganisationSelectionNode,
    },
    emailTemplateSelections: {
        connectionHook: useQueryViewerEmailTemplateSelectionsConnection,
        nodeHook: useQueryEmailTemplateSelectionNode,
    },
    emailTemplates: {
        connectionHook: useQueryViewerEmailTemplatesConnection,
        nodeHook: useQueryEmailTemplateNode,
    },
    emailTypes: {
        connectionHook: useQueryViewerEmailTypesConnection,
        nodeHook: useQueryEmailTypeNode,
    },
    entities: {
        connectionHook: useQueryViewerEntitiesConnection,
        nodeHook: useQueryEntityNode,
    },
    ezcDeviceDefinitions: {
        connectionHook: useQueryViewerEzcDeviceDefinitionsConnection,
        nodeHook: useQueryEZCDeviceDefinitionNode,
    },
    ezcDeviceLinks: {
        connectionHook: useQueryViewerEzcDeviceLinksConnection,
        nodeHook: useQueryEZCDeviceLinkNode,
    },
    ezcDevices: {
        connectionHook: useQueryViewerEzcDevicesConnection,
        nodeHook: useQueryEZCDeviceNode,
    },
    ezcServiceProviderLinks: {
        connectionHook: useQueryViewerEzcServiceProviderLinksConnection,
        nodeHook: useQueryEZCFranchiseLinkNode,
    },
    ezcServiceProviders: {
        connectionHook: useQueryViewerEzcServiceProvidersConnection,
        nodeHook: useQueryEZCServiceProviderNode,
    },
    featureFlagVariations: {
        connectionHook: useQueryViewerFeatureFlagVariationsConnection,
        nodeHook: useQueryFeatureFlagVariationNode,
    },
    featureFlags: {
        connectionHook: useQueryViewerFeatureFlagsConnection,
        nodeHook: useQueryFeatureFlagNode,
    },
    files: {
        connectionHook: useQueryViewerFilesConnection,
        nodeHook: useQueryFileUploadNode,
    },
    franchiseSequenceStore: {
        connectionHook: useQueryViewerFranchiseSequenceStoreConnection,
        nodeHook: useQueryFranchiseSequenceStoreNode,
    },
    franchiseValuesStore: {
        connectionHook: useQueryViewerFranchiseValuesStoreConnection,
        nodeHook: useQueryFranchiseValueStoreNode,
    },
    franchiseWarranters: {
        connectionHook: useQueryViewerFranchiseWarrantersConnection,
        nodeHook: useQueryFranchiseWarranterNode,
    },
    franchises: {
        connectionHook: useQueryViewerFranchisesConnection,
        nodeHook: useQueryFranchiseNode,
    },
    heaterRecommendations: {
        connectionHook: useQueryViewerHeaterRecommendationsConnection,
        nodeHook: useQueryHeaterRecommendationNode,
    },
    installableProducts: {
        connectionHook: useQueryViewerInstallableProductsConnection,
        nodeHook: useQueryInstallableProductNode,
    },
    jobTodoTemplates: {
        connectionHook: useQueryViewerJobTodoTemplatesConnection,
        nodeHook: useQueryJobTodoTemplateNode,
    },
    locales: {
        connectionHook: useQueryViewerLocalesConnection,
        nodeHook: useQueryLocalesNode,
    },
    manualProblems: {
        connectionHook: useQueryViewerManualProblemsConnection,
        nodeHook: useQueryManualProblemNode,
    },
    measurementTypes: {
        connectionHook: useQueryViewerMeasurementTypesConnection,
        nodeHook: useQueryMeasurementNode,
    },
    measurementUnits: {
        connectionHook: useQueryViewerMeasurementUnitsConnection,
        nodeHook: useQueryUnitNode,
    },
    organisationTypes: {
        connectionHook: useQueryViewerOrganisationTypesConnection,
        nodeHook: useQueryOrganisationTypeNode,
    },
    permissions: {
        connectionHook: useQueryViewerPermissionsConnection,
        nodeHook: useQueryPermissionNode,
    },
    poolBottleLeases: {
        connectionHook: useQueryViewerPoolBottleLeasesConnection,
        nodeHook: useQueryPoolBottleLeaseNode,
    },
    poolBottles: {
        connectionHook: useQueryViewerPoolBottlesConnection,
        nodeHook: useQueryPoolBottleNode,
    },
    poolDeclarationFlagFields: {
        connectionHook: useQueryViewerPoolDeclarationFlagFieldsConnection,
        nodeHook: useQueryPoolDeclarationFlagFieldNode,
    },
    poolProductDeclarations: {
        connectionHook: useQueryViewerPoolProductDeclarationsConnection,
        nodeHook: useQueryPoolProductDeclarationNode,
    },
    poolSanitisers: {
        connectionHook: useQueryViewerPoolSanitisersConnection,
        nodeHook: useQuerySanitiserNode,
    },
    poolTransfers: {
        connectionHook: useQueryViewerPoolTransfersConnection,
        nodeHook: useQueryPoolOwnershipHistoryNode,
    },
    poolTypes: {
        connectionHook: useQueryViewerPoolTypesConnection,
        nodeHook: useQueryPoolTypeNode,
    },
    pools: {
        connectionHook: useQueryViewerPoolsConnection,
        nodeHook: useQueryPoolNode,
    },
    productCompany: {
        connectionHook: useQueryViewerProductCompanyConnection,
        nodeHook: useQueryProductCompanyNode,
    },
    productDeclarations: {
        connectionHook: useQueryViewerProductDeclarationsConnection,
        nodeHook: useQueryProductTraitDeclarationNode,
    },
    productDocuments: {
        connectionHook: useQueryViewerProductDocumentsConnection,
        nodeHook: useQueryProductDocumentNode,
    },
};
