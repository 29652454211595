import * as React from 'react';
import { useMemo, useState } from 'react';
import {
    AsyncCheckbox,
    Button,
    ButtonWithConfirm,
    ConnectionErrorMessage,
    ConnectionTableDef,
    DefaultConnectionTable,
    MenuBarHeaderItem,
    MenuBarItem,
    MenuBarSection,
    Modal,
    PageLayout,
    PageSkeletonLoader,
    Panel,
    SectionHeader,
    StickyMenuBar,
    toastError,
    useModalCtrl,
    VStack,
} from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { fromEdges, NodeType, useMutationFeatureFlag, useMutationFeatureFlagVariation } from '@poolware/api';
import { NotFoundPage, useViewer } from '@poolware/app-shell';

import { useQueryFeatureFlag } from './use-query-feature-flag-connection';
import {
    FeatureFlagValueType,
    FormEditDescription,
    FormNewFeatureFlagVariation,
    FormUpdateFeatureFlagVariation,
    prepareMutationCreateVariation,
    prepareMutationUpdateVariation,
} from './FormFeatureFlag';
import { IconFeatureStatus } from './components';

const ButtonDeleteVariation: React.FC<{ onClick }> = ({ onClick }) => {
    return (
        <ButtonWithConfirm
            icon={'trash'}
            basic={true}
            size={'mini'}
            onClick={onClick}
            popup={{ content: 'Delete Variation' }}
            confirm={{
                confirmMessage: {
                    header: 'Delete Flag?',
                    content: 'There is no undo! This flag will be deleted forever! ',
                },
                negative: true,
                confirmButton: {
                    content: 'Delete',
                    icon: 'trash',
                },
            }}
        />
    );
};

export const PageView: React.FC = () => {
    const { AppNavigator } = useAppNavigator();
    const { viewer } = useViewer();
    const modalNewVariation = useModalCtrl();
    const modalEditVariation = useModalCtrl();
    const modalEditFlagDescription = useModalCtrl();
    const [editVariation, setEditVariation] = useState<NodeType.FeatureFlagVariation>(null);

    const { node, error, loading, refetchQuery } = useQueryFeatureFlag(AppNavigator.params?.id);
    const { update: updateFeatureFlag } = useMutationFeatureFlag({ refetchQueries: [refetchQuery] });
    const {
        update: updateVariation,
        create,
        delete: deleteVariation,
    } = useMutationFeatureFlagVariation({
        refetchQueries: [refetchQuery],
    });

    const variations = useMemo(
        () =>
            fromEdges(node?.variations).sort((a, b) => {
                let aCount = 0;
                if (a?.franchise) aCount++;
                if (a?.organisation) aCount++;
                if (a?.role) aCount++;
                let bCount = 0;
                if (b?.franchise) bCount++;
                if (b?.organisation) bCount++;
                if (b?.role) bCount++;
                return aCount > bCount ? 1 : -1;
            }),
        [node]
    );

    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    } else if (!loading && !node) {
        return <NotFoundPage />;
    }

    const goToList = () => {
        AppNavigator.navigateRelative('/');
    };

    const onCreateNewVariation = async (values: NodeType.FeatureFlagVariation) => {
        try {
            await create(prepareMutationCreateVariation(values));
            await viewer.refetch?.();
            modalNewVariation.onClose();
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    const onUpdateVariation = async (values) => {
        try {
            await updateVariation(prepareMutationUpdateVariation(values));
            await viewer.refetch?.();
            modalEditVariation.onClose();
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    const onEditVariation = (variation: NodeType.FeatureFlagVariation) => () => {
        setEditVariation(variation);
        modalEditVariation.onOpen();
    };

    const onDeleteVariation = (variation: NodeType.FeatureFlagVariation) => async () => {
        const shouldNavToList = fromEdges(node?.variations).length === 1;
        try {
            await deleteVariation({ id: variation.id });
            await viewer.refetch?.();
            if (shouldNavToList) {
                AppNavigator.navigateRelative('/');
            }
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    const onSubmitUpdateDescription = async (values) => {
        try {
            await updateFeatureFlag({
                id: node.id,
                description: values.description?.trim() || null,
            });
            modalEditFlagDescription.onClose();
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    const toggleActiveState = (variation: NodeType.FeatureFlagVariation) => async (checked: boolean) => {
        try {
            await updateVariation({
                id: variation.id,
                active: checked,
            });
            await viewer.refetch?.();
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    const tableDef: ConnectionTableDef<NodeType.FeatureFlagVariation> = [
        {
            header: '',
            cellProps: { textAlign: 'center' },
            cell: (r) => {
                return <IconFeatureStatus active={r.active} />;
            },
        },
        {
            header: 'Activate',
            width: '1',

            cell: (r) => {
                return <AsyncCheckbox toggle={true} onToggle={toggleActiveState(r)} checked={r.active} />;
            },
        },
        {
            header: 'Value',
            width: 4,
            cell: (r) => {
                if (r.value === null) {
                    return <span style={{ color: 'teal' }}>UNSET</span>;
                } else if (r.value === '') {
                    return <i style={{ color: 'teal' }}>empty</i>;
                } else {
                    return <code>{r.value}</code>;
                }
            },
        },
        {
            header: 'Org',
            width: 3,
            cell: (r) => {
                return <>{r.organisation?.name || 'any'}</>;
            },
        },
        {
            header: 'Franchise',
            width: 3,
            cell: (r) => {
                return <>{r.franchise?.name || 'any'}</>;
            },
        },
        {
            header: 'Role',
            width: 3,
            cell: (r) => {
                return <>{r.role?.name || 'any'}</>;
            },
        },
        {
            header: '',
            width: 1,
            cell: (r) => {
                return (
                    <div className={'gap-1 flex flex-row'}>
                        <Button size={'mini'} onClick={onEditVariation(r)} content={'Edit'} />
                        <ButtonDeleteVariation onClick={onDeleteVariation(r)} />
                    </div>
                );
            },
        },
    ];

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarItem onClick={goToList} icon={'chevron left'} title={'To List'} />
                    <MenuBarHeaderItem icon={'flag checkered'}>Feature Flag</MenuBarHeaderItem>
                </MenuBarSection>
            </StickyMenuBar>

            <Panel>
                <Panel.Header
                    content={'Feature Flag'}
                    button={{ content: 'Edit', onClick: modalEditFlagDescription.onOpen }}
                />
                <Panel.Body>
                    <Panel.Item label={'Tag'} content={node.tag} />
                    <Panel.ItemText label={'Description'} content={node.description} />
                </Panel.Body>
            </Panel>

            <SectionHeader
                button={{
                    color: 'green',
                    content: 'Add Variation',
                    icon: 'plus',
                    onClick: modalNewVariation.onOpen,
                }}
            >
                Variations
            </SectionHeader>

            <DefaultConnectionTable tableProps={{ color: 'grey' }} tableDef={tableDef} connectionData={variations} />

            <Modal {...modalNewVariation}>
                <FormNewFeatureFlagVariation
                    tagReadOnly={true}
                    initialValues={{ tag: node.tag, valueType: FeatureFlagValueType.SET }}
                    onCancel={modalNewVariation.onClose}
                    onSubmit={onCreateNewVariation}
                />
            </Modal>
            <Modal {...modalEditFlagDescription}>
                <FormEditDescription
                    initialValues={node}
                    onCancel={modalEditFlagDescription.onClose}
                    onSubmit={onSubmitUpdateDescription}
                />
            </Modal>
            <Modal {...modalEditVariation}>
                {editVariation && (
                    <FormUpdateFeatureFlagVariation
                        initialValues={{
                            ...editVariation,
                            valueType:
                                editVariation?.value === null ? FeatureFlagValueType.UNSET : FeatureFlagValueType.SET,
                        }}
                        onCancel={modalEditVariation.onClose}
                        onSubmit={onUpdateVariation}
                    />
                )}
            </Modal>
            {/*<DebugJSON data={viewer?.me} />*/}
        </PageLayout>
    );
};
