import { NodeType } from '@poolware/api';
import * as React from 'react';
import { Icon } from '@ez/components';

export interface OwnershipLabelProps {
    franchise?: NodeType.Franchise;
}

export const OwnershipLabel: React.FC<OwnershipLabelProps> = ({ franchise }) => {
    return (
        <>
            {franchise ? (
                <>
                    <Icon name={'building'} />
                    {franchise?.name}
                </>
            ) : (
                <>
                    <Icon name={'globe'} />
                    Global
                </>
            )}
        </>
    );
};
