import * as React from 'react';
import { MenuBar, PageLayout, StickyMenuBar } from '@ez/components';
import { ModuleNavLink, ModuleRoute } from '@poolware/react-app-navigator';
import BrandsTable from './BrandsTable';
import { ViewBrandDetailsPage } from './ViewBrandDetails';

const BrandListPage: React.FC = () => {
    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBar.Section>
                    <MenuBar.Item>
                        <ModuleNavLink exact={true} to={'/'}>
                            All Companies
                        </ModuleNavLink>
                    </MenuBar.Item>
                    <MenuBar.Item>
                        <ModuleNavLink exact={true} to={'/brands'}>
                            All Brands
                        </ModuleNavLink>
                    </MenuBar.Item>
                </MenuBar.Section>
            </StickyMenuBar>
            <BrandsTable companyId={undefined} />
        </PageLayout>
    );
};

export const ListBrands = () => {
    return (
        <>
            <ModuleRoute path={`/brands`} exact={true} component={BrandListPage} />
            <ModuleRoute path={`/brands/:brandId`} component={ViewBrandDetailsPage} />
        </>
    );
};
