import * as React from 'react';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, FormLabel, MenuBar, PageLayout, ScrollX, Table, toastError } from '@ez/components';
import Papa, { ParseResult } from 'papaparse';
import { CustomerCreateInput, ICustomerMutatorsProps, withCustomerMutators } from '@poolware/api';
import * as _ from 'lodash';
import { SuggestInputFranchise } from '@poolware/app-shell';
import { Alert, clsxm, Icon, Segment } from '@ez/components';

const CsvTable: React.FC<{ csv: ParseResult<any> }> = ({ csv }) => {
    const columnKeys = csv.meta.fields;
    const data = csv.data;

    return (
        <Table size={'small'} celled singleLine unstackable={true}>
            <Table.Header>
                <Table.Row>
                    {columnKeys.map((item) => {
                        return <Table.HeaderCell key={item}>{item}</Table.HeaderCell>;
                    })}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {data.map((row, i) => {
                    return (
                        <Table.Row key={i}>
                            {columnKeys.map((key, ii) => {
                                return <Table.Cell key={ii}>{row[key]}</Table.Cell>;
                            })}
                        </Table.Row>
                    );
                })}
            </Table.Body>
        </Table>
    );
};

const CsvSummary: React.FC<{ csv: ParseResult<any> }> = ({ csv }) => {
    return <Segment>Count: {csv.data.length}</Segment>;
};

const example = {
    company_name: '525 HLG p/l atf Fleming Investment Trust*',
    customer_group_name: 'Regular Service',
    email: 'stevenfleming4@me.com',
    first_name: 'Steven',
    last_name: 'Fleming*',
    mobile: '0402 345 476',
    phone: '',
    postal_address2: '21 Pelican Street',
    postal_country_id: 'AU',
    postal_postcode: '4573',
    postal_state: 'Qld',
    postal_suburb: 'Peregian Beach',
};

const requiredFields = [
    'company_name',
    'customer_group_name',
    'email',
    'first_name',
    'last_name',
    'mobile',
    'phone',
    'postal_address2',
    'postal_country_id',
    'postal_postcode',
    'postal_state',
    'postal_suburb',
];

const mapCustomerObjectToMutationConfig = (data: typeof example, franchiseId: string): CustomerCreateInput => {
    // @ts-ignore
    const conf: CustomerCreateInput = {
        note: data.customer_group_name,
        user: {
            companyName: data.company_name,
            firstName: data.first_name,
            lastName: data.last_name,
        },
        franchiseId: franchiseId,
        primaryAddress: {
            // poBoxNumber: data.
            destinationName: data.postal_address2,
            // buildingName: String
            // streetNumber: String
            // streetName: data.postal_address2,
            postCode: data.postal_postcode,
            state: data.postal_state,
            city: data.postal_suburb,
            // country: data.
        },
        contacts: [
            data.email && {
                data: data.email,
                type: { id: 'Q29udGFjdFR5cGU6Mw==' },
            },
            data.mobile && {
                data: data.mobile,
                type: { id: 'Q29udGFjdFR5cGU6MQ==' },
            },
            data.phone && {
                data: data.phone,
                type: { id: 'Q29udGFjdFR5cGU6Mg==' },
            },
        ],
    };
    return conf;
};

interface CsvFilePickerProps {
    onCsvLoad: (data: ParseResult<any>, fileName: string) => any;
}

const CsvFilePicker: React.FC<CsvFilePickerProps> = ({ onCsvLoad }) => {
    const onDrop = useCallback(
        (acceptedFiles) => {
            // console.log(acceptedFiles);
            const firstFile = acceptedFiles[0];
            Papa.parse(firstFile, {
                header: true,
                complete: function (results, file) {
                    // console.log('Parsing complete:', results, file);
                    onCsvLoad(results, file.name);
                },
            });
        },
        [onCsvLoad]
    );

    const { getRootProps, getInputProps, open } = useDropzone({ onDrop, multiple: false });
    return (
        <CsvFilePickerContainer {...getRootProps({ refKey: 'innerRef' })}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop csv file here, or click to select files</p>
            <button type="button" onClick={open}>
                Open File Dialog
            </button>
        </CsvFilePickerContainer>
    );
};

const CsvFilePickerContainer: React.FC<{ className?: string }> = ({ children, className, ...props }) => {
    return (
        <div className={clsxm('p-2 bg-gray-300 rounded border-dash', className)} {...props}>
            {children}
        </div>
    );
};

const DataImport: React.FC<ICustomerMutatorsProps> = ({ CustomerMutator }) => {
    const [csvData, setCsvData] = useState<ParseResult<any>>();
    const [csvFilename, setCsvFilename] = useState<string>();
    const [csvError, setCsvError] = useState<string>();
    const [_franchise, setFranchise] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [finished, setFinished] = useState(false);

    const onChangeFranchise = (item) => {
        setFranchise(item);
    };

    const resetState = () => {
        setCsvData(null);
        setCsvError(null);
        setUploading(false);
        setFinished(false);
        setCsvFilename(null);
    };

    const setUploadSuccess = () => {
        setFinished(true);
    };

    const createCustomers = async () => {
        setUploading(true);
        try {
            const inputs = csvData.data.map((data) => mapCustomerObjectToMutationConfig(data, _franchise.id));
            // console.log(inputs);
            // await wait(1000);
            const res = await CustomerMutator.createCustomers(inputs);
            // console.log(res);
            setUploadSuccess();
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to upload', description: e.message });
        }
        setUploading(false);
    };

    const validateResult = (parseResult: ParseResult<any>) => {
        if (parseResult.errors && parseResult.errors.length > 0) {
            return parseResult.errors.map((e) => e.message).join('\n');
        }
        const a1 = parseResult.meta.fields;
        const hasRequiredFields = _.isEqual(_.sortBy(a1), _.sortBy(requiredFields));
        if (!hasRequiredFields) {
            return 'Csv file is not formatted correctly.\nRequired fields: ' + requiredFields.join(', ');
        }

        if (parseResult.data.length > 100) {
            return `Csv file contains ${parseResult.data.length} customers. Max limit is 100.`;
        }

        return null;
    };

    const onCsvLoad = (parseResult: ParseResult<any>, filename: string) => {
        setCsvData(parseResult);
        setCsvFilename(filename);
        const errors = validateResult(parseResult);
        if (errors) {
            setCsvError(errors);
        }
    };

    const canUpload = !!_franchise && csvData && csvData.data.length > 0 && !csvError;
    const canClear = csvData || csvError;

    return (
        <PageLayout>
            <MenuBar.Root>
                <MenuBar.Section>
                    <MenuBar.HeaderItem>
                        <Icon name={'upload'} />
                        Customer Import
                    </MenuBar.HeaderItem>
                </MenuBar.Section>
            </MenuBar.Root>

            {finished ? (
                <Alert type={'success'}>
                    <Icon name={'checkmark'} color={'green'} /> Data uploaded successfully!!
                    <div className={'my-2'} />
                    <Button basic color={'teal'} onClick={resetState}>
                        Ok
                    </Button>
                </Alert>
            ) : (
                <PageLayout.BodySection width={'screen-md'} className={'grid gap-2'}>
                    <>
                        <Segment>
                            <FormLabel>Franchise</FormLabel>
                            <SuggestInputFranchise onChange={onChangeFranchise} value={_franchise} />
                        </Segment>
                        <Segment>
                            <FormLabel>CSV file</FormLabel>
                            {csvFilename ? (
                                <CsvFilePickerContainer>"{csvFilename}"</CsvFilePickerContainer>
                            ) : (
                                <CsvFilePicker onCsvLoad={onCsvLoad} />
                            )}
                        </Segment>
                        <Segment className={'gap-2 flex flex-row justify-between'}>
                            <Button disabled={!canClear || uploading} content={'Clear'} onClick={resetState} />
                            <Button
                                loading={uploading}
                                variant={'primary'}
                                disabled={!canUpload}
                                content={'Upload'}
                                onClick={createCustomers}
                            />
                        </Segment>
                    </>
                    {csvError && <Alert type={'error'} content={csvError} />}
                    {csvData && !csvError && (
                        <div>
                            <CsvSummary csv={csvData} />
                            <ScrollX style={{ maxHeight: '600px', fontSize: '0.9em' }}>
                                <CsvTable csv={csvData} />
                            </ScrollX>
                        </div>
                    )}
                </PageLayout.BodySection>
            )}
        </PageLayout>
    );
};

export default withCustomerMutators([])(DataImport);
