import * as React from 'react';
import { Button, Icon, Panel, Table } from '@ez/components';
import { PageControl } from './Page.Control';
import { ModuleLink } from '@poolware/react-app-navigator';
import { ProjectsURLBuilder } from '../index';
import { NodeType } from '@poolware/api';

class Page extends React.Component<PageControl> {
    view = (project: NodeType.Project) => {
        this.props.AppNavigator.navigate(ProjectsURLBuilder.ViewProject(project.id), { relativeToModule: true });
    };

    addNew = () => {
        this.props.AppNavigator.navigate(ProjectsURLBuilder.NewProject(), { setOrigin: true, relativeToModule: true });
    };

    render() {
        const { projects } = this.props;

        return (
            <Panel>
                <Panel.Header
                    content="Applications"
                    button={{
                        content: 'New',
                        color: 'green',
                        onClick: this.addNew,
                    }}
                />
                <Panel.Body>
                    <Table color="grey" size="small">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan="1" />
                                <Table.HeaderCell colSpan="1">Applications</Table.HeaderCell>
                                <Table.HeaderCell colSpan="1">Version</Table.HeaderCell>
                                <Table.HeaderCell colSpan="1">Platform</Table.HeaderCell>
                                <Table.HeaderCell colSpan="1">Download Link</Table.HeaderCell>
                                <Table.HeaderCell colSpan="1" />
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {projects.map((project) => {
                                const { enabled } = project;
                                const releaseNumber = project?.recommendedVersion?.releaseNumber;
                                const versionNumber = project?.recommendedVersion?.versionNumber;

                                return (
                                    <Table.Row key={project.id}>
                                        <Table.Cell width={1}>
                                            <Icon name={enabled ? 'eye' : 'hide'} color={enabled ? 'green' : 'grey'} />
                                        </Table.Cell>
                                        <Table.Cell>
                                            <ModuleLink to={ProjectsURLBuilder.ViewProject(project.id)}>
                                                {' '}
                                                {project.name}
                                            </ModuleLink>
                                        </Table.Cell>
                                        <Table.Cell width={'2'}>
                                            {releaseNumber && versionNumber && (
                                                <span>
                                                    #{releaseNumber} (v{versionNumber}){' '}
                                                </span>
                                            )}
                                        </Table.Cell>
                                        <Table.Cell>{project.platformKey}</Table.Cell>
                                        <Table.Cell>
                                            {project.fileURL && (
                                                <a rel="noopener noreferrer" href={project.fileURL} target={'_blank'}>
                                                    <Icon name={'external'} /> {project.fileURL}{' '}
                                                </a>
                                            )}
                                        </Table.Cell>
                                        <Table.Cell textAlign="right">
                                            <Button
                                                size={'tiny'}
                                                basic
                                                onClick={() => this.view(project)}
                                                content={'Details'}
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </Panel.Body>
            </Panel>
        );
    }
}

export default Page;
