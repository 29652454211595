import * as React from 'react';
import { useState } from 'react';
import { NodeType, RoleFlagMutatorAdded } from '@poolware/api';
import {
    confirmModalImperative,
    ConfirmProps,
    ConnectionTableDef,
    DefaultConnectionTable,
    MenuBar,
    Modal,
    PageLayout,
    StickyMenuBar,
    useModalCtrl,
    VStack,
} from '@ez/components';
import { FormEditFlag } from './FormEditFlag';
import { FeatureFlag } from '@poolware/app-shell';

export interface TableRowViewOrEditFlagProps extends RoleFlagMutatorAdded {
    flag: NodeType.RoleFlag;
}

export interface FlagsTableProps extends RoleFlagMutatorAdded {
    roleFlags: NodeType.RoleFlag[];
}

const cellPropsFn = (r) => {
    const isAdminFlag = r.tag === FeatureFlag.ADMIN;
    if (isAdminFlag) {
        return { disabled: true };
    }
    return undefined;
};

const confirmDelete: ConfirmProps = {
    confirmMessage: {
        header: 'Delete Flag',
        content: 'There is no UNDO!',
    },
    cancelButton: {
        content: 'Keep',
    },
    negative: true,
    confirmButton: {
        content: 'Delete',
        icon: 'trash',
    },
};

const FlagsTable: React.FC<FlagsTableProps> = (props) => {
    const { roleFlags } = props;
    const [editFlag, setEditFlag] = useState(null);
    const editModal = useModalCtrl();
    const newModal = useModalCtrl();

    const tableDef: ConnectionTableDef<NodeType.RoleFlag> = [
        {
            header: 'Tag',
            width: '1',
            cellProps: cellPropsFn,
            cell: (r) => {
                return <>{r.tag}</>;
            },
        },
        {
            header: 'Description',
            cellProps: cellPropsFn,
            cell: (r) => {
                return <>{r.description}</>;
            },
        },
    ];

    const onEdit = (flag) => {
        if (flag.tag === 'ADMIN_ACCESS') {
            return;
        }
        setEditFlag(flag);
        editModal.onOpen();
    };
    const onDone = () => {
        setEditFlag(false);
        editModal.onClose();
        newModal.onClose();
    };

    const onSubmitUpdate = async (values) => {
        await props.RoleFlagMutator.updateFlag(values, values.tag, values.description);
        onDone();
    };

    const onSubmitNew = async (values) => {
        await props.RoleFlagMutator.createFlag(values.tag, values.description);
        onDone();
    };

    const onDelete = async (flag) => {
        const answer = await confirmModalImperative(confirmDelete);
        if (!answer) {
            return;
        }
        await props.RoleFlagMutator.deleteFlag(flag);
        onDone();
    };

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBar.Section>
                    <MenuBar.HeaderItem icon={'flag'}>UI Flags</MenuBar.HeaderItem>
                </MenuBar.Section>
                <MenuBar.Section position={'right'}>
                    <MenuBar.Item icon={'plus'} color={'green'} onClick={newModal.onOpen}>
                        New
                    </MenuBar.Item>
                </MenuBar.Section>
            </StickyMenuBar>
            <DefaultConnectionTable
                tableProps={{ color: 'grey' }}
                onRowClick={onEdit}
                tableDef={tableDef}
                connectionData={roleFlags}
                connectionState={undefined}
            />
            {editFlag && (
                <Modal {...editModal}>
                    <FormEditFlag
                        headerButton={{ content: 'Delete', onClick: () => onDelete(editFlag) }}
                        debug={true}
                        initialValues={editFlag}
                        onSubmit={onSubmitUpdate}
                        onCancel={onDone}
                    />
                </Modal>
            )}
            <Modal {...newModal}>
                <FormEditFlag
                    debug={true}
                    initialValues={{ tag: '', description: '' }}
                    submitButton={{ content: 'Create' }}
                    onSubmit={onSubmitNew}
                    onCancel={onDone}
                />
            </Modal>
        </PageLayout>
    );
};

export default FlagsTable;
