import * as React from 'react';
import { useAppConfig } from '../app-config';
import { useViewer } from '../Viewer';
import { useApolloClient } from 'react-apollo';
import { Dropdown, Icon, Navbar } from '@ez/components';
import _truncate from 'lodash/truncate';

export const DropdownMenuAccount = () => {
    const client = useApolloClient();
    const appConfig = useAppConfig();
    const { checkingAuthState, Auth, viewer } = useViewer();
    const profileLInk = appConfig?.apps?.account || '/profile';

    const logOut = async () => {
        try {
            await Auth.AuthAction.logout();
            await client.resetStore();
        } catch (e) {
            console.error(e);
        }
    };

    const label = _truncate(Auth.AuthState.user.username, { length: 20 });
    const labelItem = _truncate(Auth.AuthState.user.username, { length: 40 });

    return (
        <>
            <Navbar.Dropdown.Menu
                icon={<Icon circular={true} name={'spy'} loading={checkingAuthState} />}
                label={label}
            >
                <Navbar.Dropdown.Label>{labelItem}</Navbar.Dropdown.Label>
                <Navbar.Dropdown.Separator />
                <Navbar.Dropdown.Item icon={<Icon name={'user'} />}>
                    <a href={profileLInk} className={'group-hover:text-accent-foreground'}>
                        My Profile
                    </a>
                </Navbar.Dropdown.Item>
                <Navbar.Dropdown.Separator />
                <Navbar.Dropdown.Item icon={<Icon name={'log out'} />} content="Log Out" onClick={logOut} />
            </Navbar.Dropdown.Menu>
        </>
    );
};
