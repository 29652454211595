import * as React from 'react';
import { ModuleRootSwitch, ModuleRoute } from '@poolware/react-app-navigator';
import List from './List';
import New from './New';
import View from './View';

const Router: React.FC = () => {
    return (
        <ModuleRootSwitch>
            <ModuleRoute path={'/new'} exact component={New} />
            <ModuleRoute path={'/:id'} exact component={View} />
            <ModuleRoute path={'/'} exact component={List} />
        </ModuleRootSwitch>
    );
};

export default Router;
