import * as React from 'react';
import { ModuleRootSwitch, ModuleRoute } from '@poolware/react-app-navigator';
import List from './List';
import { View } from './View';

export interface RouterProps {}

export const Router: React.FC<RouterProps> = () => {
    return (
        <ModuleRootSwitch>
            <ModuleRoute path={'/'} exact={true} component={List} />
            <ModuleRoute path={'/:id'} component={View} />
        </ModuleRootSwitch>
    );
};
