import gql from 'graphql-tag';
import { createUseQueryConnectionHook, NodeType } from '@poolware/api';

const QL = gql`
    query SentEmailQuery(
        $first: Int
        $page: Int
        $sort: EmailMessageViewerSentEmailsSort
        $search: EmailMessageViewerSentEmailsSearch
    ) {
        viewer {
            sentEmails(first: $first, page: $page, sort: $sort, search: $search) {
                edges {
                    node {
                        createdAt
                        id
                        isSent
                        subject
                        recipientEmail
                        type {
                            name
                            id
                        }
                        submittedBy {
                            id
                            franchise {
                                id
                                name
                            }
                            user {
                                id
                                firstName
                                lastName
                            }
                        }
                    }
                }
                pageMeta {
                    totalCount
                    pageCount
                }
                pageInfo {
                    startCursor
                    hasPreviousPage
                    hasNextPage
                    endCursor
                }
            }
        }
    }
`;

export const useQuerySentEmailsConnection = createUseQueryConnectionHook<
    NodeType.EmailMessage,
    NodeType.ViewerSentEmailsArgs['search']
>({ query: QL, connectionPath: 'viewer.sentEmails' });
