import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AppLayout } from './Layout/AppLayout';
import { routes } from './Pages/top-route-config';
import { AuthenticatedSwitch, NotFoundPage, useViewerContext } from '@poolware/app-shell';
import { useAppNavContext } from '@poolware/react-app-navigator';
import { useEffect } from 'react';

const modules = routes
    .filter((r) => !!r.moduleID)
    .reduce((prev, curr) => {
        prev[curr.moduleID] = curr.path;
        return prev;
    }, {});

const App: React.FC = () => {
    const viewerContext = useViewerContext();
    const appNavContext = useAppNavContext();
    useEffect(() => {
        appNavContext.registerModules(modules);
    }, []);

    return (
        <AuthenticatedSwitch>
            <AppLayout>
                {({ location }) => {
                    return (
                        <Switch location={location}>
                            {routes.map((r, index) => (
                                <Route exact={r.exact} key={index} path={r.path} component={r.component} />
                            ))}
                            <Route path={'**'} component={NotFoundPage} />
                        </Switch>
                    );
                }}
            </AppLayout>
            <Route path={'**'} component={NotFoundPage} />
        </AuthenticatedSwitch>
    );
};

export default App;
