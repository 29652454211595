//
// DO NOT EDIT!!!
// THIS IS AN AUTOMATICALLY GENERATED FILE.
// Date:  Tue Aug 13 2024 16:10:05 GMT+1000 (Australian Eastern Standard Time)
//

export enum FieldTypeRenderName {
    ID = 'ID',
    JSON = 'JSON',
    Date = 'Date',
    Int = 'Int',
    String = 'String',
    Boolean = 'Boolean',
    Object = 'Object',
    Unknown = 'Unknown',
    Address = 'Address',
    Staff = 'Staff',
    Entity = 'Entity',
    Franchise = 'Franchise',
    Customer = 'Customer',
    CheckMutations = 'CheckMutations',
    Enum = 'Enum',
}

// ************** abstractDocuments ***************** //

// abstractDocuments: ui config
export const uiConfigViewerAbstractDocumentsConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            dateDue: 'Boolean',
            first: 'ID',
            issuedAt: 'Boolean',
            refNumber: 'Boolean',
            updatedAt: 'Boolean',
        },
        search: {
            addedBy: 'StaffNodeMatchSearchCommon',
            completedAt: 'DateRangeSearchCommon',
            completedById: 'StaffNodeMatchSearchCommon',
            customer: 'CustomerNodeMatchSearchCommon',
            dateDue: 'DateRangeSearchCommon',
            franchise: 'FranchiseNodeMatchSearchCommon',
            issuedAt: 'DateRangeSearchCommon',
            refNumber: 'StringMatchSearchCommon',
            serviceJob: 'ServiceJobNodeMatchSearchCommon',
            statusTag: 'StringMatchSearchCommon',
            typeTag: 'StringMatchSearchCommon',
            workOrder: 'WorkOrderNodeMatchSearchCommon',
        },
    },
    node: {
        addedBy: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        completedAt: 'Date',
        completedById: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        completionNote: 'String',
        createdAt: 'Date',
        customer: {
            __typename: 'Customer',
            addedBy: 'Staff',
            assignedTo: 'Staff',
            billAddress: 'Address',
            checkMutations: 'CustomerMutationCheck',
            companyName: 'String',
            contactName: 'String',
            createdAt: 'Date',
            crn: 'String',
            id: 'ID',
            nextAppointment: 'AppointmentItem',
            note: 'String',
            primaryAddress: 'Address',
            primaryEmail: 'Contact',
            primaryPhone: 'Contact',
            refId: 'String',
            registeredAt: 'Date',
            root: 'Entity',
            user: 'User',
            vendLink: 'VendLink',
        },
        dateDue: 'Date',
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        id: 'ID',
        issuedAt: 'Date',
        jsonBody: 'JSON',
        refNumber: 'String',
        serviceJob: {
            __typename: 'ServiceJob',
            address: 'Address',
            archivedAt: 'Date',
            checkMutations: 'ServiceJobMutationCheck',
            closedAt: 'Date',
            createdAt: 'Date',
            createdBy: 'Staff',
            customer: 'Customer',
            description: 'String',
            dueDate: 'Date',
            entity: 'Entity',
            followedFrom: 'ServiceJob',
            group: 'ServiceJobGroup',
            id: 'ID',
            implicitType: 'ServiceJobImplicitTypeEnum',
            isImplicit: 'Boolean',
            isRecurring: 'Boolean',
            isSimpleMode: 'Boolean',
            lastAppointment: 'Appointment',
            lastAppointmentDate: 'Date',
            lastRecurrentAppointment: 'Appointment',
            pool: 'Pool',
            preferredContact: 'Contact',
            serviceJobNumber: 'String',
            staff: 'Staff',
            stage: 'ServiceJobStage',
            stageCandidates: 'ServiceJobStage',
            stageStatus: 'ServiceJobStageType',
            stopsAt: 'Date',
            title: 'String',
            updatedAt: 'Date',
            usedTemplate: 'ServiceJobTemplate',
        },
        statusTag: 'String',
        typeTag: 'String',
        updatedAt: 'Date',
        workOrder: {
            __typename: 'WorkOrder',
            address: 'Address',
            appointmentItem: 'AppointmentItem',
            archivedAt: 'Date',
            assignedTo: 'Staff',
            checkMutations: 'WorkOrderMutationCheck',
            checkedInAt: 'Date',
            checkedOutAt: 'Date',
            createdAt: 'Date',
            createdBy: 'Staff',
            customer: 'Customer',
            description: 'String',
            dueRangeEnd: 'Date',
            dueRangeStart: 'Date',
            followUpFrom: 'WorkOrder',
            id: 'ID',
            paymentUrl: 'String',
            pool: 'Pool',
            preferredContact: 'Contact',
            requiresAddress: 'Boolean',
            requiresAppointment: 'Boolean',
            requiresAssignedTo: 'Boolean',
            requiresPool: 'Boolean',
            requiresPreferredContact: 'Boolean',
            requiresTestReport: 'Boolean',
            requiresWorkFor: 'Boolean',
            serviceJob: 'ServiceJob',
            stage: 'ServiceJobStage',
            stageCandidates: 'ServiceJobStage',
            statusClosedAt: 'Date',
            title: 'String',
            updatedAt: 'Date',
            usedTemplate: 'WorkOrderTemplate',
            workFor: 'Entity',
            workOrderNumber: 'String',
        },
    },
};

// ************** announcements ***************** //

// announcements: ui config
export const uiConfigViewerAnnouncementsConnection = {
    meta: {
        search: {
            franchise: 'FranchiseNodeMatchSearchCommon',
            organisationType: 'OrganisationTypeNodeMatchSearchCommon',
            publishedAt: 'DateRangeSearchCommon',
        },
    },
    node: {
        addedBy: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        authorName: 'String',
        body: 'String',
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            publish: 'Boolean',
            unpublish: 'Boolean',
            update: 'Boolean',
        },
        createdAt: 'Date',
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        id: 'ID',
        link: 'String',
        organisationType: {
            __typename: 'OrganisationType',
            id: 'ID',
            locales: 'String',
            logo: 'String',
            name: 'String',
            timeZone: 'String',
            token: 'String',
        },
        preview: 'String',
        publishedAt: 'Date',
        subject: 'String',
    },
};

// ************** appointmentGroups ***************** //

// appointmentGroups: ui config
export const uiConfigViewerAppointmentGroupsConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            first: 'ID',
            priority: 'Boolean',
        },
        search: {
            anyString: 'String',
            franchise: 'FranchiseNodeMatchSearchCommon',
            title: 'StringLikeOrMatchSearchCommon',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        colorPalette: 'JSON',
        createdAt: 'Date',
        defaultColor: 'String',
        description: 'String',
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        id: 'ID',
        priority: 'Int',
        statusTitleCustom: 'String',
        statusTitleFailed: 'String',
        statusTitleFinished: 'String',
        statusTitlePending: 'String',
        statusTitleStarted: 'String',
        statusVisibilityCustom: 'Boolean',
        statusVisibilityStarted: 'Boolean',
        title: 'String',
        updatedAt: 'Date',
    },
};

// ************** appointmentItems ***************** //

// appointmentItems: ui config
export const uiConfigViewerAppointmentItemsConnection = {
    meta: {
        search: {
            serviceJob: 'AppointmentItemSearchCommonServiceJob',
            staff: 'AppointmentItemSearchCommonStaff',
            stageType: 'ServiceJobStageType',
            workOrder: 'AppointmentItemSearchCommonWorkOrder',
        },
    },
    node: {
        address: {
            __typename: 'Address',
            buildingName: 'String',
            checkMutations: 'AddressMutationCheck',
            city: 'String',
            country: 'String',
            createdAt: 'Date',
            destinationName: 'String',
            entity: 'Entity',
            id: 'ID',
            isBill: 'Boolean',
            isPrimary: 'Boolean',
            poBoxNumber: 'String',
            postCode: 'String',
            refId: 'String',
            state: 'String',
            streetName: 'String',
            streetNumber: 'String',
            suburb: 'String',
            uuid: 'String',
        },
        adhoc: 'Boolean',
        appointment: {
            __typename: 'Appointment',
            addedBy: 'Staff',
            baseInstance: 'AppointmentInstance',
            checkMutations: 'AppointmentMutationCheck',
            endPatternDate: 'Date',
            franchise: 'Franchise',
            id: 'ID',
            parentAppointment: 'Appointment',
            patternBlockId: 'String',
            recurrence: 'Recurrence',
            startDate: 'Date',
            workOrderTemplate: 'WorkOrderTemplate',
        },
        checkMutations: {
            __typename: 'CheckMutations',
            addSale: 'Boolean',
            changeRecurrence: 'Boolean',
            changeRecurrenceFrom: 'Boolean',
            delete: 'Boolean',
            startWorkOrder: 'Boolean',
            update: 'Boolean',
            updateFuture: 'Boolean',
            updateSingle: 'Boolean',
        },
        color: 'String',
        customer: {
            __typename: 'Customer',
            addedBy: 'Staff',
            assignedTo: 'Staff',
            billAddress: 'Address',
            checkMutations: 'CustomerMutationCheck',
            companyName: 'String',
            contactName: 'String',
            createdAt: 'Date',
            crn: 'String',
            id: 'ID',
            nextAppointment: 'AppointmentItem',
            note: 'String',
            primaryAddress: 'Address',
            primaryEmail: 'Contact',
            primaryPhone: 'Contact',
            refId: 'String',
            registeredAt: 'Date',
            root: 'Entity',
            user: 'User',
            vendLink: 'VendLink',
        },
        deleted: 'Boolean',
        duration: 'Int',
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        group: {
            __typename: 'AppointmentGroup',
            checkMutations: 'AppointmentGroupMutationCheck',
            colorPalette: 'JSON',
            createdAt: 'Date',
            defaultColor: 'String',
            description: 'String',
            franchise: 'Franchise',
            id: 'ID',
            priority: 'Int',
            statusTitleCustom: 'String',
            statusTitleFailed: 'String',
            statusTitleFinished: 'String',
            statusTitlePending: 'String',
            statusTitleStarted: 'String',
            statusVisibilityCustom: 'Boolean',
            statusVisibilityStarted: 'Boolean',
            title: 'String',
            updatedAt: 'Date',
        },
        id: 'ID',
        isEnd: 'Boolean',
        isRecurrentOrigin: 'Boolean',
        isRecurring: 'Boolean',
        isStart: 'Boolean',
        isWorkOrderPending: 'Boolean',
        note: 'String',
        originPattern: {
            __typename: 'RecurrencePattern',
            dayOfMonth: 'Int',
            daysOfWeek: 'WeekdayEnum',
            index: 'WeekIndexEnum',
            interval: 'Int',
            month: 'MonthEnum',
            type: 'PatternTypeEnum',
        },
        originalDate: 'Date',
        patternBlockId: 'String',
        paymentUrl: 'String',
        pool: {
            __typename: 'Pool',
            address: 'Address',
            attachedFlagFields: 'PoolAttachedFlagField',
            bottleNumber: 'String',
            checkMutations: 'PoolMutationCheck',
            createdAt: 'Date',
            dataOwnership: 'EntityOwnershipType',
            entity: 'Entity',
            franchise: 'Franchise',
            id: 'ID',
            installedSanitisers: 'Item',
            name: 'String',
            note: 'String',
            notePlain: 'String',
            refId: 'String',
            revision: 'Int',
            sanitiser: 'Sanitiser',
            site: 'Site',
            type: 'PoolType',
            uuid: 'String',
            volume: 'Float',
        },
        serviceJob: {
            __typename: 'ServiceJob',
            address: 'Address',
            archivedAt: 'Date',
            checkMutations: 'ServiceJobMutationCheck',
            closedAt: 'Date',
            createdAt: 'Date',
            createdBy: 'Staff',
            customer: 'Customer',
            description: 'String',
            dueDate: 'Date',
            entity: 'Entity',
            followedFrom: 'ServiceJob',
            group: 'ServiceJobGroup',
            id: 'ID',
            implicitType: 'ServiceJobImplicitTypeEnum',
            isImplicit: 'Boolean',
            isRecurring: 'Boolean',
            isSimpleMode: 'Boolean',
            lastAppointment: 'Appointment',
            lastAppointmentDate: 'Date',
            lastRecurrentAppointment: 'Appointment',
            pool: 'Pool',
            preferredContact: 'Contact',
            serviceJobNumber: 'String',
            staff: 'Staff',
            stage: 'ServiceJobStage',
            stageCandidates: 'ServiceJobStage',
            stageStatus: 'ServiceJobStageType',
            stopsAt: 'Date',
            title: 'String',
            updatedAt: 'Date',
            usedTemplate: 'ServiceJobTemplate',
        },
        splitAppointment: {
            __typename: 'Appointment',
            addedBy: 'Staff',
            baseInstance: 'AppointmentInstance',
            checkMutations: 'AppointmentMutationCheck',
            endPatternDate: 'Date',
            franchise: 'Franchise',
            id: 'ID',
            parentAppointment: 'Appointment',
            patternBlockId: 'String',
            recurrence: 'Recurrence',
            startDate: 'Date',
            workOrderTemplate: 'WorkOrderTemplate',
        },
        staff: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        startDate: 'Date',
        status: 'Enum',
        statusMap: {
            __typename: 'AppointmentStateMap',
            custom: 'AppointmentStateField',
            failed: 'AppointmentStateField',
            finished: 'AppointmentStateField',
            pending: 'AppointmentStateField',
            started: 'AppointmentStateField',
        },
        vendSale: {
            __typename: 'VendAppointmentSaleType',
            appointmentInstance: 'AppointmentInstance',
            appointmentItem: 'AppointmentItem',
            historyUrl: 'String',
            id: 'ID',
            vend: 'VendAPI',
            vendSale: 'VendSale',
            webRegistryUrl: 'String',
        },
        workOrder: {
            __typename: 'WorkOrder',
            address: 'Address',
            appointmentItem: 'AppointmentItem',
            archivedAt: 'Date',
            assignedTo: 'Staff',
            checkMutations: 'WorkOrderMutationCheck',
            checkedInAt: 'Date',
            checkedOutAt: 'Date',
            createdAt: 'Date',
            createdBy: 'Staff',
            customer: 'Customer',
            description: 'String',
            dueRangeEnd: 'Date',
            dueRangeStart: 'Date',
            followUpFrom: 'WorkOrder',
            id: 'ID',
            paymentUrl: 'String',
            pool: 'Pool',
            preferredContact: 'Contact',
            requiresAddress: 'Boolean',
            requiresAppointment: 'Boolean',
            requiresAssignedTo: 'Boolean',
            requiresPool: 'Boolean',
            requiresPreferredContact: 'Boolean',
            requiresTestReport: 'Boolean',
            requiresWorkFor: 'Boolean',
            serviceJob: 'ServiceJob',
            stage: 'ServiceJobStage',
            stageCandidates: 'ServiceJobStage',
            statusClosedAt: 'Date',
            title: 'String',
            updatedAt: 'Date',
            usedTemplate: 'WorkOrderTemplate',
            workFor: 'Entity',
            workOrderNumber: 'String',
        },
    },
};

// ************** appointments ***************** //

// appointments: ui config
export const uiConfigViewerAppointmentsConnection = {
    meta: {
        search: {
            addedBy: 'AppointmentViewerAppointmentsSearchAddedBy',
            franchise: 'FranchiseNodeMatchSearchCommon',
        },
    },
    node: {
        addedBy: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        baseInstance: {
            __typename: 'AppointmentInstance',
            address: 'Address',
            appointment: 'Appointment',
            checkMutations: 'AppointmentInstanceMutationCheck',
            color: 'String',
            completed: 'Boolean',
            createdAt: 'Date',
            customer: 'Customer',
            duration: 'Int',
            group: 'AppointmentGroup',
            id: 'ID',
            note: 'String',
            originalDate: 'Date',
            patternExcepted: 'Boolean',
            paymentUrl: 'String',
            pool: 'Pool',
            selectedColor: 'String',
            serviceJob: 'ServiceJob',
            site: 'Site',
            staff: 'Staff',
            startDate: 'Date',
            status: 'AppointmentStatusEnum',
            statusMap: 'AppointmentStateMap',
            vendSale: 'VendAppointmentSaleType',
            workOrder: 'WorkOrder',
        },
        checkMutations: {
            __typename: 'CheckMutations',
            changeEndDate: 'Boolean',
            delete: 'Boolean',
            split: 'Boolean',
            update: 'Boolean',
            updateTimeZone: 'Boolean',
        },
        endPatternDate: 'Date',
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        id: 'ID',
        parentAppointment: {
            __typename: 'Appointment',
            addedBy: 'Staff',
            baseInstance: 'AppointmentInstance',
            checkMutations: 'AppointmentMutationCheck',
            endPatternDate: 'Date',
            franchise: 'Franchise',
            id: 'ID',
            parentAppointment: 'Appointment',
            patternBlockId: 'String',
            recurrence: 'Recurrence',
            startDate: 'Date',
            workOrderTemplate: 'WorkOrderTemplate',
        },
        patternBlockId: 'String',
        recurrence: {
            __typename: 'Recurrence',
            pattern: 'RecurrencePattern',
            range: 'RecurrenceRange',
        },
        startDate: 'Date',
        workOrderTemplate: {
            __typename: 'WorkOrderTemplate',
            addedBy: 'Staff',
            archivedAt: 'Date',
            checkMutations: 'WorkOrderTemplateMutationCheck',
            createdAt: 'Date',
            defaultStage: 'ServiceJobStage',
            franchise: 'Franchise',
            group: 'ServiceJobGroup',
            hideStageActionRequired: 'Boolean',
            hideStageInProgress: 'Boolean',
            hideStageSuspended: 'Boolean',
            id: 'ID',
            onlyOwnedStages: 'Boolean',
            organisationType: 'OrganisationType',
            ownedByServiceJobTemplate: 'ServiceJobTemplate',
            providedDescription: 'String',
            providedTitle: 'String',
            requiresAddress: 'Boolean',
            requiresContact: 'Boolean',
            requiresCustomer: 'Boolean',
            requiresPool: 'Boolean',
            requiresStaff: 'Boolean',
            stageReport: 'ServiceJobStageTemplateOwnership',
            tag: 'String',
            templateDescription: 'String',
            templateTitle: 'String',
            updatedAt: 'Date',
        },
    },
};

// ************** brands ***************** //

// brands: ui config
export const uiConfigViewerBrandsConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            first: 'ID',
            name: 'Boolean',
            updatedAt: 'Boolean',
        },
        search: {
            anyString: 'String',
            company: 'BrandViewerBrandsSearchCompany',
            franchise: 'FranchiseNodeMatchSearchCommon',
            name: 'StringLikeOrMatchSearchCommon',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        company: {
            __typename: 'ProductCompany',
            checkMutations: 'ProductCompanyMutationCheck',
            createdAt: 'Date',
            description: 'String',
            franchise: 'Franchise',
            id: 'ID',
            name: 'String',
            parentCompany: 'ProductCompany',
            updatedAt: 'Date',
        },
        createdAt: 'Date',
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        id: 'ID',
        identification: 'String',
        name: 'String',
        updatedAt: 'Date',
    },
};

// ************** consumableProducts ***************** //

// consumableProducts: ui config
export const uiConfigViewerConsumableProductsConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            customSku: 'Boolean',
            first: 'ID',
            identification: 'Boolean',
            name: 'Boolean',
            sku: 'Boolean',
            updatedAt: 'Boolean',
        },
        search: {
            product: 'ConsumableProductViewerConsumableProductsSearchProduct',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        id: 'ID',
        product: {
            __typename: 'Product',
            addedBy: 'Staff',
            brand: 'Brand',
            brochure: 'ProductDocument',
            checkMutations: 'ProductMutationCheck',
            consumable: 'ConsumableProduct',
            createdAt: 'Date',
            customSku: 'String',
            description: 'String',
            familyCode: 'String',
            franchise: 'Franchise',
            id: 'ID',
            identification: 'String',
            installable: 'InstallableProduct',
            isGrouping: 'Boolean',
            isOneOff: 'Boolean',
            name: 'String',
            organisationType: 'OrganisationType',
            retiredAt: 'Date',
            retiredBy: 'Staff',
            sku: 'String',
            supplier: 'ProductCompany',
            updatedAt: 'Date',
        },
    },
};

// ************** contactTypes ***************** //

// contactTypes: ui config
export const uiConfigViewerContactTypesConnection = {
    meta: {
        sort: {
            first: 'ID',
            name: 'Boolean',
        },
        search: {
            identificationTag: 'StringMatchSearchCommon',
            name: 'StringMatchSearchCommon',
            validatorTag: 'StringMatchSearchCommon',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            update: 'Boolean',
        },
        iconPath: 'String',
        id: 'ID',
        identificationTag: 'String',
        name: 'String',
        refId: 'String',
        uuid: 'String',
        validatorTag: 'String',
    },
};

// ************** converters ***************** //

// converters: ui config
export const uiConfigViewerConvertersConnection = {
    meta: {
        search: {
            name: 'StringMatchSearchCommon',
            tag: 'StringMatchSearchCommon',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        id: 'ID',
        name: 'String',
        tag: 'String',
    },
};

// ************** customContracts ***************** //

// customContracts: ui config
export const uiConfigViewerCustomContractsConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            first: 'ID',
            startDate: 'Boolean',
            updatedAt: 'Boolean',
        },
        search: {
            addedBy: 'StaffNodeMatchSearchCommon',
            anyString: 'String',
            assignedTo: 'StaffNodeMatchSearchCommon',
            contractNumber: 'StringLikeOrMatchSearchCommon',
            endDate: 'DateRangeSearchCommon',
            entity: 'CustomContractViewerCustomContractsSearchEntity',
            startDate: 'DateRangeSearchCommon',
            status: 'CustomContractStatusEnumEnumMatchSearchCommon',
        },
    },
    node: {
        addedBy: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        assignedTo: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        contractNumber: 'String',
        createdAt: 'Date',
        endDate: 'Date',
        entity: {
            __typename: 'Entity',
            active: 'Boolean',
            chainUpdate: 'Date',
            checkMutations: 'EntityMutationCheck',
            dataOwnership: 'EntityOwnershipType',
            franchise: 'Franchise',
            id: 'ID',
            lastReportActivity: 'Date',
            serviceJob: 'ServiceJob',
            shop: 'FranchiseShop',
            user: 'User',
            uuid: 'String',
            warrantyClaim: 'WarrantyClaim',
        },
        frequency: 'String',
        id: 'ID',
        note: 'String',
        rate: 'Int',
        startDate: 'Date',
        status: 'Enum',
        term: 'String',
        terminationDate: 'Date',
        updatedAt: 'Date',
    },
};

// ************** customerTags ***************** //

// customerTags: ui config
export const uiConfigViewerCustomerTagsConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            first: 'ID',
            name: 'Boolean',
        },
        search: {
            anyString: 'String',
            franchise: 'FranchiseNodeMatchSearchCommon',
            name: 'StringLikeOrMatchSearchCommon',
            organisationType: 'OrganisationTypeNodeMatchSearchCommon',
        },
    },
    node: {
        addedBy: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        createdAt: 'Date',
        description: 'String',
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        id: 'ID',
        name: 'String',
        organisationType: {
            __typename: 'OrganisationType',
            id: 'ID',
            locales: 'String',
            logo: 'String',
            name: 'String',
            timeZone: 'String',
            token: 'String',
        },
    },
};

// ************** customers ***************** //

// customers: ui config
export const uiConfigViewerCustomersConnection = {
    meta: {
        sort: {
            chainUpdate: 'Boolean',
            contactName: 'Boolean',
            crn: 'Boolean',
            first: 'ID',
            firstName: 'Boolean',
            lastName: 'Boolean',
        },
        search: {
            addedBy: 'CustomerViewerCustomersSearchAddedBy',
            anyString: 'String',
            assignedTo: 'StaffNodeMatchSearchCommon',
            billAddress: 'CustomerViewerCustomersSearchBillAddress',
            companyName: 'StringLikeOrMatchSearchCommon',
            contactName: 'StringLikeOrMatchSearchCommon',
            createdAt: 'DateRangeSearchCommon',
            crn: 'StringLikeOrMatchSearchCommon',
            inVend: 'Boolean',
            primaryAddress: 'CustomerViewerCustomersSearchPrimaryAddress',
            tags: 'CustomerViewerCustomersSearchTags',
            user: 'CustomerViewerCustomersSearchUser',
        },
    },
    node: {
        addedBy: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        assignedTo: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        billAddress: {
            __typename: 'Address',
            buildingName: 'String',
            checkMutations: 'AddressMutationCheck',
            city: 'String',
            country: 'String',
            createdAt: 'Date',
            destinationName: 'String',
            entity: 'Entity',
            id: 'ID',
            isBill: 'Boolean',
            isPrimary: 'Boolean',
            poBoxNumber: 'String',
            postCode: 'String',
            refId: 'String',
            state: 'String',
            streetName: 'String',
            streetNumber: 'String',
            suburb: 'String',
            uuid: 'String',
        },
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        companyName: 'String',
        contactName: 'String',
        createdAt: 'Date',
        crn: 'String',
        id: 'ID',
        nextAppointment: {
            __typename: 'AppointmentItem',
            address: 'Address',
            adhoc: 'Boolean',
            appointment: 'Appointment',
            checkMutations: 'AppointmentItemMutationCheck',
            color: 'String',
            customer: 'Customer',
            deleted: 'Boolean',
            duration: 'Int',
            franchise: 'Franchise',
            group: 'AppointmentGroup',
            id: 'ID',
            isEnd: 'Boolean',
            isRecurrentOrigin: 'Boolean',
            isRecurring: 'Boolean',
            isStart: 'Boolean',
            isWorkOrderPending: 'Boolean',
            note: 'String',
            originPattern: 'RecurrencePattern',
            originalDate: 'Date',
            patternBlockId: 'String',
            paymentUrl: 'String',
            pool: 'Pool',
            serviceJob: 'ServiceJob',
            splitAppointment: 'Appointment',
            staff: 'Staff',
            startDate: 'Date',
            status: 'AppointmentStatusEnum',
            statusMap: 'AppointmentStateMap',
            vendSale: 'VendAppointmentSaleType',
            workOrder: 'WorkOrder',
        },
        note: 'String',
        primaryAddress: {
            __typename: 'Address',
            buildingName: 'String',
            checkMutations: 'AddressMutationCheck',
            city: 'String',
            country: 'String',
            createdAt: 'Date',
            destinationName: 'String',
            entity: 'Entity',
            id: 'ID',
            isBill: 'Boolean',
            isPrimary: 'Boolean',
            poBoxNumber: 'String',
            postCode: 'String',
            refId: 'String',
            state: 'String',
            streetName: 'String',
            streetNumber: 'String',
            suburb: 'String',
            uuid: 'String',
        },
        primaryEmail: {
            __typename: 'Contact',
            checkMutations: 'ContactMutationCheck',
            createdAt: 'Date',
            data: 'String',
            entity: 'Entity',
            id: 'ID',
            isPrimary: 'Boolean',
            label: 'String',
            refId: 'String',
            type: 'ContactType',
            updatedAt: 'Date',
            uuid: 'String',
        },
        primaryPhone: {
            __typename: 'Contact',
            checkMutations: 'ContactMutationCheck',
            createdAt: 'Date',
            data: 'String',
            entity: 'Entity',
            id: 'ID',
            isPrimary: 'Boolean',
            label: 'String',
            refId: 'String',
            type: 'ContactType',
            updatedAt: 'Date',
            uuid: 'String',
        },
        refId: 'String',
        registeredAt: 'Date',
        root: {
            __typename: 'Entity',
            active: 'Boolean',
            chainUpdate: 'Date',
            checkMutations: 'EntityMutationCheck',
            dataOwnership: 'EntityOwnershipType',
            franchise: 'Franchise',
            id: 'ID',
            lastReportActivity: 'Date',
            serviceJob: 'ServiceJob',
            shop: 'FranchiseShop',
            user: 'User',
            uuid: 'String',
            warrantyClaim: 'WarrantyClaim',
        },
        user: {
            __typename: 'User',
            checkMutations: 'UserMutationCheck',
            customer: 'Customer',
            entity: 'Entity',
            firstName: 'String',
            id: 'ID',
            lastName: 'String',
            staff: 'Staff',
            title: 'String',
        },
        vendLink: {
            __typename: 'VendLink',
            checkMutations: 'VendLinkMutationCheck',
            id: 'ID',
            isDeleted: 'Boolean',
            localCustomer: 'Customer',
            vendCustomer: 'VendCustomer',
            vendURL: 'String',
            vendUUID: 'String',
        },
    },
};

// ************** emailGlobalTemplateSelections ***************** //

// emailGlobalTemplateSelections: ui config
export const uiConfigViewerEmailGlobalTemplateSelectionsConnection = {
    meta: {
        search: {
            template: 'EmailTemplateNodeMatchSearchCommon',
            type: 'EmailTypeNodeMatchSearchCommon',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
        },
        id: 'ID',
        template: {
            __typename: 'EmailTemplate',
            checkMutations: 'EmailTemplateMutationCheck',
            createdAt: 'Date',
            franchise: 'Franchise',
            id: 'ID',
            isSelectedForOwnFranchise: 'Boolean',
            isSelectedForOwnOrganisation: 'Boolean',
            isSelectedGlobally: 'Boolean',
            name: 'String',
            organisation: 'OrganisationType',
            preview: 'EmailTemplateMock',
            provider: 'EmailTemplateProvider',
            selectedForFranchises: 'Franchise',
            selectedForOrganisations: 'OrganisationType',
            templateBody: 'String',
            templateMetadata: 'JSON',
            type: 'EmailType',
            updatedAt: 'Date',
        },
        type: {
            __typename: 'EmailType',
            defaultBody: 'String',
            id: 'ID',
            name: 'String',
            tagName: 'String',
        },
    },
};

// ************** emailOrganisationTemplateSelections ***************** //

// emailOrganisationTemplateSelections: ui config
export const uiConfigViewerEmailOrganisationTemplateSelectionsConnection = {
    meta: {
        sort: {
            first: 'ID',
        },
        search: {
            organisation: 'OrganisationTypeNodeMatchSearchCommon',
            template: 'EmailTemplateNodeMatchSearchCommon',
            type: 'EmailTypeNodeMatchSearchCommon',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
        },
        id: 'ID',
        organisation: {
            __typename: 'OrganisationType',
            id: 'ID',
            locales: 'String',
            logo: 'String',
            name: 'String',
            timeZone: 'String',
            token: 'String',
        },
        template: {
            __typename: 'EmailTemplate',
            checkMutations: 'EmailTemplateMutationCheck',
            createdAt: 'Date',
            franchise: 'Franchise',
            id: 'ID',
            isSelectedForOwnFranchise: 'Boolean',
            isSelectedForOwnOrganisation: 'Boolean',
            isSelectedGlobally: 'Boolean',
            name: 'String',
            organisation: 'OrganisationType',
            preview: 'EmailTemplateMock',
            provider: 'EmailTemplateProvider',
            selectedForFranchises: 'Franchise',
            selectedForOrganisations: 'OrganisationType',
            templateBody: 'String',
            templateMetadata: 'JSON',
            type: 'EmailType',
            updatedAt: 'Date',
        },
        type: {
            __typename: 'EmailType',
            defaultBody: 'String',
            id: 'ID',
            name: 'String',
            tagName: 'String',
        },
    },
};

// ************** emailTemplateSelections ***************** //

// emailTemplateSelections: ui config
export const uiConfigViewerEmailTemplateSelectionsConnection = {
    meta: {
        sort: {
            first: 'ID',
        },
        search: {
            franchise: 'FranchiseNodeMatchSearchCommon',
            template: 'EmailTemplateNodeMatchSearchCommon',
            type: 'EmailTypeNodeMatchSearchCommon',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
        },
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        id: 'ID',
        template: {
            __typename: 'EmailTemplate',
            checkMutations: 'EmailTemplateMutationCheck',
            createdAt: 'Date',
            franchise: 'Franchise',
            id: 'ID',
            isSelectedForOwnFranchise: 'Boolean',
            isSelectedForOwnOrganisation: 'Boolean',
            isSelectedGlobally: 'Boolean',
            name: 'String',
            organisation: 'OrganisationType',
            preview: 'EmailTemplateMock',
            provider: 'EmailTemplateProvider',
            selectedForFranchises: 'Franchise',
            selectedForOrganisations: 'OrganisationType',
            templateBody: 'String',
            templateMetadata: 'JSON',
            type: 'EmailType',
            updatedAt: 'Date',
        },
        type: {
            __typename: 'EmailType',
            defaultBody: 'String',
            id: 'ID',
            name: 'String',
            tagName: 'String',
        },
    },
};

// ************** emailTemplates ***************** //

// emailTemplates: ui config
export const uiConfigViewerEmailTemplatesConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            first: 'ID',
            updatedAt: 'Boolean',
        },
        search: {
            anyString: 'String',
            franchise: 'FranchiseNodeMatchSearchCommon',
            name: 'StringLikeOrMatchSearchCommon',
            organisation: 'OrganisationTypeNodeMatchSearchCommon',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            selectForFranchise: 'Boolean',
            selectForOrganisation: 'Boolean',
            selectGlobally: 'Boolean',
            unselectForFranchise: 'Boolean',
            unselectForOrganisation: 'Boolean',
            unselectGlobally: 'Boolean',
            update: 'Boolean',
        },
        createdAt: 'Date',
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        id: 'ID',
        isSelectedForOwnFranchise: 'Boolean',
        isSelectedForOwnOrganisation: 'Boolean',
        isSelectedGlobally: 'Boolean',
        name: 'String',
        organisation: {
            __typename: 'OrganisationType',
            id: 'ID',
            locales: 'String',
            logo: 'String',
            name: 'String',
            timeZone: 'String',
            token: 'String',
        },
        preview: {
            __typename: 'EmailTemplateMock',
            emailBody: 'String',
            error: 'String',
        },
        provider: 'Enum',
        selectedForFranchises: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        selectedForOrganisations: {
            __typename: 'OrganisationType',
            id: 'ID',
            locales: 'String',
            logo: 'String',
            name: 'String',
            timeZone: 'String',
            token: 'String',
        },
        templateBody: 'String',
        templateMetadata: 'JSON',
        type: {
            __typename: 'EmailType',
            defaultBody: 'String',
            id: 'ID',
            name: 'String',
            tagName: 'String',
        },
        updatedAt: 'Date',
    },
};

// ************** emailTypes ***************** //

// emailTypes: ui config
export const uiConfigViewerEmailTypesConnection = {
    meta: {},
    node: {
        defaultBody: 'String',
        id: 'ID',
        name: 'String',
        tagName: 'String',
    },
};

// ************** entities ***************** //

// entities: ui config
export const uiConfigViewerEntitiesConnection = {
    meta: {
        search: {
            addresses: 'EntityViewerEntitiesSearchAddresses',
            chainUpdate: 'DateRangeSearchCommon',
            contacts: 'EntityViewerEntitiesSearchContacts',
            franchise: 'FranchiseNodeMatchSearchCommon',
            pools: 'EntityViewerEntitiesSearchPools',
            serviceJob: 'EntityViewerEntitiesSearchServiceJob',
            sites: 'EntityViewerEntitiesSearchSites',
            user: 'EntityViewerEntitiesSearchUser',
            uuid: 'StringMatchSearchCommon',
        },
    },
    node: {
        active: 'Boolean',
        chainUpdate: 'Date',
        checkMutations: {
            __typename: 'CheckMutations',
            superDelete: 'Boolean',
            update: 'Boolean',
        },
        dataOwnership: 'Enum',
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        id: 'ID',
        lastReportActivity: 'Date',
        serviceJob: {
            __typename: 'ServiceJob',
            address: 'Address',
            archivedAt: 'Date',
            checkMutations: 'ServiceJobMutationCheck',
            closedAt: 'Date',
            createdAt: 'Date',
            createdBy: 'Staff',
            customer: 'Customer',
            description: 'String',
            dueDate: 'Date',
            entity: 'Entity',
            followedFrom: 'ServiceJob',
            group: 'ServiceJobGroup',
            id: 'ID',
            implicitType: 'ServiceJobImplicitTypeEnum',
            isImplicit: 'Boolean',
            isRecurring: 'Boolean',
            isSimpleMode: 'Boolean',
            lastAppointment: 'Appointment',
            lastAppointmentDate: 'Date',
            lastRecurrentAppointment: 'Appointment',
            pool: 'Pool',
            preferredContact: 'Contact',
            serviceJobNumber: 'String',
            staff: 'Staff',
            stage: 'ServiceJobStage',
            stageCandidates: 'ServiceJobStage',
            stageStatus: 'ServiceJobStageType',
            stopsAt: 'Date',
            title: 'String',
            updatedAt: 'Date',
            usedTemplate: 'ServiceJobTemplate',
        },
        shop: {
            __typename: 'FranchiseShop',
            address: 'Address',
            checkMutations: 'FranchiseShopMutationCheck',
            entity: 'Entity',
            id: 'ID',
            manager: 'Staff',
        },
        user: {
            __typename: 'User',
            checkMutations: 'UserMutationCheck',
            customer: 'Customer',
            entity: 'Entity',
            firstName: 'String',
            id: 'ID',
            lastName: 'String',
            staff: 'Staff',
            title: 'String',
        },
        uuid: 'String',
        warrantyClaim: {
            __typename: 'WarrantyClaim',
            addedBy: 'Staff',
            address: 'Address',
            assetInstallDate: 'Date',
            assetLocation: 'String',
            assetName: 'String',
            assetSerial: 'String',
            case: 'WarrantyClaimCase',
            checkMutations: 'WarrantyClaimMutationCheck',
            claimNumber: 'String',
            claimedAt: 'Date',
            comment: 'String',
            confirmedAt: 'Date',
            createdAt: 'Date',
            customerCompanyName: 'String',
            customerEmail: 'String',
            customerFirstName: 'String',
            customerLastName: 'String',
            customerPhone: 'String',
            emailCC: 'String',
            emailReplyTo: 'String',
            entity: 'Entity',
            externalRMA: 'String',
            externalSO: 'String',
            externalSRN: 'String',
            faultDescription: 'String',
            franchise: 'Franchise',
            id: 'ID',
            installerName: 'String',
            receiverEmail: 'String',
            receiverStaff: 'Staff',
            receiverWarranter: 'FranchiseWarranter',
            refAsset: 'Item',
            refCustomer: 'Customer',
            refSite: 'Site',
            siteAccessKey: 'String',
            status: 'WarrantyClaimStatusEnum',
            statusChangedAt: 'Date',
            statusGroup: 'WarrantyClaimStatusGroupEnum',
            statusMessage: 'String',
            submittedAt: 'Date',
            updatedAt: 'Date',
            warrantyClaimNumber: 'String',
        },
    },
};

// ************** ezcDeviceDefinitions ***************** //

// ezcDeviceDefinitions: ui config
export const uiConfigViewerEzcDeviceDefinitionsConnection = {
    meta: {},
    node: {
        definitionId: 'String',
        definitionVersion: 'String',
        familyId: 'String',
        familyVariation: 'String',
        id: 'ID',
        name: 'String',
        openAPIURL: 'String',
        presentationId: 'String',
        setupStrategyType: 'String',
    },
};

// ************** ezcDeviceLinks ***************** //

// ezcDeviceLinks: ui config
export const uiConfigViewerEzcDeviceLinksConnection = {
    meta: {
        sort: {
            archivedAt: 'Boolean',
            createdAt: 'Boolean',
            first: 'ID',
        },
        search: {
            archivedAt: 'DateRangeSearchCommon',
            device: 'EZCDeviceNodeMatchSearchCommon',
            franchise: 'FranchiseNodeMatchSearchCommon',
            owner: 'CustomerNodeMatchSearchCommon',
            product: 'ProductNodeMatchSearchCommon',
        },
    },
    node: {
        archivedAt: 'Date',
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        createdAt: 'Date',
        device: {
            __typename: 'EZCDevice',
            createdAt: 'Date',
            definition: 'EZCDeviceDefinition',
            deviceId: 'String',
            id: 'ID',
            title: 'String',
            version: 'String',
        },
        deviceName: 'String',
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        id: 'ID',
        item: {
            __typename: 'Item',
            checkMutations: 'ItemMutationCheck',
            createdAt: 'Date',
            id: 'ID',
            installedAt: 'Date',
            installedByStaff: 'Staff',
            label: 'String',
            note: 'String',
            owner: 'Entity',
            product: 'Product',
            serial: 'String',
            updatedAt: 'Date',
            uuid: 'String',
        },
        label: 'String',
        owner: {
            __typename: 'Customer',
            addedBy: 'Staff',
            assignedTo: 'Staff',
            billAddress: 'Address',
            checkMutations: 'CustomerMutationCheck',
            companyName: 'String',
            contactName: 'String',
            createdAt: 'Date',
            crn: 'String',
            id: 'ID',
            nextAppointment: 'AppointmentItem',
            note: 'String',
            primaryAddress: 'Address',
            primaryEmail: 'Contact',
            primaryPhone: 'Contact',
            refId: 'String',
            registeredAt: 'Date',
            root: 'Entity',
            user: 'User',
            vendLink: 'VendLink',
        },
        product: {
            __typename: 'Product',
            addedBy: 'Staff',
            brand: 'Brand',
            brochure: 'ProductDocument',
            checkMutations: 'ProductMutationCheck',
            consumable: 'ConsumableProduct',
            createdAt: 'Date',
            customSku: 'String',
            description: 'String',
            familyCode: 'String',
            franchise: 'Franchise',
            id: 'ID',
            identification: 'String',
            installable: 'InstallableProduct',
            isGrouping: 'Boolean',
            isOneOff: 'Boolean',
            name: 'String',
            organisationType: 'OrganisationType',
            retiredAt: 'Date',
            retiredBy: 'Staff',
            sku: 'String',
            supplier: 'ProductCompany',
            updatedAt: 'Date',
        },
        updatedAt: 'Date',
    },
};

// ************** ezcDevices ***************** //

// ezcDevices: ui config
export const uiConfigViewerEzcDevicesConnection = {
    meta: {},
    node: {
        createdAt: 'Date',
        definition: {
            __typename: 'EZCDeviceDefinition',
            definitionId: 'String',
            definitionVersion: 'String',
            familyId: 'String',
            familyVariation: 'String',
            id: 'ID',
            name: 'String',
            openAPIURL: 'String',
            presentationId: 'String',
            setupStrategyType: 'String',
        },
        deviceId: 'String',
        id: 'ID',
        title: 'String',
        version: 'String',
    },
};

// ************** ezcServiceProviderLinks ***************** //

// ezcServiceProviderLinks: ui config
export const uiConfigViewerEzcServiceProviderLinksConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            first: 'ID',
            updatedAt: 'Boolean',
        },
        search: {
            franchise: 'FranchiseNodeMatchSearchCommon',
            serviceProvider: 'EZCServiceProviderNodeMatchSearchCommon',
        },
    },
    node: {
        addedBy: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
        },
        createdAt: 'Date',
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        id: 'ID',
        label: 'String',
        serviceProvider: {
            __typename: 'EZCServiceProvider',
            createdAt: 'Date',
            id: 'ID',
            name: 'String',
            updatedAt: 'Date',
        },
        updatedAt: 'Date',
    },
};

// ************** ezcServiceProviders ***************** //

// ezcServiceProviders: ui config
export const uiConfigViewerEzcServiceProvidersConnection = {
    meta: {},
    node: {
        createdAt: 'Date',
        id: 'ID',
        name: 'String',
        updatedAt: 'Date',
    },
};

// ************** featureFlagVariations ***************** //

// featureFlagVariations: ui config
export const uiConfigViewerFeatureFlagVariationsConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            first: 'ID',
            tag: 'Boolean',
        },
        search: {
            franchise: 'FranchiseNodeMatchSearchCommon',
            organisation: 'OrganisationTypeNodeMatchSearchCommon',
            role: 'RoleNodeMatchSearchCommon',
        },
    },
    node: {
        active: 'Boolean',
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        createdAt: 'Date',
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        id: 'ID',
        organisation: {
            __typename: 'OrganisationType',
            id: 'ID',
            locales: 'String',
            logo: 'String',
            name: 'String',
            timeZone: 'String',
            token: 'String',
        },
        role: {
            __typename: 'Role',
            checkMutations: 'RoleMutationCheck',
            id: 'ID',
            name: 'String',
        },
        tag: 'String',
        value: 'String',
    },
};

// ************** featureFlags ***************** //

// featureFlags: ui config
export const uiConfigViewerFeatureFlagsConnection = {
    meta: {},
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        description: 'String',
        id: 'ID',
        tag: 'String',
        variations: {
            __typename: 'FeatureFlagVariation',
            active: 'Boolean',
            checkMutations: 'FeatureFlagVariationMutationCheck',
            createdAt: 'Date',
            franchise: 'Franchise',
            id: 'ID',
            organisation: 'OrganisationType',
            role: 'Role',
            tag: 'String',
            value: 'String',
        },
    },
};

// ************** files ***************** //

// files: ui config
export const uiConfigViewerFilesConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            first: 'ID',
        },
        search: {
            anyString: 'String',
            fileName: 'StringLikeOrMatchSearchCommon',
            franchise: 'FranchiseNodeMatchSearchCommon',
            organisationType: 'OrganisationTypeNodeMatchSearchCommon',
            uploadedBy: 'FileUploadViewerFilesSearchUploadedBy',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        createdAt: 'Date',
        fileName: 'String',
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        id: 'ID',
        imageUrl: 'String',
        isImage: 'Boolean',
        isPublic: 'Boolean',
        location: 'String',
        mimeType: 'String',
        note: 'String',
        organisationType: {
            __typename: 'OrganisationType',
            id: 'ID',
            locales: 'String',
            logo: 'String',
            name: 'String',
            timeZone: 'String',
            token: 'String',
        },
        uploadedBy: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        url: 'String',
    },
};

// ************** franchiseSequenceStore ***************** //

// franchiseSequenceStore: ui config
export const uiConfigViewerFranchiseSequenceStoreConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            first: 'ID',
            key: 'Boolean',
            updatedAt: 'Boolean',
        },
        search: {
            franchise: 'FranchiseNodeMatchSearchCommon',
            group: 'StringMatchSearchCommon',
            key: 'StringMatchSearchCommon',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            update: 'Boolean',
        },
        createdAt: 'Date',
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        group: 'String',
        id: 'ID',
        key: 'String',
        sequence: 'Int',
        updatedAt: 'Date',
    },
};

// ************** franchiseValuesStore ***************** //

// franchiseValuesStore: ui config
export const uiConfigViewerFranchiseValuesStoreConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            first: 'ID',
            key: 'Boolean',
            updatedAt: 'Boolean',
        },
        search: {
            franchise: 'FranchiseNodeMatchSearchCommon',
            group: 'StringMatchSearchCommon',
            key: 'StringMatchSearchCommon',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        createdAt: 'Date',
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        group: 'String',
        id: 'ID',
        key: 'String',
        updatedAt: 'Date',
        value: 'String',
    },
};

// ************** franchiseWarranters ***************** //

// franchiseWarranters: ui config
export const uiConfigViewerFranchiseWarrantersConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            first: 'ID',
            updatedAt: 'Boolean',
        },
        search: {
            anyString: 'String',
            disabled: 'BooleanMatchSearchCommon',
            email: 'StringLikeOrMatchSearchCommon',
            name: 'StringLikeOrMatchSearchCommon',
        },
    },
    node: {
        actingFranchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        createdAt: 'Date',
        disabled: 'Boolean',
        email: 'String',
        id: 'ID',
        name: 'String',
        updatedAt: 'Date',
    },
};

// ************** franchises ***************** //

// franchises: ui config
export const uiConfigViewerFranchisesConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            first: 'ID',
            name: 'Boolean',
            updatedAt: 'Boolean',
        },
        search: {
            anyString: 'String',
            contactExport: 'BooleanMatchSearchCommon',
            isWarranter: 'Boolean',
            manager: 'StaffNodeMatchSearchCommon',
            name: 'StringLikeOrMatchSearchCommon',
            organisationType: 'OrganisationTypeNodeMatchSearchCommon',
            parentFranchise: 'FranchiseNodeMatchSearchCommon',
            treatmentGroups: 'FranchiseViewerFranchisesSearchTreatmentGroups',
        },
    },
    node: {
        activities: {
            __typename: 'RecentActivity',
            franchise: 'Franchise',
            lastCustomerDate: 'Date',
            lastReportDate: 'Date',
        },
        address: {
            __typename: 'Address',
            buildingName: 'String',
            checkMutations: 'AddressMutationCheck',
            city: 'String',
            country: 'String',
            createdAt: 'Date',
            destinationName: 'String',
            entity: 'Entity',
            id: 'ID',
            isBill: 'Boolean',
            isPrimary: 'Boolean',
            poBoxNumber: 'String',
            postCode: 'String',
            refId: 'String',
            state: 'String',
            streetName: 'String',
            streetNumber: 'String',
            suburb: 'String',
            uuid: 'String',
        },
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            setParentFranchise: 'Boolean',
            update: 'Boolean',
        },
        contactExport: 'Boolean',
        createdAt: 'Date',
        currentLocales: {
            __typename: 'Locales',
            id: 'ID',
            locale: 'String',
        },
        currentTimezone: {
            __typename: 'Timezone',
            id: 'ID',
            localiseTime: 'String',
            timeZone: 'String',
        },
        customerCount: 'Int',
        email: 'String',
        ezcServiceProviderLink: {
            __typename: 'EZCFranchiseLink',
            addedBy: 'Staff',
            checkMutations: 'EZCFranchiseLinkMutationCheck',
            createdAt: 'Date',
            franchise: 'Franchise',
            id: 'ID',
            label: 'String',
            serviceProvider: 'EZCServiceProvider',
            updatedAt: 'Date',
        },
        fileSafeName: 'String',
        headOffice: {
            __typename: 'FranchiseShop',
            address: 'Address',
            checkMutations: 'FranchiseShopMutationCheck',
            entity: 'Entity',
            id: 'ID',
            manager: 'Staff',
        },
        id: 'ID',
        locales: {
            __typename: 'Locales',
            id: 'ID',
            locale: 'String',
        },
        manager: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        name: 'String',
        organisationType: {
            __typename: 'OrganisationType',
            id: 'ID',
            locales: 'String',
            logo: 'String',
            name: 'String',
            timeZone: 'String',
            token: 'String',
        },
        parentFranchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        phoneNumber: 'String',
        poolCount: 'Int',
        refId: 'String',
        staffCount: 'Int',
        timeZone: {
            __typename: 'Timezone',
            id: 'ID',
            localiseTime: 'String',
            timeZone: 'String',
        },
        types: 'Enum',
        updatedAt: 'Date',
        uuid: 'String',
        vend: {
            __typename: 'VendAPI',
            addedCustomerId: 'Customer',
            checkMutations: 'VendAPIMutationCheck',
            disabledAt: 'Date',
            franchise: 'Franchise',
            id: 'ID',
            key: 'String',
            shopUri: 'String',
            vendTest: 'Boolean',
        },
        waterTestBranding: 'Enum',
    },
};

// ************** heaterRecommendations ***************** //

// heaterRecommendations: ui config
export const uiConfigViewerHeaterRecommendationsConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            expiryDate: 'Boolean',
            first: 'ID',
            updatedAt: 'Boolean',
        },
        search: {
            addedBy: 'StaffNodeMatchSearchCommon',
            anyString: 'String',
            entity: 'HeaterRecommendationViewerHeaterRecommendationsSearchEntity',
            expiryDate: 'DateRangeSearchCommon',
            franchise: 'FranchiseNodeMatchSearchCommon',
            recNumber: 'StringLikeOrMatchSearchCommon',
            status: 'HeaterRecommendationStatusEnumEnumMatchSearchCommon',
        },
    },
    node: {
        addedBy: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        createdAt: 'Date',
        entity: {
            __typename: 'Entity',
            active: 'Boolean',
            chainUpdate: 'Date',
            checkMutations: 'EntityMutationCheck',
            dataOwnership: 'EntityOwnershipType',
            franchise: 'Franchise',
            id: 'ID',
            lastReportActivity: 'Date',
            serviceJob: 'ServiceJob',
            shop: 'FranchiseShop',
            user: 'User',
            uuid: 'String',
            warrantyClaim: 'WarrantyClaim',
        },
        expiryDate: 'Date',
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        id: 'ID',
        note: 'String',
        payload: 'JSON',
        product: {
            __typename: 'Product',
            addedBy: 'Staff',
            brand: 'Brand',
            brochure: 'ProductDocument',
            checkMutations: 'ProductMutationCheck',
            consumable: 'ConsumableProduct',
            createdAt: 'Date',
            customSku: 'String',
            description: 'String',
            familyCode: 'String',
            franchise: 'Franchise',
            id: 'ID',
            identification: 'String',
            installable: 'InstallableProduct',
            isGrouping: 'Boolean',
            isOneOff: 'Boolean',
            name: 'String',
            organisationType: 'OrganisationType',
            retiredAt: 'Date',
            retiredBy: 'Staff',
            sku: 'String',
            supplier: 'ProductCompany',
            updatedAt: 'Date',
        },
        recNumber: 'String',
        status: 'Enum',
        updatedAt: 'Date',
        urlPdf: 'String',
    },
};

// ************** installableProducts ***************** //

// installableProducts: ui config
export const uiConfigViewerInstallableProductsConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            customSku: 'Boolean',
            first: 'ID',
            identification: 'Boolean',
            name: 'Boolean',
            sku: 'Boolean',
            updatedAt: 'Boolean',
        },
        search: {
            product: 'InstallableProductViewerInstallableProductsSearchProduct',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        id: 'ID',
        product: {
            __typename: 'Product',
            addedBy: 'Staff',
            brand: 'Brand',
            brochure: 'ProductDocument',
            checkMutations: 'ProductMutationCheck',
            consumable: 'ConsumableProduct',
            createdAt: 'Date',
            customSku: 'String',
            description: 'String',
            familyCode: 'String',
            franchise: 'Franchise',
            id: 'ID',
            identification: 'String',
            installable: 'InstallableProduct',
            isGrouping: 'Boolean',
            isOneOff: 'Boolean',
            name: 'String',
            organisationType: 'OrganisationType',
            retiredAt: 'Date',
            retiredBy: 'Staff',
            sku: 'String',
            supplier: 'ProductCompany',
            updatedAt: 'Date',
        },
    },
};

// ************** jobTodoTemplates ***************** //

// jobTodoTemplates: ui config
export const uiConfigViewerJobTodoTemplatesConnection = {
    meta: {
        search: {
            anyString: 'String',
            archivedAt: 'DateRangeSearchCommon',
            description: 'StringLikeOrMatchSearchCommon',
            franchise: 'FranchiseNodeMatchSearchCommon',
            group: 'ServiceJobGroupNodeMatchSearchCommon',
            organisation: 'OrganisationTypeNodeMatchSearchCommon',
            ownedByWorkOrderTemplate: 'WorkOrderTemplateNodeMatchSearchCommon',
            title: 'StringLikeOrMatchSearchCommon',
        },
    },
    node: {
        archivedAt: 'Date',
        checkMutations: {
            __typename: 'CheckMutations',
            archive: 'Boolean',
            delete: 'Boolean',
            update: 'Boolean',
        },
        description: 'String',
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        group: {
            __typename: 'ServiceJobGroup',
            checkMutations: 'ServiceJobGroupMutationCheck',
            createdAt: 'Date',
            franchise: 'Franchise',
            id: 'ID',
            organisationType: 'OrganisationType',
            parent: 'ServiceJobGroup',
            priority: 'Int',
            tag: 'String',
            title: 'String',
        },
        id: 'ID',
        maxTimeEstimate: 'Int',
        minTimeEstimate: 'Int',
        organisation: {
            __typename: 'OrganisationType',
            id: 'ID',
            locales: 'String',
            logo: 'String',
            name: 'String',
            timeZone: 'String',
            token: 'String',
        },
        ownedByWorkOrderTemplate: {
            __typename: 'WorkOrderTemplate',
            addedBy: 'Staff',
            archivedAt: 'Date',
            checkMutations: 'WorkOrderTemplateMutationCheck',
            createdAt: 'Date',
            defaultStage: 'ServiceJobStage',
            franchise: 'Franchise',
            group: 'ServiceJobGroup',
            hideStageActionRequired: 'Boolean',
            hideStageInProgress: 'Boolean',
            hideStageSuspended: 'Boolean',
            id: 'ID',
            onlyOwnedStages: 'Boolean',
            organisationType: 'OrganisationType',
            ownedByServiceJobTemplate: 'ServiceJobTemplate',
            providedDescription: 'String',
            providedTitle: 'String',
            requiresAddress: 'Boolean',
            requiresContact: 'Boolean',
            requiresCustomer: 'Boolean',
            requiresPool: 'Boolean',
            requiresStaff: 'Boolean',
            stageReport: 'ServiceJobStageTemplateOwnership',
            tag: 'String',
            templateDescription: 'String',
            templateTitle: 'String',
            updatedAt: 'Date',
        },
        recommendedPrice: 'Int',
        timeEstimate: 'Int',
        title: 'String',
    },
};

// ************** locales ***************** //

// locales: ui config
export const uiConfigViewerLocalesConnection = {
    meta: {},
    node: {
        id: 'ID',
        locale: 'String',
    },
};

// ************** manualProblems ***************** //

// manualProblems: ui config
export const uiConfigViewerManualProblemsConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            first: 'ID',
        },
        search: {
            organisationType: 'OrganisationTypeNodeMatchSearchCommon',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        createdAt: 'Date',
        description: 'String',
        id: 'ID',
        name: 'String',
        organisationType: {
            __typename: 'OrganisationType',
            id: 'ID',
            locales: 'String',
            logo: 'String',
            name: 'String',
            timeZone: 'String',
            token: 'String',
        },
        solution: 'String',
    },
};

// ************** measurementTypes ***************** //

// measurementTypes: ui config
export const uiConfigViewerMeasurementTypesConnection = {
    meta: {
        sort: {
            first: 'ID',
            priority: 'Boolean',
        },
        search: {
            anyString: 'String',
            name: 'StringLikeOrMatchSearchCommon',
            priority: 'IntMatchSearchCommon',
            tagIdentifier: 'StringMatchSearchCommon',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        description: 'String',
        id: 'ID',
        linearConversion: {
            __typename: 'UnitConversion',
            checkMutations: 'UnitConversionMutationCheck',
            destination: 'Unit',
            factors: 'Float',
            id: 'ID',
            method: 'UnitConverter',
            source: 'Unit',
        },
        linearForm: {
            __typename: 'Measurement',
            checkMutations: 'MeasurementMutationCheck',
            description: 'String',
            id: 'ID',
            linearConversion: 'UnitConversion',
            linearForm: 'Measurement',
            name: 'String',
            priority: 'Int',
            solutionCoefficient: 'Float',
            tagIdentifier: 'String',
            unit: 'Unit',
        },
        name: 'String',
        priority: 'Int',
        solutionCoefficient: 'Float',
        tagIdentifier: 'String',
        unit: {
            __typename: 'Unit',
            checkMutations: 'UnitMutationCheck',
            id: 'ID',
            linearConversion: 'UnitConversion',
            linearForm: 'Unit',
            name: 'String',
        },
    },
};

// ************** measurementUnits ***************** //

// measurementUnits: ui config
export const uiConfigViewerMeasurementUnitsConnection = {
    meta: {
        sort: {
            first: 'ID',
            name: 'Boolean',
        },
        search: {
            linearForm: 'UnitNodeMatchSearchCommon',
            name: 'StringMatchSearchCommon',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            deleteUnit: 'Boolean',
            update: 'Boolean',
        },
        id: 'ID',
        linearConversion: {
            __typename: 'UnitConversion',
            checkMutations: 'UnitConversionMutationCheck',
            destination: 'Unit',
            factors: 'Float',
            id: 'ID',
            method: 'UnitConverter',
            source: 'Unit',
        },
        linearForm: {
            __typename: 'Unit',
            checkMutations: 'UnitMutationCheck',
            id: 'ID',
            linearConversion: 'UnitConversion',
            linearForm: 'Unit',
            name: 'String',
        },
        name: 'String',
    },
};

// ************** organisationTypes ***************** //

// organisationTypes: ui config
export const uiConfigViewerOrganisationTypesConnection = {
    meta: {},
    node: {
        id: 'ID',
        locales: 'String',
        logo: 'String',
        name: 'String',
        timeZone: 'String',
        token: 'String',
    },
};

// ************** permissions ***************** //

// permissions: ui config
export const uiConfigViewerPermissionsConnection = {
    meta: {
        search: {
            description: 'StringMatchSearchCommon',
            tag: 'StringMatchSearchCommon',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            update: 'Boolean',
        },
        description: 'String',
        id: 'ID',
        tag: 'String',
    },
};

// ************** poolBottleLeases ***************** //

// poolBottleLeases: ui config
export const uiConfigViewerPoolBottleLeasesConnection = {
    meta: {
        search: {
            bottle: 'PoolBottleLeaseViewerPoolBottleLeasesSearchBottle',
            leaseTo: 'EntityNodeMatchSearchCommon',
            leasedAt: 'DateRangeSearchCommon',
            leasedBy: 'StaffNodeMatchSearchCommon',
            pool: 'PoolNodeMatchSearchCommon',
            receivedBy: 'StaffNodeMatchSearchCommon',
            returnedAt: 'DateRangeSearchCommon',
        },
    },
    node: {
        bottle: {
            __typename: 'PoolBottle',
            checkMutations: 'PoolBottleMutationCheck',
            createdAt: 'Date',
            franchise: 'Franchise',
            id: 'ID',
            lastLease: 'PoolBottleLease',
            scanCode: 'String',
        },
        checkMutations: {
            __typename: 'CheckMutations',
            processReturn: 'Boolean',
        },
        id: 'ID',
        leaseTo: {
            __typename: 'Entity',
            active: 'Boolean',
            chainUpdate: 'Date',
            checkMutations: 'EntityMutationCheck',
            dataOwnership: 'EntityOwnershipType',
            franchise: 'Franchise',
            id: 'ID',
            lastReportActivity: 'Date',
            serviceJob: 'ServiceJob',
            shop: 'FranchiseShop',
            user: 'User',
            uuid: 'String',
            warrantyClaim: 'WarrantyClaim',
        },
        leasedAt: 'Date',
        leasedBy: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        pool: {
            __typename: 'Pool',
            address: 'Address',
            attachedFlagFields: 'PoolAttachedFlagField',
            bottleNumber: 'String',
            checkMutations: 'PoolMutationCheck',
            createdAt: 'Date',
            dataOwnership: 'EntityOwnershipType',
            entity: 'Entity',
            franchise: 'Franchise',
            id: 'ID',
            installedSanitisers: 'Item',
            name: 'String',
            note: 'String',
            notePlain: 'String',
            refId: 'String',
            revision: 'Int',
            sanitiser: 'Sanitiser',
            site: 'Site',
            type: 'PoolType',
            uuid: 'String',
            volume: 'Float',
        },
        receivedBy: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        returnedAt: 'Date',
    },
};

// ************** poolBottles ***************** //

// poolBottles: ui config
export const uiConfigViewerPoolBottlesConnection = {
    meta: {
        search: {
            franchise: 'FranchiseNodeMatchSearchCommon',
            scanCode: 'StringMatchSearchCommon',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            processReturn: 'Boolean',
        },
        createdAt: 'Date',
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        id: 'ID',
        lastLease: {
            __typename: 'PoolBottleLease',
            bottle: 'PoolBottle',
            checkMutations: 'PoolBottleLeaseMutationCheck',
            id: 'ID',
            leaseTo: 'Entity',
            leasedAt: 'Date',
            leasedBy: 'Staff',
            pool: 'Pool',
            receivedBy: 'Staff',
            returnedAt: 'Date',
        },
        scanCode: 'String',
    },
};

// ************** poolDeclarationFlagFields ***************** //

// poolDeclarationFlagFields: ui config
export const uiConfigViewerPoolDeclarationFlagFieldsConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            first: 'ID',
            priority: 'Boolean',
        },
        search: {
            name: 'StringMatchSearchCommon',
            organisation: 'OrganisationTypeNodeMatchSearchCommon',
        },
    },
    node: {
        activatedAt: 'Date',
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            setActive: 'Boolean',
            update: 'Boolean',
        },
        createdAt: 'Date',
        description: 'String',
        exportName: 'String',
        id: 'ID',
        name: 'String',
        organisation: {
            __typename: 'OrganisationType',
            id: 'ID',
            locales: 'String',
            logo: 'String',
            name: 'String',
            timeZone: 'String',
            token: 'String',
        },
        priority: 'Int',
        tag: 'String',
        updatedAt: 'Date',
        useInExport: 'Boolean',
    },
};

// ************** poolProductDeclarations ***************** //

// poolProductDeclarations: ui config
export const uiConfigViewerPoolProductDeclarationsConnection = {
    meta: {
        sort: {
            first: 'ID',
            priority: 'Boolean',
        },
        search: {
            declaration: 'PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclaration',
            optional: 'BooleanMatchSearchCommon',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        declaration: {
            __typename: 'ProductTraitDeclaration',
            checkMutations: 'ProductTraitDeclarationMutationCheck',
            createdAt: 'Date',
            directChildren: 'ProductTraitDeclaration',
            franchise: 'Franchise',
            id: 'ID',
            isCategory: 'Boolean',
            name: 'String',
            organisationType: 'OrganisationType',
            parent: 'ProductTraitDeclaration',
            subDeclarations: 'ProductTraitDeclaration',
            tag: 'String',
            updatedAt: 'Date',
        },
        id: 'ID',
        name: 'String',
        optional: 'Boolean',
        organisationType: {
            __typename: 'OrganisationType',
            id: 'ID',
            locales: 'String',
            logo: 'String',
            name: 'String',
            timeZone: 'String',
            token: 'String',
        },
        priority: 'Int',
    },
};

// ************** poolSanitisers ***************** //

// poolSanitisers: ui config
export const uiConfigViewerPoolSanitisersConnection = {
    meta: {
        sort: {
            first: 'ID',
            name: 'Boolean',
            priority: 'Boolean',
            publishedAt: 'Boolean',
        },
        search: {
            anyString: 'String',
            criteria: 'SanitiserViewerPoolSanitisersSearchCriteria',
            name: 'StringLikeOrMatchSearchCommon',
            organisationType: 'OrganisationTypeNodeMatchSearchCommon',
            publishedAt: 'DateRangeSearchCommon',
            relatedDeclarations: 'SanitiserViewerPoolSanitisersSearchRelatedDeclarations',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            changePublishedStatus: 'Boolean',
            delete: 'Boolean',
            update: 'Boolean',
        },
        id: 'ID',
        name: 'String',
        organisationType: {
            __typename: 'OrganisationType',
            id: 'ID',
            locales: 'String',
            logo: 'String',
            name: 'String',
            timeZone: 'String',
            token: 'String',
        },
        priority: 'Int',
        publishedAt: 'Date',
        refId: 'String',
        reportName: 'String',
        uuid: 'String',
    },
};

// ************** poolTransfers ***************** //

// poolTransfers: ui config
export const uiConfigViewerPoolTransfersConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            first: 'ID',
        },
        search: {
            pool: 'PoolOwnershipHistoryViewerPoolTransfersSearchPool',
            transferredBy: 'StaffNodeMatchSearchCommon',
            transferredFrom: 'EntityNodeMatchSearchCommon',
            transferredTo: 'EntityNodeMatchSearchCommon',
        },
    },
    node: {
        createdAt: 'Date',
        id: 'ID',
        pool: {
            __typename: 'Pool',
            address: 'Address',
            attachedFlagFields: 'PoolAttachedFlagField',
            bottleNumber: 'String',
            checkMutations: 'PoolMutationCheck',
            createdAt: 'Date',
            dataOwnership: 'EntityOwnershipType',
            entity: 'Entity',
            franchise: 'Franchise',
            id: 'ID',
            installedSanitisers: 'Item',
            name: 'String',
            note: 'String',
            notePlain: 'String',
            refId: 'String',
            revision: 'Int',
            sanitiser: 'Sanitiser',
            site: 'Site',
            type: 'PoolType',
            uuid: 'String',
            volume: 'Float',
        },
        transferredBy: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        transferredFrom: {
            __typename: 'Entity',
            active: 'Boolean',
            chainUpdate: 'Date',
            checkMutations: 'EntityMutationCheck',
            dataOwnership: 'EntityOwnershipType',
            franchise: 'Franchise',
            id: 'ID',
            lastReportActivity: 'Date',
            serviceJob: 'ServiceJob',
            shop: 'FranchiseShop',
            user: 'User',
            uuid: 'String',
            warrantyClaim: 'WarrantyClaim',
        },
        transferredTo: {
            __typename: 'Entity',
            active: 'Boolean',
            chainUpdate: 'Date',
            checkMutations: 'EntityMutationCheck',
            dataOwnership: 'EntityOwnershipType',
            franchise: 'Franchise',
            id: 'ID',
            lastReportActivity: 'Date',
            serviceJob: 'ServiceJob',
            shop: 'FranchiseShop',
            user: 'User',
            uuid: 'String',
            warrantyClaim: 'WarrantyClaim',
        },
    },
};

// ************** poolTypes ***************** //

// poolTypes: ui config
export const uiConfigViewerPoolTypesConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            first: 'ID',
            name: 'Boolean',
            priority: 'Boolean',
            publishedAt: 'Boolean',
            updatedAt: 'Boolean',
        },
        search: {
            anyString: 'String',
            createdAt: 'DateRangeSearchCommon',
            criteria: 'PoolTypeViewerPoolTypesSearchCriteria',
            name: 'StringLikeOrMatchSearchCommon',
            organisationType: 'OrganisationTypeNodeMatchSearchCommon',
            pools: 'PoolTypeViewerPoolTypesSearchPools',
            publishedAt: 'DateRangeSearchCommon',
            updatedAt: 'DateRangeSearchCommon',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            changePublishedStatus: 'Boolean',
            delete: 'Boolean',
            update: 'Boolean',
        },
        createdAt: 'Date',
        description: 'String',
        id: 'ID',
        name: 'String',
        organisationType: {
            __typename: 'OrganisationType',
            id: 'ID',
            locales: 'String',
            logo: 'String',
            name: 'String',
            timeZone: 'String',
            token: 'String',
        },
        priority: 'Int',
        publishedAt: 'Date',
        refId: 'String',
        updatedAt: 'Date',
        uuid: 'String',
    },
};

// ************** pools ***************** //

// pools: ui config
export const uiConfigViewerPoolsConnection = {
    meta: {
        sort: {
            bottleNumber: 'Boolean',
            createdAt: 'Boolean',
            first: 'ID',
            volume: 'Boolean',
        },
        search: {
            anyString: 'String',
            bottleNumber: 'StringLikeOrMatchSearchCommon',
            createdAt: 'DateRangeSearchCommon',
            entity: 'PoolViewerPoolsSearchEntity',
            name: 'StringLikeOrMatchSearchCommon',
            ownershipHistory: 'PoolViewerPoolsSearchOwnershipHistory',
            reports: 'PoolViewerPoolsSearchReports',
            sanitiser: 'PoolViewerPoolsSearchSanitiser',
            site: 'PoolViewerPoolsSearchSite',
            type: 'PoolViewerPoolsSearchType',
            volume: 'FloatRangeSearchCommon',
        },
    },
    node: {
        address: {
            __typename: 'Address',
            buildingName: 'String',
            checkMutations: 'AddressMutationCheck',
            city: 'String',
            country: 'String',
            createdAt: 'Date',
            destinationName: 'String',
            entity: 'Entity',
            id: 'ID',
            isBill: 'Boolean',
            isPrimary: 'Boolean',
            poBoxNumber: 'String',
            postCode: 'String',
            refId: 'String',
            state: 'String',
            streetName: 'String',
            streetNumber: 'String',
            suburb: 'String',
            uuid: 'String',
        },
        attachedFlagFields: {
            __typename: 'PoolAttachedFlagField',
            checkMutations: 'PoolAttachedFlagFieldMutationCheck',
            createdAt: 'Date',
            fieldDeclaration: 'PoolDeclarationFlagField',
            franchise: 'Franchise',
            id: 'ID',
            pool: 'Pool',
            setBy: 'Staff',
            updatedAt: 'Date',
            value: 'Boolean',
        },
        bottleNumber: 'String',
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            transferToSite: 'Boolean',
            update: 'Boolean',
        },
        createdAt: 'Date',
        dataOwnership: 'Enum',
        entity: {
            __typename: 'Entity',
            active: 'Boolean',
            chainUpdate: 'Date',
            checkMutations: 'EntityMutationCheck',
            dataOwnership: 'EntityOwnershipType',
            franchise: 'Franchise',
            id: 'ID',
            lastReportActivity: 'Date',
            serviceJob: 'ServiceJob',
            shop: 'FranchiseShop',
            user: 'User',
            uuid: 'String',
            warrantyClaim: 'WarrantyClaim',
        },
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        id: 'ID',
        installedSanitisers: {
            __typename: 'Item',
            checkMutations: 'ItemMutationCheck',
            createdAt: 'Date',
            id: 'ID',
            installedAt: 'Date',
            installedByStaff: 'Staff',
            label: 'String',
            note: 'String',
            owner: 'Entity',
            product: 'Product',
            serial: 'String',
            updatedAt: 'Date',
            uuid: 'String',
        },
        name: 'String',
        note: 'String',
        notePlain: 'String',
        refId: 'String',
        revision: 'Int',
        sanitiser: {
            __typename: 'Sanitiser',
            checkMutations: 'SanitiserMutationCheck',
            id: 'ID',
            name: 'String',
            organisationType: 'OrganisationType',
            priority: 'Int',
            publishedAt: 'Date',
            refId: 'String',
            reportName: 'String',
            uuid: 'String',
        },
        site: {
            __typename: 'Site',
            accessKeyLocation: 'String',
            address: 'Address',
            checkMutations: 'SiteMutationCheck',
            createdAt: 'Date',
            dataOwnership: 'EntityOwnershipType',
            entity: 'Entity',
            id: 'ID',
            name: 'String',
            refId: 'String',
            updatedAt: 'Date',
            uuid: 'String',
        },
        type: {
            __typename: 'PoolType',
            checkMutations: 'PoolTypeMutationCheck',
            createdAt: 'Date',
            description: 'String',
            id: 'ID',
            name: 'String',
            organisationType: 'OrganisationType',
            priority: 'Int',
            publishedAt: 'Date',
            refId: 'String',
            updatedAt: 'Date',
            uuid: 'String',
        },
        uuid: 'String',
        volume: 'Float',
    },
};

// ************** productCompany ***************** //

// productCompany: ui config
export const uiConfigViewerProductCompanyConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            first: 'ID',
            updatedAt: 'Boolean',
        },
        search: {
            anyString: 'String',
            franchise: 'FranchiseNodeMatchSearchCommon',
            name: 'StringLikeOrMatchSearchCommon',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        createdAt: 'Date',
        description: 'String',
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        id: 'ID',
        name: 'String',
        parentCompany: {
            __typename: 'ProductCompany',
            checkMutations: 'ProductCompanyMutationCheck',
            createdAt: 'Date',
            description: 'String',
            franchise: 'Franchise',
            id: 'ID',
            name: 'String',
            parentCompany: 'ProductCompany',
            updatedAt: 'Date',
        },
        updatedAt: 'Date',
    },
};

// ************** productDeclarations ***************** //

// productDeclarations: ui config
export const uiConfigViewerProductDeclarationsConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            first: 'ID',
            name: 'Boolean',
            updatedAt: 'Boolean',
        },
        search: {
            createdAt: 'DateRangeSearchCommon',
            franchise: 'FranchiseNodeMatchSearchCommon',
            name: 'StringMatchSearchCommon',
            organisationType: 'OrganisationTypeNodeMatchSearchCommon',
            parent: 'ProductTraitDeclarationNodeMatchSearchCommon',
            products: 'ProductTraitDeclarationViewerProductDeclarationsSearchProducts',
            relatedSanitisers: 'ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisers',
            tag: 'StringMatchSearchCommon',
            updatedAt: 'DateRangeSearchCommon',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        createdAt: 'Date',
        directChildren: {
            __typename: 'ProductTraitDeclaration',
            checkMutations: 'ProductTraitDeclarationMutationCheck',
            createdAt: 'Date',
            directChildren: 'ProductTraitDeclaration',
            franchise: 'Franchise',
            id: 'ID',
            isCategory: 'Boolean',
            name: 'String',
            organisationType: 'OrganisationType',
            parent: 'ProductTraitDeclaration',
            subDeclarations: 'ProductTraitDeclaration',
            tag: 'String',
            updatedAt: 'Date',
        },
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        id: 'ID',
        isCategory: 'Boolean',
        name: 'String',
        organisationType: {
            __typename: 'OrganisationType',
            id: 'ID',
            locales: 'String',
            logo: 'String',
            name: 'String',
            timeZone: 'String',
            token: 'String',
        },
        parent: {
            __typename: 'ProductTraitDeclaration',
            checkMutations: 'ProductTraitDeclarationMutationCheck',
            createdAt: 'Date',
            directChildren: 'ProductTraitDeclaration',
            franchise: 'Franchise',
            id: 'ID',
            isCategory: 'Boolean',
            name: 'String',
            organisationType: 'OrganisationType',
            parent: 'ProductTraitDeclaration',
            subDeclarations: 'ProductTraitDeclaration',
            tag: 'String',
            updatedAt: 'Date',
        },
        subDeclarations: {
            __typename: 'ProductTraitDeclaration',
            checkMutations: 'ProductTraitDeclarationMutationCheck',
            createdAt: 'Date',
            directChildren: 'ProductTraitDeclaration',
            franchise: 'Franchise',
            id: 'ID',
            isCategory: 'Boolean',
            name: 'String',
            organisationType: 'OrganisationType',
            parent: 'ProductTraitDeclaration',
            subDeclarations: 'ProductTraitDeclaration',
            tag: 'String',
            updatedAt: 'Date',
        },
        tag: 'String',
        updatedAt: 'Date',
    },
};

// ************** productDocuments ***************** //

// productDocuments: ui config
export const uiConfigViewerProductDocumentsConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            first: 'ID',
            title: 'Boolean',
            updatedAt: 'Boolean',
        },
        search: {
            anyString: 'String',
            brand: 'BrandNodeMatchSearchCommon',
            company: 'ProductCompanyNodeMatchSearchCommon',
            fileName: 'StringLikeOrMatchSearchCommon',
            franchise: 'FranchiseNodeMatchSearchCommon',
            organisationType: 'OrganisationTypeNodeMatchSearchCommon',
            type: 'ProductDocumentTypeEnumMatchSearchCommon',
            uploadedBy: 'ProductDocumentViewerProductDocumentsSearchUploadedBy',
        },
    },
    node: {
        brand: {
            __typename: 'Brand',
            checkMutations: 'BrandMutationCheck',
            company: 'ProductCompany',
            createdAt: 'Date',
            franchise: 'Franchise',
            id: 'ID',
            identification: 'String',
            name: 'String',
            updatedAt: 'Date',
        },
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        company: {
            __typename: 'ProductCompany',
            checkMutations: 'ProductCompanyMutationCheck',
            createdAt: 'Date',
            description: 'String',
            franchise: 'Franchise',
            id: 'ID',
            name: 'String',
            parentCompany: 'ProductCompany',
            updatedAt: 'Date',
        },
        createdAt: 'Date',
        fileName: 'String',
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        id: 'ID',
        imageUrl: 'String',
        isImage: 'Boolean',
        isPublic: 'Boolean',
        location: 'String',
        mimeType: 'String',
        note: 'String',
        organisationType: {
            __typename: 'OrganisationType',
            id: 'ID',
            locales: 'String',
            logo: 'String',
            name: 'String',
            timeZone: 'String',
            token: 'String',
        },
        title: 'String',
        type: 'Enum',
        updatedAt: 'Date',
        uploadedBy: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        url: 'String',
    },
};

// ************** products ***************** //

// products: ui config
export const uiConfigViewerProductsConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            customSku: 'Boolean',
            first: 'ID',
            identification: 'Boolean',
            name: 'Boolean',
            sku: 'Boolean',
            updatedAt: 'Boolean',
        },
        search: {
            anyString: 'String',
            brand: 'ProductViewerProductsSearchBrand',
            customSku: 'StringLikeOrMatchSearchCommon',
            declarations: 'ProductViewerProductsSearchDeclarations',
            franchise: 'FranchiseNodeMatchSearchCommon',
            identification: 'StringLikeOrMatchSearchCommon',
            isOneOff: 'BooleanMatchSearchCommon',
            name: 'StringLikeOrMatchSearchCommon',
            organisationType: 'OrganisationTypeNodeMatchSearchCommon',
            relatedNames: 'String',
            retiredAt: 'DateRangeSearchCommon',
            sku: 'StringLikeOrMatchSearchCommon',
            supplier: 'ProductCompanyNodeMatchSearchCommon',
        },
    },
    node: {
        addedBy: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        brand: {
            __typename: 'Brand',
            checkMutations: 'BrandMutationCheck',
            company: 'ProductCompany',
            createdAt: 'Date',
            franchise: 'Franchise',
            id: 'ID',
            identification: 'String',
            name: 'String',
            updatedAt: 'Date',
        },
        brochure: {
            __typename: 'ProductDocument',
            brand: 'Brand',
            checkMutations: 'ProductDocumentMutationCheck',
            company: 'ProductCompany',
            createdAt: 'Date',
            fileName: 'String',
            franchise: 'Franchise',
            id: 'ID',
            imageUrl: 'String',
            isImage: 'Boolean',
            isPublic: 'Boolean',
            location: 'String',
            mimeType: 'String',
            note: 'String',
            organisationType: 'OrganisationType',
            title: 'String',
            type: 'ProductDocumentType',
            updatedAt: 'Date',
            uploadedBy: 'Staff',
            url: 'String',
        },
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            retire: 'Boolean',
            unretire: 'Boolean',
            update: 'Boolean',
        },
        consumable: {
            __typename: 'ConsumableProduct',
            checkMutations: 'ConsumableProductMutationCheck',
            id: 'ID',
            product: 'Product',
        },
        createdAt: 'Date',
        customSku: 'String',
        description: 'String',
        familyCode: 'String',
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        id: 'ID',
        identification: 'String',
        installable: {
            __typename: 'InstallableProduct',
            checkMutations: 'InstallableProductMutationCheck',
            id: 'ID',
            product: 'Product',
        },
        isGrouping: 'Boolean',
        isOneOff: 'Boolean',
        name: 'String',
        organisationType: {
            __typename: 'OrganisationType',
            id: 'ID',
            locales: 'String',
            logo: 'String',
            name: 'String',
            timeZone: 'String',
            token: 'String',
        },
        retiredAt: 'Date',
        retiredBy: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        sku: 'String',
        supplier: {
            __typename: 'ProductCompany',
            checkMutations: 'ProductCompanyMutationCheck',
            createdAt: 'Date',
            description: 'String',
            franchise: 'Franchise',
            id: 'ID',
            name: 'String',
            parentCompany: 'ProductCompany',
            updatedAt: 'Date',
        },
        updatedAt: 'Date',
    },
};

// ************** projectHosting ***************** //

// projectHosting: ui config
export const uiConfigViewerProjectHostingConnection = {
    meta: {
        sort: {
            first: 'ID',
            priority: 'Boolean',
        },
        search: {
            enabled: 'BooleanMatchSearchCommon',
            enabledFrom: 'DateRangeSearchCommon',
            name: 'StringMatchSearchCommon',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        createdAt: 'Date',
        description: 'String',
        documentationProject: {
            __typename: 'Project',
            checkMutations: 'ProjectMutationCheck',
            createdAt: 'Date',
            description: 'String',
            documentationProject: 'Project',
            enabled: 'Boolean',
            enabledFrom: 'Date',
            fileURL: 'String',
            franchiseRestricted: 'Boolean',
            id: 'ID',
            latestRelease: 'ProjectVersion',
            name: 'String',
            organisation: 'OrganisationType',
            pinnedVersion: 'ProjectVersion',
            platformKey: 'String',
            priority: 'Int',
            recommendedVersion: 'ProjectVersion',
            releaseCount: 'Int',
            remoteThumbnailURL: 'String',
            thumbnailFile: 'FileUpload',
            thumbnailURL: 'String',
            updatedAt: 'Date',
            uuid: 'String',
        },
        enabled: 'Boolean',
        enabledFrom: 'Date',
        fileURL: 'String',
        franchiseRestricted: 'Boolean',
        id: 'ID',
        latestRelease: {
            __typename: 'ProjectVersion',
            changelogSummary: 'String',
            checkMutations: 'ProjectVersionMutationCheck',
            description: 'String',
            enabled: 'Boolean',
            enabledFrom: 'Date',
            fileURL: 'String',
            fileUpload: 'FileUpload',
            id: 'ID',
            isPinned: 'Boolean',
            project: 'Project',
            releaseNumber: 'Int',
            remoteFileURL: 'String',
            requirements: 'String',
            versionNumber: 'String',
        },
        name: 'String',
        organisation: {
            __typename: 'OrganisationType',
            id: 'ID',
            locales: 'String',
            logo: 'String',
            name: 'String',
            timeZone: 'String',
            token: 'String',
        },
        pinnedVersion: {
            __typename: 'ProjectVersion',
            changelogSummary: 'String',
            checkMutations: 'ProjectVersionMutationCheck',
            description: 'String',
            enabled: 'Boolean',
            enabledFrom: 'Date',
            fileURL: 'String',
            fileUpload: 'FileUpload',
            id: 'ID',
            isPinned: 'Boolean',
            project: 'Project',
            releaseNumber: 'Int',
            remoteFileURL: 'String',
            requirements: 'String',
            versionNumber: 'String',
        },
        platformKey: 'String',
        priority: 'Int',
        recommendedVersion: {
            __typename: 'ProjectVersion',
            changelogSummary: 'String',
            checkMutations: 'ProjectVersionMutationCheck',
            description: 'String',
            enabled: 'Boolean',
            enabledFrom: 'Date',
            fileURL: 'String',
            fileUpload: 'FileUpload',
            id: 'ID',
            isPinned: 'Boolean',
            project: 'Project',
            releaseNumber: 'Int',
            remoteFileURL: 'String',
            requirements: 'String',
            versionNumber: 'String',
        },
        releaseCount: 'Int',
        remoteThumbnailURL: 'String',
        thumbnailFile: {
            __typename: 'FileUpload',
            checkMutations: 'FileUploadMutationCheck',
            createdAt: 'Date',
            fileName: 'String',
            franchise: 'Franchise',
            id: 'ID',
            imageUrl: 'String',
            isImage: 'Boolean',
            isPublic: 'Boolean',
            location: 'String',
            mimeType: 'String',
            note: 'String',
            organisationType: 'OrganisationType',
            uploadedBy: 'Staff',
            url: 'String',
        },
        thumbnailURL: 'String',
        updatedAt: 'Date',
        uuid: 'String',
    },
};

// ************** reports ***************** //

// reports: ui config
export const uiConfigViewerReportsConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            first: 'ID',
            updatedAt: 'Boolean',
        },
        search: {
            createdAt: 'DateRangeSearchCommon',
            franchise: 'FranchiseNodeMatchSearchCommon',
            pool: 'TestReportViewerReportsSearchPool',
            refId: 'StringMatchSearchCommon',
            reportBy: 'StaffNodeMatchSearchCommon',
            reportTo: 'TestReportViewerReportsSearchReportTo',
            sampleSet: 'TestReportViewerReportsSearchSampleSet',
            updatedAt: 'DateRangeSearchCommon',
            workOrder: 'WorkOrderNodeMatchSearchCommon',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            archiveReport: 'Boolean',
            delete: 'Boolean',
            finaliseReport: 'Boolean',
            update: 'Boolean',
        },
        createdAt: 'Date',
        dataOwnership: 'Enum',
        docVersion: 'Int',
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        id: 'ID',
        isArchived: 'Boolean',
        isFinalised: 'Boolean',
        note: 'String',
        pdfUrl: 'String',
        pool: {
            __typename: 'Pool',
            address: 'Address',
            attachedFlagFields: 'PoolAttachedFlagField',
            bottleNumber: 'String',
            checkMutations: 'PoolMutationCheck',
            createdAt: 'Date',
            dataOwnership: 'EntityOwnershipType',
            entity: 'Entity',
            franchise: 'Franchise',
            id: 'ID',
            installedSanitisers: 'Item',
            name: 'String',
            note: 'String',
            notePlain: 'String',
            refId: 'String',
            revision: 'Int',
            sanitiser: 'Sanitiser',
            site: 'Site',
            type: 'PoolType',
            uuid: 'String',
            volume: 'Float',
        },
        poolRevision: 'Int',
        previewPdfUrl: 'String',
        previousReport: {
            __typename: 'TestReport',
            checkMutations: 'TestReportMutationCheck',
            createdAt: 'Date',
            dataOwnership: 'EntityOwnershipType',
            docVersion: 'Int',
            franchise: 'Franchise',
            id: 'ID',
            isArchived: 'Boolean',
            isFinalised: 'Boolean',
            note: 'String',
            pdfUrl: 'String',
            pool: 'Pool',
            poolRevision: 'Int',
            previewPdfUrl: 'String',
            previousReport: 'TestReport',
            refId: 'String',
            reportBy: 'Staff',
            reportTo: 'Entity',
            sampleSet: 'SampleSet',
            submissionMetadata: 'TestReportSubmission',
            testingRevision: 'Int',
            testingVersion: 'String',
            updatedAt: 'Date',
            workOrder: 'WorkOrder',
        },
        refId: 'String',
        reportBy: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        reportTo: {
            __typename: 'Entity',
            active: 'Boolean',
            chainUpdate: 'Date',
            checkMutations: 'EntityMutationCheck',
            dataOwnership: 'EntityOwnershipType',
            franchise: 'Franchise',
            id: 'ID',
            lastReportActivity: 'Date',
            serviceJob: 'ServiceJob',
            shop: 'FranchiseShop',
            user: 'User',
            uuid: 'String',
            warrantyClaim: 'WarrantyClaim',
        },
        sampleSet: {
            __typename: 'SampleSet',
            addedBy: 'Staff',
            checkMutations: 'SampleSetMutationCheck',
            createdAt: 'Date',
            hasTestReports: 'Int',
            id: 'ID',
            pool: 'Pool',
            toPlain: 'JSON',
        },
        submissionMetadata: {
            __typename: 'TestReportSubmission',
            deviceVersion: 'String',
            method: 'String',
            methodVersion: 'String',
            reagentsContext: 'String',
            submittedAt: 'Date',
            toolContext: 'String',
            typeContext: 'String',
        },
        testingRevision: 'Int',
        testingVersion: 'String',
        updatedAt: 'Date',
        workOrder: {
            __typename: 'WorkOrder',
            address: 'Address',
            appointmentItem: 'AppointmentItem',
            archivedAt: 'Date',
            assignedTo: 'Staff',
            checkMutations: 'WorkOrderMutationCheck',
            checkedInAt: 'Date',
            checkedOutAt: 'Date',
            createdAt: 'Date',
            createdBy: 'Staff',
            customer: 'Customer',
            description: 'String',
            dueRangeEnd: 'Date',
            dueRangeStart: 'Date',
            followUpFrom: 'WorkOrder',
            id: 'ID',
            paymentUrl: 'String',
            pool: 'Pool',
            preferredContact: 'Contact',
            requiresAddress: 'Boolean',
            requiresAppointment: 'Boolean',
            requiresAssignedTo: 'Boolean',
            requiresPool: 'Boolean',
            requiresPreferredContact: 'Boolean',
            requiresTestReport: 'Boolean',
            requiresWorkFor: 'Boolean',
            serviceJob: 'ServiceJob',
            stage: 'ServiceJobStage',
            stageCandidates: 'ServiceJobStage',
            statusClosedAt: 'Date',
            title: 'String',
            updatedAt: 'Date',
            usedTemplate: 'WorkOrderTemplate',
            workFor: 'Entity',
            workOrderNumber: 'String',
        },
    },
};

// ************** roleFlags ***************** //

// roleFlags: ui config
export const uiConfigViewerRoleFlagsConnection = {
    meta: {
        search: {
            description: 'StringMatchSearchCommon',
            tag: 'StringMatchSearchCommon',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        description: 'String',
        id: 'ID',
        tag: 'String',
    },
};

// ************** roles ***************** //

// roles: ui config
export const uiConfigViewerRolesConnection = {
    meta: {
        search: {
            anyString: 'String',
            name: 'StringLikeOrMatchSearchCommon',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            update: 'Boolean',
        },
        id: 'ID',
        name: 'String',
    },
};

// ************** sampleSets ***************** //

// sampleSets: ui config
export const uiConfigViewerSampleSetsConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            first: 'ID',
        },
        search: {
            addedBy: 'StaffNodeMatchSearchCommon',
            createdAt: 'DateRangeSearchCommon',
            pool: 'PoolNodeMatchSearchCommon',
        },
    },
    node: {
        addedBy: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        checkMutations: {
            __typename: 'CheckMutations',
            update: 'Boolean',
        },
        createdAt: 'Date',
        hasTestReports: 'Int',
        id: 'ID',
        pool: {
            __typename: 'Pool',
            address: 'Address',
            attachedFlagFields: 'PoolAttachedFlagField',
            bottleNumber: 'String',
            checkMutations: 'PoolMutationCheck',
            createdAt: 'Date',
            dataOwnership: 'EntityOwnershipType',
            entity: 'Entity',
            franchise: 'Franchise',
            id: 'ID',
            installedSanitisers: 'Item',
            name: 'String',
            note: 'String',
            notePlain: 'String',
            refId: 'String',
            revision: 'Int',
            sanitiser: 'Sanitiser',
            site: 'Site',
            type: 'PoolType',
            uuid: 'String',
            volume: 'Float',
        },
        toPlain: 'JSON',
    },
};

// ************** sampleSources ***************** //

// sampleSources: ui config
export const uiConfigViewerSampleSourcesConnection = {
    meta: {
        search: {
            name: 'StringMatchSearchCommon',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            update: 'Boolean',
        },
        id: 'ID',
        name: 'String',
    },
};

// ************** sentEmails ***************** //

// sentEmails: ui config
export const uiConfigViewerSentEmailsConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            first: 'ID',
        },
        search: {
            createdAt: 'DateRangeSearchCommon',
            mailTo: 'EntityNodeMatchSearchCommon',
            report: 'TestReportNodeMatchSearchCommon',
            submittedBy: 'EmailMessageViewerSentEmailsSearchSubmittedBy',
            type: 'EmailTypeNodeMatchSearchCommon',
        },
    },
    node: {
        claim: {
            __typename: 'WarrantyClaim',
            addedBy: 'Staff',
            address: 'Address',
            assetInstallDate: 'Date',
            assetLocation: 'String',
            assetName: 'String',
            assetSerial: 'String',
            case: 'WarrantyClaimCase',
            checkMutations: 'WarrantyClaimMutationCheck',
            claimNumber: 'String',
            claimedAt: 'Date',
            comment: 'String',
            confirmedAt: 'Date',
            createdAt: 'Date',
            customerCompanyName: 'String',
            customerEmail: 'String',
            customerFirstName: 'String',
            customerLastName: 'String',
            customerPhone: 'String',
            emailCC: 'String',
            emailReplyTo: 'String',
            entity: 'Entity',
            externalRMA: 'String',
            externalSO: 'String',
            externalSRN: 'String',
            faultDescription: 'String',
            franchise: 'Franchise',
            id: 'ID',
            installerName: 'String',
            receiverEmail: 'String',
            receiverStaff: 'Staff',
            receiverWarranter: 'FranchiseWarranter',
            refAsset: 'Item',
            refCustomer: 'Customer',
            refSite: 'Site',
            siteAccessKey: 'String',
            status: 'WarrantyClaimStatusEnum',
            statusChangedAt: 'Date',
            statusGroup: 'WarrantyClaimStatusGroupEnum',
            statusMessage: 'String',
            submittedAt: 'Date',
            updatedAt: 'Date',
            warrantyClaimNumber: 'String',
        },
        createdAt: 'Date',
        emailCC: 'String',
        id: 'ID',
        isSent: 'Boolean',
        mailTo: {
            __typename: 'Entity',
            active: 'Boolean',
            chainUpdate: 'Date',
            checkMutations: 'EntityMutationCheck',
            dataOwnership: 'EntityOwnershipType',
            franchise: 'Franchise',
            id: 'ID',
            lastReportActivity: 'Date',
            serviceJob: 'ServiceJob',
            shop: 'FranchiseShop',
            user: 'User',
            uuid: 'String',
            warrantyClaim: 'WarrantyClaim',
        },
        messageBody: 'String',
        note: 'String',
        previewBody: 'String',
        recipientEmail: 'String',
        replyTo: 'String',
        report: {
            __typename: 'TestReport',
            checkMutations: 'TestReportMutationCheck',
            createdAt: 'Date',
            dataOwnership: 'EntityOwnershipType',
            docVersion: 'Int',
            franchise: 'Franchise',
            id: 'ID',
            isArchived: 'Boolean',
            isFinalised: 'Boolean',
            note: 'String',
            pdfUrl: 'String',
            pool: 'Pool',
            poolRevision: 'Int',
            previewPdfUrl: 'String',
            previousReport: 'TestReport',
            refId: 'String',
            reportBy: 'Staff',
            reportTo: 'Entity',
            sampleSet: 'SampleSet',
            submissionMetadata: 'TestReportSubmission',
            testingRevision: 'Int',
            testingVersion: 'String',
            updatedAt: 'Date',
            workOrder: 'WorkOrder',
        },
        subject: 'String',
        submittedBy: {
            __typename: 'Entity',
            active: 'Boolean',
            chainUpdate: 'Date',
            checkMutations: 'EntityMutationCheck',
            dataOwnership: 'EntityOwnershipType',
            franchise: 'Franchise',
            id: 'ID',
            lastReportActivity: 'Date',
            serviceJob: 'ServiceJob',
            shop: 'FranchiseShop',
            user: 'User',
            uuid: 'String',
            warrantyClaim: 'WarrantyClaim',
        },
        template: {
            __typename: 'EmailTemplate',
            checkMutations: 'EmailTemplateMutationCheck',
            createdAt: 'Date',
            franchise: 'Franchise',
            id: 'ID',
            isSelectedForOwnFranchise: 'Boolean',
            isSelectedForOwnOrganisation: 'Boolean',
            isSelectedGlobally: 'Boolean',
            name: 'String',
            organisation: 'OrganisationType',
            preview: 'EmailTemplateMock',
            provider: 'EmailTemplateProvider',
            selectedForFranchises: 'Franchise',
            selectedForOrganisations: 'OrganisationType',
            templateBody: 'String',
            templateMetadata: 'JSON',
            type: 'EmailType',
            updatedAt: 'Date',
        },
        type: {
            __typename: 'EmailType',
            defaultBody: 'String',
            id: 'ID',
            name: 'String',
            tagName: 'String',
        },
    },
};

// ************** serviceJobGroups ***************** //

// serviceJobGroups: ui config
export const uiConfigViewerServiceJobGroupsConnection = {
    meta: {
        sort: {
            first: 'ID',
            priority: 'Boolean',
        },
        search: {
            franchise: 'FranchiseNodeMatchSearchCommon',
            organisationType: 'OrganisationTypeNodeMatchSearchCommon',
            parent: 'ServiceJobGroupNodeMatchSearchCommon',
            priority: 'IntMatchSearchCommon',
            title: 'StringMatchSearchCommon',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        createdAt: 'Date',
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        id: 'ID',
        organisationType: {
            __typename: 'OrganisationType',
            id: 'ID',
            locales: 'String',
            logo: 'String',
            name: 'String',
            timeZone: 'String',
            token: 'String',
        },
        parent: {
            __typename: 'ServiceJobGroup',
            checkMutations: 'ServiceJobGroupMutationCheck',
            createdAt: 'Date',
            franchise: 'Franchise',
            id: 'ID',
            organisationType: 'OrganisationType',
            parent: 'ServiceJobGroup',
            priority: 'Int',
            tag: 'String',
            title: 'String',
        },
        priority: 'Int',
        tag: 'String',
        title: 'String',
    },
};

// ************** serviceJobStages ***************** //

// serviceJobStages: ui config
export const uiConfigViewerServiceJobStagesConnection = {
    meta: {
        search: {
            anyString: 'String',
            archivedAt: 'DateRangeSearchCommon',
            createdAt: 'DateRangeSearchCommon',
            franchise: 'FranchiseNodeMatchSearchCommon',
            groups: 'ServiceJobStageViewerServiceJobStagesSearchGroups',
            hidden: 'BooleanMatchSearchCommon',
            isDefault: 'BooleanMatchSearchCommon',
            organisationType: 'OrganisationTypeNodeMatchSearchCommon',
            priority: 'IntMatchSearchCommon',
            tag: 'StringMatchSearchCommon',
            title: 'StringLikeOrMatchSearchCommon',
            type: 'ServiceJobStageTypeEnumMatchSearchCommon',
            updatedAt: 'DateRangeSearchCommon',
        },
    },
    node: {
        archivedAt: 'Date',
        checkMutations: {
            __typename: 'CheckMutations',
            archive: 'Boolean',
            delete: 'Boolean',
            update: 'Boolean',
        },
        color: 'String',
        createdAt: 'Date',
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        hidden: 'Boolean',
        id: 'ID',
        isDefault: 'Boolean',
        isFallback: 'Boolean',
        organisationType: {
            __typename: 'OrganisationType',
            id: 'ID',
            locales: 'String',
            logo: 'String',
            name: 'String',
            timeZone: 'String',
            token: 'String',
        },
        priority: 'Int',
        status: 'Enum',
        tag: 'String',
        title: 'String',
        type: 'Enum',
        updatedAt: 'Date',
    },
};

// ************** serviceJobTemplates ***************** //

// serviceJobTemplates: ui config
export const uiConfigViewerServiceJobTemplatesConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            first: 'ID',
            priority: 'Boolean',
            templateTitle: 'Boolean',
            updatedAt: 'Boolean',
        },
        search: {
            addedBy: 'StaffNodeMatchSearchCommon',
            anyString: 'String',
            archivedAt: 'DateRangeSearchCommon',
            canBeRecurring: 'BooleanMatchSearchCommon',
            franchise: 'FranchiseNodeMatchSearchCommon',
            group: 'ServiceJobGroupNodeMatchSearchCommon',
            isDefault: 'BooleanMatchSearchCommon',
            isPublished: 'BooleanMatchSearchCommon',
            organisationType: 'OrganisationTypeNodeMatchSearchCommon',
            tag: 'StringMatchSearchCommon',
            templateTitle: 'StringLikeOrMatchSearchCommon',
        },
    },
    node: {
        addedBy: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        archivedAt: 'Date',
        canBeRecurring: 'Boolean',
        checkMutations: {
            __typename: 'CheckMutations',
            archive: 'Boolean',
            delete: 'Boolean',
            setAsDefault: 'Boolean',
            update: 'Boolean',
        },
        createdAt: 'Date',
        defaultStage: {
            __typename: 'ServiceJobStage',
            archivedAt: 'Date',
            checkMutations: 'ServiceJobStageMutationCheck',
            color: 'String',
            createdAt: 'Date',
            franchise: 'Franchise',
            hidden: 'Boolean',
            id: 'ID',
            isDefault: 'Boolean',
            isFallback: 'Boolean',
            organisationType: 'OrganisationType',
            priority: 'Int',
            status: 'ServiceJobStageStatus',
            tag: 'String',
            title: 'String',
            type: 'ServiceJobStageType',
            updatedAt: 'Date',
        },
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        group: {
            __typename: 'ServiceJobGroup',
            checkMutations: 'ServiceJobGroupMutationCheck',
            createdAt: 'Date',
            franchise: 'Franchise',
            id: 'ID',
            organisationType: 'OrganisationType',
            parent: 'ServiceJobGroup',
            priority: 'Int',
            tag: 'String',
            title: 'String',
        },
        hideStageActionRequired: 'Boolean',
        hideStageInProgress: 'Boolean',
        hideStageSuspended: 'Boolean',
        id: 'ID',
        isDefault: 'Boolean',
        isPublished: 'Boolean',
        organisationType: {
            __typename: 'OrganisationType',
            id: 'ID',
            locales: 'String',
            logo: 'String',
            name: 'String',
            timeZone: 'String',
            token: 'String',
        },
        priority: 'Int',
        providedDescription: 'String',
        providedTitle: 'String',
        requiresAddress: 'Boolean',
        requiresContact: 'Boolean',
        requiresCustomer: 'Boolean',
        requiresPool: 'Boolean',
        requiresStaff: 'Boolean',
        stageReport: {
            __typename: 'ServiceJobStageTemplateOwnership',
            isActive: 'Boolean',
            ownershipType: 'ServiceJobStageTemplateOwnershipType',
            stage: 'ServiceJobStage',
        },
        tag: 'String',
        templateDescription: 'String',
        templateTitle: 'String',
        updatedAt: 'Date',
    },
};

// ************** serviceJobs ***************** //

// serviceJobs: ui config
export const uiConfigViewerServiceJobsConnection = {
    meta: {
        sort: {
            chainUpdate: 'Boolean',
            createdAt: 'Boolean',
            dueDate: 'Boolean',
            first: 'ID',
            serviceJobNumber: 'Boolean',
            stopsAt: 'Boolean',
            updatedAt: 'Boolean',
        },
        search: {
            address: 'ServiceJobViewerServiceJobsSearchAddress',
            anyString: 'String',
            archivedAt: 'DateRangeSearchCommon',
            closedAt: 'DateRangeSearchCommon',
            createdAt: 'DateRangeSearchCommon',
            createdBy: 'StaffNodeMatchSearchCommon',
            customer: 'ServiceJobViewerServiceJobsSearchCustomer',
            description: 'StringMatchSearchCommon',
            dueDate: 'DateRangeSearchCommon',
            entity: 'ServiceJobViewerServiceJobsSearchEntity',
            group: 'ServiceJobGroupNodeMatchSearchCommon',
            implicitType: 'ServiceJobImplicitTypeEnumEnumMatchSearchCommon',
            isImplicit: 'Boolean',
            isRecurring: 'BooleanMatchSearchCommon',
            pool: 'ServiceJobViewerServiceJobsSearchPool',
            relatedCustomer: 'ID',
            relatedPool: 'ID',
            relatedStaff: 'ID',
            relatedStageType: 'ServiceJobStageType',
            serviceJobNumber: 'String',
            staff: 'StaffNodeMatchSearchCommon',
            stage: 'ServiceJobStageNodeMatchSearchCommon',
            stageType: 'ServiceJobStageType',
            stopsAt: 'DateRangeSearchCommon',
            title: 'StringLikeOrMatchSearchCommon',
            usedTemplate: 'ServiceJobTemplateNodeMatchSearchCommon',
            workOrderStageType: 'ServiceJobStageType',
            workOrders: 'ServiceJobViewerServiceJobsSearchWorkOrders',
        },
    },
    node: {
        address: {
            __typename: 'Address',
            buildingName: 'String',
            checkMutations: 'AddressMutationCheck',
            city: 'String',
            country: 'String',
            createdAt: 'Date',
            destinationName: 'String',
            entity: 'Entity',
            id: 'ID',
            isBill: 'Boolean',
            isPrimary: 'Boolean',
            poBoxNumber: 'String',
            postCode: 'String',
            refId: 'String',
            state: 'String',
            streetName: 'String',
            streetNumber: 'String',
            suburb: 'String',
            uuid: 'String',
        },
        archivedAt: 'Date',
        checkMutations: {
            __typename: 'CheckMutations',
            archive: 'Boolean',
            delete: 'Boolean',
            setStopDate: 'Boolean',
            unArchive: 'Boolean',
            update: 'Boolean',
            updateRecurringJob: 'Boolean',
        },
        closedAt: 'Date',
        createdAt: 'Date',
        createdBy: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        customer: {
            __typename: 'Customer',
            addedBy: 'Staff',
            assignedTo: 'Staff',
            billAddress: 'Address',
            checkMutations: 'CustomerMutationCheck',
            companyName: 'String',
            contactName: 'String',
            createdAt: 'Date',
            crn: 'String',
            id: 'ID',
            nextAppointment: 'AppointmentItem',
            note: 'String',
            primaryAddress: 'Address',
            primaryEmail: 'Contact',
            primaryPhone: 'Contact',
            refId: 'String',
            registeredAt: 'Date',
            root: 'Entity',
            user: 'User',
            vendLink: 'VendLink',
        },
        description: 'String',
        dueDate: 'Date',
        entity: {
            __typename: 'Entity',
            active: 'Boolean',
            chainUpdate: 'Date',
            checkMutations: 'EntityMutationCheck',
            dataOwnership: 'EntityOwnershipType',
            franchise: 'Franchise',
            id: 'ID',
            lastReportActivity: 'Date',
            serviceJob: 'ServiceJob',
            shop: 'FranchiseShop',
            user: 'User',
            uuid: 'String',
            warrantyClaim: 'WarrantyClaim',
        },
        followedFrom: {
            __typename: 'ServiceJob',
            address: 'Address',
            archivedAt: 'Date',
            checkMutations: 'ServiceJobMutationCheck',
            closedAt: 'Date',
            createdAt: 'Date',
            createdBy: 'Staff',
            customer: 'Customer',
            description: 'String',
            dueDate: 'Date',
            entity: 'Entity',
            followedFrom: 'ServiceJob',
            group: 'ServiceJobGroup',
            id: 'ID',
            implicitType: 'ServiceJobImplicitTypeEnum',
            isImplicit: 'Boolean',
            isRecurring: 'Boolean',
            isSimpleMode: 'Boolean',
            lastAppointment: 'Appointment',
            lastAppointmentDate: 'Date',
            lastRecurrentAppointment: 'Appointment',
            pool: 'Pool',
            preferredContact: 'Contact',
            serviceJobNumber: 'String',
            staff: 'Staff',
            stage: 'ServiceJobStage',
            stageCandidates: 'ServiceJobStage',
            stageStatus: 'ServiceJobStageType',
            stopsAt: 'Date',
            title: 'String',
            updatedAt: 'Date',
            usedTemplate: 'ServiceJobTemplate',
        },
        group: {
            __typename: 'ServiceJobGroup',
            checkMutations: 'ServiceJobGroupMutationCheck',
            createdAt: 'Date',
            franchise: 'Franchise',
            id: 'ID',
            organisationType: 'OrganisationType',
            parent: 'ServiceJobGroup',
            priority: 'Int',
            tag: 'String',
            title: 'String',
        },
        id: 'ID',
        implicitType: 'Enum',
        isImplicit: 'Boolean',
        isRecurring: 'Boolean',
        isSimpleMode: 'Boolean',
        lastAppointment: {
            __typename: 'Appointment',
            addedBy: 'Staff',
            baseInstance: 'AppointmentInstance',
            checkMutations: 'AppointmentMutationCheck',
            endPatternDate: 'Date',
            franchise: 'Franchise',
            id: 'ID',
            parentAppointment: 'Appointment',
            patternBlockId: 'String',
            recurrence: 'Recurrence',
            startDate: 'Date',
            workOrderTemplate: 'WorkOrderTemplate',
        },
        lastAppointmentDate: 'Date',
        lastRecurrentAppointment: {
            __typename: 'Appointment',
            addedBy: 'Staff',
            baseInstance: 'AppointmentInstance',
            checkMutations: 'AppointmentMutationCheck',
            endPatternDate: 'Date',
            franchise: 'Franchise',
            id: 'ID',
            parentAppointment: 'Appointment',
            patternBlockId: 'String',
            recurrence: 'Recurrence',
            startDate: 'Date',
            workOrderTemplate: 'WorkOrderTemplate',
        },
        pool: {
            __typename: 'Pool',
            address: 'Address',
            attachedFlagFields: 'PoolAttachedFlagField',
            bottleNumber: 'String',
            checkMutations: 'PoolMutationCheck',
            createdAt: 'Date',
            dataOwnership: 'EntityOwnershipType',
            entity: 'Entity',
            franchise: 'Franchise',
            id: 'ID',
            installedSanitisers: 'Item',
            name: 'String',
            note: 'String',
            notePlain: 'String',
            refId: 'String',
            revision: 'Int',
            sanitiser: 'Sanitiser',
            site: 'Site',
            type: 'PoolType',
            uuid: 'String',
            volume: 'Float',
        },
        preferredContact: {
            __typename: 'Contact',
            checkMutations: 'ContactMutationCheck',
            createdAt: 'Date',
            data: 'String',
            entity: 'Entity',
            id: 'ID',
            isPrimary: 'Boolean',
            label: 'String',
            refId: 'String',
            type: 'ContactType',
            updatedAt: 'Date',
            uuid: 'String',
        },
        serviceJobNumber: 'String',
        staff: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        stage: {
            __typename: 'ServiceJobStage',
            archivedAt: 'Date',
            checkMutations: 'ServiceJobStageMutationCheck',
            color: 'String',
            createdAt: 'Date',
            franchise: 'Franchise',
            hidden: 'Boolean',
            id: 'ID',
            isDefault: 'Boolean',
            isFallback: 'Boolean',
            organisationType: 'OrganisationType',
            priority: 'Int',
            status: 'ServiceJobStageStatus',
            tag: 'String',
            title: 'String',
            type: 'ServiceJobStageType',
            updatedAt: 'Date',
        },
        stageCandidates: {
            __typename: 'ServiceJobStage',
            archivedAt: 'Date',
            checkMutations: 'ServiceJobStageMutationCheck',
            color: 'String',
            createdAt: 'Date',
            franchise: 'Franchise',
            hidden: 'Boolean',
            id: 'ID',
            isDefault: 'Boolean',
            isFallback: 'Boolean',
            organisationType: 'OrganisationType',
            priority: 'Int',
            status: 'ServiceJobStageStatus',
            tag: 'String',
            title: 'String',
            type: 'ServiceJobStageType',
            updatedAt: 'Date',
        },
        stageStatus: 'Enum',
        stopsAt: 'Date',
        title: 'String',
        updatedAt: 'Date',
        usedTemplate: {
            __typename: 'ServiceJobTemplate',
            addedBy: 'Staff',
            archivedAt: 'Date',
            canBeRecurring: 'Boolean',
            checkMutations: 'ServiceJobTemplateMutationCheck',
            createdAt: 'Date',
            defaultStage: 'ServiceJobStage',
            franchise: 'Franchise',
            group: 'ServiceJobGroup',
            hideStageActionRequired: 'Boolean',
            hideStageInProgress: 'Boolean',
            hideStageSuspended: 'Boolean',
            id: 'ID',
            isDefault: 'Boolean',
            isPublished: 'Boolean',
            organisationType: 'OrganisationType',
            priority: 'Int',
            providedDescription: 'String',
            providedTitle: 'String',
            requiresAddress: 'Boolean',
            requiresContact: 'Boolean',
            requiresCustomer: 'Boolean',
            requiresPool: 'Boolean',
            requiresStaff: 'Boolean',
            stageReport: 'ServiceJobStageTemplateOwnership',
            tag: 'String',
            templateDescription: 'String',
            templateTitle: 'String',
            updatedAt: 'Date',
        },
    },
};

// ************** settingStore ***************** //

// settingStore: ui config
export const uiConfigViewerSettingStoreConnection = {
    meta: {
        sort: {
            first: 'ID',
            group: 'Boolean',
            name: 'Boolean',
        },
        search: {
            group: 'StringMatchSearchCommon',
            name: 'StringMatchSearchCommon',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        group: 'String',
        id: 'ID',
        name: 'String',
        value: 'String',
    },
};

// ************** shops ***************** //

// shops: ui config
export const uiConfigViewerShopsConnection = {
    meta: {
        sort: {
            chainUpdate: 'Boolean',
            first: 'ID',
        },
        search: {
            address: 'AddressNodeMatchSearchCommon',
            entity: 'FranchiseShopViewerShopsSearchEntity',
            manager: 'StaffNodeMatchSearchCommon',
        },
    },
    node: {
        address: {
            __typename: 'Address',
            buildingName: 'String',
            checkMutations: 'AddressMutationCheck',
            city: 'String',
            country: 'String',
            createdAt: 'Date',
            destinationName: 'String',
            entity: 'Entity',
            id: 'ID',
            isBill: 'Boolean',
            isPrimary: 'Boolean',
            poBoxNumber: 'String',
            postCode: 'String',
            refId: 'String',
            state: 'String',
            streetName: 'String',
            streetNumber: 'String',
            suburb: 'String',
            uuid: 'String',
        },
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        entity: {
            __typename: 'Entity',
            active: 'Boolean',
            chainUpdate: 'Date',
            checkMutations: 'EntityMutationCheck',
            dataOwnership: 'EntityOwnershipType',
            franchise: 'Franchise',
            id: 'ID',
            lastReportActivity: 'Date',
            serviceJob: 'ServiceJob',
            shop: 'FranchiseShop',
            user: 'User',
            uuid: 'String',
            warrantyClaim: 'WarrantyClaim',
        },
        id: 'ID',
        manager: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
    },
};

// ************** sites ***************** //

// sites: ui config
export const uiConfigViewerSitesConnection = {
    meta: {
        search: {
            address: 'SiteViewerSitesSearchAddress',
            anyString: 'String',
            createdAt: 'DateRangeSearchCommon',
            entity: 'SiteViewerSitesSearchEntity',
            name: 'StringLikeOrMatchSearchCommon',
            updatedAt: 'DateRangeSearchCommon',
        },
    },
    node: {
        accessKeyLocation: 'String',
        address: {
            __typename: 'Address',
            buildingName: 'String',
            checkMutations: 'AddressMutationCheck',
            city: 'String',
            country: 'String',
            createdAt: 'Date',
            destinationName: 'String',
            entity: 'Entity',
            id: 'ID',
            isBill: 'Boolean',
            isPrimary: 'Boolean',
            poBoxNumber: 'String',
            postCode: 'String',
            refId: 'String',
            state: 'String',
            streetName: 'String',
            streetNumber: 'String',
            suburb: 'String',
            uuid: 'String',
        },
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            transferTo: 'Boolean',
            update: 'Boolean',
        },
        createdAt: 'Date',
        dataOwnership: 'Enum',
        entity: {
            __typename: 'Entity',
            active: 'Boolean',
            chainUpdate: 'Date',
            checkMutations: 'EntityMutationCheck',
            dataOwnership: 'EntityOwnershipType',
            franchise: 'Franchise',
            id: 'ID',
            lastReportActivity: 'Date',
            serviceJob: 'ServiceJob',
            shop: 'FranchiseShop',
            user: 'User',
            uuid: 'String',
            warrantyClaim: 'WarrantyClaim',
        },
        id: 'ID',
        name: 'String',
        refId: 'String',
        updatedAt: 'Date',
        uuid: 'String',
    },
};

// ************** staff ***************** //

// staff: ui config
export const uiConfigViewerStaffConnection = {
    meta: {
        sort: {
            chainUpdate: 'Boolean',
            first: 'ID',
            firstName: 'Boolean',
            lastName: 'Boolean',
        },
        search: {
            archivedAt: 'DateRangeSearchCommon',
            isSystemHidden: 'BooleanMatchSearchCommon',
            registration: 'StaffViewerStaffSearchRegistration',
            teams: 'StaffViewerStaffSearchTeams',
            user: 'StaffViewerStaffSearchUser',
        },
    },
    node: {
        archivedAt: 'Date',
        archivedBy: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        checkMutations: {
            __typename: 'CheckMutations',
            archive: 'Boolean',
            unarchive: 'Boolean',
            update: 'Boolean',
        },
        color: 'String',
        id: 'ID',
        isSystemHidden: 'Boolean',
        managedBy: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        registration: {
            __typename: 'StaffRegistration',
            checkMutations: 'StaffRegistrationMutationCheck',
            disabledAt: 'Date',
            disabledBy: 'Staff',
            email: 'String',
            id: 'ID',
            root: 'Entity',
            staff: 'Staff',
        },
        role: {
            __typename: 'Role',
            checkMutations: 'RoleMutationCheck',
            id: 'ID',
            name: 'String',
        },
        root: {
            __typename: 'Entity',
            active: 'Boolean',
            chainUpdate: 'Date',
            checkMutations: 'EntityMutationCheck',
            dataOwnership: 'EntityOwnershipType',
            franchise: 'Franchise',
            id: 'ID',
            lastReportActivity: 'Date',
            serviceJob: 'ServiceJob',
            shop: 'FranchiseShop',
            user: 'User',
            uuid: 'String',
            warrantyClaim: 'WarrantyClaim',
        },
        user: {
            __typename: 'User',
            checkMutations: 'UserMutationCheck',
            customer: 'Customer',
            entity: 'Entity',
            firstName: 'String',
            id: 'ID',
            lastName: 'String',
            staff: 'Staff',
            title: 'String',
        },
    },
};

// ************** staffTeams ***************** //

// staffTeams: ui config
export const uiConfigViewerStaffTeamsConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            first: 'ID',
            title: 'Boolean',
            updatedAt: 'Boolean',
        },
        search: {
            addedBy: 'StaffNodeMatchSearchCommon',
            anyString: 'String',
            franchise: 'FranchiseNodeMatchSearchCommon',
            tag: 'StringMatchSearchCommon',
            title: 'StringLikeOrMatchSearchCommon',
        },
    },
    node: {
        addedBy: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        checkMutations: {
            __typename: 'CheckMutations',
            addStaff: 'Boolean',
            delete: 'Boolean',
            update: 'Boolean',
        },
        color: 'String',
        createdAt: 'Date',
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        id: 'ID',
        tag: 'String',
        title: 'String',
        updatedAt: 'Date',
    },
};

// ************** staffValuesStore ***************** //

// staffValuesStore: ui config
export const uiConfigViewerStaffValuesStoreConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            first: 'ID',
            key: 'Boolean',
            updatedAt: 'Boolean',
        },
        search: {
            group: 'StringMatchSearchCommon',
            key: 'StringMatchSearchCommon',
            staff: 'StaffNodeMatchSearchCommon',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        createdAt: 'Date',
        group: 'String',
        id: 'ID',
        key: 'String',
        staff: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        updatedAt: 'Date',
        value: 'String',
    },
};

// ************** testCriteria ***************** //

// testCriteria: ui config
export const uiConfigViewerTestCriteriaConnection = {
    meta: {
        sort: {
            actualHigherThreshold: 'Boolean',
            actualLowerThreshold: 'Boolean',
            actualTarget: 'Boolean',
            archivedAt: 'Boolean',
            first: 'ID',
            higherThreshold: 'Boolean',
            lowerThreshold: 'Boolean',
            name: 'Boolean',
            targetValue: 'Boolean',
        },
        search: {
            actualHigherThreshold: 'FloatMatchSearchCommon',
            actualLowerThreshold: 'FloatMatchSearchCommon',
            actualTarget: 'FloatMatchSearchCommon',
            anyString: 'String',
            archivedAt: 'DateRangeSearchCommon',
            description: 'StringMatchSearchCommon',
            higherThreshold: 'FloatMatchSearchCommon',
            lowerThreshold: 'FloatMatchSearchCommon',
            measurementType: 'TestCriteriaViewerTestCriteriaSearchMeasurementType',
            name: 'StringLikeOrMatchSearchCommon',
            organisationType: 'OrganisationTypeNodeMatchSearchCommon',
            poolTypes: 'TestCriteriaViewerTestCriteriaSearchPoolTypes',
            sanitisers: 'TestCriteriaViewerTestCriteriaSearchSanitisers',
            targetValue: 'FloatMatchSearchCommon',
            toLowValueProblem: 'TestCriteriaViewerTestCriteriaSearchToLowValueProblem',
        },
    },
    node: {
        actualHigherThreshold: 'Float',
        actualLowerThreshold: 'Float',
        actualTarget: 'Float',
        archivedAt: 'Date',
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        countReportsAffected: 'Int',
        description: 'String',
        higherThreshold: 'Float',
        id: 'ID',
        isUniversal: 'Boolean',
        lowerThreshold: 'Float',
        measurementType: {
            __typename: 'Measurement',
            checkMutations: 'MeasurementMutationCheck',
            description: 'String',
            id: 'ID',
            linearConversion: 'UnitConversion',
            linearForm: 'Measurement',
            name: 'String',
            priority: 'Int',
            solutionCoefficient: 'Float',
            tagIdentifier: 'String',
            unit: 'Unit',
        },
        name: 'String',
        organisationType: {
            __typename: 'OrganisationType',
            id: 'ID',
            locales: 'String',
            logo: 'String',
            name: 'String',
            timeZone: 'String',
            token: 'String',
        },
        targetValue: 'Float',
        toHighValueProblem: {
            __typename: 'TestProblem',
            archivedAt: 'Date',
            checkMutations: 'TestProblemMutationCheck',
            countReportsAffected: 'Int',
            createdAt: 'Date',
            description: 'String',
            id: 'ID',
            name: 'String',
            organisationType: 'OrganisationType',
            updatedAt: 'Date',
        },
        toLowValueProblem: {
            __typename: 'TestProblem',
            archivedAt: 'Date',
            checkMutations: 'TestProblemMutationCheck',
            countReportsAffected: 'Int',
            createdAt: 'Date',
            description: 'String',
            id: 'ID',
            name: 'String',
            organisationType: 'OrganisationType',
            updatedAt: 'Date',
        },
        universal: {
            __typename: 'UniversalCriteria',
            checkMutations: 'UniversalCriteriaMutationCheck',
            criterion: 'TestCriteria',
            id: 'ID',
        },
    },
};

// ************** testProblems ***************** //

// testProblems: ui config
export const uiConfigViewerTestProblemsConnection = {
    meta: {
        sort: {
            archivedAt: 'Boolean',
            createdAt: 'Boolean',
            first: 'ID',
            name: 'Boolean',
            updatedAt: 'Boolean',
        },
        search: {
            anyString: 'String',
            archivedAt: 'DateRangeSearchCommon',
            createdAt: 'DateRangeSearchCommon',
            description: 'StringLikeOrMatchSearchCommon',
            name: 'StringLikeOrMatchSearchCommon',
            organisationType: 'OrganisationTypeNodeMatchSearchCommon',
            treatments: 'TestProblemViewerTestProblemsSearchTreatments',
            updatedAt: 'DateRangeSearchCommon',
        },
    },
    node: {
        archivedAt: 'Date',
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        countReportsAffected: 'Int',
        createdAt: 'Date',
        description: 'String',
        id: 'ID',
        name: 'String',
        organisationType: {
            __typename: 'OrganisationType',
            id: 'ID',
            locales: 'String',
            logo: 'String',
            name: 'String',
            timeZone: 'String',
            token: 'String',
        },
        updatedAt: 'Date',
    },
};

// ************** testTreatmentGroupForFranchises ***************** //

// testTreatmentGroupForFranchises: ui config
export const uiConfigViewerTestTreatmentGroupForFranchisesConnection = {
    meta: {
        sort: {
            first: 'ID',
            priority: 'Boolean',
        },
        search: {
            franchise: 'FranchiseNodeMatchSearchCommon',
            treatmentGroup: 'TestTreatmentGroupNodeMatchSearchCommon',
        },
    },
    node: {
        active: 'Boolean',
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
        },
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        id: 'ID',
        priority: 'Int',
        treatmentGroup: {
            __typename: 'TestTreatmentGroup',
            active: 'Boolean',
            checkMutations: 'TestTreatmentGroupMutationCheck',
            createdAt: 'Date',
            id: 'ID',
            name: 'String',
            organisationType: 'OrganisationType',
            priority: 'Int',
            updatedAt: 'Date',
        },
    },
};

// ************** testTreatmentGroups ***************** //

// testTreatmentGroups: ui config
export const uiConfigViewerTestTreatmentGroupsConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            first: 'ID',
            priority: 'Boolean',
        },
        search: {
            active: 'BooleanMatchSearchCommon',
            createdAt: 'DateRangeSearchCommon',
            organisationType: 'OrganisationTypeNodeMatchSearchCommon',
            priority: 'IntMatchSearchCommon',
        },
    },
    node: {
        active: 'Boolean',
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        createdAt: 'Date',
        id: 'ID',
        name: 'String',
        organisationType: {
            __typename: 'OrganisationType',
            id: 'ID',
            locales: 'String',
            logo: 'String',
            name: 'String',
            timeZone: 'String',
            token: 'String',
        },
        priority: 'Int',
        updatedAt: 'Date',
    },
};

// ************** testTreatments ***************** //

// testTreatments: ui config
export const uiConfigViewerTestTreatmentsConnection = {
    meta: {
        sort: {
            archivedAt: 'Boolean',
            createdAt: 'Boolean',
            first: 'ID',
            name: 'Boolean',
            priority: 'Boolean',
            updatedAt: 'Boolean',
        },
        search: {
            affects: 'TestTreatmentViewerTestTreatmentsSearchAffects',
            anyString: 'String',
            archivedAt: 'DateRangeSearchCommon',
            consumableProduct: 'ConsumableProductNodeMatchSearchCommon',
            franchiseBlacklists: 'TestTreatmentViewerTestTreatmentsSearchFranchiseBlacklists',
            franchisePriorities: 'TestTreatmentViewerTestTreatmentsSearchFranchisePriorities',
            group: 'TestTreatmentViewerTestTreatmentsSearchGroup',
            instruction: 'StringLikeOrMatchSearchCommon',
            isRadical: 'BooleanMatchSearchCommon',
            name: 'StringLikeOrMatchSearchCommon',
            organisationType: 'OrganisationTypeNodeMatchSearchCommon',
            priority: 'IntMatchSearchCommon',
            relatedProblems: 'TestTreatmentViewerTestTreatmentsSearchRelatedProblems',
            unit: 'UnitNodeMatchSearchCommon',
        },
    },
    node: {
        archivedAt: 'Date',
        checkMutations: {
            __typename: 'CheckMutations',
            erase: 'Boolean',
            setBlacklistForFranchise: 'Boolean',
            setPriorityForFranchise: 'Boolean',
            update: 'Boolean',
        },
        consumableProduct: {
            __typename: 'ConsumableProduct',
            checkMutations: 'ConsumableProductMutationCheck',
            id: 'ID',
            product: 'Product',
        },
        countReportsAffected: 'Int',
        createdAt: 'Date',
        decimalPlaces: 'Int',
        group: {
            __typename: 'TestTreatmentGroup',
            active: 'Boolean',
            checkMutations: 'TestTreatmentGroupMutationCheck',
            createdAt: 'Date',
            id: 'ID',
            name: 'String',
            organisationType: 'OrganisationType',
            priority: 'Int',
            updatedAt: 'Date',
        },
        id: 'ID',
        instruction: 'String',
        instructionPlain: 'String',
        isRadical: 'Boolean',
        name: 'String',
        organisationType: {
            __typename: 'OrganisationType',
            id: 'ID',
            locales: 'String',
            logo: 'String',
            name: 'String',
            timeZone: 'String',
            token: 'String',
        },
        priority: 'Int',
        unit: {
            __typename: 'Unit',
            checkMutations: 'UnitMutationCheck',
            id: 'ID',
            linearConversion: 'UnitConversion',
            linearForm: 'Unit',
            name: 'String',
        },
        updatedAt: 'Date',
    },
};

// ************** timezones ***************** //

// timezones: ui config
export const uiConfigViewerTimezonesConnection = {
    meta: {},
    node: {
        id: 'ID',
        localiseTime: 'String',
        timeZone: 'String',
    },
};

// ************** unitConversions ***************** //

// unitConversions: ui config
export const uiConfigViewerUnitConversionsConnection = {
    meta: {
        search: {
            destination: 'UnitNodeMatchSearchCommon',
            factors: 'FloatMatchSearchCommon',
            method: 'UnitConverterNodeMatchSearchCommon',
            source: 'UnitNodeMatchSearchCommon',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        destination: {
            __typename: 'Unit',
            checkMutations: 'UnitMutationCheck',
            id: 'ID',
            linearConversion: 'UnitConversion',
            linearForm: 'Unit',
            name: 'String',
        },
        factors: 'Float',
        id: 'ID',
        method: {
            __typename: 'UnitConverter',
            id: 'ID',
            name: 'String',
            requiresFactors: 'Int',
            tag: 'String',
        },
        source: {
            __typename: 'Unit',
            checkMutations: 'UnitMutationCheck',
            id: 'ID',
            linearConversion: 'UnitConversion',
            linearForm: 'Unit',
            name: 'String',
        },
    },
};

// ************** unitConverters ***************** //

// unitConverters: ui config
export const uiConfigViewerUnitConvertersConnection = {
    meta: {},
    node: {
        id: 'ID',
        name: 'String',
        requiresFactors: 'Int',
        tag: 'String',
    },
};

// ************** universalCriteria ***************** //

// universalCriteria: ui config
export const uiConfigViewerUniversalCriteriaConnection = {
    meta: {
        search: {
            criterion: 'TestCriteriaNodeMatchSearchCommon',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
        },
        criterion: {
            __typename: 'TestCriteria',
            actualHigherThreshold: 'Float',
            actualLowerThreshold: 'Float',
            actualTarget: 'Float',
            archivedAt: 'Date',
            checkMutations: 'TestCriteriaMutationCheck',
            countReportsAffected: 'Int',
            description: 'String',
            higherThreshold: 'Float',
            id: 'ID',
            isUniversal: 'Boolean',
            lowerThreshold: 'Float',
            measurementType: 'Measurement',
            name: 'String',
            organisationType: 'OrganisationType',
            targetValue: 'Float',
            toHighValueProblem: 'TestProblem',
            toLowValueProblem: 'TestProblem',
            universal: 'UniversalCriteria',
        },
        id: 'ID',
    },
};

// ************** users ***************** //

// users: ui config
export const uiConfigViewerUsersConnection = {
    meta: {
        sort: {
            chainUpdate: 'Boolean',
            first: 'ID',
            firstName: 'Boolean',
            lastName: 'Boolean',
        },
        search: {
            anyString: 'String',
            customer: 'UserViewerUsersSearchCustomer',
            entity: 'UserViewerUsersSearchEntity',
            firstName: 'StringLikeOrMatchSearchCommon',
            lastName: 'StringLikeOrMatchSearchCommon',
            staff: 'UserViewerUsersSearchStaff',
        },
    },
    node: {
        checkMutations: {
            __typename: 'CheckMutations',
            update: 'Boolean',
        },
        customer: {
            __typename: 'Customer',
            addedBy: 'Staff',
            assignedTo: 'Staff',
            billAddress: 'Address',
            checkMutations: 'CustomerMutationCheck',
            companyName: 'String',
            contactName: 'String',
            createdAt: 'Date',
            crn: 'String',
            id: 'ID',
            nextAppointment: 'AppointmentItem',
            note: 'String',
            primaryAddress: 'Address',
            primaryEmail: 'Contact',
            primaryPhone: 'Contact',
            refId: 'String',
            registeredAt: 'Date',
            root: 'Entity',
            user: 'User',
            vendLink: 'VendLink',
        },
        entity: {
            __typename: 'Entity',
            active: 'Boolean',
            chainUpdate: 'Date',
            checkMutations: 'EntityMutationCheck',
            dataOwnership: 'EntityOwnershipType',
            franchise: 'Franchise',
            id: 'ID',
            lastReportActivity: 'Date',
            serviceJob: 'ServiceJob',
            shop: 'FranchiseShop',
            user: 'User',
            uuid: 'String',
            warrantyClaim: 'WarrantyClaim',
        },
        firstName: 'String',
        id: 'ID',
        lastName: 'String',
        staff: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        title: 'String',
    },
};

// ************** warranterClaimMessages ***************** //

// warranterClaimMessages: ui config
export const uiConfigViewerWarranterClaimMessagesConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            first: 'ID',
            updatedAt: 'Boolean',
        },
        search: {
            addedBy: 'StaffNodeMatchSearchCommon',
            claim: 'WarrantyClaimNodeMatchSearchCommon',
            createdAt: 'DateRangeSearchCommon',
            origin: 'WarrantyMessageOrigin',
        },
    },
    node: {
        addedBy: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            update: 'Boolean',
        },
        claim: {
            __typename: 'WarrantyClaim',
            addedBy: 'Staff',
            address: 'Address',
            assetInstallDate: 'Date',
            assetLocation: 'String',
            assetName: 'String',
            assetSerial: 'String',
            case: 'WarrantyClaimCase',
            checkMutations: 'WarrantyClaimMutationCheck',
            claimNumber: 'String',
            claimedAt: 'Date',
            comment: 'String',
            confirmedAt: 'Date',
            createdAt: 'Date',
            customerCompanyName: 'String',
            customerEmail: 'String',
            customerFirstName: 'String',
            customerLastName: 'String',
            customerPhone: 'String',
            emailCC: 'String',
            emailReplyTo: 'String',
            entity: 'Entity',
            externalRMA: 'String',
            externalSO: 'String',
            externalSRN: 'String',
            faultDescription: 'String',
            franchise: 'Franchise',
            id: 'ID',
            installerName: 'String',
            receiverEmail: 'String',
            receiverStaff: 'Staff',
            receiverWarranter: 'FranchiseWarranter',
            refAsset: 'Item',
            refCustomer: 'Customer',
            refSite: 'Site',
            siteAccessKey: 'String',
            status: 'WarrantyClaimStatusEnum',
            statusChangedAt: 'Date',
            statusGroup: 'WarrantyClaimStatusGroupEnum',
            statusMessage: 'String',
            submittedAt: 'Date',
            updatedAt: 'Date',
            warrantyClaimNumber: 'String',
        },
        createdAt: 'Date',
        id: 'ID',
        isRead: 'Boolean',
        message: 'String',
        updatedAt: 'Date',
    },
};

// ************** warrantyClaimCases ***************** //

// warrantyClaimCases: ui config
export const uiConfigViewerWarrantyClaimCasesConnection = {
    meta: {
        sort: {
            claim: 'Boolean',
            createdAt: 'Boolean',
            first: 'ID',
            lastMessageAt: 'Boolean',
            lastMessageReadAt: 'Boolean',
            updatedAt: 'Boolean',
        },
        search: {
            assignedTo: 'StaffNodeMatchSearchCommon',
            claim: 'WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaim',
            createdAt: 'DateRangeSearchCommon',
            franchise: 'FranchiseNodeMatchSearchCommon',
            serviceJob: 'ServiceJobNodeMatchSearchCommon',
        },
    },
    node: {
        assignedTo: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            markMessagesAsRead: 'Boolean',
            update: 'Boolean',
            updateStatus: 'Boolean',
        },
        claim: {
            __typename: 'WarrantyClaim',
            addedBy: 'Staff',
            address: 'Address',
            assetInstallDate: 'Date',
            assetLocation: 'String',
            assetName: 'String',
            assetSerial: 'String',
            case: 'WarrantyClaimCase',
            checkMutations: 'WarrantyClaimMutationCheck',
            claimNumber: 'String',
            claimedAt: 'Date',
            comment: 'String',
            confirmedAt: 'Date',
            createdAt: 'Date',
            customerCompanyName: 'String',
            customerEmail: 'String',
            customerFirstName: 'String',
            customerLastName: 'String',
            customerPhone: 'String',
            emailCC: 'String',
            emailReplyTo: 'String',
            entity: 'Entity',
            externalRMA: 'String',
            externalSO: 'String',
            externalSRN: 'String',
            faultDescription: 'String',
            franchise: 'Franchise',
            id: 'ID',
            installerName: 'String',
            receiverEmail: 'String',
            receiverStaff: 'Staff',
            receiverWarranter: 'FranchiseWarranter',
            refAsset: 'Item',
            refCustomer: 'Customer',
            refSite: 'Site',
            siteAccessKey: 'String',
            status: 'WarrantyClaimStatusEnum',
            statusChangedAt: 'Date',
            statusGroup: 'WarrantyClaimStatusGroupEnum',
            statusMessage: 'String',
            submittedAt: 'Date',
            updatedAt: 'Date',
            warrantyClaimNumber: 'String',
        },
        createdAt: 'Date',
        externalRMA: 'String',
        externalSO: 'String',
        externalSRN: 'String',
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        id: 'ID',
        lastMessageAt: 'Date',
        lastMessageReadAt: 'Date',
        note: 'String',
        serviceJob: {
            __typename: 'ServiceJob',
            address: 'Address',
            archivedAt: 'Date',
            checkMutations: 'ServiceJobMutationCheck',
            closedAt: 'Date',
            createdAt: 'Date',
            createdBy: 'Staff',
            customer: 'Customer',
            description: 'String',
            dueDate: 'Date',
            entity: 'Entity',
            followedFrom: 'ServiceJob',
            group: 'ServiceJobGroup',
            id: 'ID',
            implicitType: 'ServiceJobImplicitTypeEnum',
            isImplicit: 'Boolean',
            isRecurring: 'Boolean',
            isSimpleMode: 'Boolean',
            lastAppointment: 'Appointment',
            lastAppointmentDate: 'Date',
            lastRecurrentAppointment: 'Appointment',
            pool: 'Pool',
            preferredContact: 'Contact',
            serviceJobNumber: 'String',
            staff: 'Staff',
            stage: 'ServiceJobStage',
            stageCandidates: 'ServiceJobStage',
            stageStatus: 'ServiceJobStageType',
            stopsAt: 'Date',
            title: 'String',
            updatedAt: 'Date',
            usedTemplate: 'ServiceJobTemplate',
        },
        updatedAt: 'Date',
    },
};

// ************** warrantyClaims ***************** //

// warrantyClaims: ui config
export const uiConfigViewerWarrantyClaimsConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            first: 'ID',
            submittedAt: 'Boolean',
            updatedAt: 'Boolean',
        },
        search: {
            addedBy: 'StaffNodeMatchSearchCommon',
            claimNumber: 'String',
            createdAt: 'DateRangeSearchCommon',
            franchise: 'FranchiseNodeMatchSearchCommon',
            receiverStaff: 'StaffNodeMatchSearchCommon',
            receiverWarranter: 'FranchiseWarranterNodeMatchSearchCommon',
            refAsset: 'ItemNodeMatchSearchCommon',
            refCustomer: 'WarrantyClaimViewerWarrantyClaimsSearchRefCustomer',
            refSite: 'SiteNodeMatchSearchCommon',
            status: 'WarrantyClaimStatusEnumEnumMatchSearchCommon',
            statusGroup: 'WarrantyClaimStatusGroupEnumEnumMatchSearchCommon',
            submittedAt: 'DateRangeSearchCommon',
        },
    },
    node: {
        addedBy: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        address: {
            __typename: 'Address',
            buildingName: 'String',
            checkMutations: 'AddressMutationCheck',
            city: 'String',
            country: 'String',
            createdAt: 'Date',
            destinationName: 'String',
            entity: 'Entity',
            id: 'ID',
            isBill: 'Boolean',
            isPrimary: 'Boolean',
            poBoxNumber: 'String',
            postCode: 'String',
            refId: 'String',
            state: 'String',
            streetName: 'String',
            streetNumber: 'String',
            suburb: 'String',
            uuid: 'String',
        },
        assetInstallDate: 'Date',
        assetLocation: 'String',
        assetName: 'String',
        assetSerial: 'String',
        case: {
            __typename: 'WarrantyClaimCase',
            assignedTo: 'Staff',
            checkMutations: 'WarrantyClaimCaseMutationCheck',
            claim: 'WarrantyClaim',
            createdAt: 'Date',
            externalRMA: 'String',
            externalSO: 'String',
            externalSRN: 'String',
            franchise: 'Franchise',
            id: 'ID',
            lastMessageAt: 'Date',
            lastMessageReadAt: 'Date',
            note: 'String',
            serviceJob: 'ServiceJob',
            updatedAt: 'Date',
        },
        checkMutations: {
            __typename: 'CheckMutations',
            delete: 'Boolean',
            executeWarrantyClaimRequest: 'Boolean',
            forceClaimCase: 'Boolean',
            update: 'Boolean',
        },
        claimNumber: 'String',
        claimedAt: 'Date',
        comment: 'String',
        confirmedAt: 'Date',
        createdAt: 'Date',
        customerCompanyName: 'String',
        customerEmail: 'String',
        customerFirstName: 'String',
        customerLastName: 'String',
        customerPhone: 'String',
        emailCC: 'String',
        emailReplyTo: 'String',
        entity: {
            __typename: 'Entity',
            active: 'Boolean',
            chainUpdate: 'Date',
            checkMutations: 'EntityMutationCheck',
            dataOwnership: 'EntityOwnershipType',
            franchise: 'Franchise',
            id: 'ID',
            lastReportActivity: 'Date',
            serviceJob: 'ServiceJob',
            shop: 'FranchiseShop',
            user: 'User',
            uuid: 'String',
            warrantyClaim: 'WarrantyClaim',
        },
        externalRMA: 'String',
        externalSO: 'String',
        externalSRN: 'String',
        faultDescription: 'String',
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        id: 'ID',
        installerName: 'String',
        receiverEmail: 'String',
        receiverStaff: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        receiverWarranter: {
            __typename: 'FranchiseWarranter',
            actingFranchise: 'Franchise',
            checkMutations: 'FranchiseWarranterMutationCheck',
            createdAt: 'Date',
            disabled: 'Boolean',
            email: 'String',
            id: 'ID',
            name: 'String',
            updatedAt: 'Date',
        },
        refAsset: {
            __typename: 'Item',
            checkMutations: 'ItemMutationCheck',
            createdAt: 'Date',
            id: 'ID',
            installedAt: 'Date',
            installedByStaff: 'Staff',
            label: 'String',
            note: 'String',
            owner: 'Entity',
            product: 'Product',
            serial: 'String',
            updatedAt: 'Date',
            uuid: 'String',
        },
        refCustomer: {
            __typename: 'Customer',
            addedBy: 'Staff',
            assignedTo: 'Staff',
            billAddress: 'Address',
            checkMutations: 'CustomerMutationCheck',
            companyName: 'String',
            contactName: 'String',
            createdAt: 'Date',
            crn: 'String',
            id: 'ID',
            nextAppointment: 'AppointmentItem',
            note: 'String',
            primaryAddress: 'Address',
            primaryEmail: 'Contact',
            primaryPhone: 'Contact',
            refId: 'String',
            registeredAt: 'Date',
            root: 'Entity',
            user: 'User',
            vendLink: 'VendLink',
        },
        refSite: {
            __typename: 'Site',
            accessKeyLocation: 'String',
            address: 'Address',
            checkMutations: 'SiteMutationCheck',
            createdAt: 'Date',
            dataOwnership: 'EntityOwnershipType',
            entity: 'Entity',
            id: 'ID',
            name: 'String',
            refId: 'String',
            updatedAt: 'Date',
            uuid: 'String',
        },
        siteAccessKey: 'String',
        status: 'Enum',
        statusChangedAt: 'Date',
        statusGroup: 'Enum',
        statusMessage: 'String',
        submittedAt: 'Date',
        updatedAt: 'Date',
        warrantyClaimNumber: 'String',
    },
};

// ************** workOrderFeed ***************** //

// workOrderFeed: ui config
export const uiConfigViewerWorkOrderFeedConnection = {
    meta: {
        search: {
            customer: 'ID',
            dateRange: 'WorkOrderFeedItemViewerWorkOrderFeedSearchDateRange',
            franchiseId: 'ID',
            hasAppointment: 'Boolean',
            hasWorkOrder: 'Boolean',
            serviceJob: 'ID',
            serviceJobGroup: 'ID',
            serviceJobNumber: 'String',
            staff: 'ID',
            stageType: 'ServiceJobStageType',
            usedTemplate: 'ID',
            workOrderNumber: 'String',
        },
    },
    node: {
        address: {
            __typename: 'Address',
            buildingName: 'String',
            checkMutations: 'AddressMutationCheck',
            city: 'String',
            country: 'String',
            createdAt: 'Date',
            destinationName: 'String',
            entity: 'Entity',
            id: 'ID',
            isBill: 'Boolean',
            isPrimary: 'Boolean',
            poBoxNumber: 'String',
            postCode: 'String',
            refId: 'String',
            state: 'String',
            streetName: 'String',
            streetNumber: 'String',
            suburb: 'String',
            uuid: 'String',
        },
        appointmentItem: {
            __typename: 'AppointmentItem',
            address: 'Address',
            adhoc: 'Boolean',
            appointment: 'Appointment',
            checkMutations: 'AppointmentItemMutationCheck',
            color: 'String',
            customer: 'Customer',
            deleted: 'Boolean',
            duration: 'Int',
            franchise: 'Franchise',
            group: 'AppointmentGroup',
            id: 'ID',
            isEnd: 'Boolean',
            isRecurrentOrigin: 'Boolean',
            isRecurring: 'Boolean',
            isStart: 'Boolean',
            isWorkOrderPending: 'Boolean',
            note: 'String',
            originPattern: 'RecurrencePattern',
            originalDate: 'Date',
            patternBlockId: 'String',
            paymentUrl: 'String',
            pool: 'Pool',
            serviceJob: 'ServiceJob',
            splitAppointment: 'Appointment',
            staff: 'Staff',
            startDate: 'Date',
            status: 'AppointmentStatusEnum',
            statusMap: 'AppointmentStateMap',
            vendSale: 'VendAppointmentSaleType',
            workOrder: 'WorkOrder',
        },
        checkMutations: {
            __typename: 'CheckMutations',
            startWorkOrder: 'Boolean',
        },
        customer: {
            __typename: 'Customer',
            addedBy: 'Staff',
            assignedTo: 'Staff',
            billAddress: 'Address',
            checkMutations: 'CustomerMutationCheck',
            companyName: 'String',
            contactName: 'String',
            createdAt: 'Date',
            crn: 'String',
            id: 'ID',
            nextAppointment: 'AppointmentItem',
            note: 'String',
            primaryAddress: 'Address',
            primaryEmail: 'Contact',
            primaryPhone: 'Contact',
            refId: 'String',
            registeredAt: 'Date',
            root: 'Entity',
            user: 'User',
            vendLink: 'VendLink',
        },
        date: 'Date',
        id: 'ID',
        pool: {
            __typename: 'Pool',
            address: 'Address',
            attachedFlagFields: 'PoolAttachedFlagField',
            bottleNumber: 'String',
            checkMutations: 'PoolMutationCheck',
            createdAt: 'Date',
            dataOwnership: 'EntityOwnershipType',
            entity: 'Entity',
            franchise: 'Franchise',
            id: 'ID',
            installedSanitisers: 'Item',
            name: 'String',
            note: 'String',
            notePlain: 'String',
            refId: 'String',
            revision: 'Int',
            sanitiser: 'Sanitiser',
            site: 'Site',
            type: 'PoolType',
            uuid: 'String',
            volume: 'Float',
        },
        serviceJob: {
            __typename: 'ServiceJob',
            address: 'Address',
            archivedAt: 'Date',
            checkMutations: 'ServiceJobMutationCheck',
            closedAt: 'Date',
            createdAt: 'Date',
            createdBy: 'Staff',
            customer: 'Customer',
            description: 'String',
            dueDate: 'Date',
            entity: 'Entity',
            followedFrom: 'ServiceJob',
            group: 'ServiceJobGroup',
            id: 'ID',
            implicitType: 'ServiceJobImplicitTypeEnum',
            isImplicit: 'Boolean',
            isRecurring: 'Boolean',
            isSimpleMode: 'Boolean',
            lastAppointment: 'Appointment',
            lastAppointmentDate: 'Date',
            lastRecurrentAppointment: 'Appointment',
            pool: 'Pool',
            preferredContact: 'Contact',
            serviceJobNumber: 'String',
            staff: 'Staff',
            stage: 'ServiceJobStage',
            stageCandidates: 'ServiceJobStage',
            stageStatus: 'ServiceJobStageType',
            stopsAt: 'Date',
            title: 'String',
            updatedAt: 'Date',
            usedTemplate: 'ServiceJobTemplate',
        },
        staff: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        title: 'String',
        workOrder: {
            __typename: 'WorkOrder',
            address: 'Address',
            appointmentItem: 'AppointmentItem',
            archivedAt: 'Date',
            assignedTo: 'Staff',
            checkMutations: 'WorkOrderMutationCheck',
            checkedInAt: 'Date',
            checkedOutAt: 'Date',
            createdAt: 'Date',
            createdBy: 'Staff',
            customer: 'Customer',
            description: 'String',
            dueRangeEnd: 'Date',
            dueRangeStart: 'Date',
            followUpFrom: 'WorkOrder',
            id: 'ID',
            paymentUrl: 'String',
            pool: 'Pool',
            preferredContact: 'Contact',
            requiresAddress: 'Boolean',
            requiresAppointment: 'Boolean',
            requiresAssignedTo: 'Boolean',
            requiresPool: 'Boolean',
            requiresPreferredContact: 'Boolean',
            requiresTestReport: 'Boolean',
            requiresWorkFor: 'Boolean',
            serviceJob: 'ServiceJob',
            stage: 'ServiceJobStage',
            stageCandidates: 'ServiceJobStage',
            statusClosedAt: 'Date',
            title: 'String',
            updatedAt: 'Date',
            usedTemplate: 'WorkOrderTemplate',
            workFor: 'Entity',
            workOrderNumber: 'String',
        },
    },
};

// ************** workOrderTemplates ***************** //

// workOrderTemplates: ui config
export const uiConfigViewerWorkOrderTemplatesConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            first: 'ID',
            templateTitle: 'Boolean',
            updatedAt: 'Boolean',
        },
        search: {
            addedBy: 'StaffNodeMatchSearchCommon',
            anyString: 'String',
            archivedAt: 'DateRangeSearchCommon',
            franchise: 'FranchiseNodeMatchSearchCommon',
            group: 'ServiceJobGroupNodeMatchSearchCommon',
            organisationType: 'OrganisationTypeNodeMatchSearchCommon',
            ownedByServiceJobTemplate: 'ServiceJobTemplateNodeMatchSearchCommon',
            templateTitle: 'StringLikeOrMatchSearchCommon',
        },
    },
    node: {
        addedBy: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        archivedAt: 'Date',
        checkMutations: {
            __typename: 'CheckMutations',
            archive: 'Boolean',
            delete: 'Boolean',
            update: 'Boolean',
        },
        createdAt: 'Date',
        defaultStage: {
            __typename: 'ServiceJobStage',
            archivedAt: 'Date',
            checkMutations: 'ServiceJobStageMutationCheck',
            color: 'String',
            createdAt: 'Date',
            franchise: 'Franchise',
            hidden: 'Boolean',
            id: 'ID',
            isDefault: 'Boolean',
            isFallback: 'Boolean',
            organisationType: 'OrganisationType',
            priority: 'Int',
            status: 'ServiceJobStageStatus',
            tag: 'String',
            title: 'String',
            type: 'ServiceJobStageType',
            updatedAt: 'Date',
        },
        franchise: {
            __typename: 'Franchise',
            activities: 'RecentActivity',
            address: 'Address',
            checkMutations: 'FranchiseMutationCheck',
            contactExport: 'Boolean',
            createdAt: 'Date',
            currentLocales: 'Locales',
            currentTimezone: 'Timezone',
            customerCount: 'Int',
            email: 'String',
            ezcServiceProviderLink: 'EZCFranchiseLink',
            fileSafeName: 'String',
            headOffice: 'FranchiseShop',
            id: 'ID',
            locales: 'Locales',
            manager: 'Staff',
            name: 'String',
            organisationType: 'OrganisationType',
            parentFranchise: 'Franchise',
            phoneNumber: 'String',
            poolCount: 'Int',
            refId: 'String',
            staffCount: 'Int',
            timeZone: 'Timezone',
            types: 'FranchiseType',
            updatedAt: 'Date',
            uuid: 'String',
            vend: 'VendAPI',
            waterTestBranding: 'OrganisationBrandingEnum',
        },
        group: {
            __typename: 'ServiceJobGroup',
            checkMutations: 'ServiceJobGroupMutationCheck',
            createdAt: 'Date',
            franchise: 'Franchise',
            id: 'ID',
            organisationType: 'OrganisationType',
            parent: 'ServiceJobGroup',
            priority: 'Int',
            tag: 'String',
            title: 'String',
        },
        hideStageActionRequired: 'Boolean',
        hideStageInProgress: 'Boolean',
        hideStageSuspended: 'Boolean',
        id: 'ID',
        onlyOwnedStages: 'Boolean',
        organisationType: {
            __typename: 'OrganisationType',
            id: 'ID',
            locales: 'String',
            logo: 'String',
            name: 'String',
            timeZone: 'String',
            token: 'String',
        },
        ownedByServiceJobTemplate: {
            __typename: 'ServiceJobTemplate',
            addedBy: 'Staff',
            archivedAt: 'Date',
            canBeRecurring: 'Boolean',
            checkMutations: 'ServiceJobTemplateMutationCheck',
            createdAt: 'Date',
            defaultStage: 'ServiceJobStage',
            franchise: 'Franchise',
            group: 'ServiceJobGroup',
            hideStageActionRequired: 'Boolean',
            hideStageInProgress: 'Boolean',
            hideStageSuspended: 'Boolean',
            id: 'ID',
            isDefault: 'Boolean',
            isPublished: 'Boolean',
            organisationType: 'OrganisationType',
            priority: 'Int',
            providedDescription: 'String',
            providedTitle: 'String',
            requiresAddress: 'Boolean',
            requiresContact: 'Boolean',
            requiresCustomer: 'Boolean',
            requiresPool: 'Boolean',
            requiresStaff: 'Boolean',
            stageReport: 'ServiceJobStageTemplateOwnership',
            tag: 'String',
            templateDescription: 'String',
            templateTitle: 'String',
            updatedAt: 'Date',
        },
        providedDescription: 'String',
        providedTitle: 'String',
        requiresAddress: 'Boolean',
        requiresContact: 'Boolean',
        requiresCustomer: 'Boolean',
        requiresPool: 'Boolean',
        requiresStaff: 'Boolean',
        stageReport: {
            __typename: 'ServiceJobStageTemplateOwnership',
            isActive: 'Boolean',
            ownershipType: 'ServiceJobStageTemplateOwnershipType',
            stage: 'ServiceJobStage',
        },
        tag: 'String',
        templateDescription: 'String',
        templateTitle: 'String',
        updatedAt: 'Date',
    },
};

// ************** workOrders ***************** //

// workOrders: ui config
export const uiConfigViewerWorkOrdersConnection = {
    meta: {
        sort: {
            createdAt: 'Boolean',
            dueRangeEnd: 'Boolean',
            dueRangeStart: 'Boolean',
            first: 'ID',
            statusClosedAt: 'Boolean',
            updatedAt: 'Boolean',
            workOrderNumber: 'Boolean',
        },
        search: {
            archivedAt: 'DateRangeSearchCommon',
            assignedTo: 'StaffNodeMatchSearchCommon',
            createdAt: 'DateRangeSearchCommon',
            createdBy: 'StaffNodeMatchSearchCommon',
            followUpFrom: 'WorkOrderNodeMatchSearchCommon',
            hasAppointment: 'Boolean',
            pool: 'PoolNodeMatchSearchCommon',
            serviceJob: 'WorkOrderViewerWorkOrdersSearchServiceJob',
            stage: 'ServiceJobStageNodeMatchSearchCommon',
            stageType: 'ServiceJobStageType',
            statusClosedAt: 'DateRangeSearchCommon',
            title: 'StringMatchSearchCommon',
            updatedAt: 'DateRangeSearchCommon',
            usedTemplate: 'WorkOrderTemplateNodeMatchSearchCommon',
            workFor: 'EntityNodeMatchSearchCommon',
            workOrderNumber: 'String',
        },
    },
    node: {
        address: {
            __typename: 'Address',
            buildingName: 'String',
            checkMutations: 'AddressMutationCheck',
            city: 'String',
            country: 'String',
            createdAt: 'Date',
            destinationName: 'String',
            entity: 'Entity',
            id: 'ID',
            isBill: 'Boolean',
            isPrimary: 'Boolean',
            poBoxNumber: 'String',
            postCode: 'String',
            refId: 'String',
            state: 'String',
            streetName: 'String',
            streetNumber: 'String',
            suburb: 'String',
            uuid: 'String',
        },
        appointmentItem: {
            __typename: 'AppointmentItem',
            address: 'Address',
            adhoc: 'Boolean',
            appointment: 'Appointment',
            checkMutations: 'AppointmentItemMutationCheck',
            color: 'String',
            customer: 'Customer',
            deleted: 'Boolean',
            duration: 'Int',
            franchise: 'Franchise',
            group: 'AppointmentGroup',
            id: 'ID',
            isEnd: 'Boolean',
            isRecurrentOrigin: 'Boolean',
            isRecurring: 'Boolean',
            isStart: 'Boolean',
            isWorkOrderPending: 'Boolean',
            note: 'String',
            originPattern: 'RecurrencePattern',
            originalDate: 'Date',
            patternBlockId: 'String',
            paymentUrl: 'String',
            pool: 'Pool',
            serviceJob: 'ServiceJob',
            splitAppointment: 'Appointment',
            staff: 'Staff',
            startDate: 'Date',
            status: 'AppointmentStatusEnum',
            statusMap: 'AppointmentStateMap',
            vendSale: 'VendAppointmentSaleType',
            workOrder: 'WorkOrder',
        },
        archivedAt: 'Date',
        assignedTo: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        checkMutations: {
            __typename: 'CheckMutations',
            addJobTodoFromTemplate: 'Boolean',
            addVendSale: 'Boolean',
            appendWorkOrderTemplate: 'Boolean',
            assignAppointmentItem: 'Boolean',
            delete: 'Boolean',
            replaceWorkOrderTemplate: 'Boolean',
            update: 'Boolean',
        },
        checkedInAt: 'Date',
        checkedOutAt: 'Date',
        createdAt: 'Date',
        createdBy: {
            __typename: 'Staff',
            archivedAt: 'Date',
            archivedBy: 'Staff',
            checkMutations: 'StaffMutationCheck',
            color: 'String',
            id: 'ID',
            isSystemHidden: 'Boolean',
            managedBy: 'Staff',
            registration: 'StaffRegistration',
            role: 'Role',
            root: 'Entity',
            user: 'User',
        },
        customer: {
            __typename: 'Customer',
            addedBy: 'Staff',
            assignedTo: 'Staff',
            billAddress: 'Address',
            checkMutations: 'CustomerMutationCheck',
            companyName: 'String',
            contactName: 'String',
            createdAt: 'Date',
            crn: 'String',
            id: 'ID',
            nextAppointment: 'AppointmentItem',
            note: 'String',
            primaryAddress: 'Address',
            primaryEmail: 'Contact',
            primaryPhone: 'Contact',
            refId: 'String',
            registeredAt: 'Date',
            root: 'Entity',
            user: 'User',
            vendLink: 'VendLink',
        },
        description: 'String',
        dueRangeEnd: 'Date',
        dueRangeStart: 'Date',
        followUpFrom: {
            __typename: 'WorkOrder',
            address: 'Address',
            appointmentItem: 'AppointmentItem',
            archivedAt: 'Date',
            assignedTo: 'Staff',
            checkMutations: 'WorkOrderMutationCheck',
            checkedInAt: 'Date',
            checkedOutAt: 'Date',
            createdAt: 'Date',
            createdBy: 'Staff',
            customer: 'Customer',
            description: 'String',
            dueRangeEnd: 'Date',
            dueRangeStart: 'Date',
            followUpFrom: 'WorkOrder',
            id: 'ID',
            paymentUrl: 'String',
            pool: 'Pool',
            preferredContact: 'Contact',
            requiresAddress: 'Boolean',
            requiresAppointment: 'Boolean',
            requiresAssignedTo: 'Boolean',
            requiresPool: 'Boolean',
            requiresPreferredContact: 'Boolean',
            requiresTestReport: 'Boolean',
            requiresWorkFor: 'Boolean',
            serviceJob: 'ServiceJob',
            stage: 'ServiceJobStage',
            stageCandidates: 'ServiceJobStage',
            statusClosedAt: 'Date',
            title: 'String',
            updatedAt: 'Date',
            usedTemplate: 'WorkOrderTemplate',
            workFor: 'Entity',
            workOrderNumber: 'String',
        },
        id: 'ID',
        paymentUrl: 'String',
        pool: {
            __typename: 'Pool',
            address: 'Address',
            attachedFlagFields: 'PoolAttachedFlagField',
            bottleNumber: 'String',
            checkMutations: 'PoolMutationCheck',
            createdAt: 'Date',
            dataOwnership: 'EntityOwnershipType',
            entity: 'Entity',
            franchise: 'Franchise',
            id: 'ID',
            installedSanitisers: 'Item',
            name: 'String',
            note: 'String',
            notePlain: 'String',
            refId: 'String',
            revision: 'Int',
            sanitiser: 'Sanitiser',
            site: 'Site',
            type: 'PoolType',
            uuid: 'String',
            volume: 'Float',
        },
        preferredContact: {
            __typename: 'Contact',
            checkMutations: 'ContactMutationCheck',
            createdAt: 'Date',
            data: 'String',
            entity: 'Entity',
            id: 'ID',
            isPrimary: 'Boolean',
            label: 'String',
            refId: 'String',
            type: 'ContactType',
            updatedAt: 'Date',
            uuid: 'String',
        },
        requiresAddress: 'Boolean',
        requiresAppointment: 'Boolean',
        requiresAssignedTo: 'Boolean',
        requiresPool: 'Boolean',
        requiresPreferredContact: 'Boolean',
        requiresTestReport: 'Boolean',
        requiresWorkFor: 'Boolean',
        serviceJob: {
            __typename: 'ServiceJob',
            address: 'Address',
            archivedAt: 'Date',
            checkMutations: 'ServiceJobMutationCheck',
            closedAt: 'Date',
            createdAt: 'Date',
            createdBy: 'Staff',
            customer: 'Customer',
            description: 'String',
            dueDate: 'Date',
            entity: 'Entity',
            followedFrom: 'ServiceJob',
            group: 'ServiceJobGroup',
            id: 'ID',
            implicitType: 'ServiceJobImplicitTypeEnum',
            isImplicit: 'Boolean',
            isRecurring: 'Boolean',
            isSimpleMode: 'Boolean',
            lastAppointment: 'Appointment',
            lastAppointmentDate: 'Date',
            lastRecurrentAppointment: 'Appointment',
            pool: 'Pool',
            preferredContact: 'Contact',
            serviceJobNumber: 'String',
            staff: 'Staff',
            stage: 'ServiceJobStage',
            stageCandidates: 'ServiceJobStage',
            stageStatus: 'ServiceJobStageType',
            stopsAt: 'Date',
            title: 'String',
            updatedAt: 'Date',
            usedTemplate: 'ServiceJobTemplate',
        },
        stage: {
            __typename: 'ServiceJobStage',
            archivedAt: 'Date',
            checkMutations: 'ServiceJobStageMutationCheck',
            color: 'String',
            createdAt: 'Date',
            franchise: 'Franchise',
            hidden: 'Boolean',
            id: 'ID',
            isDefault: 'Boolean',
            isFallback: 'Boolean',
            organisationType: 'OrganisationType',
            priority: 'Int',
            status: 'ServiceJobStageStatus',
            tag: 'String',
            title: 'String',
            type: 'ServiceJobStageType',
            updatedAt: 'Date',
        },
        stageCandidates: {
            __typename: 'ServiceJobStage',
            archivedAt: 'Date',
            checkMutations: 'ServiceJobStageMutationCheck',
            color: 'String',
            createdAt: 'Date',
            franchise: 'Franchise',
            hidden: 'Boolean',
            id: 'ID',
            isDefault: 'Boolean',
            isFallback: 'Boolean',
            organisationType: 'OrganisationType',
            priority: 'Int',
            status: 'ServiceJobStageStatus',
            tag: 'String',
            title: 'String',
            type: 'ServiceJobStageType',
            updatedAt: 'Date',
        },
        statusClosedAt: 'Date',
        title: 'String',
        updatedAt: 'Date',
        usedTemplate: {
            __typename: 'WorkOrderTemplate',
            addedBy: 'Staff',
            archivedAt: 'Date',
            checkMutations: 'WorkOrderTemplateMutationCheck',
            createdAt: 'Date',
            defaultStage: 'ServiceJobStage',
            franchise: 'Franchise',
            group: 'ServiceJobGroup',
            hideStageActionRequired: 'Boolean',
            hideStageInProgress: 'Boolean',
            hideStageSuspended: 'Boolean',
            id: 'ID',
            onlyOwnedStages: 'Boolean',
            organisationType: 'OrganisationType',
            ownedByServiceJobTemplate: 'ServiceJobTemplate',
            providedDescription: 'String',
            providedTitle: 'String',
            requiresAddress: 'Boolean',
            requiresContact: 'Boolean',
            requiresCustomer: 'Boolean',
            requiresPool: 'Boolean',
            requiresStaff: 'Boolean',
            stageReport: 'ServiceJobStageTemplateOwnership',
            tag: 'String',
            templateDescription: 'String',
            templateTitle: 'String',
            updatedAt: 'Date',
        },
        workFor: {
            __typename: 'Entity',
            active: 'Boolean',
            chainUpdate: 'Date',
            checkMutations: 'EntityMutationCheck',
            dataOwnership: 'EntityOwnershipType',
            franchise: 'Franchise',
            id: 'ID',
            lastReportActivity: 'Date',
            serviceJob: 'ServiceJob',
            shop: 'FranchiseShop',
            user: 'User',
            uuid: 'String',
            warrantyClaim: 'WarrantyClaim',
        },
        workOrderNumber: 'String',
    },
};

const __availableUIConfigs: { [keys: string]: any } = {
    abstractDocuments: {
        connectionId: 'abstractDocuments',
        uiConfig: uiConfigViewerAbstractDocumentsConnection,
    },
    announcements: {
        connectionId: 'announcements',
        uiConfig: uiConfigViewerAnnouncementsConnection,
    },
    appointmentGroups: {
        connectionId: 'appointmentGroups',
        uiConfig: uiConfigViewerAppointmentGroupsConnection,
    },
    appointmentItems: {
        connectionId: 'appointmentItems',
        uiConfig: uiConfigViewerAppointmentItemsConnection,
    },
    appointments: {
        connectionId: 'appointments',
        uiConfig: uiConfigViewerAppointmentsConnection,
    },
    brands: {
        connectionId: 'brands',
        uiConfig: uiConfigViewerBrandsConnection,
    },
    consumableProducts: {
        connectionId: 'consumableProducts',
        uiConfig: uiConfigViewerConsumableProductsConnection,
    },
    contactTypes: {
        connectionId: 'contactTypes',
        uiConfig: uiConfigViewerContactTypesConnection,
    },
    converters: {
        connectionId: 'converters',
        uiConfig: uiConfigViewerConvertersConnection,
    },
    customContracts: {
        connectionId: 'customContracts',
        uiConfig: uiConfigViewerCustomContractsConnection,
    },
    customerTags: {
        connectionId: 'customerTags',
        uiConfig: uiConfigViewerCustomerTagsConnection,
    },
    customers: {
        connectionId: 'customers',
        uiConfig: uiConfigViewerCustomersConnection,
    },
    emailGlobalTemplateSelections: {
        connectionId: 'emailGlobalTemplateSelections',
        uiConfig: uiConfigViewerEmailGlobalTemplateSelectionsConnection,
    },
    emailOrganisationTemplateSelections: {
        connectionId: 'emailOrganisationTemplateSelections',
        uiConfig: uiConfigViewerEmailOrganisationTemplateSelectionsConnection,
    },
    emailTemplateSelections: {
        connectionId: 'emailTemplateSelections',
        uiConfig: uiConfigViewerEmailTemplateSelectionsConnection,
    },
    emailTemplates: {
        connectionId: 'emailTemplates',
        uiConfig: uiConfigViewerEmailTemplatesConnection,
    },
    emailTypes: {
        connectionId: 'emailTypes',
        uiConfig: uiConfigViewerEmailTypesConnection,
    },
    entities: {
        connectionId: 'entities',
        uiConfig: uiConfigViewerEntitiesConnection,
    },
    ezcDeviceDefinitions: {
        connectionId: 'ezcDeviceDefinitions',
        uiConfig: uiConfigViewerEzcDeviceDefinitionsConnection,
    },
    ezcDeviceLinks: {
        connectionId: 'ezcDeviceLinks',
        uiConfig: uiConfigViewerEzcDeviceLinksConnection,
    },
    ezcDevices: {
        connectionId: 'ezcDevices',
        uiConfig: uiConfigViewerEzcDevicesConnection,
    },
    ezcServiceProviderLinks: {
        connectionId: 'ezcServiceProviderLinks',
        uiConfig: uiConfigViewerEzcServiceProviderLinksConnection,
    },
    ezcServiceProviders: {
        connectionId: 'ezcServiceProviders',
        uiConfig: uiConfigViewerEzcServiceProvidersConnection,
    },
    featureFlagVariations: {
        connectionId: 'featureFlagVariations',
        uiConfig: uiConfigViewerFeatureFlagVariationsConnection,
    },
    featureFlags: {
        connectionId: 'featureFlags',
        uiConfig: uiConfigViewerFeatureFlagsConnection,
    },
    files: {
        connectionId: 'files',
        uiConfig: uiConfigViewerFilesConnection,
    },
    franchiseSequenceStore: {
        connectionId: 'franchiseSequenceStore',
        uiConfig: uiConfigViewerFranchiseSequenceStoreConnection,
    },
    franchiseValuesStore: {
        connectionId: 'franchiseValuesStore',
        uiConfig: uiConfigViewerFranchiseValuesStoreConnection,
    },
    franchiseWarranters: {
        connectionId: 'franchiseWarranters',
        uiConfig: uiConfigViewerFranchiseWarrantersConnection,
    },
    franchises: {
        connectionId: 'franchises',
        uiConfig: uiConfigViewerFranchisesConnection,
    },
    heaterRecommendations: {
        connectionId: 'heaterRecommendations',
        uiConfig: uiConfigViewerHeaterRecommendationsConnection,
    },
    installableProducts: {
        connectionId: 'installableProducts',
        uiConfig: uiConfigViewerInstallableProductsConnection,
    },
    jobTodoTemplates: {
        connectionId: 'jobTodoTemplates',
        uiConfig: uiConfigViewerJobTodoTemplatesConnection,
    },
    locales: {
        connectionId: 'locales',
        uiConfig: uiConfigViewerLocalesConnection,
    },
    manualProblems: {
        connectionId: 'manualProblems',
        uiConfig: uiConfigViewerManualProblemsConnection,
    },
    measurementTypes: {
        connectionId: 'measurementTypes',
        uiConfig: uiConfigViewerMeasurementTypesConnection,
    },
    measurementUnits: {
        connectionId: 'measurementUnits',
        uiConfig: uiConfigViewerMeasurementUnitsConnection,
    },
    organisationTypes: {
        connectionId: 'organisationTypes',
        uiConfig: uiConfigViewerOrganisationTypesConnection,
    },
    permissions: {
        connectionId: 'permissions',
        uiConfig: uiConfigViewerPermissionsConnection,
    },
    poolBottleLeases: {
        connectionId: 'poolBottleLeases',
        uiConfig: uiConfigViewerPoolBottleLeasesConnection,
    },
    poolBottles: {
        connectionId: 'poolBottles',
        uiConfig: uiConfigViewerPoolBottlesConnection,
    },
    poolDeclarationFlagFields: {
        connectionId: 'poolDeclarationFlagFields',
        uiConfig: uiConfigViewerPoolDeclarationFlagFieldsConnection,
    },
    poolProductDeclarations: {
        connectionId: 'poolProductDeclarations',
        uiConfig: uiConfigViewerPoolProductDeclarationsConnection,
    },
    poolSanitisers: {
        connectionId: 'poolSanitisers',
        uiConfig: uiConfigViewerPoolSanitisersConnection,
    },
    poolTransfers: {
        connectionId: 'poolTransfers',
        uiConfig: uiConfigViewerPoolTransfersConnection,
    },
    poolTypes: {
        connectionId: 'poolTypes',
        uiConfig: uiConfigViewerPoolTypesConnection,
    },
    pools: {
        connectionId: 'pools',
        uiConfig: uiConfigViewerPoolsConnection,
    },
    productCompany: {
        connectionId: 'productCompany',
        uiConfig: uiConfigViewerProductCompanyConnection,
    },
    productDeclarations: {
        connectionId: 'productDeclarations',
        uiConfig: uiConfigViewerProductDeclarationsConnection,
    },
    productDocuments: {
        connectionId: 'productDocuments',
        uiConfig: uiConfigViewerProductDocumentsConnection,
    },
    products: {
        connectionId: 'products',
        uiConfig: uiConfigViewerProductsConnection,
    },
    projectHosting: {
        connectionId: 'projectHosting',
        uiConfig: uiConfigViewerProjectHostingConnection,
    },
    reports: {
        connectionId: 'reports',
        uiConfig: uiConfigViewerReportsConnection,
    },
    roleFlags: {
        connectionId: 'roleFlags',
        uiConfig: uiConfigViewerRoleFlagsConnection,
    },
    roles: {
        connectionId: 'roles',
        uiConfig: uiConfigViewerRolesConnection,
    },
    sampleSets: {
        connectionId: 'sampleSets',
        uiConfig: uiConfigViewerSampleSetsConnection,
    },
    sampleSources: {
        connectionId: 'sampleSources',
        uiConfig: uiConfigViewerSampleSourcesConnection,
    },
    sentEmails: {
        connectionId: 'sentEmails',
        uiConfig: uiConfigViewerSentEmailsConnection,
    },
    serviceJobGroups: {
        connectionId: 'serviceJobGroups',
        uiConfig: uiConfigViewerServiceJobGroupsConnection,
    },
    serviceJobStages: {
        connectionId: 'serviceJobStages',
        uiConfig: uiConfigViewerServiceJobStagesConnection,
    },
    serviceJobTemplates: {
        connectionId: 'serviceJobTemplates',
        uiConfig: uiConfigViewerServiceJobTemplatesConnection,
    },
    serviceJobs: {
        connectionId: 'serviceJobs',
        uiConfig: uiConfigViewerServiceJobsConnection,
    },
    settingStore: {
        connectionId: 'settingStore',
        uiConfig: uiConfigViewerSettingStoreConnection,
    },
    shops: {
        connectionId: 'shops',
        uiConfig: uiConfigViewerShopsConnection,
    },
    sites: {
        connectionId: 'sites',
        uiConfig: uiConfigViewerSitesConnection,
    },
    staff: {
        connectionId: 'staff',
        uiConfig: uiConfigViewerStaffConnection,
    },
    staffTeams: {
        connectionId: 'staffTeams',
        uiConfig: uiConfigViewerStaffTeamsConnection,
    },
    staffValuesStore: {
        connectionId: 'staffValuesStore',
        uiConfig: uiConfigViewerStaffValuesStoreConnection,
    },
    testCriteria: {
        connectionId: 'testCriteria',
        uiConfig: uiConfigViewerTestCriteriaConnection,
    },
    testProblems: {
        connectionId: 'testProblems',
        uiConfig: uiConfigViewerTestProblemsConnection,
    },
    testTreatmentGroupForFranchises: {
        connectionId: 'testTreatmentGroupForFranchises',
        uiConfig: uiConfigViewerTestTreatmentGroupForFranchisesConnection,
    },
    testTreatmentGroups: {
        connectionId: 'testTreatmentGroups',
        uiConfig: uiConfigViewerTestTreatmentGroupsConnection,
    },
    testTreatments: {
        connectionId: 'testTreatments',
        uiConfig: uiConfigViewerTestTreatmentsConnection,
    },
    timezones: {
        connectionId: 'timezones',
        uiConfig: uiConfigViewerTimezonesConnection,
    },
    unitConversions: {
        connectionId: 'unitConversions',
        uiConfig: uiConfigViewerUnitConversionsConnection,
    },
    unitConverters: {
        connectionId: 'unitConverters',
        uiConfig: uiConfigViewerUnitConvertersConnection,
    },
    universalCriteria: {
        connectionId: 'universalCriteria',
        uiConfig: uiConfigViewerUniversalCriteriaConnection,
    },
    users: {
        connectionId: 'users',
        uiConfig: uiConfigViewerUsersConnection,
    },
    warranterClaimMessages: {
        connectionId: 'warranterClaimMessages',
        uiConfig: uiConfigViewerWarranterClaimMessagesConnection,
    },
    warrantyClaimCases: {
        connectionId: 'warrantyClaimCases',
        uiConfig: uiConfigViewerWarrantyClaimCasesConnection,
    },
    warrantyClaims: {
        connectionId: 'warrantyClaims',
        uiConfig: uiConfigViewerWarrantyClaimsConnection,
    },
    workOrderFeed: {
        connectionId: 'workOrderFeed',
        uiConfig: uiConfigViewerWorkOrderFeedConnection,
    },
    workOrderTemplates: {
        connectionId: 'workOrderTemplates',
        uiConfig: uiConfigViewerWorkOrderTemplatesConnection,
    },
    workOrders: {
        connectionId: 'workOrders',
        uiConfig: uiConfigViewerWorkOrdersConnection,
    },
};

export const getAvailableUIConfigs = () => {
    return __availableUIConfigs;
};

export const getConnectionUIConfigById = (connectionId: keyof typeof __availableUIConfigs) => {
    return __availableUIConfigs[connectionId];
};
