import gql from 'graphql-tag';
import * as _ from 'lodash';
import { createUseQueryNodeHook, NodeType, useDefaultConnectionState, useQueryConnection } from '@poolware/api';

const featureFlagFragment = gql`
    fragment FeatureFlagFragment on FeatureFlag {
        id
        tag
        description
        variations {
            id
            value
            active
            tag
            organisation {
                id
                name
            }
            franchise {
                id
                name
            }
            role {
                id
                name
            }
        }
    }
`;

const ListQL = gql`
    query QueryFeatureFlagsConnection {
        viewer {
            featureFlags {
                edges {
                    node {
                        id
                        ...FeatureFlagFragment
                    }
                }
                pageInfo {
                    startCursor
                    hasPreviousPage
                    hasNextPage
                    endCursor
                }
            }
        }
    }
    ${featureFlagFragment}
`;

export interface UseQueryCustomerTagsConnectionProps {
    searchName?: string;
}

function mapPropsToSearchQuery(props?: UseQueryCustomerTagsConnectionProps) {
    if (!props) {
        return undefined;
    }

    const { searchName } = props;
    const search = {};
    if (searchName) {
        _.set(search, 'name.like', searchName);
    }
    return search;
}

export function useQueryFeatureFlagsConnection(props?: UseQueryCustomerTagsConnectionProps) {
    const connectionPath = 'viewer.featureFlags';
    const { variables, connectionState } = useDefaultConnectionState({
        defaultPageSize: 20,
        search: mapPropsToSearchQuery(props),
    });

    return useQueryConnection<NodeType.FeatureFlag>({
        query: ListQL,
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
}

const SingleQL = gql`
    query QueryFeatureFlag($id: ID!) {
        node(id: $id) {
            ...FeatureFlagFragment
        }
    }
    ${featureFlagFragment}
`;

export const useQueryFeatureFlag = createUseQueryNodeHook<NodeType.FeatureFlag>(SingleQL);
