import * as Yup from 'yup';
import * as React from 'react';
import {
    FormikDefaultForm,
    FormikDefaultFormProps,
    FormikInputField,
    FormikRadioField,
    FormikTextareaField,
    FormSectionBody,
    FormSectionHeader,
    Panel,
} from '@ez/components';
import { getIn } from 'formik';
import {
    FormikSuggestInputFranchise,
    FormikSuggestInputOrganisationType,
    FormikSuggestInputRole,
} from '@poolware/app-shell';

const validationSchema = Yup.object().shape({
    tag: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Required'),
    value: Yup.string().max(100, 'Too Long!').notRequired().nullable(),
});

export enum FeatureFlagValueType {
    SET = 'set',
    UNSET = 'unset',
}

const valueTypeOptions = [
    {
        key: FeatureFlagValueType.SET,
        text: 'Set',
        value: FeatureFlagValueType.SET,
    },
    {
        key: FeatureFlagValueType.UNSET,
        text: 'Unset',
        value: FeatureFlagValueType.UNSET,
    },
];

const defaultInitialValues = {
    tag: '',
    valueType: FeatureFlagValueType.SET,
    value: '',
    franchise: null,
    organisation: null,
    role: null,
};
export const FormNewFeatureFlagVariation: React.FC<FormikDefaultFormProps & { tagReadOnly?: boolean }> = (props) => {
    return (
        <FormikDefaultForm
            debug={false}
            validationSchema={validationSchema}
            header={'Feature Flag'}
            submitButton={{ content: 'Submit' }}
            initialValues={defaultInitialValues}
            {...props}
        >
            {({ values }) => {
                const valueType = getIn(values, 'valueType');
                return (
                    <>
                        <FormikInputField label={'Tag'} name={'tag'} readOnly={props.tagReadOnly} required={true} />
                        <FormikRadioField inline={true} label={'Value'} name={'valueType'} options={valueTypeOptions} />
                        {valueType === 'set' && <FormikInputField label={'Value'} name={'value'} />}
                        <FormSectionHeader>Targeting</FormSectionHeader>
                        <FormSectionBody>
                            <FormikSuggestInputOrganisationType label={'Organisation'} name={'organisation'} />
                            <FormikSuggestInputFranchise label={'Franchise'} name={'franchise'} />
                            <FormikSuggestInputRole label={'Role'} name={'role'} />
                        </FormSectionBody>
                    </>
                );
            }}
        </FormikDefaultForm>
    );
};

export const FormUpdateFeatureFlagVariation: React.FC<FormikDefaultFormProps & { tagReadOnly?: boolean }> = (props) => {
    return (
        <FormikDefaultForm
            debug={false}
            validationSchema={validationSchema}
            header={'Feature Flag'}
            submitButton={{ content: 'Submit' }}
            {...props}
        >
            {({ values }) => {
                const valueType = getIn(values, 'valueType');
                return (
                    <>
                        <Panel.Item label={'Tag'}>{props.initialValues?.tag}</Panel.Item>
                        <Panel.Item label={'Org'}>{props.initialValues?.organisation?.name}</Panel.Item>
                        <Panel.Item label={'Franchise'}>{props.initialValues?.franchise?.name}</Panel.Item>
                        <Panel.Item label={'Role'}>{props.initialValues?.role?.name}</Panel.Item>
                        <Panel.Divider />
                        <FormikRadioField
                            inline={true}
                            label={'Value Type'}
                            name={'valueType'}
                            options={valueTypeOptions}
                        />
                        {valueType === 'set' && <FormikInputField label={'Value'} name={'value'} />}
                    </>
                );
            }}
        </FormikDefaultForm>
    );
};

export const FormEditDescription: React.FC<FormikDefaultFormProps> = (props) => {
    return (
        <FormikDefaultForm
            debug={false}
            validationSchema={validationSchema}
            header={'Feature Flag'}
            submitButton={{ content: 'Submit' }}
            {...props}
        >
            <Panel.Item label={'Tag'}>{props.initialValues?.tag}</Panel.Item>
            <Panel.Divider />
            <FormikTextareaField rows={6} label={'Description'} name={'description'} />
        </FormikDefaultForm>
    );
};

export const prepareMutationCreateVariation = (values) => {
    const isUnset = values?.valueType === FeatureFlagValueType.UNSET;
    return {
        tag: values.tag?.trim() || '',
        value: isUnset ? null : values.value?.trim() || '',
        franchise: values.franchise?.id || null,
        organisation: values.organisation?.id || null,
        role: values.role?.id || null,
    };
};

export const prepareMutationUpdateVariation = (values) => {
    const isUnset = values?.valueType === FeatureFlagValueType.UNSET;
    return {
        id: values.id,
        value: isUnset ? null : values.value?.trim() || '',
    };
};
