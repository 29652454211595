import {
    confirmModalImperative,
    ConfirmProps,
    Display,
    IconButton,
    Modal,
    Panel,
    toastError,
    useModalCtrl,
} from '@ez/components';
import * as React from 'react';
import {
    fromEdges,
    NodeType,
    useMutationStaffAccessToken,
    useMutationStaffAccessTokenRegistration,
} from '@poolware/api';
import { mutateStaffAccessTokenRegistrationQuery } from '../FranchiseApiKeys/use-query-franchises-connection';
import { PanelFranchiseApiKeys } from '../FranchiseApiKeys/PanelFranchiseApiKeys';

const confirmDelete: ConfirmProps = {
    cancelButton: { content: 'Keep' },
    negative: true,
    confirmButton: {
        content: 'Delete',
        icon: 'trash',
    },
    confirmMessage: {
        content: 'Delete API key?',
    },
};

const confirmCreate: ConfirmProps = {
    confirmButton: {
        content: 'Create',
    },
    confirmMessage: {
        content: 'Create API key?',
    },
};

const confirmRegen: ConfirmProps = {
    confirmButton: {
        content: 'Regenerate',
    },
    confirmMessage: {
        content: 'Current API Key will be invalidated, and new key will be created?',
    },
};

export const PanelAccessKeys: React.FC<{ franchise: NodeType.Franchise; refetchQueries: any[] }> = ({
    franchise,
    refetchQueries,
}) => {
    const modalCtrl = useModalCtrl();
    const { resolveAndRefreshManagerToken } = useMutationStaffAccessTokenRegistration({
        refetchQueries: refetchQueries,
        mutation: mutateStaffAccessTokenRegistrationQuery,
    });
    const { delete: deleteToken } = useMutationStaffAccessToken({ refetchQueries: refetchQueries });

    const tokens = fromEdges(franchise.staffAccessTokens);

    const onRegenerate = async () => {
        const res = await confirmModalImperative(confirmRegen);
        if (!res) {
            return;
        }
        await resolveAndRefreshManagerToken({ franchise: franchise.id });
    };

    const onDelete = async (token: NodeType.StaffAccessToken) => {
        const res = await confirmModalImperative(confirmDelete);
        if (!res) {
            return;
        }
        await deleteToken({ id: token.id });
    };

    const onCreate = async () => {
        const res = await confirmModalImperative(confirmCreate);
        if (!res) {
            return;
        }
        await resolveAndRefreshManagerToken({ franchise: franchise.id });
    };

    const headerButton =
        tokens?.length === 0
            ? {
                  content: 'Add API Key',
                  onClick: onCreate,
              }
            : undefined;

    return (
        <Panel>
            <Panel.Header button={headerButton}>Access Tokens</Panel.Header>
            <Panel.Body>
                {tokens.map((t, i) => {
                    const tokenString = t.token;
                    return (
                        <div key={i}>
                            <Panel.SectionHeader
                                button={{
                                    content: 'Edit',
                                    menuItems: [
                                        {
                                            content: 'Regenerate Token',
                                            icon: 'refresh',
                                            onClick: () => onRegenerate(),
                                        },
                                        {
                                            content: 'Delete',
                                            icon: 'trash',
                                            onClick: () => onDelete(t),
                                        },
                                    ],
                                }}
                            >
                                Token
                            </Panel.SectionHeader>
                            <Panel.ItemEntity label={'Staff'} content={t.staff} />
                            <Panel.Item label={'Role'} content={t.staff?.role?.name || '--'} />
                            <Panel.Item label={'Expires'}>
                                <Display.Date value={t.expiresAt} defaultString={'Never'} />
                            </Panel.Item>
                            <Panel.Item wrap={false} label={'Token'}>
                                <div className={'break-words break-all p-2 rounded bg-tertiary'}>
                                    {tokenString}
                                    <CopyToClipboardButton value={tokenString} />
                                </div>
                            </Panel.Item>
                        </div>
                    );
                })}
                {modalCtrl.open && (
                    <Modal {...modalCtrl} closeIcon={true}>
                        <Modal.Content>
                            <PanelFranchiseApiKeys id={franchise?.id} />
                        </Modal.Content>
                    </Modal>
                )}
            </Panel.Body>
        </Panel>
    );
};

const CopyToClipboardButton: React.FC<{ value: string }> = ({ value }) => {
    const onCopy = async () => {
        try {
            if (!window.isSecureContext) {
                console.warn('Clipboard API is not available in insecure context');
                return null;
            }
            await window.navigator?.clipboard?.writeText(value);
        } catch (e) {
            toastError(e);
        }
    };

    // clipboard only work in secure context (i.e. https)
    return <IconButton icon={'clipboard'} size={'xxs'} onClick={onCopy} />;
};
