import * as React from 'react';
import _truncate from 'lodash/truncate';
import { Display, Icon, Popup, ViewJSON } from '@ez/components';
import { FieldTypeRenderName } from './types';

const DisplaySummary: React.FC<{ summary; content }> = ({ summary, content }) => {
    return (
        <details open={false} className={'w-full max-w-screen-sm'}>
            <summary className={'cursor-pointer'}>{summary}</summary>
            {content}
        </details>
    );
};

const DisplayBoolean: React.FC<{ value?: boolean }> = ({ value }) => {
    return !!value ? <Icon name={'check square'} /> : <Icon name={'square outline'} />;
};

function linkify(text) {
    var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return text.replace(urlRegex, function (url) {
        return '<a href="' + url + '">' + url + '</a>';
    });
}

function validURL(str) {
    var pattern = new RegExp(
        '^(https?:\\/\\/)' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
        'i'
    ); // fragment locator
    return !!pattern.test(str);
}

function isImageURL(url) {
    var imageReg = /[\/.](gif|jpg|jpeg|tiff|png)$/i;
    return !!imageReg.test(url);
}

const DisplayString: React.FC<{ value: string; full?: boolean }> = ({ value, full }) => {
    if (!full) {
        return <span>{_truncate(value || '', { length: 40 })}</span>;
    }

    if (validURL(value)) {
        if (isImageURL(value)) {
            return (
                <DisplaySummary
                    summary={value}
                    content={
                        <div
                            className={
                                'w-full flex flex-row justify-center shadow-inner p-8 my-4 border rounded-md  bg-gray-400'
                            }
                        >
                            <a href={value} target="_blank">
                                <img src={value} />
                            </a>
                        </div>
                    }
                />
            );
        }
        return (
            <a href={value} target={'_blank'} rel="noopener noreferrer">
                {value}
            </a>
        );
    }
    return <span>{value}</span>;
};

export const getFieldRenderer =
    (fieldConf: FieldTypeRenderName | string | any, full?: boolean): React.FC<{ value; expandNodes?: boolean }> =>
    ({ value, expandNodes = false }) => {
        if (!fieldConf) return <span className={'text-red-600'}>conf err.</span>;
        if (value === null) return <span className={'text-gray-400'}>null</span>;
        if (value === undefined) return <>undefined</>;

        // Handle basic types first (i.e. GraphQLScalarType)
        switch (fieldConf) {
            case FieldTypeRenderName.ID: {
                if (full) {
                    return <span>{value}</span>;
                } else {
                    return (
                        <Popup
                            hoverable={true}
                            content={value}
                            trigger={<span className={'cursor-pointer text-blue-900'}>ID</span>}
                        />
                    );
                }
            }
            case FieldTypeRenderName.Boolean:
                return <DisplayBoolean value={value} />;
            case FieldTypeRenderName.Date:
                return <Display.Date value={value} />;
            case FieldTypeRenderName.Enum:
            case FieldTypeRenderName.String:
                return <DisplayString value={value} full={full} />;
            case FieldTypeRenderName.Int:
                return <span>{value}</span>;
        }

        // Handle object type (i.e.GraphQLObjectType)
        const _typename = fieldConf['__typename'];

        let label: any = '';
        let customLabel = true;
        // Custom presentation overrides
        if (_typename) {
            switch (_typename) {
                case 'AppointmentItem':
                    label = <Display.Date value={value?.startDate} />;
                    break;
                case FieldTypeRenderName.Staff:
                    label = (
                        <span>
                            <Display.Entity value={value} /> | {value?.role?.name}
                        </span>
                    );
                    break;
                case FieldTypeRenderName.Entity:
                    label = <Display.Entity value={value} />;
                    break;
                case FieldTypeRenderName.Address:
                    label = <Display.Address value={value} />;
                    break;
                case FieldTypeRenderName.Customer:
                    label = <span>{value.crn}</span>;
                    break;
                case FieldTypeRenderName.CheckMutations: {
                    return (
                        <span>
                            {Object.keys(value).map((key) => {
                                if (key === '__typename') {
                                    return null;
                                }
                                return (
                                    <span key={key}>
                                        {key}
                                        <DisplayBoolean value={value[key]} />
                                    </span>
                                );
                            })}
                        </span>
                    );
                }
                case FieldTypeRenderName.Franchise:
                default:
                    label = value['name'] || value['title'] || 'node';
                    label = _truncate(label, { length: 40 });
                    customLabel = false;
            }
        }

        if (expandNodes) {
            return <DisplaySummary summary={label} content={<ViewJSON data={value} />} />;
        }

        return (
            <Popup
                size={'small'}
                position={'bottom center'}
                hoverable={true}
                trigger={<span className={'cursor-pointer text-blue-900'}>[{label}]</span>}
            >
                <Popup.Content>
                    <div className={'w-96'}>
                        <ViewJSON data={value} />
                    </div>
                </Popup.Content>
            </Popup>
        );
    };
