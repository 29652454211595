import * as React from 'react';

import {
    confirmModalImperative,
    ConnectionErrorMessage,
    Container,
    FormikDefaultForm,
    FormikInputField,
    PageSkeletonLoader,
    Panel,
    toastError,
} from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useMutationServiceJobGroup } from '@poolware/api';
import { useQueryServiceJobGroup } from './use-query-service-job-group';
import { NotFoundPage } from '@poolware/app-shell';

export interface EditProps {}

export const Edit: React.FC<EditProps> = ({}) => {
    const { AppNavigator, params } = useAppNavigator();

    const { loading, error, node, refetchQuery } = useQueryServiceJobGroup(params.id);
    const { update, delete: deleteGroup } = useMutationServiceJobGroup({ refetchQueries: [refetchQuery] });
    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    } else if (!loading && !node) {
        return <NotFoundPage />;
    }

    const initialValues = {
        title: node?.title,
    };

    const onCancel = () => {
        AppNavigator.replaceToOrigin('/', { relativeToModule: true });
    };

    const onDelete = async () => {
        const isYes = await confirmModalImperative({
            confirmMessage: {
                header: 'Delete Group?',
                content: 'There is NO UNDO!!',
            },
            cancelButton: {
                content: 'Keep',
            },
            negative: true,
            confirmButton: {
                content: 'Delete Group',
                icon: 'trash',
            },
        });
        if (!isYes) {
            return;
        }

        try {
            await deleteGroup({ id: node.id });
            AppNavigator.replaceToOrigin();
        } catch (e) {
            toastError(e);
        }
    };

    const onSubmit = async (values: typeof initialValues) => {
        try {
            const res = await update({
                id: node.id,
                title: values.title?.trim() || '',
            });
            AppNavigator.replaceToOrigin('/', { relativeToModule: true });
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    return (
        <Container>
            <FormikDefaultForm
                header={'Service Group'}
                initialValues={initialValues}
                submitButton={{ content: 'Update' }}
                onSubmit={onSubmit}
                onCancel={onCancel}
                headerButton={{
                    content: 'Delete',
                    icon: 'trash',
                    onClick: onDelete,
                }}
            >
                <Panel.Item label={'Organisation'} content={node?.organisationType?.name} />
                <Panel.Item label={'Franchise'} content={node?.franchise?.name} />
                <Panel.Divider />
                <FormikInputField label={'Title'} name={'title'} />
            </FormikDefaultForm>
        </Container>
    );
};
