import Page from './Page';
import * as _ from 'lodash';
import { compose, mapProps } from '@ez/tools';
import { withApolloLoading } from '@ez/components';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import { IProjectMutatorsProps, NodeType, withProjectMutators } from '@poolware/api';

const QL = gql`
    query QueryProjectVersionDetails($versionId: ID!) {
        version: node(id: $versionId) {
            id
            ... on ProjectVersion {
                id
                releaseNumber
                versionNumber
                isPinned
                changelogSummary
                description
                enabled
                enabledFrom
                requirements
                fileURL
                remoteFileURL
                project {
                    id
                }
            }
        }
    }
`;

export default compose(
    graphql(QL, {
        options: (props) => ({
            variables: { versionId: _.get(props, 'match.params.versionId') },
        }),
    }),
    withApolloLoading({ show404ForPath: 'data.version' }),
    mapProps((props) => {
        let version = _.get(props, 'data.version');
        return {
            ...props,
            version,
        };
    }),
    withAppNavigator(),
    withProjectMutators(['QueryProjectDetails', 'QueryProjectVersionDetails'])
)(Page);

export interface PageControl extends IAppNavigatorProps, IProjectMutatorsProps {
    version: NodeType.ProjectVersion;
}
