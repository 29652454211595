import * as React from 'react';
import { AppSwitcherMenuItems, DropdownMenuAccount, useViewer } from '@poolware/app-shell';
import { Icon, Navbar } from '@ez/components';

const TopMenuAuthenticated: React.FC = (props) => {
    const { isAuthenticated } = useViewer();
    if (!isAuthenticated) {
        return null;
    }
    return (
        <Navbar.Root {...(props as any)}>
            <Navbar.Section position="left">
                <Navbar.Item>
                    <Icon name={'spy'} color={'yellow'} /> Admin Console
                </Navbar.Item>
            </Navbar.Section>
            <Navbar.Section position="right">
                <AppSwitcherMenuItems />
                <DropdownMenuAccount />
            </Navbar.Section>
        </Navbar.Root>
    );
};

export default TopMenuAuthenticated;
