import * as React from 'react';
import { NodeType, useMutationFranchise } from '@poolware/api';
import { FormikCheckboxField, FormikDefaultForm, Modal, Panel, toastError } from '@ez/components';

export const FranchisePropEditorModal: React.FC<{ onClose; franchise: NodeType.Franchise; refetchQuery }> = ({
    onClose,
    franchise,
    refetchQuery,
}) => {
    const { update } = useMutationFranchise({ refetchQueries: [refetchQuery] });

    if (!franchise) {
        return null;
    }

    const initialValues = {
        ...franchise,
    };

    const onSubmit = async (values: typeof initialValues) => {
        try {
            await update({
                id: franchise.id,
                contactExport: values.contactExport,
            });
            onClose();
        } catch (e) {
            toastError(e);
        }
    };

    return (
        <Modal open={Boolean(franchise)} onClose={onClose} centered={false}>
            <FormikDefaultForm
                header={'Franchise'}
                activateOnDirty={true}
                submitButton={{ content: 'Update' }}
                initialValues={initialValues}
                onCancel={onClose}
                onSubmit={onSubmit}
            >
                <Panel.Item label={'Franchise'} content={franchise.name} />
                <Panel.Item label={'Type'} content={franchise.types?.join(', ')} />
                <Panel.Divider />
                <Panel.Item label={'Exportable'}>
                    <FormikCheckboxField name={'contactExport'} label={'Include in bulk contact export '} />
                </Panel.Item>
            </FormikDefaultForm>
        </Modal>
    );
};
