import { NodeType, QueryConnectionConfigProps, useDefaultConnectionState, useQueryConnection } from '@poolware/api';
import gql from 'graphql-tag';

const QL = gql`
    query QuerySettingStore($pageSize: Int, $page: Int) {
        viewer {
            settingStore(first: $pageSize, page: $page) {
                edges {
                    node {
                        id
                        name
                        value
                        group
                    }
                }
                pageMeta {
                    totalCount
                    pageCount
                }
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                }
            }
        }
    }
`;

export interface QuerySettingStoreConnectionProps extends Partial<QueryConnectionConfigProps> {}

const mapPropsToSearchQuery = (props: QuerySettingStoreConnectionProps): NodeType.ServiceJobViewerServiceJobsSearch => {
    return {};
};

export function useQuerySettingStoreConnection(props?: QuerySettingStoreConnectionProps) {
    const connectionPath = 'viewer.settingStore';
    const { variables, connectionState } = useDefaultConnectionState({
        ...props,
    });

    variables.search = mapPropsToSearchQuery(props);

    return useQueryConnection({
        query: QL,
        // fetchPolicy: 'cache-first',
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
}
