import * as React from 'react';
import { ModuleRootSwitch, ModuleRoute } from '@poolware/react-app-navigator';
import { PageList } from './Page.List';
import { PageNew } from './Page.New';
import { PageEdit } from './Page.Edit';

export const Router: React.FC = () => {
    return (
        <ModuleRootSwitch>
            <ModuleRoute path={'/'} exact={true} component={PageList} />
            <ModuleRoute path={'/new'} component={PageNew} />
            <ModuleRoute path={'/:id/edit'} component={PageEdit} />
        </ModuleRootSwitch>
    );
};
