import * as React from 'react';
import { PageControl } from './Page.Control';
import RoleFlagsTable from './RoleFlagsTable';
import RoleFlagAssignmentTable from './RoleFlagAssignmentTable';
import { FranchiseList } from './FranchiseFlags/Franchise.List';
import { Alert, Icon, Tab } from '@ez/components';

class Page extends React.Component<PageControl> {
    renderRolesAssignedFlags = () => {
        const { roles, roleFlags } = this.props;
        return (
            <React.Fragment>
                <h3>Global UI flags</h3>
                <p>These flags are applied to all roles across the whole system.</p>

                <RoleFlagAssignmentTable
                    RoleFlagMutator={this.props.RoleFlagMutator}
                    roleFlags={roleFlags}
                    roles={roles}
                />

                <div className={'my-2'} />

                <Alert type={'success'}>
                    <p>
                        <Icon color={'yellow'} name={'warning sign'} /> These flags are used to enable/disable features
                        on the frontend UI.
                    </p>
                    <p>
                        These are <b>NOT</b> permission flags - they are NOT enforcing any API restrictions. They only
                        control visual layout of the frontend.
                    </p>
                </Alert>
            </React.Fragment>
        );
    };

    renderAllFlags = () => {
        const { roleFlags } = this.props;
        return <RoleFlagsTable roleFlags={roleFlags} RoleFlagMutator={this.props.RoleFlagMutator} />;
    };

    renderFranchiseOverwrite = () => {
        const { roleFlags } = this.props;
        return (
            <div>
                <FranchiseList />
            </div>
        );
    };

    render() {
        const panes = [
            { menuItem: 'Global Roles & Flags', render: () => <Tab.Pane>{this.renderRolesAssignedFlags()}</Tab.Pane> },
            { menuItem: 'Franchise Overwrites', render: () => <Tab.Pane>{this.renderFranchiseOverwrite()}</Tab.Pane> },
            { menuItem: 'UI Flags', render: () => <Tab.Pane>{this.renderAllFlags()}</Tab.Pane> },
        ];

        return (
            <div>
                <h1>UI Flags</h1>
                <Tab defaultActiveIndex={2} menu={{ color: 'red' }} panes={panes} />
            </div>
        );
    }
}

export default Page;
